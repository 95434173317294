import './NavButton.scss';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';

export interface NavButtonProps {
  isClose?: boolean;
  isBack?: boolean;
  onClose?: () => void;
  onBack?: () => void;
}

interface NavButtonLocalProps extends NavButtonProps {
  className?: string;
}

export const NavButton = ({
  isClose,
  isBack = true,
  onClose,
  onBack,
  className,
}: NavButtonLocalProps): JSX.Element => {
  const { setSelectedConvoSid } = useLayoutContext();

  const navButtonCls = classNames('conversation-nav-button', className);

  return (
    <>
      {isBack && (
        <Button
          data-testid='conversation-nav-btn'
          className={navButtonCls}
          size='small'
          icon={<ArrowLeftOutlined className='conversation-nav-button__icon' />}
          onClick={() => (onBack ? onBack() : setSelectedConvoSid(null))}
        />
      )}
      {!isBack && isClose && onClose && (
        <Button
          data-testid='conversation-nav-btn'
          className={navButtonCls}
          size='small'
          icon={<CloseOutlined className='conversation-nav-button__icon' />}
          onClick={onClose}
        />
      )}
    </>
  );
};
