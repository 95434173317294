import { Partner } from '@inovirtue/admin';
import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getPartners } from '../api/partnersApi';

export const usePartnersList = () => {
  const fetchPartners = useCallback(() => getPartners(), []);

  const { data, isProcessing } = usePromiseWithProgress<Partner[]>(fetchPartners);

  return {
    partners: data,
    isProcessing,
  };
};
