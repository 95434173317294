import { Form, Layout } from 'antd';
import { Popover, Button } from '@inovirtue/components';
import './InviteEmployees.scss';
import { t } from '@inovirtue/localization';
import { Content } from 'antd/lib/layout/layout';
import { Trans } from 'react-i18next';
import CompaniesSelect from '../../../Companies/components/CompaniesSelect/CompaniesSelect';
import { AddInvitationForm, AddedInvitationsList } from '@inovirtue/admin';
import useInvite from '../../hooks/useInvite/useInvite';
import AddPositionButton from '../../../Positions/components/AddPositionsButton/AddPositionButton';
import AddDepartmentButton from '../../../../Modules/Departments/components/AddDepartmentsButton/AddDepartmentButton';

const InviteEmployees = (): JSX.Element => {
  const {
    choosenCompany,
    setChoosenCompany,
    addInvitation,
    invitationList,
    removeInvitation,
    positions,
    departments,
    submitSendInvitations,
    clearInvitationsList,
    fetchPositions,
    submitPositions,
    fetchDepartments,
    submitDepartments,
    onClose,
  } = useInvite();
  return (
    <Popover
      onClose={onClose}
      isFullScreen
      actions={[
        <Button
          data-testid='clearInvitationsList'
          type='default'
          key='clearList'
          disabled={invitationList.length === 0}
          onClick={() => clearInvitationsList()}
        >
          {t('common:clear-list')}
        </Button>,
        <Button
          data-testid='sendInvitations'
          type='primary'
          key='sendInv'
          disabled={invitationList.length === 0}
          onClick={() => submitSendInvitations()}
        >
          {t('cockpit:add-employees.send-invite-email')}
        </Button>,
      ]}
    >
      <Layout className='invite-employees'>
        <Content>
          <header className='invite-employees-header'>
            <h1 className='invite-employees-header__title'> {t('cockpit:add-employees:title')}</h1>
            <p className='invite-employees-header__info'>
              <Trans i18nKey='cockpit:add-employees.info' />
            </p>
          </header>
          <div className='invite-employees-content'>
            <Form.Item
              className='invite-employees-content__company-select'
              label={t('company:employees:invitations-table:company')}
              required
            >
              <CompaniesSelect
                data-testi='select-company'
                value={choosenCompany}
                onChange={setChoosenCompany}
                disabled={Boolean(invitationList.length)}
              />
            </Form.Item>
            <AddInvitationForm
              positions={positions}
              departments={departments}
              disbaleInputs={!choosenCompany}
              addInvitation={addInvitation}
              positionsDropdownElements={
                <AddPositionButton
                  block
                  submitPositions={submitPositions}
                  companyId={choosenCompany as string}
                  fetchPositions={fetchPositions}
                  positions={positions}
                />
              }
              departmentsDropdownElements={
                <AddDepartmentButton
                  block
                  submitDepartments={submitDepartments}
                  companyId={choosenCompany as string}
                  fetchDepartments={fetchDepartments}
                  departments={departments}
                />
              }
              invitationsList={invitationList}
              className='invite-employees__add-form'
            />
            <AddedInvitationsList
              title={t('cockpit:add-employees.employees-list-title')}
              className='invite-employees__employees-list'
              employeesList={invitationList}
              removeAction={removeInvitation}
              removeLabel={t('common:remove')}
              emptyListText={t('cockpit:add-employees.empty-list')}
              positions={positions}
              departments={departments}
            />
          </div>
        </Content>
      </Layout>
    </Popover>
  );
};

export default InviteEmployees;
