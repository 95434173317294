import React from 'react';
import { Menu } from 'antd';

import './TableTabs.scss';

export type TableTabsItemProps = {
  key: string;
  label: string | React.ReactNode;
};

export type TableTabsProps = {
  currentTab: string;
  navigateAction(path: string): void;
  tabs: TableTabsItemProps[];
};

export const TableTabs = ({ currentTab, navigateAction, tabs }: TableTabsProps): JSX.Element => {
  return (
    <Menu
      mode='horizontal'
      onClick={(x) => navigateAction(x.key)}
      activeKey={currentTab}
      className='inovirtue-table-tabs'
    >
      {tabs.map((t: TableTabsItemProps) => (
        <Menu.Item
          key={t.key}
          className={`inovirtue-table-tabs__item ${
            t.key === currentTab && 'inovirtue-table-tabs__item--active'
          }`}
        >
          {t.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};
