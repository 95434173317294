import { useMemo } from 'react';
import { Workshop } from '../model/Workshop';

export const useCanPublishWorkshop = (workshop?: Workshop) => {
  const canPublish = useMemo<boolean>(() => {
    if (!workshop) {
      return false;
    }
    return (
      workshop.status === 'Unpublished' &&
      ((workshop.membersCount != undefined && workshop.membersCount > 0) as boolean) &&
      ((workshop.scheduledSessionsCount != undefined &&
        workshop.scheduledSessionsCount > 0) as boolean)
    );
  }, [workshop]);

  const showPublish = useMemo<boolean>(() => {
    if (!workshop) {
      return false;
    }
    return workshop.status === 'Unpublished';
  }, [workshop]);

  return { canPublish, showPublish };
};
