import React from 'react';
import { Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import './SignPostButton.Component.scss';
import { Button } from '../Button/Button';
const { Title } = Typography;

export type SignPostButtonProps = {
  onClick(): void;
  leftIcon?: JSX.Element;
  title: string;
  description: string;
};

export const SignPostButton = ({
  onClick,
  leftIcon,
  title,
  description,
}: SignPostButtonProps): JSX.Element => (
  <Button className='signPostButton' onClick={onClick}>
    {leftIcon && <div className='leftIcon'>{leftIcon}</div>}
    <div className='buttonContent'>
      <Title level={5}>{title}</Title>
      <p>{description}</p>
    </div>
    <div className='rightIcon'>
      <RightOutlined />
    </div>
  </Button>
);
