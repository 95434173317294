import { isEmpty } from 'lodash';
import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import Provider from '../../Providers/models/Provider';
import { Consumer } from '@inovirtue/admin';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getConsumers = async (
  pageIndex: number,
  searchString?: string,
  searchCompanyId?: string,
): Promise<Consumer[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Consumer[]>(`/Profiles/consumers`, {
    params: {
      pageIndex,
      pageSize: config.pagination.pageSize,
      searchString,
      companyId: searchCompanyId,
    },
  });
  return data;
};

export const getConsumer = async (profileName: string): Promise<Consumer> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Consumer>(`/Profiles/consumers/${profileName}`);
  return data;
};

export const updateConsumer = async (
  profileName: string,
  allowedNumberOfSessions: number,
  allowedNumberOfGroupSessions: number,
  recommendedProfileNames: string[],
  companyId?: string,
): Promise<void> => {
  const apiClient = await getApiClientAsync();
  await apiClient.put(`/Consumers/${profileName}`, {
    profileName,
    companyId,
    allowedNumberOfSessions,
    allowedNumberOfGroupSessions,
    recommendedProfileNames,
  });
};

export const setAllowedNumberOfSessions = async (
  profileName: string,
  allowedNumberOfSessions: number,
): Promise<void> => {
  const apiClient = await getApiClientAsync();
  await apiClient.put(`/profiles/${profileName}/subscription/allowed-number-of-sessions`, {
    allowedNumberOfSessions,
  });
};

export const getProfileInterests = async (profileName: string): Promise<string[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<string[]>(`/Profiles/${profileName}/interests`);
  return data;
};

export const getProvidersForConsumer = async (
  profileName: string,
  pageIndex: number,
  searchString?: string,
): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();
  const params: { pageIndex: number; pageSize: number; searchString?: string } = {
    pageIndex,
    pageSize: 1000,
  };

  if (!isEmpty(searchString)) params.searchString = searchString;

  const { data } = await axiosInstance.get<Provider[]>(
    `/Profiles/${profileName}/providers/interests`,
    {
      params,
    },
  );
  return data;
};
