import { createQuery } from '@datorama/akita';
import { ParticipantState, participantStore } from './participant.store';
import { BehaviorSubject } from 'rxjs';

export const participantQuery = createQuery<ParticipantState>(participantStore);

export const selectedParticipant$ = new BehaviorSubject(
  participantQuery.getValue().selectedParticipant,
);
participantQuery.select((s) => s.selectedParticipant).subscribe(selectedParticipant$);

export const screenShareParticipant$ = new BehaviorSubject(
  participantQuery.getValue().screenShareParticipant,
);
participantQuery.select((s) => s.screenShareParticipant).subscribe(screenShareParticipant$);

export const profileParticipants$ = new BehaviorSubject(
  participantQuery.getValue().profileParticipants,
);
participantQuery.select((s) => s.profileParticipants).subscribe(profileParticipants$);

export const currentProfile$ = new BehaviorSubject(participantQuery.getValue().currentProfile);
participantQuery.select((s) => s.currentProfile).subscribe(currentProfile$);

export const reconnectingParticipants$ = new BehaviorSubject(
  participantQuery.getValue().reconnectingParticipants,
);
participantQuery.select((s) => s.reconnectingParticipants).subscribe(reconnectingParticipants$);
