import { BlobServiceClient, BlockBlobClient, ContainerClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';
import { RcFile } from 'antd/lib/upload';
import { getApiClient } from './apiClient';
import config from '../../../config';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

const generateStorageUploadToken = async (body: {
  profileName: string;
}): Promise<{ token: string }> => {
  const apiClient = await getApiClientAsync();
  const { data } = await apiClient.post<{ token: string; storageId: string }>(
    `/storagetoken`,
    body,
  );
  return data;
};

const getNewFileName = (fileName: string): string => {
  const fileExtension = fileName.match(/^(.*?)(.[^.]*)?$/)?.pop();

  const newFileName = `${uuidv4()}${fileExtension}`;

  return newFileName;
};

const getBlobClient = (token: string, folder: string, fileName: string): BlockBlobClient => {
  const blobService = new BlobServiceClient(token);

  const containerClient: ContainerClient = blobService.getContainerClient(folder);

  return containerClient.getBlockBlobClient(fileName);
};

const uploadFileToBlobAuthorized = async (
  token: string,
  file: File,
  folder: string,
): Promise<string> => {
  const newFileName = getNewFileName(file.name);

  const blobClient = getBlobClient(token, folder, newFileName);

  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  await blobClient.uploadData(file, options);

  return newFileName;
};

export const uploadFileToBlob = async (
  profileName: string,
  file: File,
  folder: 'images' | 'backgrounds',
): Promise<string> => {
  const { token } = await generateStorageUploadToken({ profileName });

  return uploadFileToBlobAuthorized(token, file, folder);
};

export const uploadImageToBlob = (partnerName: string, file: RcFile): Promise<string> =>
  uploadFileToBlob(partnerName, file, 'images');

export const uploadBackgroundToBlob = (partnerName: string, file: RcFile): Promise<string> =>
  uploadFileToBlob(partnerName, file, 'backgrounds');
