import breakpoints from './breakpoints.module.scss';

const { xsBreak, smBreak, mdBreak, lgBreak, xlBreak, xxlBreak } = breakpoints;

type AvailableBreakpoints = 'xsBreak' | 'smBreak' | 'mdBreak' | 'lgBreak' | 'xlBreak' | 'xxlBreak';

export const Breakpoints: Record<AvailableBreakpoints, string> = {
  xsBreak,
  smBreak,
  mdBreak,
  lgBreak,
  xlBreak,
  xxlBreak,
};
