import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { fetchCategoryTags } from '../../ProfileTags/api/Providers.Api';
import { FilterCoachesFormProps } from '../components/FilterCoaches/FilterCoaches';
import { FilterParams } from '../models/filterParams';

type FormValues = FilterParams;

export type UseSearchProviderFormParams = FilterCoachesFormProps;

const useFilterProvidersForm = ({ onFilterChange }: UseSearchProviderFormParams) => {
  const { handleSubmit, control, reset } = useForm();
  const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);
  const [categories, setCategories] = useState<Map<string, string[]>>();

  useEffect(() => {
    const fetch = async () => {
      const items = await fetchCategoryTags();
      setCategories(items);
      setIsCategoriesLoaded(true);
    };

    if (!categories && !isCategoriesLoaded) fetch();
  }, [categories, isCategoriesLoaded]);

  const onSubmit: SubmitHandler<FormValues> = (values) =>
    onFilterChange({
      ...values,
    });

  const clearResults = () => {
    reset();
    onSubmit({});
  };

  return {
    control,
    clearResults,
    categories,
    categoriesIsLoading: !isCategoriesLoaded,
    submitForm: handleSubmit(onSubmit),
  };
};

export default useFilterProvidersForm;
