import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback, useMemo } from 'react';
import { getWorkshopById } from '../api/workshopApi';
import { Workshop } from '@inovirtue/admin';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useWorkshopDetails = (id: string) => {
  const fetchGroupByIdMemo = useCallback(() => getWorkshopById(id), [id]);
  const { data, isProcessing, process } = usePromiseWithProgress<Workshop>(fetchGroupByIdMemo);
  const { editMembersAfterPublish } = useFlags();

  const refresh = useCallback(() => {
    process();
  }, [process]);

  const hasMembers = useMemo(() => {
    return (data?.membersCount !== undefined && data?.membersCount > 0) as boolean;
  }, [data]);

  const hasScheduledSessions = useMemo(() => {
    return (data?.scheduledSessionsCount !== undefined &&
      data?.scheduledSessionsCount > 0) as boolean;
  }, [data]);

  const canEdit = useMemo(() => {
    return data?.status === 'Unpublished' || (data?.status === 'Active' && editMembersAfterPublish);
  }, [data?.status, editMembersAfterPublish]);

  return {
    workshop: data,
    hasMembers,
    hasScheduledSessions,
    canEdit,
    isProcessing,
    refresh,
  };
};
