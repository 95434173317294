import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getCompanies } from '../api/companiesApi';
import { Company } from '../models/domain/Company';

export const useCompaniesList = () => {
  const fetchCompanies = useCallback(() => getCompanies(), []);
  const { data, isProcessing } = usePromiseWithProgress<Company[]>(fetchCompanies);

  return {
    companies: data,
    isProcessing,
  };
};
