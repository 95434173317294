import { createEntityStore } from '@datorama/akita';

const STORE_KEY = 'global';

export interface GlobalState {
  showFullPageLoader: boolean;
}

const initialState = {
  showFullPageLoader: false,
};

export const globalStateStore = createEntityStore<GlobalState>(initialState, {
  name: STORE_KEY,
});
