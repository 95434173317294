import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { localAudioTrack$ } from '../../../store/audio';
import { AudioMutedOutlined, AudioOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';
import { Button } from '@inovirtue/components';
import { useBehaviorSubjectState } from '@inovirtue/hooks';

const iconStyle: CSSProperties = { fontSize: '24px', color: 'white' };

export interface ToggleAudioButtonProps {
  disabled?: boolean;
  className?: string;
}

const ToggleAudioButton = ({ disabled, className }: ToggleAudioButtonProps): JSX.Element => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const hasAudioTrack = useBehaviorSubjectState(localAudioTrack$);
  return (
    <Button
      className={className}
      data-testid='toggleAudioButton'
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || disabled}
      icon={
        isAudioEnabled ? (
          <AudioOutlined style={iconStyle} />
        ) : (
          <AudioMutedOutlined style={iconStyle} />
        )
      }></Button>
  );
};

export default ToggleAudioButton;
