import { createQuery } from '@datorama/akita';
import employeesStore, { EmployeesStoreState } from './employees-state.store';
import { BehaviorSubject } from 'rxjs';

export const employeesStateQuery = createQuery<EmployeesStoreState>(employeesStore);

export const activeEmployeesTotal$ = new BehaviorSubject(
  employeesStateQuery.getValue().activeEmployees.total,
);
employeesStateQuery.select((s) => s.activeEmployees.total).subscribe(activeEmployeesTotal$);

export const pendingEmployeesTotal$ = new BehaviorSubject(
  employeesStateQuery.getValue().pendingEmployees.total,
);
employeesStateQuery.select((s) => s.pendingEmployees.total).subscribe(pendingEmployeesTotal$);

export const waitingForCoachEmployeesTotal$ = new BehaviorSubject(
  employeesStateQuery.getValue().waitingForCoachEmployees.total,
);
employeesStateQuery
  .select((s) => s.waitingForCoachEmployees.total)
  .subscribe(waitingForCoachEmployeesTotal$);
