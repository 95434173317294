import moment from 'moment';
import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { NewProviderRequest } from '../models/domain/NewProviderRequest';
import { NewProviderRequestDto } from '@inovirtue/admin';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getActiveNewProviderRequest = async (
  profileName: string,
): Promise<NewProviderRequest | undefined> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<NewProviderRequestDto>(
    `/Requests/${profileName}/newProvider/active`,
  );

  if (data) {
    return { ...data, whenCreated: moment.utc(data.whenCreated) } as NewProviderRequest;
  }
};
