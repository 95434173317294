import './CompanyContacts.scss';
import { ContactsOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CompanyContact } from '../../models/CompanyContact';
import { CompanyContactList } from '../CompanyContactList/CompanyContactList';
import { t } from '@inovirtue/localization';

export type CompanyContactsAccessProps = {
  canEdit?: boolean;
  canDelete?: boolean;
  canResendInvite?: boolean;
  showStatus?: boolean;
};

export type CompanyContactActionProps = {
  onEditClick?: (contact: CompanyContact) => void;
  onDeleteClick?: (contact: CompanyContact) => void;
  onResendInviteClick?: (contact: CompanyContact) => void;
};

export type CompanyContactsProps = {
  contacts?: CompanyContact[];
  isEditable?: boolean;
  canAdd?: boolean;
  onAddContact?: () => void;
} & CompanyContactsAccessProps &
  CompanyContactActionProps;

export const CompanyContacts = (props: CompanyContactsProps): JSX.Element => {
  const { canAdd, onAddContact } = props;
  return (
    <div className='company-contacts'>
      <div className='company-contacts__title'>
        <div className='company-contacts__title__left-panel'>
          <span className='company-contacts__title__left-panel__icon'>
            <ContactsOutlined />
          </span>
          <div className='company-contacts__title__left-panel__text'>
            {t('company:contacts.header')}
          </div>
        </div>
      </div>
      <CompanyContactList {...props} />
      {canAdd && (
        <div className='company-contacts__invite'>
          <Button
            data-testid='invite-manager-button'
            className='company-contacts__invite-button'
            icon={<PlusOutlined />}
            onClick={() => onAddContact?.()}
          >
            {t('company:contacts.invite-manager')}
          </Button>
        </div>
      )}
    </div>
  );
};
