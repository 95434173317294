import { useCallback } from 'react';
import { isSettingsOpen$, setIsSettingsOpen } from '../../../store/video';
import { useBehaviorSubjectState } from '@inovirtue/hooks';

export default function useSettingsToggle(): () => void {
  const isOpen = useBehaviorSubjectState(isSettingsOpen$);

  const toggleSettings = useCallback(() => {
    setIsSettingsOpen(!isOpen);
  }, [isOpen]);

  return toggleSettings;
}
