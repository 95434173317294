import { useLayoutEffect, useRef } from 'react';
import { useIntersectionObserver } from '../useIntersectionObserver/useIntersectionObserver';

export const useInfiniteScroll = (rootMargin = '0px', dataLength: number) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const fetchTriger = useIntersectionObserver(targetRef, { rootRef, rootMargin });

  useLayoutEffect(() => {
    const scrollToElement = scrollToRef.current;
    if (!scrollToElement) return;

    scrollToElement.scrollIntoView({ behavior: 'auto', block: 'start' });
  }, [dataLength]);

  return {
    rootRef,
    targetRef,
    scrollToRef,
    fetchTriger,
  };
};
