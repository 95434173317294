import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import styles from './ImageUpload.module.scss';
import { t } from '@inovirtue/localization';
import { ImageInputValue } from '../../models/ImageInputValue';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

export interface ImageUploadProps {
  name: string;
  title: string;
  description: string;
  value?: ImageInputValue;
  disabled?: boolean;
  imagesSrc?: string;
  onChange?: (value: ImageInputValue) => void;
}

const beforeUpload = (file: RcFile) => {
  const isValidFileType =
    file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isValidFileType) {
    message.error('You can only upload JPG/PNG/SVG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isValidFileType && isLt2M;
};

const ImageUpload = ({
  name,
  value = {},
  onChange,
  title,
  description,
  disabled,
  imagesSrc,
}: ImageUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(imagesSrc);

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
  };

  useEffect(() => {
    if (value.url) {
      setImageUrl(value.url);
    }
  }, [value.url]);

  const handleUpload = ({ file }: RcCustomRequestOptions) => {
    const fileBlob = file as RcFile;
    const imageBlobUrl = URL.createObjectURL(fileBlob);
    setImageUrl(imageBlobUrl);
    setLoading(false);

    onChange?.({ url: imageBlobUrl, file: fileBlob });
  };

  return (
    <div className={styles.imageUpload}>
      <p className={styles.imageUpload__title}>{title}</p>
      <Upload
        customRequest={handleUpload}
        disabled={disabled}
        name={name}
        listType='picture-card'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img src={imageUrl} alt='image' className={styles.imageUpload__img} />
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>{t('common:upload')}</div>
          </div>
        )}
      </Upload>
      <p className={styles.imageUpload__description}>{description}</p>
    </div>
  );
};

export default ImageUpload;
