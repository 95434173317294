import { Fragment } from 'react';
import { useConvoDispatch, useConvoSelector } from '../../hooks/storeHooks/storeHooks';
import { useConversationTimeline } from '../../hooks/useConversationTimeline/useConversationTimeline';
import { selectLastReadMessageIndexBySid } from '../../store/horizonSlice';
import { fetchPrevMessages, selectHasPrevPageByConvoSid } from '../../store/messagesSlice';
import {
  isSameAuthor,
  isSameDayMessage,
} from '../../utils/formatConversationTimeline/formatConversationTimeline';
import { ErrorTimelineMessage } from '../ErrorTimelineMessage/ErrorTimelineMessage';
import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll';
import { MessageFactory } from '../MessageFactory/MessageFactory';
import { ReadHorizon } from '../ReadHorizon/ReadHorizon';
import { TimelineDate } from '../TimelineDate/TimelineDate';
import './ConversationTimeline.scss';

export const ConversationTimeline = (): JSX.Element => {
  const dispatch = useConvoDispatch();
  const { messages, convoSid } = useConversationTimeline();
  const lastReadMessagesIndex = useConvoSelector(selectLastReadMessageIndexBySid(convoSid));
  const hasPrevPage = useConvoSelector(selectHasPrevPageByConvoSid(convoSid));

  return (
    <InfiniteScroll
      className='conversation-timeline'
      dataLength={messages.length}
      inverse
      margin='100px 0px 0px 0px'
      hasMore={!!hasPrevPage}
      fetchMore={() => dispatch(fetchPrevMessages(convoSid))}>
      <div className='conversation-timeline__timeline'>
        {convoSid &&
          messages?.map((message, index, array) => {
            const prevMessage = array[index - 1];

            const isTimelineDateVisible = !isSameDayMessage(message, prevMessage);
            const isSameAsPrev = isSameAuthor(message, prevMessage);
            const lastReadMessageIndex = lastReadMessagesIndex;

            return (
              <Fragment key={message.sid}>
                {isTimelineDateVisible && <TimelineDate date={message.dateCreated} />}
                {lastReadMessageIndex === prevMessage?.index && <ReadHorizon />}
                <MessageFactory
                  message={message}
                  convoSid={convoSid}
                  isSameAsPrev={isSameAsPrev || isTimelineDateVisible}
                />
              </Fragment>
            );
          })}
      </div>
      <ErrorTimelineMessage className='conversation-timeline__error' />
    </InfiniteScroll>
  );
};
