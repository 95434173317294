import { useCallback } from 'react';
import { publishWorkshop } from '../api/workshopApi';

export const useWorkshopPublish = () => {
  const publish = useCallback(async (workshopId: string) => {
    try {
      await publishWorkshop(workshopId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    publish,
  };
};
