import { CardField, DetailsCardBase, WebIcon } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { PartnerDto } from '../../models/PartnerDto';
import styles from './PartnerUrl.module.scss';

interface PartnerUrlProps {
  partner: PartnerDto;
}

const PartnerUrl = ({ partner }: PartnerUrlProps): JSX.Element => {
  return (
    <div className={styles.partnerUrl}>
      <DetailsCardBase header={t('partners:details.url')} icon={<WebIcon />}>
        <div>
          <CardField label={t('partners:details.url-path')} value={partner.name} />
          <CardField
            className={styles.partnerUrl__fieldLabel}
            label={t('partners:details.tenant')}
            value={partner.tenant}
          />
        </div>
      </DetailsCardBase>
    </div>
  );
};

export default PartnerUrl;
