import { t } from '@inovirtue/localization';
import { message } from 'antd';
import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { updateCompany } from '../api/companiesApi';
import { mapCompanyToCompanyDto } from '../mapper/Company.Mapper';
import { Company } from '../models/domain/Company';

export const useCompanyUpdate = () => {
  const onSubmit = async (company: Company) => {
    try {
      enableFullPageLoader();
      await updateCompany(mapCompanyToCompanyDto(company));
      message.success(t('save-success'));
    } catch (err) {
      message.error(t('save-error'));
    } finally {
      disableFullPageLoader();
    }
  };
  return {
    onSubmit,
  };
};
