export const DataIcon = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='0.000488281' width='40' height='40' rx='8' fill='#F0F0F0' />
    <g clipPath='url(#clip0_3232_104019)'>
      <g clipPath='url(#clip1_3232_104019)'>
        <path
          d='M28.5708 8.00049H11.4279C10.9538 8.00049 10.5708 8.38352 10.5708 8.85763V31.1433C10.5708 31.6175 10.9538 32.0005 11.4279 32.0005H28.5708C29.0449 32.0005 29.4279 31.6175 29.4279 31.1433V8.85763C29.4279 8.38352 29.0449 8.00049 28.5708 8.00049ZM12.4994 9.92906H27.4994V15.5005H12.4994V9.92906ZM27.4994 22.7862H12.4994V17.2148H27.4994V22.7862ZM27.4994 30.0719H12.4994V24.5005H27.4994V30.0719ZM14.4279 12.7148C14.4279 12.9989 14.5408 13.2715 14.7418 13.4724C14.9427 13.6733 15.2152 13.7862 15.4994 13.7862C15.7835 13.7862 16.0561 13.6733 16.257 13.4724C16.4579 13.2715 16.5708 12.9989 16.5708 12.7148C16.5708 12.4306 16.4579 12.1581 16.257 11.9572C16.0561 11.7562 15.7835 11.6433 15.4994 11.6433C15.2152 11.6433 14.9427 11.7562 14.7418 11.9572C14.5408 12.1581 14.4279 12.4306 14.4279 12.7148V12.7148ZM14.4279 20.0005C14.4279 20.2846 14.5408 20.5572 14.7418 20.7581C14.9427 20.959 15.2152 21.0719 15.4994 21.0719C15.7835 21.0719 16.0561 20.959 16.257 20.7581C16.4579 20.5572 16.5708 20.2846 16.5708 20.0005C16.5708 19.7163 16.4579 19.4438 16.257 19.2429C16.0561 19.0419 15.7835 18.9291 15.4994 18.9291C15.2152 18.9291 14.9427 19.0419 14.7418 19.2429C14.5408 19.4438 14.4279 19.7163 14.4279 20.0005V20.0005ZM14.4279 27.2862C14.4279 27.5704 14.5408 27.8429 14.7418 28.0438C14.9427 28.2447 15.2152 28.3576 15.4994 28.3576C15.7835 28.3576 16.0561 28.2447 16.257 28.0438C16.4579 27.8429 16.5708 27.5704 16.5708 27.2862C16.5708 27.002 16.4579 26.7295 16.257 26.5286C16.0561 26.3277 15.7835 26.2148 15.4994 26.2148C15.2152 26.2148 14.9427 26.3277 14.7418 26.5286C14.5408 26.7295 14.4279 27.002 14.4279 27.2862V27.2862Z'
          fill='black'
          fillOpacity='0.85'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3232_104019'>
        <rect width='24' height='24' fill='white' transform='translate(8 8.00049)' />
      </clipPath>
      <clipPath id='clip1_3232_104019'>
        <rect width='24' height='24' fill='white' transform='translate(8 8.00049)' />
      </clipPath>
    </defs>
  </svg>
);
