import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getWorkshopSessions } from '../api/workshopApi';
import { Session } from '@inovirtue/admin';

export const useWorkshopSessionsList = (id: string) => {
  const fetchGroupSessionsByIdMemo = useCallback(() => getWorkshopSessions(id), [id]);
  const { data, isProcessing } = usePromiseWithProgress<Session[]>(fetchGroupSessionsByIdMemo);
  return {
    sessions: data,
    isProcessing,
  };
};
