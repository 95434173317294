import { useEffect } from 'react';
import { t } from '@inovirtue/localization';
import { VideoCall } from '@inovirtue/video-call';
import useVideoCallInit from './../../../Modules/VideoCall/hooks/useVideoCallInit/useVideoCallInit';
import { getServerTime } from './../../../Modules/Sessions/api/sessionsApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

const VideoCallView = (): JSX.Element => {
  const { videoCallChat } = useFlags();
  const {
    roomName,
    roomType,
    currentProfile,
    participants,
    isInitialized,
    canJoin,
    startDate,
    endDate,
    onRequestToken,
    onRedirect,
    workshop,
  } = useVideoCallInit();

  const fetchServerDate = async (): Promise<Date> => (await getServerTime())?.serverTime;

  useEffect(() => {
    document.title = t('videoCall:videocall-in-progress');
  }, []);

  return (
    <>
      {isInitialized && (
        <VideoCall
          roomName={roomName}
          canJoin={canJoin}
          startDate={startDate?.toString() ?? ''}
          endDate={endDate?.toString() ?? ''}
          currentProfile={currentProfile}
          participants={participants}
          onRedirect={onRedirect}
          onRequestToken={onRequestToken}
          roomType={roomType}
          sessionExtendedNotification={undefined}
          onSessionJoinSubscribe={() => Promise.resolve()}
          onSessionJoinUnsubscribe={() => Promise.resolve()}
          onSessionExtended={() => Promise.resolve()}
          fetchServerDate={fetchServerDate}
          workshopName={workshop?.name}
          isChat={videoCallChat}
        />
      )}
    </>
  );
};

export default VideoCallView;
