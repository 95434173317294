import Video from 'twilio-video';
import { Alert } from 'antd';
import { t } from '@inovirtue/localization';
import { Trans } from 'react-i18next';

//Added ignore due to Trans component needs to have content in one line to works properly.
//prettier-ignore
const Description = () => (
  <Trans i18nKey='videoCall:unsupported-browser.description'>
    You will not be able to join the call with the browser you are currently using. Please use another browser. Visit the <a href='https://www.twilio.com/docs/video/javascript#supported-browsers' target='_blank' rel='noreferrer noopener'>supported browsers</a> page for more information.
  </Trans>
);

export interface UnsupportedBrowserWarningProps {
  className?: string;
}

const UnsupportedBrowserWarning = ({ className }: UnsupportedBrowserWarningProps): JSX.Element => {
  return (
    <>
      {!Video.isSupported && (
        <Alert
          className={className}
          message={t('videoCall:unsupported-browser.message')}
          description={<Description />}
          type='warning'
          showIcon
        />
      )}
    </>
  );
};

export default UnsupportedBrowserWarning;
