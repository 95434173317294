import { Spin } from 'antd';
import React, { FC, ReactElement } from 'react';
import './ContentTile.Component.scss';

export type ContentTileProps = {
  title?: string | ReactElement;
  actions?: ReactElement;
  isLoading?: boolean;
  onClick?: () => void;
  children: ReactElement;
};

export const ContentTile: FC<ContentTileProps> = ({
  title,
  actions,
  isLoading,
  onClick,
  children,
}) => {
  return (
    <div className='content-tile'>
      <div className='header'>
        <div className='title'>{title}</div>
        <div className='actions'>{actions}</div>
      </div>
      <div onClick={onClick}>{children}</div>
      {isLoading && <Spin className='loader'></Spin>}
    </div>
  );
};
