import { Step, StepType } from '../../../store/models/Step.model';
import CallPrepStep from '../CallPrepStep/CallPrepStep.Component';
import CallEndedStep from '../CallEndedStep/CallEndedStep.Component';
import CallLeftStep from '../CallLeftStep/CallLeftStep.Component';
import NoAnswerStep from '../NoAnswerStep/NoAnswerStep.Component';
import CallRoomStep from '../CallRoomStep/CallRoomStep.Components';
import CallErrorStep from '../CallErrorStep/CallErrorStep.Component';
import CallExpiredStep from '../CallExpiredStep/CallExpiredStep.Component';
import CallWaitingRoomStep from '../CallWaitingRoomStep/CallWaitingRoomStep.Component';

export interface StepFactoryProps {
  step: StepType;
}

const StepFactory = ({ step }: StepFactoryProps): JSX.Element | null => {
  switch (step) {
    case Step.CallPrepStep:
      return <CallPrepStep />;
    case Step.CallWaitingRoomStep:
      return <CallWaitingRoomStep />;
    case Step.CallRoomStep:
      return <CallRoomStep />;
    case Step.CallLeftStep:
      return <CallLeftStep />;
    case Step.NoAnswerStep:
      return <NoAnswerStep />;
    case Step.CallEndedStep:
      return <CallEndedStep />;
    case Step.CallExpiredStep:
      return <CallExpiredStep />;
    case Step.CallErrorStep:
      return <CallErrorStep />;
    default:
      return null;
  }
};

export default StepFactory;
