import { createEntityStore } from '@datorama/akita';

const STORE_KEY = 'employees';

export type EmployeesState = {
  total: number;
};

export type EmployeesStoreState = {
  activeEmployees: EmployeesState;
  pendingEmployees: EmployeesState;
  waitingForCoachEmployees: EmployeesState;
};

const initialState: EmployeesStoreState = {
  activeEmployees: {
    total: 0,
  },
  pendingEmployees: {
    total: 0,
  },
  waitingForCoachEmployees: {
    total: 0,
  },
};

const employeesStore = createEntityStore<EmployeesStoreState>(initialState, {
  name: STORE_KEY,
});

export default employeesStore;
