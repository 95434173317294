import { LayoutHeader } from '@inovirtue/admin';
import { Button } from 'antd';
import { t } from '@inovirtue/localization';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router';
import routes from '../../../../App/routes/constants/Routes';

export const WorkshopsListHeader = () => {
  const history = useHistory();
  const { createGroupFlag } = useFlags();
  return (
    <LayoutHeader
      title={
        <div className='workshops-list-view__header'>
          <span className='workshops-list-view__header__text'>{t('common:groups')}</span>
          {createGroupFlag && (
            <Button
              data-testid='create-group-btn'
              type='primary'
              onClick={() => history.push(routes.WorkshopCreate)}
            >
              {t('common:add-entity', { entityName: 'Group' })}
            </Button>
          )}
        </div>
      }
      subtitle={t('group:groups-view-subheader')}
      onNavigate={history.push}
    />
  );
};
