import { useEffect, useRef, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { timer } from 'rxjs';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import React from 'react';

let clipId = 0;
const getUniqueClipId = () => clipId++;

const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

const initializeAnalyser = (stream: MediaStream): AnalyserNode => {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
};

export interface AudioLevelIndicatorProps {
  audioTrack?: AudioTrack;
}

const AudioLevelIndicator = ({ audioTrack }: AudioLevelIndicatorProps): JSX.Element => {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      const stopAllMediaStreamTracks = () =>
        newMediaStream.getTracks().forEach((track) => track.stop());

      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (isTrackEnabled && SVGClipElement && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const t = timer(0, 100).subscribe(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(13, Math.max(3, Math.log10(values / length / 3) * 7));
        SVGClipElement?.setAttribute('y', String(13 - volume));
      });

      return () => {
        SVGClipElement.setAttribute('y', '13');
        t.unsubscribe();
      };
    }
  }, [isTrackEnabled, analyser]);

  const { current: clipPathId } = useRef(`audio-level-clip-${getUniqueClipId()}`);

  return isTrackEnabled ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='currentColor'>
      <defs>
        <clipPath id={clipPathId}>
          <rect ref={SVGRectRef} x='0' y='13' width='24' height='24' />
        </clipPath>
      </defs>
      <rect
        clipPath={`url(#${clipPathId})`}
        width='5'
        height='11'
        x='9.5'
        y='2'
        rx='3'
        ry='3'
        fill='#23BF6E'></rect>
      <path d='M19.734 10.641c0 -0.103 -0.084 -0.188 -0.188 -0.188h-1.406c-0.103 0 -0.188 0.084 -0.188 0.188 0 3.288 -2.665 5.953 -5.953 5.953S6.047 13.929 6.047 10.641c0 -0.103 -0.084 -0.188 -0.188 -0.188h-1.406c-0.103 0 -0.188 0.084 -0.188 0.188 0 3.954 2.967 7.216 6.797 7.678V20.719H7.657c-0.321 0 -0.579 0.335 -0.579 0.75v0.844c0 0.103 0.066 0.188 0.145 0.188h9.553c0.08 0 0.145 -0.084 0.145 -0.188v-0.844c0 -0.415 -0.258 -0.75 -0.579 -0.75H12.844V18.33c3.874 -0.422 6.891 -3.703 6.891 -7.69zM12 14.625c2.201 0 3.984 -1.763 3.984 -3.938V5.438c0 -2.175 -1.784 -3.938 -3.984 -3.938s-3.984 1.763 -3.984 3.938v5.25c0 2.175 1.784 3.938 3.984 3.938zm-2.203 -9.188c0 -1.186 0.982 -2.156 2.203 -2.156s2.203 0.97 2.203 2.156v5.25c0 1.186 -0.982 2.156 -2.203 2.156s-2.203 -0.97 -2.203 -2.156V5.438z' />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      width='1em'
      height='1em'
      fill='currentColor'>
      <path d='M15.984 10.664V7.289l-1.781 1.781v1.594c-0.002 1.188 -0.984 2.159 -2.203 2.156 -0.448 0.002 -0.862 -0.127 -1.219 -0.352l-1.266 1.289c0.682 0.525 1.545 0.844 2.484 0.844 2.198 0 3.984 -1.76 3.984 -3.938z' />
      <path d='M19.523 10.453h-1.406c-0.103 0 -0.188 0.084 -0.188 0.188 0 3.288 -2.665 5.953 -5.953 5.953 -1.477 0 -2.829 -0.539 -3.867 -1.43l-1.266 1.266c1.146 1.013 2.597 1.695 4.195 1.898v2.391H7.641c-0.326 0 -0.584 0.335 -0.586 0.75v0.844c0.002 0.103 0.068 0.188 0.141 0.188h9.563c0.075 0 0.141 -0.084 0.141 -0.188v-0.844c0 -0.415 -0.258 -0.75 -0.586 -0.75H12.82V18.328c3.874 -0.42 6.891 -3.701 6.891 -7.688 0 -0.103 -0.084 -0.188 -0.188 -0.188zm0.307 -8.852 -1.02 -0.982c-0.073 -0.07 -0.19 -0.07 -0.262 0.002l-3.023 3.023C14.866 2.372 13.523 1.5 11.977 1.5c-2.201 0 -3.984 1.765 -3.984 3.938v5.25c0 0.157 0.009 0.312 0.028 0.464l-1.594 1.594c-0.246 -0.654 -0.382 -1.364 -0.38 -2.105 -0.005 -0.103 -0.089 -0.188 -0.188 -0.188h-1.406c-0.103 0 -0.188 0.084 -0.188 0.188 0 1.242 0.293 2.414 0.811 3.455l-3.211 3.211c-0.073 0.073 -0.073 0.192 0 0.265l1.001 1.001c0.073 0.073 0.192 0.073 0.265 0L19.833 1.87l0.002 -0.002c0.073 -0.075 0.07 -0.195 -0.005 -0.267zM9.773 9.398V5.438c0 -1.186 0.982 -2.156 2.203 -2.156 1.078 0 1.971 0.757 2.163 1.751L9.773 9.398z' />
    </svg>
  );
};

export default React.memo(AudioLevelIndicator);
