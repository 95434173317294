
import { Tooltip } from 'antd';
import { PushpinOutlined } from '@ant-design/icons';

const PinIcon = (): JSX.Element => {
  return (
    <Tooltip title="Participant is pinned. Click to un-pin." placement="top">
      <PushpinOutlined />
    </Tooltip>
  );
}

export default PinIcon;
