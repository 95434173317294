import { Input, InputProps } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import './InputWCharactersLimit.scss';

export interface InputWCharactersLimitProps extends InputProps {
  maxLength: number;
}

export const InputWCharactersLimit = ({
  maxLength,
  value,
  onChange,
  ...props
}: InputWCharactersLimitProps) => {
  const [valueLength, setValueLength] = useState(value?.toString().length ?? 0);

  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueLength(event.target.value.length);
    onChange?.(event);
  };

  return (
    <>
      <Input
        data-testid='input-w-characters-limit'
        className='input-w-characters-limit'
        onChange={onValueChange}
        {...props}
        {...{ value, maxLength }}
      />
      <span className='input-w-characters-limit__limit-label'>
        {valueLength}/{maxLength}
      </span>
    </>
  );
};
