import { useContext } from 'react';
import { ErrorContext } from '../../contexts/ErrorContext';

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('Please provide error context.');
  }

  return context;
};
