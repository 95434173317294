import './PartnerProviderSelect.scss';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Avatar, Form, Select, Space } from 'antd';
import { useCallback } from 'react';
import { Provider } from '../../model/Provider';

export type ProviderSelectProps = {
  onSelect: (provider: Provider) => void;
  onSearchProvider: (value: string, tenantName: string) => void;
  onDropdownVisibleChange?: (isOpen: boolean) => void;
  providers: Provider[];
  tenantName: string;
};

type FormData = {
  selectedEmployee?: string;
};

export const ProviderSelect = ({
  tenantName,
  providers,
  onSearchProvider,
  onSelect,
  onDropdownVisibleChange,
}: ProviderSelectProps) => {
  const [form] = Form.useForm();

  const onFormSubmit = useCallback(
    (formData: FormData) => {
      if (formData.selectedEmployee) {
        const foundMember = providers.find((x) => x.name == formData.selectedEmployee);
        if (foundMember) {
          onSelect(foundMember);
        }
      }
      form.resetFields();
    },
    [form, providers, onSelect],
  );

  return (
    <div className='partner-provider-select'>
      <Form form={form} onFinish={onFormSubmit} layout='inline'>
        <Form.Item name={['selectedEmployee']}>
          <Select
            className='partner-provider-select__member-select'
            showSearch
            allowClear
            placeholder={t('group:edit-group-members.placeholder-type')}
            optionLabelProp='title'
            onDropdownVisibleChange={onDropdownVisibleChange}
            onSearch={(text) => onSearchProvider(text, tenantName)}
            filterOption={false}
          >
            {providers.map((e) => (
              <Select.Option key={`select_${e.name}`} value={e.name} title={e.displayName}>
                <Space>
                  <Avatar size='small' src={e.avatarUrl} />
                  {e.displayName}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button htmlType='submit'>{t('common:add')}</Button>
      </Form>
    </div>
  );
};
