import { QuotIcon } from '@inovirtue/components';
import { Typography } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import './Philosophy.scss';

const { Paragraph } = Typography;

export interface PhilosophyProps {
  description: ReactNode;
  className: string;
}

const Philosophy = ({ description, className }: PhilosophyProps) => {
  const philosophyCls = classNames('philosophy', className);

  return (
    <Paragraph className={philosophyCls}>
      <span className='philosophy__quot'>
        <QuotIcon />
      </span>
      {description}
    </Paragraph>
  );
};

export default Philosophy;
