import './WorkshopListItem.scss';
import { Workshop } from '../../model/Workshop';
import { WorkshopAvatar } from '../WorkshopAvatar/WorkshopAvatar';
import { WorkshopStatusLabel } from '../WorkshopStatusLabel/WorkshopStatusLabel';
import { Avatar } from 'antd';
import { t } from '@inovirtue/localization';
import { useMemo } from 'react';

export type WorkshopListItemProps = {
  workshop: Workshop;
  showCompany?: boolean;
};

export const WorkshopListItem = ({ workshop, showCompany = false }: WorkshopListItemProps) => {
  const hasMembers = useMemo(() => workshop.members && workshop.members.length > 0, [workshop]);
  return (
    <div className='group-list-item'>
      <div className='group-list-item__data'>
        <div className='group-list-item__data-title'>
          <WorkshopAvatar groupName={workshop.name} />{' '}
          <span className='group-list-item__data-title__group-name'>{workshop.name}</span>
        </div>
        {showCompany && (
          <div className='group-list-item__data-details'>
            <div className='group-list-item__data__field'>
              <label>{t('common:company')}</label>
              <span>{workshop.selectedCompanyName} </span>
            </div>
            <div className='group-list-item__data__field'>
              <label>{t('common:coach')}</label>
              <div className='coach-details'>
                {workshop.provider && (
                  <>
                    <Avatar
                      className='coach-details__avatar'
                      key={`a_${workshop.provider?.name}`}
                      src={workshop.provider?.avatarUrl}
                    ></Avatar>
                    <span>{workshop.provider?.displayName}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className='group-list-item__data__field'>
          <label>
            {t('group:members')} ({workshop.members?.length})
          </label>
          <span>
            {hasMembers ? (
              <Avatar.Group maxCount={5}>
                {workshop.members?.map((x) => (
                  <Avatar key={`a_${x.name}`} src={x.avatarUrl} />
                ))}
              </Avatar.Group>
            ) : (
              <div className='group-list-item__data__field__empty'></div>
            )}
          </span>
        </div>
        <div className='group-list-item__data__status'>
          <WorkshopStatusLabel status={workshop.status} />
        </div>
      </div>
    </div>
  );
};
