import { ConvoType } from '@inovirtue/chat';
import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { CreateConversationProfile } from '../models/createConversationProfile';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const createConversation = async (
  profiles: CreateConversationProfile[],
): Promise<string> => {
  const axiosInstance = await getApiClientAsync();

  const { data } = await axiosInstance.post<{ conversationSid: string }>(`/chat`, {
    type: ConvoType.OneOnOne,
    profiles: profiles,
  });

  return data.conversationSid;
};

export const getChatToken = async (name: string): Promise<string> => {
  const apiClient = await getApiClient(`${config.twilioChatConfig.twilioTokenServerUrl}`);
  const { data } = await apiClient.post<{ token: string }>('token', {
    profileName: name,
  });

  return data?.token;
};
