import employeesStore from './employees-state.store';

export const updateActiveEmployeesTotal = (total: number): void =>
  employeesStore.update((oldState) => ({
    ...oldState,
    activeEmployees: {
      ...oldState.activeEmployees,
      total,
    },
  }));

export const updatePendingEmployeesTotal = (total: number): void =>
  employeesStore.update((oldState) => ({
    ...oldState,
    pendingEmployees: {
      ...oldState.pendingEmployees,
      total,
    },
  }));

export const updateWaitingForCoachEmployeesTotal = (total: number): void =>
  employeesStore.update((oldState) => ({
    ...oldState,
    waitingForCoachEmployees: {
      ...oldState.waitingForCoachEmployees,
      total,
    },
  }));
