import { Consumer } from '@inovirtue/admin';
import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { useCompanyEmployees } from '../../Modules/Companies/hooks/useCompanyEmployees';
import { useWorkshopDetails } from '../../Modules/Workshops/hooks/useWorkshopDetails';
import { useWorkshopMemberEdit } from '../../Modules/Workshops/hooks/useWorkshopMemberEdit';
import { useWorkshopMembers } from '../../Modules/Workshops/hooks/useWorkshopMembers';
import routes from '../routes/constants/Routes';

export const useWorkshopEditMembers = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const [selectedMembers, setSelectedMembers] = useState<Consumer[]>([]);

  const { workshop } = useWorkshopDetails(id);
  const { data: members, isFetched: membersFetched } = useWorkshopMembers(id);
  const { data: employees, isFetched: employeesFetched } = useCompanyEmployees(
    workshop?.selectedCompanyId,
  );

  const loaded = useMemo(() => {
    return membersFetched && employeesFetched;
  }, [membersFetched, employeesFetched]);

  const navigateBack = useCallback(
    () => push(generatePath(routes.WorkshopDetailsMembers, { id })),
    [id, push],
  );

  const updateSelectedMembers = useCallback(
    (newMembers) => setSelectedMembers([...newMembers]),
    [],
  );

  const { updateMembers } = useWorkshopMemberEdit();

  const saveChanges = useCallback(async () => {
    try {
      await updateMembers(
        id,
        selectedMembers.map((x) => x.name),
      );
      showToast(t('common:save-success'));
      navigateBack();
    } catch (error) {
      showToast(t('errors:unhandled-error'));
    }
  }, [id, selectedMembers, navigateBack, updateMembers]);

  return {
    members,
    employees,
    selectedMembers,
    updateSelectedMembers,
    saveChanges,
    navigateBack,
    loaded,
  };
};
