import { useMemo } from 'react';
import { Workshop } from '../model/Workshop';

export const useCanJoinWorkshopChat = (workshop?: Workshop) => {
  const canJoinGroupChat = useMemo<boolean>(() => {
    if (!workshop) {
      return false;
    }
    return workshop.status === 'Active';
  }, [workshop]);

  const showJoinGroupChat = useMemo<boolean>(() => {
    if (!workshop) {
      return false;
    }
    return (
      workshop.status === 'Active' ||
      workshop.status === 'Deactivated' ||
      workshop.status === 'Completed'
    );
  }, [workshop]);

  return { canJoinGroupChat, showJoinGroupChat };
};
