const getGroupedCategorySelectedTags = (
  allCategoryTags: Map<string, string[]>,
  selectedTags: string[],
  groupByCategories: string[],
): Map<string, string[]> => {
  const result = new Map<string, string[]>();
  groupByCategories.forEach((category) => {
    const tagsByCategory = allCategoryTags.get(category) ?? [];
    const items = [...selectedTags.filter((t) => tagsByCategory.includes(t))];
    if (items.length > 0) result.set(category, items);
  });

  return result;
};

export default getGroupedCategorySelectedTags;
