import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Client, Conversation as TwilioConversation } from '@twilio/conversations';
import { getSubscribedConversations } from '../api/conversation';
import { getConversationParticipants } from '../api/participant';
import { Convo, SelectedConvoSid } from '../models/conversation';
import { conversationActions } from '../service/conversationService';
import { serializeConversation } from '../utils/serialize/serialize';
import { conversationsComparer } from '../utils/sort/sort';
import { fetchAllHorizons } from './horizonSlice';
import { ConversationsState } from './store';

const conversationsAdapter = createEntityAdapter<Convo>({
  selectId: (conversation) => conversation.sid,
  sortComparer: conversationsComparer,
});

export const assembleConversation = createAsyncThunk(
  'conversations/assembleConversation',
  async (conversation: TwilioConversation) => {
    const participants = await getConversationParticipants(conversation);

    return { conversation, participants };
  },
);

export const assembleSubscribedConversations = createAsyncThunk(
  'conversations/assembleSubscribedConversations',
  async (client: Client, { dispatch }) => {
    const subscribedConversations = await getSubscribedConversations(client);

    dispatch(fetchAllHorizons(subscribedConversations.items));

    const conversationsParticipants = await getConversationParticipants(
      subscribedConversations.items,
    );

    return { conversations: subscribedConversations, participants: conversationsParticipants };
  },
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: conversationsAdapter.getInitialState(),
  reducers: {
    upsertConversation: (state: EntityState<Convo>, action: PayloadAction<TwilioConversation>) => {
      conversationActions.upsertConversation(action.payload);
      const serializedConvo = serializeConversation(action.payload);
      conversationsAdapter.upsertOne(state, serializedConvo);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(assembleSubscribedConversations.fulfilled, (state, action) => {
      const { conversations } = action.payload;
      conversationActions.setAll(conversations);
      const serializedConvos = serializeConversation(conversations.items);
      conversationsAdapter.upsertMany(state, serializedConvos);
    });
    builder.addCase(assembleConversation.fulfilled, (state, action) => {
      const { conversation } = action.payload;
      conversationActions.addConversation(conversation);
      const serializedConvo = serializeConversation(conversation);
      conversationsAdapter.upsertOne(state, serializedConvo);
    });
  },
});

export const { upsertConversation } = conversationsSlice.actions;

export const conversationsReducer = conversationsSlice.reducer;

const { selectAll, selectById, selectIds } = conversationsAdapter.getSelectors(
  (state: ConversationsState) => state.conversations,
);

export const selectAllConversations = selectAll;
export const selectConversationIds = selectIds;

export const selectConversationBySid = (convoSid: SelectedConvoSid) => {
  if (!convoSid) return () => undefined;

  return createSelector(
    (state: ConversationsState) => state,
    (state) => selectById(state, convoSid),
  );
};
