export const ImageContentTypeEnum = {
  Png: 'image/png',
  Jpg: 'image/jpeg',
  Gif: 'image/gif',
} as const;

export const imageContentTypes = Object.values(ImageContentTypeEnum);

export const FileContentTypeEnum = {
  Pdf: 'application/pdf',
  Word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PowerPoint: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Txt: 'text/plain',
  Zip: 'application/zip',
  Mp3: 'audio/mpeg',
} as const;

export const fileContentTypes = Object.values(FileContentTypeEnum);
