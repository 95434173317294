import './WorkshopMembersList.scss';
import { EditOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Avatar, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { MembersIcon } from '../../icons/MembersIcon';
import { Consumer } from '../../model/Consumer';

type WorkshopMembersListProps = {
  data: Array<Consumer>;
  isEditable: boolean;
  onAddClick?: () => void;
};

export const WorkshopMembersList = ({
  data,
  isEditable = false,
  onAddClick,
}: WorkshopMembersListProps) => {
  const columns: ColumnsType<Consumer> = useMemo(
    () => [
      {
        title: `${t('group:members')} (${data.length})`,
        width: '100%',
        key: 'group-members',
        render: (c: Consumer) => {
          return (
            <Space className='members-list__table-display_name' key={`mm_${c.name}`}>
              <Avatar src={c.avatarUrl} />
              <span>{c.displayName}</span>
            </Space>
          );
        },
      },
    ],
    [data.length],
  );

  return (
    <div className='members-list'>
      <div className='members-list__header'>
        <div className='members-list__header__main'>
          <MembersIcon />
          <div className='members-list__header__main__title'>
            <span>{t('group:members')}</span>
          </div>
        </div>
        <div className='members-list__header__add'>
          {isEditable && (
            <Button onClick={onAddClick}>
              <EditOutlined /> {t('group:edit-members')}
            </Button>
          )}
        </div>
      </div>
      <div>
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </div>
  );
};
