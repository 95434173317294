import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useBehaviorSubjectState<Type>(subject: BehaviorSubject<Type>): Type {
  const [subjectState, setSubjectState] = useState<Type>(subject.value);

  useEffect(() => {
    const sub = subject.subscribe((nextState: Type) => setSubjectState(nextState));

    return () => sub.unsubscribe();
  }, [subject]);

  return subjectState;
}
