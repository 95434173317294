import { Loader, Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { generatePath, useHistory, useParams } from 'react-router';
import { FormMode } from '../../../Modules/Common/model/FormMode';
import WorkshopForm from '../../../Modules/Workshops/components/WorkshopForm/WorkshopForm';
import WorkshopFormHeader from '../../../Modules/Workshops/components/WorkshopFormHeader/WorkshopFormHeader';
import { useEditWorkshop } from '../../../Modules/Workshops/hooks/useEditWorkshop';
import { useWorkshopDetails } from '../../../Modules/Workshops/hooks/useWorkshopDetails';
import routes from '../../routes/constants/Routes';

export const WorkshopEditView = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { workshop, isProcessing } = useWorkshopDetails(id);
  const { onSubmit } = useEditWorkshop();

  return (
    <div data-testid={'create-group-view'}>
      <Modal
        isFullScreen={true}
        visible={true}
        onClose={() => history.push(generatePath(routes.WorkshopDetails, { id }))}
      >
        {!isProcessing ? (
          <WorkshopForm
            mode={FormMode.Edit}
            formData={workshop}
            onSubmit={onSubmit}
            submitText={t('group:edit-group-submit')}
            formHeader={
              <WorkshopFormHeader
                headerText={t('group:edit-group-header.main')}
                subheaderText={t('group:edit-group-header.sub')}
              />
            }
          />
        ) : (
          <Loader spaceSize={'middle'} spinSize={'default'} />
        )}
      </Modal>
    </div>
  );
};
