export type PatchItem = {
  op: string;
  path: string;
  value?: unknown;
};

export const getPatchBody = (input: Record<string, unknown>): PatchItem[] =>
  Object.entries(input).map((element) => ({
    op: 'replace',
    path: `/${element[0]}`,
    value: element[1],
  }));
