import { getApiClient } from '../../Common/api/apiClient';
import config from '../../../config';
import { ProfileDTO } from '@inovirtue/profile';
import { Session } from '@inovirtue/admin';

export const getServerTime = async () => {
  const apiClient = await getApiClient(config.apiAddresses.schedulingService);
  const { data } = await apiClient.get<{ serverTime: Date }>(`/serverTime`);
  return data;
};

export const getSessionParticipants = async (sessionId: string): Promise<ProfileDTO[]> => {
  const apiClient = await getApiClient(`${config.apiAddresses.socialNetworkService}`);
  const { data } = await apiClient.get<ProfileDTO[]>(`/sessions/${sessionId}/participants`);
  return data;
};

export const getSessionById = async (sessionId: string): Promise<Session> => {
  const apiClient = await getApiClient(`${config.apiAddresses.socialNetworkService}`);
  const { data } = await apiClient.get<Session>(`/sessions/${sessionId}`);
  return data;
};
