import { RefObject, useCallback, useEffect, useState } from 'react';

interface IntersectionObserverOptions extends Omit<IntersectionObserverInit, 'root'> {
  rootRef?: RefObject<Element> | null;
  enabled?: boolean;
}

export const useIntersectionObserver = (
  targetRef: RefObject<Element>,
  { rootRef, rootMargin, threshold, enabled = true }: IntersectionObserverOptions,
) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const updateEntry = useCallback(([intersectionObserverEntry]: IntersectionObserverEntry[]) => {
    setEntry(intersectionObserverEntry);
  }, []);

  useEffect(() => {
    const root = rootRef?.current;
    const target = targetRef.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !target || !enabled) return;

    const observer = new IntersectionObserver(updateEntry, { root: root, rootMargin, threshold });

    observer.observe(target);

    return () => observer.disconnect();
  }, [enabled, rootMargin, rootRef, targetRef, threshold, updateEntry]);

  return entry;
};
