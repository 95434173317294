import './CompanyDetails.scss';
import { EditOutlined, ProfileOutlined } from '@ant-design/icons';
import {
  CompanyContractDetails,
  CompanyContractDetailsProps,
} from '../CompanyContractDetails/CompanyContractDetails';
import Divider from 'antd/lib/divider';
import { CompanyAddress, CompanyAddressProps } from '../CompanyAddress/CompanyAddress';
import { Button } from 'antd';
import { Modal } from '@inovirtue/components';
import { useState } from 'react';

export type CompanyDetailsProps = {
  isEditable: boolean;
  editForm?: JSX.Element;
} & CompanyContractDetailsProps &
  CompanyAddressProps;

export const CompanyDetails = (props: CompanyDetailsProps): JSX.Element => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  return (
    <div className='company-details'>
      <Modal visible={isEditModalVisible} onClose={() => setIsEditModalVisible(false)}>
        {props.editForm}
      </Modal>
      <div className='company-details__title'>
        <div className='company-details__title__left-panel'>
          <span className='company-details__title__left-panel__icon'>
            <ProfileOutlined />
          </span>
          <div className='company-details__title__left-panel__text'>Company Details</div>
        </div>
        {props.isEditable && (
          <Button
            className='company-details__title__edit-button'
            data-testid='company-details-edit-button'
            onClick={() => setIsEditModalVisible(true)}
            type='text'
            icon={<EditOutlined />}
          />
        )}
      </div>
      <div className='company-details__contract-details'>
        <CompanyContractDetails
          contractEndDate={props.contractEndDate}
          contractStartDate={props.contractStartDate}
          contractStatus={props.contractStatus}
        />
      </div>
      <Divider />
      <div className='company-details__address'>
        <CompanyAddress
          addressCompanyName={props.addressCompanyName}
          addressLine1={props.addressLine1}
          addressLine2={props.addressLine2}
          zip={props.zip}
          city={props.city}
          country={props.country}
        />
      </div>
    </div>
  );
};
