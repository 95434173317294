import dayjs from 'dayjs';
import { EventProps } from 'react-big-calendar';
import { CalendarEvent } from '../../models/CalendarEvent';
import { CalendarEventsTypesEnum } from '../../models/CalendarEventsTypesEnum';
import { SyncOutlined } from '@ant-design/icons';
import { WorkshopAvatar } from '@inovirtue/admin';

import './SchedulerEvent.scss';

interface SchedulerEventProps extends EventProps {
  event: CalendarEvent;
}

export const SchedulerEvent = ({ event }: SchedulerEventProps): JSX.Element => {
  return (
    <div className='schedulerEvent'>
      {event.type === CalendarEventsTypesEnum.block && event.recursive && <SyncOutlined />}
      {event.type === CalendarEventsTypesEnum.event && event.avatarUrl && (
        <img className='avatar' src={event.avatarUrl} />
      )}
      {event.type === CalendarEventsTypesEnum.groupEvent && event.groupName && (
        <WorkshopAvatar size='small' groupName={event.groupName} avatarCls='groupAvatar' />
      )}
      <span>{event.title}</span>
      {event.start && event.end && (
        <div>{`${dayjs(new Date(event.start)).format('HH:mm')} - ${dayjs(
          new Date(event.end),
        ).format('HH:mm')}`}</div>
      )}
    </div>
  );
};
