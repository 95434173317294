import { MessageOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from '@inovirtue/components';

export type WorkshopChatButtonProps = { visible: boolean } & ButtonProps;
export const WorkshopChatButton = (props: WorkshopChatButtonProps) => {
  const { visible } = props;
  if (!visible) {
    return null;
  }

  return (
    <Button {...props}>
      <MessageOutlined /> Message group
    </Button>
  );
};
