import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo.Component';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks.Component';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../hooks/useSelectedParticipant/useSelectedParticipant';
import { room$ } from '../../store/video';
import { useBehaviorSubjectState } from '@inovirtue/hooks';

export interface MainParticipantProps {
  className?: string;
}

const MainParticipant = ({ className }: MainParticipantProps): JSX.Element => {
  const mainParticipant = useMainParticipant();
  const room = useBehaviorSubjectState(room$);
  const localParticipant = room?.localParticipant;
  const selectedParticipant = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;

  if (!mainParticipant) return <></>;

  return (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo className={className} participant={mainParticipant}>
      <ParticipantTracks
        videoOnly
        participant={mainParticipant}
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
};

export default MainParticipant;
