import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { ConversationsProvider } from '@inovirtue/chat';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { changeLanguage } from '@inovirtue/localization';
import { getBrowserLanguage } from '@inovirtue/localization/dist/getBrowserLanguage';
import { ConfigProvider } from 'antd';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_GB';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppEntry } from '../../../App/hooks/useAppEntry/useAppEntry';
import { InitializationStatus } from '../../../App/models/InitializationStatus';
import { getChatToken } from '../../../Modules/Chat/api/chat';
import { currentProfileName$ } from '../../../Modules/Profile/store';
import { AnonymousRouting } from '../../routes/AnonymousRouting';
import Routing from '../../routes/Routing/Routing';
import './App.scss';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const LANGUAGE = {
  en,
  de,
};

const App = () => {
  const { initStatus } = useAppEntry();
  const currentProfileName = useBehaviorSubjectState(currentProfileName$);

  useEffect(() => {
    const language = navigator.language.split('-')[0];
    changeLanguage(
      Object.keys(LANGUAGE).includes(language as keyof typeof LANGUAGE) ? language : 'en',
    );
  }, []);

  return (
    <ConfigProvider locale={getBrowserLanguage(LANGUAGE)}>
      <BrowserRouter>
        <AuthenticatedTemplate>
          {initStatus === InitializationStatus.completed && (
            <ConversationsProvider requestToken={() => getChatToken(currentProfileName)}>
              <Routing />
            </ConversationsProvider>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AnonymousRouting />
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
