import './ReadHorizon.scss';
import { t } from '@inovirtue/localization';

export const ReadHorizon = (): JSX.Element => {
  return (
    <div data-testid='read-horizon' className='read-horizon'>
      {t('chat:unread')}
    </div>
  );
};
