import { t } from '@inovirtue/localization';
import { ContactsIcon } from '../../icons/ContactsIcon';
import { Provider } from '../../model/Provider';
import { CardField } from '../CardField/CardField';
import { DetailsCardBase } from '../DetailsCardBase/DetailsCardBase';
import { Avatar } from 'antd';

import './WorkshopCoach.scss';

export type WorkshopCoachProps = {
  provider: Provider;
};

export const WorkshopCoach = ({ provider }: WorkshopCoachProps) => {
  return (
    <DetailsCardBase header={t('group:group-coach-section.section-title')} icon={<ContactsIcon />}>
      <CardField
        placement='vertical'
        label={t('group:group-coach-section.section-title')}
        value={
          <>
            <Avatar src={provider.avatarUrl} size='large' />
            <span className='workshopCoach__name'>{provider.displayName}</span>
          </>
        }
      />
    </DetailsCardBase>
  );
};
