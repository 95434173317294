import './SearchProfile.scss';
import { AutoComplete, Spin } from 'antd';
import { useProfileSearch } from '../../hooks/useProfileSearch';
import { SearchProfileItem } from '../../models/SearchProfileItem';
import SearchProfileInfo from '../SearchProfileInfo/SearchProfileInfo';
import { t } from '@inovirtue/localization';
import { useLayoutContext } from '@inovirtue/chat';
import { showToast } from '@inovirtue/components';
const { Option } = AutoComplete;

export interface SearchProfileProps {
  onCreated: () => void;
}

const SearchProfile = ({ onCreated }: SearchProfileProps): JSX.Element => {
  const { setSelectedConvoSid } = useLayoutContext();
  const { isSearching, isCreating, searchResult, handleSearch, createConversation } =
    useProfileSearch();

  const onSelect = async (profile: SearchProfileItem) => {
    try {
      const sid = await createConversation(profile);
      setSelectedConvoSid(sid);
      onCreated();
    } catch {
      showToast(t('errors:unhandled-error'));
    }
  };

  return (
    <Spin spinning={isCreating}>
      <div className='search-profile'>
        <AutoComplete
          className='search-profile__autocomplete'
          placeholder={t('common:search-users')}
          onSearch={handleSearch}
          data-testid={'search-profile'}>
          {isSearching && (
            <Option className='search-profile__spinner' key={'spinner'} value={'spinner'}>
              <Spin />
            </Option>
          )}
          {!isSearching &&
            searchResult.map((profile: SearchProfileItem) => (
              <Option key={profile.name} value={profile.name}>
                <SearchProfileInfo profileInfo={profile} onClick={() => onSelect(profile)} />
              </Option>
            ))}
        </AutoComplete>
        <p className='search-profile__text'>{t('chat:search-users-info')}</p>
      </div>
    </Spin>
  );
};

export default SearchProfile;
