import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useEffect, useMemo, useState } from 'react';
import ValidationResult from '../../ConsumerEdit/models/validationResult';
import { getCompanies } from '../api/companiesApi';
import { Company } from '../models/domain/Company';
import { t } from '@inovirtue/localization';

export const useCompanies = (companyId?: string) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const fetchConsumerMemo = useMemo(() => () => getCompanies(), []);
  const { data, isProcessing } = usePromiseWithProgress<Company[]>(fetchConsumerMemo);
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    isValid: false,
    validationMessage: t('consumer:consumer-edit-validation.company-required'),
  });

  useEffect(() => {
    setSelectedCompanyId(companyId);
  }, [companyId]);
  useEffect(() => {
    setValidationResult((currentState) => ({ ...currentState, isValid: !!selectedCompanyId }));
  }, [selectedCompanyId, companyId]);

  const onChange = (nextCompanyId: string) => setSelectedCompanyId(nextCompanyId);

  return {
    companies: data,
    selectedCompanyId,
    onChange,
    isProcessing,
    validationResult,
  };
};
