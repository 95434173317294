import './MenuBar.Component.scss';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton.Component';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import ConnectionTime from '../ConnectionTime/ConnectionTime.Component';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton.Component';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton.Component';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton.Component';
import { isMobile } from 'react-device-detect';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { isChat$, step$ } from '../../store';
import { ToggleChatButton } from '../Buttons/ToggleChatButton/ToggleChatButton';
import { chatButton$ } from '../../store/video-call-session';
import { useMemo } from 'react';
import { Step } from '../../store/models/Step.model';
import ToggleSettingsButton from '../Buttons/ToggleSettingsButton/ToggleSettingsButton.Component';

export interface MenuBarProps {
  convoSid?: string;
  disableTimer?: boolean;
}

const MenuBar = ({ convoSid, disableTimer = false }: MenuBarProps): JSX.Element => {
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const currentStep = useBehaviorSubjectState(step$);
  const isChat = useBehaviorSubjectState(isChat$);
  const chatButton = useBehaviorSubjectState(chatButton$);

  const isChatEnabled = useMemo(() => {
    return (
      isChat && (currentStep === Step.CallRoomStep || currentStep === Step.CallWaitingRoomStep)
    );
  }, [isChat, currentStep]);

  return (
    <div data-testid='videoCallMenuBar' className='video-menu-bar'>
      <div className='video-menu-bar__left'>
        <ConnectionTime disableTimer={disableTimer} />
      </div>
      <div className='video-menu-bar__center'>
        <ToggleAudioButton className='video-menu-bar__clear-btn' disabled={isReconnecting} />
        <EndCallButton />
        <ToggleVideoButton className='video-menu-bar__clear-btn' disabled={isReconnecting} />
      </div>
      <div className='video-menu-bar__right'>
        {!isMobile && (
          <>
            <ToggleSettingsButton />
            <ToggleScreenShareButton
              className='video-menu-bar__share-btn'
              disabled={isReconnecting}
            />
          </>
        )}
        {isChatEnabled && convoSid && <ToggleChatButton convoSid={convoSid} />}
        {isChatEnabled && !convoSid && chatButton}
      </div>
    </div>
  );
};

export default MenuBar;
