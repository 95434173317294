import './WorkshopListFilter.scss';
import { Form, Select } from 'antd';
import { useWorkshopsListFilter } from '../hooks/useWorkshopsListFilter';
import { t } from '@inovirtue/localization';

export type WorkshopListFilterFields = {
  companyId?: string;
};

export type WorkshopListFilterProps = {
  companyId?: string | null;
  onChanged?: (fields: WorkshopListFilterFields) => void;
};

export const WorkshopListFilter = ({ companyId, onChanged }: WorkshopListFilterProps) => {
  const { companies, isInitialized } = useWorkshopsListFilter();
  const [form] = Form.useForm<WorkshopListFilterFields>();

  return (
    <>
      {companies && isInitialized && (
        <Form form={form} initialValues={{ companyId }} onFinish={onChanged} layout='inline'>
          <Form.Item name={['companyId']}>
            <Select
              className='group-list-filter__company'
              showSearch
              allowClear
              placeholder={t('group:group-filter.company-placeholder')}
              optionLabelProp='title'
              onChange={() => {
                form.submit();
              }}
              filterOption={(input, option) => {
                const itemText = option?.title as string;
                return itemText.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {companies.map((c) => (
                <Select.Option key={`company_${c.companyId}`} value={c.companyId} title={c.name}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
