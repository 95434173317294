import  { useRef, useEffect } from 'react';
import './VideoTrack.Component.scss';
import { IVideoTrack } from '../../types';
import { Track } from 'twilio-video';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions/useVideoTrackDimensions';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { Breakpoints } from '../../styles/breakpoints/breakpoints';

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

const VideoTrack = ({ track, isLocal, priority }: VideoTrackProps): JSX.Element => {
  const ref = useRef<HTMLVideoElement>(null);
  const dimensions = useVideoTrackDimensions(track);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const isVideoPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isMediaRange = useMediaQuery({
    minWidth: Breakpoints.lgBreak,
    maxWidth: Breakpoints.xlBreak,
  });

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const videoTrackCls = classNames('video-track', {
    'video-track--mirrored': isLocal && isFrontFacing,
    'video-track--contain':
      isVideoPortrait || track.name.includes('screen') || (isPortrait && isMediaRange),
  });

  return <video className={videoTrackCls} id='video' ref={ref} />;
};

export default VideoTrack;
