import { ReactNode } from 'react';
import { ErrorProvider } from '../../contexts/ErrorContext';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';
import { ConversationHeader } from '../ConversationHeader/ConversationHeader';
import { ConversationInput } from '../ConversationInput/ConversationInput';
import { ConversationNav } from '../ConversationNav/ConversationNav';
import { ConversationTimeline } from '../ConversationTimeline/ConversationTimeline';
import './Conversation.scss';

export interface ConversationProps {
  children: ReactNode;
}

export const Conversation = ({ children }: ConversationProps): JSX.Element => {
  const { isConversation, selectedConvoSid } = useLayoutContext();
  return (
    <>
      {isConversation && selectedConvoSid && (
        <div className='conversation'>
          <ErrorProvider>{children}</ErrorProvider>
        </div>
      )}
    </>
  );
};

Conversation.Header = ConversationHeader;
Conversation.Input = ConversationInput;
Conversation.Timeline = ConversationTimeline;
Conversation.Nav = ConversationNav;
