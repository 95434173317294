import { useState, useCallback, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

const useControlCallendar = (
  onMonthChangeAction?: (date: Dayjs) => void | Promise<void>,
  currentDate?: Dayjs,
) => {
  const [insideDate, setInsideDate] = useState(() => (currentDate ? currentDate : dayjs()));
  const [monthsDiff, setMonthsDiff] = useState<number>(0);

  const onMonthChange = useCallback(
    (value: Dayjs, shift: number) => {
      const newDate = value.add(shift, 'month');
      onMonthChangeAction?.(newDate);
      setInsideDate(newDate);
    },
    [setInsideDate, onMonthChangeAction],
  );

  useEffect(() => {
    const now = dayjs();
    if (currentDate) {
      setMonthsDiff((currentDate.year() - now.year()) * 12 + (currentDate.month() - now.month()));
    }
  }, [insideDate, currentDate]);

  return {
    monthsDiff,
    insideDate,
    onMonthChange,
  };
};

export default useControlCallendar;
