import CompanyAdd from '../../../Modules/Companies/components/CompanyAdd/CompanyAdd';
import { useHistory } from 'react-router';
import { Modal } from '@inovirtue/components';
import routes from '../../../App/routes/constants/Routes';

const CompanyAddView = () => {
  const { push } = useHistory();
  return (
    <Modal isFullScreen={true} visible={true} onClose={() => push(routes.CompaniesList)}>
      <CompanyAdd />
    </Modal>
  );
};

export default CompanyAddView;
