import { t } from '@inovirtue/localization';
import { message } from 'antd';
import { useQueryClient } from 'react-query';
import { togglePartnerActiveState } from '../api/partnersApi';

const usePartnerActivateToggle = (isActive: boolean, name: string, id: string) => {
  const queryClient = useQueryClient();

  const handleToggle = async () => {
    await togglePartnerActiveState(name, isActive);

    await queryClient.invalidateQueries(['get_partner', id]);
    message.success(t('save-success'));
  };

  return handleToggle;
};

export default usePartnerActivateToggle;
