import { Card } from 'antd';
import './DetailsCardBase.scss';

export type DetailsCardBaseProps = {
  children: React.ReactElement;
  header: string;
  icon: React.ReactElement;
};

export const DetailsCardBase = ({ children, icon, header }: DetailsCardBaseProps) => {
  return (
    <Card>
      <div className='card-header'>
        <span className='card-header__icon'>{icon}</span>
        <div className='card-header__text'>
          <span>{header}</span>
        </div>
      </div>
      {children}
    </Card>
  );
};
