import { useCallback } from 'react';
import Provider from '../../Providers/models/Provider';
import { usePromiseWithProgress } from '@inovirtue/hooks';
import { getConsumerRemovedProviders } from '../../../Modules/Providers/api/Providers.Api';

export const useConsumerRemovedProviders = (profileName: string) => {
  const fetchProviderCallback = useCallback(
    () => getConsumerRemovedProviders(profileName),
    [profileName],
  );
  const { isProcessing, data } = usePromiseWithProgress<Provider[]>(fetchProviderCallback);

  return {
    providers: data ?? [],
    isProcessing,
  };
};
