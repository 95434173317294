import { Button } from '@inovirtue/components';
import { useSettingsButton } from '../../../hooks/useSettingsButton/useSettingsButton';

export interface ToggleSettingsButtonProps {
  className?: string;
}

const ToggleSettingsButton = ({ className }: ToggleSettingsButtonProps): JSX.Element => {
  const { icon, toggleSettings } = useSettingsButton();

  return (
    <>
      <Button
        onClick={toggleSettings}
        icon={icon}
        size='large'
        shape='circle'
        className={className}
        data-testid='settings-btn'
      ></Button>
    </>
  );
};

export default ToggleSettingsButton;
