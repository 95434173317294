import { useCallback, useEffect, useRef, useState } from 'react';
import { RequestToken } from '../../models/token';

export const useToken = (requestToken: RequestToken) => {
  const requestTokenRef = useRef<() => Promise<string>>(requestToken);
  const [token, setToken] = useState<string>();

  const getToken = useCallback(async () => {
    try {
      const conversationToken = await requestTokenRef.current();
      setToken(conversationToken);
    } catch {
      throw new Error('Unexpected error when fetching conversations token.');
    }
  }, []);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return { token, getToken };
};
