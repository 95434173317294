import { ReactNode } from 'react';
import { Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './StepNotice.Component.scss';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar.Component';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { roomType$ } from '../../store/video';
import {
  sessionAvatarUrl$,
  sessionDisplayName$,
  sessionDescription$,
} from '../../store/video-call-session';

const { Title, Paragraph } = Typography;

export interface StepDescriptionProps {
  children: ReactNode;
}

interface ContentProps {
  children: ReactNode;
}

interface ActionsProps {
  children: ReactNode;
}

interface TypographyProps {
  text: string;
}

const Heading = ({ text }: TypographyProps): JSX.Element => {
  return (
    <Title level={1} className='stepnotice-heading'>
      {text}
    </Title>
  );
};

const Subheading = ({ text }: TypographyProps): JSX.Element => {
  return (
    <Title level={2} className='stepnotice-subheading'>
      {text}
    </Title>
  );
};

const UserCard = (): JSX.Element => {
  const sessionDisplayName = useBehaviorSubjectState(sessionDisplayName$);
  const sessionAvatarUrl = useBehaviorSubjectState(sessionAvatarUrl$);
  const sessionDescription = useBehaviorSubjectState(sessionDescription$);
  const roomType = useBehaviorSubjectState(roomType$);

  return (
    <div className='stepnotice-usercard'>
      <ProfileAvatar
        src={sessionAvatarUrl}
        size={56}
        icon={roomType !== 'group' ?? <UserOutlined />}
        altText={sessionDisplayName}
      />
      <div className='stepnotice-usercard__details'>
        <Paragraph className='stepnotice-usercard__details__name'>{sessionDisplayName}</Paragraph>
        {sessionDescription && (
          <Paragraph className='stepnotice-usercard__details__description'>
            {sessionDescription}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

const Content = ({ children }: ContentProps): JSX.Element => {
  return <div className='stepnotice-content'>{children}</div>;
};

const Actions = ({ children }: ActionsProps): JSX.Element => {
  return <div className='stepnotice-actions'>{children}</div>;
};

const StepNotice = ({ children }: StepDescriptionProps): JSX.Element => {
  return <div className='stepnotice'>{children}</div>;
};

StepNotice.Heading = Heading;
StepNotice.SubHeading = Subheading;
StepNotice.Profile = UserCard;
StepNotice.Content = Content;
StepNotice.Actions = Actions;

export default StepNotice;
