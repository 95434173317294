import { CompanyDetails } from '@inovirtue/company';
import { Loader } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { dateFormat } from '../../../../App/constants/date';
import { useCompany } from '../../hooks/useCompany';
import { useCompanyUpdate } from '../../hooks/useCompanyUpdate';
import CompanyForm from '../CompanyForm/CompanyForm';

type CompanyDetailsFacadeProps = {
  companyId: string;
};

const CompanyDetailsFacade = ({ companyId }: CompanyDetailsFacadeProps) => {
  const { company, isProcessing } = useCompany(companyId);
  const { onSubmit } = useCompanyUpdate();
  const { companyEditFlag } = useFlags();
  return (
    <div>
      {!isProcessing ? (
        <CompanyDetails
          isEditable={companyEditFlag}
          editForm={<CompanyForm onSubmit={onSubmit} formData={company} />}
          contractStartDate={
            moment(company?.licenseStartDate)?.format(dateFormat) || t('common:not-defined')
          }
          contractEndDate={
            company?.licenseEndDate ? moment(company?.licenseEndDate)?.format(dateFormat) : ''
          }
          contractStatus={company?.companyLicenseStatus || 'Unknown'}
          addressCompanyName={company?.name || ''}
          addressLine1={company?.addressLine1 || ''}
          addressLine2={company?.addressLine2 || ''}
          zip={company?.postalCode || ''}
          city={company?.city || ''}
          country={company?.country || ''}
        />
      ) : (
        <div className='company-details-view__loader' data-testid={'company-details-loader'}>
          <Loader spaceSize={'middle'} spinSize={'default'} />
        </div>
      )}
    </div>
  );
};

export default CompanyDetailsFacade;
