import Tag from 'antd/lib/tag';
import { t } from '@inovirtue/localization';
import './ContractStatusTag.scss';

export type ContractStatus = 'NotStarted' | 'Active' | 'ExpiringSoon' | 'Expired' | 'Unknown';

type ContractStatusTagProps = {
  status: ContractStatus;
};

const contractStatusContainerName = 'contract-status-tag';
const resolveContractStatusTag = (status: ContractStatus) => {
  switch (status) {
    case 'NotStarted':
      return (
        <Tag
          data-testid='contract-status-not-started'
          className={`${contractStatusContainerName}__not-started`}
        >
          {t('company:contract-statuses.not-started')}
        </Tag>
      );
    case 'Expired':
      return (
        <Tag
          data-testid='contract-status-expired'
          className={`${contractStatusContainerName}__expired`}
        >
          {t('company:contract-statuses.expired')}
        </Tag>
      );
    case 'Active':
      return (
        <Tag
          data-testid='contract-status-active'
          className={`${contractStatusContainerName}__active`}
        >
          {t('company:contract-statuses.active')}
        </Tag>
      );
    case 'ExpiringSoon':
      return (
        <Tag
          data-testid='contract-status-expiring-soon'
          className={`${contractStatusContainerName}__expiring-soon`}
        >
          {t('company:contract-statuses.expiring-soon')}
        </Tag>
      );
    default:
      return (
        <Tag
          data-testid='contract-status-unknown'
          className={`${contractStatusContainerName}__unknown`}
        >
          {t('company:contract-statuses.unknown')}
        </Tag>
      );
  }
};

export const ContractStatusTag = ({ status }: ContractStatusTagProps): JSX.Element => (
  <span className={contractStatusContainerName}>{resolveContractStatusTag(status)}</span>
);
