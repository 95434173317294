import { showToast } from '@inovirtue/components';
import { t } from 'i18next';
import { useState } from 'react';
import { useHistory, generatePath } from 'react-router';
import routes from '../../../../App/routes/constants/Routes';
import { validateSlots, confirmBooking } from '../../api/schedulerApi';
import { WorkshopSlotList } from '../../model/SlotModel';

const useConfirmBooking = (id: string, coachName: string, sessionsSlots: WorkshopSlotList) => {
  const history = useHistory();
  const [isChecking, setIsChecking] = useState<boolean>(false);

  const checkTimeSlotsAvailability = async () => {
    try {
      const data = await validateSlots({
        providerName: coachName as string,
        timeSlots: sessionsSlots.map((slot) => ({
          id: slot.id,
          startDateTime: new Date(slot.appoitmentsDetails?.startDateTime as unknown as Date),
          endDateTime: new Date(slot.appoitmentsDetails?.endDateTime as unknown as Date),
          durationMinutes: slot.appoitmentsDetails?.duration as number,
        })),
      });
      return data;
    } catch (err) {
      showToast(t('errors:unhandled-error'));
    }
  };

  const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const bookSlots = async () => {
    try {
      await confirmBooking({
        consumerWorkshopId: id,
        providerProfileName: coachName as string,
        sessions: sessionsSlots.map((el) => ({
          startDateTime: new Date(el.appoitmentsDetails?.startDateTime as unknown as Date),
          endDateTime: new Date(el.appoitmentsDetails?.endDateTime as unknown as Date),
        })),
      });

      //backend creates sessions asynchronously, slight delay to wait for that
      await wait(1000);

      history.push(generatePath(routes.WorkshopDetailsSessionsList, { id }));
    } catch (err) {
      showToast(t('errors:unhandled-error'));
    }
  };

  const confirmBookingSlots = async () => {
    setIsChecking(true);
    try {
      const data = await checkTimeSlotsAvailability();
      if (!data?.allAvailable) return data?.results.filter((el) => !el.isAvailable);
      await bookSlots();
    } finally {
      setIsChecking(false);
    }
  };

  return {
    confirmBookingSlots,
    isChecking,
  };
};

export default useConfirmBooking;
