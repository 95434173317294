import Video, { CreateLocalTrackOptions, LocalVideoTrack, Room, TwilioError } from 'twilio-video';
import { getDeviceInfo, isMobile } from '../../utils';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../../constants/constants';
import { RecordingRules, RoomType } from '../../types';
import { BackgroundSettings } from '../../components/VideoProvider/useBackgroundSettings/useBackgroundSettings';
import { VideoSettings, VideoSettingsAction, videoStore } from './video.store';
import { localVideoTrack$ } from '.';
import { localAudioTrack$ } from '../audio';
import getConnectionOptions from '../../utils/getConnectionOptions';
import { v4 as uuidv4 } from 'uuid';
import { setError, setIsConnecting, setStep } from '../video-call.service';
import { Step } from '../models/Step.model';
import { Observable } from 'rxjs';
import { SessionExtendedNotification } from './models/sessionExtendedNotification.model';

export const initVideoInputDevices = async (): Promise<void> => {
  const { videoInputDevices, hasVideoInputDevices } = await getDeviceInfo();
  videoStore.update((prev) => {
    const hasSelectedVideoDevice = videoInputDevices.some(
      ({ deviceId }) => deviceId === prev.selectedVideoInputDeviceId,
    );

    const selectedVideoInputDeviceId = hasSelectedVideoDevice
      ? prev.selectedVideoInputDeviceId
      : undefined;

    return {
      ...prev,
      videoInputDevices: videoInputDevices,
      hasVideoInputDevices: hasVideoInputDevices,
      selectedVideoInputDeviceId,
    };
  });
};

export const getLocalVideoTrack = async (): Promise<LocalVideoTrack> => {
  const selectedVideoInputDeviceId = videoStore.getValue().selectedVideoInputDeviceId;
  const { videoInputDevices } = await getDeviceInfo();
  const hasSelectedVideoDevice = videoInputDevices.some(
    ({ deviceId }) => selectedVideoInputDeviceId && deviceId === selectedVideoInputDeviceId,
  );

  const options = {
    ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, unknown>),
    name: `camera-${uuidv4()}`,
    ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoInputDeviceId ?? '' } }),
  };

  const localVideoTrack = await Video.createLocalVideoTrack(options as CreateLocalTrackOptions);
  videoStore.update((prev) => ({
    ...prev,
    localVideoTrack,
  }));

  return localVideoTrack;
};

export const setLocalVideoTrack = (deviceId: string): void => {
  localVideoTrack$.getValue()?.restart({
    ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, unknown>),
    deviceId: { exact: deviceId },
  });
  videoStore.update((prev) => ({
    ...prev,
    selectedVideoInputDeviceId: deviceId,
  }));
};

export const removeLocalVideoTrack = (): void => {
  const videoTrack = localVideoTrack$.getValue();

  if (videoTrack) {
    videoTrack.stop();
    videoStore.update((prev) => ({
      ...prev,
      localVideoTrack: undefined,
    }));
  }
};

const connect = (token: string): Promise<void> => {
  const localTracks = [localAudioTrack$.getValue(), localVideoTrack$.getValue()].filter(
    (track) => track !== undefined,
  );
  const connectionOptions = getConnectionOptions();

  return Video.connect(token, { ...connectionOptions, tracks: localTracks }).then((newRoom) => {
    videoStore.update((prev) => ({
      ...prev,
      room: newRoom,
    }));

    const disconnect = async () => {
      newRoom.disconnect();
    };
    newRoom.setMaxListeners(15);

    newRoom.once('disconnected', () => {
      videoStore.update((prev) => ({
        ...prev,
        selectedParticipant: null,
        screenShareParticipant: null,
        profileParticipants: new Map(),
        room: null,
      }));

      window.removeEventListener('beforeunload', disconnect);

      if (isMobile) {
        window.removeEventListener('pagehide', disconnect);
      }
    });

    newRoom.localParticipant.videoTracks.forEach((publication) => publication.setPriority('low'));

    window.addEventListener('beforeunload', disconnect);

    if (isMobile) {
      window.addEventListener('pagehide', disconnect);
    }
  });
};

export const setSettings = (settings: VideoSettings): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    settings: settings,
  }));
};

export const setBackgroundSettings = (backgroundSettings: BackgroundSettings): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    backgroundSettings: backgroundSettings,
  }));
};

export const dispatchSetting = (action: VideoSettingsAction): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    [action.name]: action.value === 'default' ? undefined : action.value,
  }));
};

export const setSessionsExtendedSubject = (
  sessionExtendedNotification: Observable<SessionExtendedNotification>,
): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    sessionExtendedNotification,
  }));
};

export const setOnRequestToken = (
  callback: (name: string, room: string, roomType: string) => Promise<string>,
): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    onRequestToken: callback,
  }));
};

export const setOnSessionSubscribe = (
  onSessionJoinSubscribe?: (sessionId: string, participant: string) => Promise<void>,
): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    onSessionJoinSubscribe,
  }));
};

export const setOnSessionUnsubscribe = (
  onSessionJoinUnsubscribe?: (sessionId: string, participants: string[]) => Promise<void>,
): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    onSessionJoinUnsubscribe,
  }));
};

export const setFetchServerDate = (fetchServerDate: () => Promise<Date>) => {
  videoStore.update((oldState) => ({
    ...oldState,
    fetchServerDate,
  }));
};

export const setOnSessionExtended = (
  onSessionExtended?: (sessionId: string, endDate: Date) => Promise<void>,
): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    onSessionExtended,
  }));
};

export const setOnRedirect = (onRedirect: () => void): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    onRedirect,
  }));
};

export const setOnRejoin = (onRejoin?: () => void): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    onRejoin,
  }));
};

export const setIsDisconnectedByUser = (isDisconnectedByUser: boolean) => {
  videoStore.update((oldState) => ({
    ...oldState,
    isDisconnectedByUser,
  }));
};

const getToken = async (name: string, room: string, roomType: string): Promise<string> => {
  const result = await videoStore.getValue().onRequestToken(name, room, roomType);
  return result;
};

const setIsSubscribedToSession = (isSubscribedToSession: boolean) => {
  videoStore.update((oldState) => ({
    ...oldState,
    isSubscribedToSession,
  }));
};

const onSessionJoinSubscribe = async (sessionId: string, participant: string) => {
  const subscribe = videoStore.getValue().onSessionJoinSubscribe;
  if (!subscribe) return;

  await subscribe(sessionId, participant);
  setIsSubscribedToSession(true);
};

export const onSessionJoinUnsubscribe = async (sessionId: string, participants: string[]) => {
  const isSubscribed = videoStore.getValue().isSubscribedToSession;
  const unsubscribe = videoStore.getValue().onSessionJoinUnsubscribe;
  if (!isSubscribed || !unsubscribe || !sessionId || !participants || !participants?.length) return;
  await unsubscribe(sessionId, participants);
  setIsSubscribedToSession(false);
};

export const joinRoom = async (
  name: string,
  roomName: string,
  roomType: string,
  joinWaitingRoom: boolean,
): Promise<void> => {
  try {
    setIsConnecting(true);
    setIsDisconnectedByUser(false);
    await connect(await getToken(name, roomName, roomType));
    await onSessionJoinSubscribe(roomName, name);
    setStep(joinWaitingRoom ? Step.CallWaitingRoomStep : Step.CallRoomStep);
  } catch (e) {
    setError(e as TwilioError | Error | null);
  } finally {
    setIsConnecting(false);
  }
};

export const updateRecordingRules = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _room_sid: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _rules: RecordingRules,
): Promise<unknown> =>
  /* const apiClient = await getApiClientAsync(`${config.twilioVideoConfig.twilioTokenServerUrl}`);*/ /* return apiClient.post('recordingrules', {*/ /*   room_sid,*/ /*   rules,*/ /* });*/ Promise.resolve();

export const setRoom = (room: Room | null): void => {
  videoStore.update({ room: room });
};

export const setIsScreenSharing = (isScreenSharing: boolean): void => {
  videoStore.update({ isScreenSharing: isScreenSharing });
};

export const setIsChatOpen = (isChatOpen: boolean): void => {
  videoStore.update((prev) => ({
    ...prev,
    isChatOpen,
  }));
};

export const setIsSettingsOpen = (isSettingsOpen: boolean): void => {
  videoStore.update((prev) => ({
    ...prev,
    isSettingsOpen,
  }));
};

export const toggleIsChatOpen = (): void => {
  videoStore.update((prev) => ({
    ...prev,
    isChatOpen: !prev.isChatOpen,
  }));
};

export const setIsBackgroundSelectionOpen = (value: boolean): void => {
  videoStore.update({ isBackgroundSelectionOpen: value });
};

export const onRedirect = () => videoStore.getValue().onRedirect();

export const onRejoin = () => {
  setError(null);
  setStep(Step.CallPrepStep);
  videoStore.getValue().onRejoin?.();
};

export const setRoomType = (roomType: RoomType): void => {
  videoStore.update((oldState) => ({
    ...oldState,
    roomType,
  }));
};

export const onSessionExtended = (sessionId: string, endDate: Date) => {
  const extend = videoStore.getValue().onSessionExtended;
  if (!extend) return;
  extend(sessionId, endDate);
};

export const fetchServerDate = () => videoStore.getValue().fetchServerDate();

export const getSessionExtendedNotification = () =>
  videoStore.getValue().sessionExtendedNotification;
