import { t } from '@inovirtue/localization';
import PartnerFormComponent from '../PartnerForm/PartnerForm';
import styles from './PartnerAdd.module.scss';

const PartnerAdd = () => {
  return (
    <>
      <h1>{t('partners:details.create-header')}</h1>
      <h2 className={styles.createPartner__description}>
        {t('partners:details.create-description')}
      </h2>
      <PartnerFormComponent />
    </>
  );
};

export default PartnerAdd;
