import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { HTMLAttributes, MouseEventHandler, ReactNode, useMemo } from 'react';
import { useConvoSelector } from '../../hooks/storeHooks/storeHooks';
import { useConversationType } from '../../hooks/useConversationType/useConversationType';
import { useFriendlyName } from '../../hooks/useFriendlyName/useFriendlyName';
import { Message as MessageType } from '../../models/message';
import { selectIdentity } from '../../store/commonSlice';
import './Message.scss';

export interface MessageProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isSameAsPrev: boolean;
  convoSid: string;
  author: MessageType['author'];
  dateCreated: MessageType['dateCreated'];
  onClick?: MouseEventHandler;
  className?: string;
  contentCls?: string;
}

export const Message = ({
  children,
  isSameAsPrev,
  convoSid,
  author,
  dateCreated,
  onClick,
  className,
  contentCls,
  ...divAttributes
}: MessageProps): JSX.Element => {
  const identity = useConvoSelector(selectIdentity);
  const { isOneOnOne, isSupport } = useConversationType(convoSid);
  const { getFriendlyName } = useFriendlyName(convoSid);
  const isOutgoing = useMemo(() => author === identity, [identity, author]);
  const formattedDate = useMemo(
    () => dateCreated && format(parseISO(dateCreated), 'hh:mm a'),
    [dateCreated],
  );

  const conversationMessageCls = classNames(
    'conversation-message',
    {
      'conversation-message--author-hidden': isOneOnOne || isSupport,
      'conversation-message--outgoing': isOutgoing,
      'conversation-message--gap': !isSameAsPrev,
      'conversation-message--clickable': !!onClick,
    },
    className,
  );

  return (
    <div
      className={conversationMessageCls}
      data-testid='conversation-message'
      onClick={onClick}
      {...divAttributes}>
      <div className='conversation-message__author'>{getFriendlyName(author)}</div>
      <span data-testid='conversation-message-body' className={contentCls}>
        {children}
      </span>
      {!!dateCreated && (
        <>
          <span className='conversation-message__date'>{formattedDate}</span>
          <span className='conversation-message__date-hidden' aria-hidden='true'>
            {formattedDate}
          </span>
        </>
      )}
    </div>
  );
};
