import classNames from 'classnames';
import { useMemo } from 'react';
import './CardField.scss';
type CardFieldProps = {
  label: string;
  value: string | React.ReactNode;
  placement?: 'vertical' | 'horizontal';
  className?: string;
};

export const CardField = ({
  label,
  value,
  placement = 'horizontal',
  className,
}: CardFieldProps) => {
  const cardFieldClass = useMemo(
    () =>
      classNames(
        'card-field',
        {
          'card-field--vertical': placement === 'vertical',
        },
        className,
      ),
    [className, placement],
  );
  return (
    <div className={cardFieldClass}>
      <span className='card-field__label'>{label}</span>
      <span className='card-field__value'>{value}</span>
    </div>
  );
};
