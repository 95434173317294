import { Popover } from '@inovirtue/components';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { t } from '@inovirtue/localization';
import { Typography } from 'antd';
import classNames from 'classnames';
import { useVideoCallJoin } from '../../hooks/useVideoCallJoin/useVideoCallJoin';
import { canJoin$, isConnecting$, roomName$ } from '../../store';
import { Button } from '../Buttons/Button/Button.Component';
import './DeviceSelection.Component.scss';
import InputOutputDevices from './InputOutputDevices/InputOutputDevices';

const { Title } = Typography;

export interface DeviceSelectionProps {
  className?: string;
  onCancel: () => void;
}

const DeviceSelection = ({ className, onCancel }: DeviceSelectionProps): JSX.Element => {
  const isConnecting = useBehaviorSubjectState(isConnecting$);
  const roomName = useBehaviorSubjectState(roomName$);
  const canJoin = useBehaviorSubjectState(canJoin$);
  const onJoinClick = useVideoCallJoin(roomName);

  const popoverClass = classNames('device-selection', className);

  return (
    <Popover
      className={popoverClass}
      contentCls={'device-selection__content'}
      title={
        <Title className='device-selection__heading' level={2}>
          {t('videoCall:call-prep.peripherals')}
        </Title>
      }
      actions={[
        <Button
          className='device-selection__action-button'
          spinning={isConnecting}
          disabled={!canJoin}
          type='primary'
          size='large'
          key='join-now'
          onClick={onJoinClick}
          data-testid='deviceselection-join-btn'
        >
          {t('videoCall:join-btn')}
        </Button>,
        <Button
          className='device-selection__action-button'
          type='default'
          size='large'
          key='cancel'
          onClick={onCancel}
        >
          {t('common:cancel')}
        </Button>,
      ]}
    >
      <InputOutputDevices />
    </Popover>
  );
};

export default DeviceSelection;
