import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useEffect, useState } from 'react';
import {
  getLocalVideoTrack,
  hasVideoInputDevices$,
  localVideoTrack$,
  removeLocalVideoTrack,
  room$,
  selectedVideoInputDeviceId$,
} from '../../store/video';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import {
  addCameraEnabledParticipant,
  removeCameraEnabledParticipant,
} from '../../store/participant';

export default function useLocalVideoToggle(): readonly [boolean, () => void, boolean] {
  const hasVideoInputDevices = useBehaviorSubjectState(hasVideoInputDevices$);
  const localVideoTrack = useBehaviorSubjectState(localVideoTrack$);
  const selectedVideoInputDeviceId = useBehaviorSubjectState(selectedVideoInputDeviceId$);
  const room = useBehaviorSubjectState(room$);
  const localParticipant = room?.localParticipant;
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (localVideoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(localVideoTrack);
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
        if (localParticipant?.identity) removeCameraEnabledParticipant(localParticipant?.identity);
      } else {
        setIsPublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) =>
            localParticipant?.publishTrack(track, { priority: 'low' }),
          )
          .finally(() => {
            setIsPublishing(false);
          });

        if (localParticipant?.identity) addCameraEnabledParticipant(localParticipant?.identity);
      }
    }
  }, [isPublishing, localParticipant, localVideoTrack]);

  useEffect(() => {
    setIsDisabled(!hasVideoInputDevices || !selectedVideoInputDeviceId);
  }, [hasVideoInputDevices, selectedVideoInputDeviceId]);

  return [!!localVideoTrack, toggleVideoEnabled, isDisabled] as const;
}
