import VideoCallSteps from '../VideoCallSteps/VideoCallSteps.Component';
import { ReactNode, useEffect, useState } from 'react';
import { setStep, setVideoCallOptions, step$ } from '../../store';
import { Step } from '../../store/models/Step.model';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import useAttachVisibilityHandler from '../../hooks/useAttachVisibilityHandler/useAttachVisibilityHandler';
import useHandleRoomDisconnection from '../../hooks/useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from '../../hooks/useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useRestartOnDeviceChange from '../../hooks/useRestartAudioTrackOnDeviceChange/useRestartOnDeviceChange';
import { setCurrentProfile, setParticipantDetails } from '../../store/participant';
import { ProfileParticipant } from '../../store/participant/models/profileParticipant.model';
import {
  setFetchServerDate,
  setOnRedirect,
  setOnRejoin,
  setOnRequestToken,
  setOnSessionExtended,
  setOnSessionSubscribe,
  setOnSessionUnsubscribe,
  setRoomType,
  setSessionsExtendedSubject,
} from '../../store/video';
import { RoomType } from '../../types';
import { Observable } from 'rxjs';
import { SessionExtendedNotification } from '../../store/video/models/sessionExtendedNotification.model';
import { setVideoCallSessionDetails } from './../../store/video-call-session/video-call-session.service';
import { FullPageLoader } from '@inovirtue/components';

export interface VideoCallProps {
  roomName: string;
  canJoin: boolean;
  canRejoin?: boolean;
  startDate: string;
  endDate: string | null;
  currentProfile: ProfileParticipant;
  participants: ProfileParticipant[];
  roomType: RoomType;
  sessionExtendedNotification?: Observable<SessionExtendedNotification>;
  fetchServerDate: () => Promise<Date>;
  onRedirect: () => void;
  onRejoin?: () => void;
  onRequestToken: (name: string, room: string, roomType: string) => Promise<string>;
  onSessionJoinSubscribe?: (sessionId: string, profileName: string) => Promise<void>;
  onSessionJoinUnsubscribe?: (sessionId: string, profileNames: string[]) => Promise<void>;
  onSessionExtended?: (sessionId: string, endDate: Date) => Promise<void>;
  workshopName?: string;
  workshopId?: string;
  isChat?: boolean;
  isDismissed?: boolean;
  leftCall?: ReactNode;
  chatButton?: ReactNode;
  className?: string;
}

export const VideoCall = ({
  roomName,
  canJoin,
  canRejoin = false,
  startDate,
  endDate,
  currentProfile,
  participants,
  roomType,
  sessionExtendedNotification,
  fetchServerDate,
  onRedirect,
  onRejoin,
  onRequestToken,
  onSessionJoinSubscribe,
  onSessionJoinUnsubscribe,
  onSessionExtended,
  workshopName,
  workshopId,
  isChat = false,
  isDismissed = false,
  leftCall,
  chatButton,
  className,
}: VideoCallProps): JSX.Element => {
  const currentStep = useBehaviorSubjectState(step$);
  const [isInitialized, setIsInitialized] = useState<boolean>();
  const [isParticipantValid, setIsParticipantValid] = useState<boolean>();

  useEffect(() => {
    if (isInitialized) return;

    setRoomType(roomType);
    setCurrentProfile(currentProfile);
    setOnRequestToken(onRequestToken);
    setOnRedirect(onRedirect);
    setOnRejoin(onRejoin);
    if (sessionExtendedNotification) setSessionsExtendedSubject(sessionExtendedNotification);
    setOnSessionSubscribe(onSessionJoinSubscribe);
    setOnSessionUnsubscribe(onSessionJoinUnsubscribe);
    setOnSessionExtended(onSessionExtended);
    setFetchServerDate(fetchServerDate);
    const sessionParticipant =
      roomType === 'group' ? undefined : participants.find((x) => x.name !== currentProfile.name);
    setVideoCallSessionDetails({
      sessionId: roomName,
      workshopId,
      avatarUrl: sessionParticipant?.avatarUrl,
      displayName: roomType === 'group' ? workshopName : sessionParticipant?.displayName,
      description: roomType === 'group' ? '' : sessionParticipant?.description,
      startDate,
      endDate,
      leftCall,
      chatButton,
    });
    participants?.forEach((participant) => setParticipantDetails(participant.name, participant));
    setIsParticipantValid(
      participants.some(
        (s) => s.name?.toLocaleLowerCase() === currentProfile?.name?.toLocaleLowerCase(),
      ),
    );
    setIsInitialized(true);
  }, [
    currentProfile,
    isInitialized,
    sessionExtendedNotification,
    roomName,
    onRedirect,
    onRejoin,
    onRequestToken,
    onSessionExtended,
    onSessionJoinSubscribe,
    onSessionJoinUnsubscribe,
    roomType,
    fetchServerDate,
    workshopName,
    workshopId,
    participants,
    startDate,
    endDate,
    leftCall,
    chatButton,
  ]);

  useAttachVisibilityHandler();
  useHandleRoomDisconnection();
  useHandleTrackPublicationFailed();
  useRestartOnDeviceChange();

  useEffect(() => {
    setStep(Step.CallPrepStep);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    setVideoCallOptions({
      roomName,
      roomType,
      isRoomValid: canJoin,
      canRejoin,
      isChat,
    });

    if (currentStep === Step.CallPrepStep && (!canJoin || !isParticipantValid))
      setStep(Step.CallLeftStep);

    if (isDismissed) setStep(Step.NoAnswerStep);
  }, [
    roomName,
    roomType,
    canJoin,
    canRejoin,
    currentStep,
    startDate,
    endDate,
    isInitialized,
    isParticipantValid,
    isChat,
    isDismissed,
  ]);

  return !isInitialized ? <FullPageLoader /> : <VideoCallSteps className={className} />;
};
