import { Col, Row } from 'antd';
import StepNotice from '../../StepNotice/StepNotice.Component';
import { t } from '@inovirtue/localization';
import { Button } from '../../Buttons/Button/Button.Component';
import { onRedirect } from '../../../store/video';
import { useEffect } from 'react';
import { useMediaTracksSwitchOff } from '../../../hooks/useMediaTracksSwitchOff/useMediaTracksSwitchOff';
import './CallExpiredStep.Component.scss';

const { Actions, Heading, SubHeading } = StepNotice;

const CallExpiredStep = (): JSX.Element => {
  const { switchOffMediaTracks } = useMediaTracksSwitchOff();

  useEffect(() => {
    switchOffMediaTracks();
  }, [switchOffMediaTracks]);

  return (
    <div className='callexpiredstep' data-testid='videoCallExpiredStep'>
      <Row>
        <Col>
          <StepNotice>
            <Heading text={t('videoCall:call-error.invalid-link-heading')} />
            <SubHeading text={t('videoCall:call-error.invalid-link-subheading')} />
            <Actions>
              <Button type='primary' onClick={onRedirect} className='callexpiredstep__button'>
                {t('videoCall:homepage-btn')}
              </Button>
            </Actions>
          </StepNotice>
        </Col>
      </Row>
    </div>
  );
};

export default CallExpiredStep;
