import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { ProfileParticipant } from '@inovirtue/video-call';
import { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { currentProfileDisplayName$, currentProfileName$ } from '../../../../Modules/Profile/store';
import { useSessionParticipants } from './../../../Sessions/hooks/useSessionParticipants/useSessionParticipants';
import {
  disableFullPageLoader,
  enableFullPageLoader,
} from './../../../../App/state/global-state.service';
import routes from './../../../../App/routes/constants/Routes';
import { getToken } from './../../api/videoCallApi';
import { useCanJoinSessionVideoCall } from '../../../Sessions/hooks/useCanJoinSessionVideoCall/useCanJoinSessionVideoCall';
import { RoomType } from '@inovirtue/video-call/dist/types';

interface VideoCallViewParams {
  roomName: string;
}

export default function useVideoCallInit() {
  const history = useHistory();
  const currentProfileName = useBehaviorSubjectState(currentProfileName$);
  const currentProfileDisplayName = useBehaviorSubjectState(currentProfileDisplayName$);
  const { roomName } = useParams<VideoCallViewParams>();
  const { canJoin, startDate, endDate, serverTime, workshop } =
    useCanJoinSessionVideoCall(roomName);
  const { sessionParticipants, isLoading } = useSessionParticipants(roomName);

  const roomType: RoomType = useMemo(
    () => (sessionParticipants ? (sessionParticipants?.length <= 2 ? 'go' : 'group') : 'go'),
    [sessionParticipants],
  );

  useEffect(() => {
    if (isLoading) enableFullPageLoader();
    else disableFullPageLoader();
  }, [isLoading]);

  const onRequestToken = useCallback(() => {
    return currentProfileName
      ? getToken(currentProfileName, roomName, roomType)
      : Promise.reject('current profile is required');
  }, [currentProfileName, roomName, roomType]);
  const onRedirect = useCallback(() => history.push(routes.Home), [history]);

  return {
    roomName,
    roomType,
    currentProfile: {
      name: currentProfileName,
      displayName: currentProfileDisplayName,
    } as ProfileParticipant,
    participants: sessionParticipants ?? [],
    isInitialized: !isLoading,
    canJoin: canJoin,
    startDate,
    endDate,
    serverTime,
    onRequestToken,
    onRedirect,
    workshop,
  };
}
