import { useCallback } from 'react';
import { getProviderSessions } from '../api/Calendar.Api';
import { useScheduler, Scheduler } from './useSchedule';
import dayjs from 'dayjs';
import { CalendarEvent } from '../models/CalendarEvent';
import { CalendarEventsTypesEnum } from '../models/CalendarEventsTypesEnum';
import PersonalAndWorkshopSessionDto from '../models/PersonalAndWorkshopSessionDto';

export interface ResourceSchedule extends Scheduler {
  onSideCalendarDateSet(date: Date): Promise<void>;
}

export const mapSessionsToEvents = (sessions: PersonalAndWorkshopSessionDto[]): CalendarEvent[] => {
  return sessions.map((s) => {
    if (s.workshop)
      return {
        id: s.id,
        type: CalendarEventsTypesEnum.groupEvent,
        title: `${s.numberOfGroupSession}/${s.workshop.sessionsInTotal} - ${s.workshop.name}`,
        groupName: s.workshop.name,
        start: new Date(s.plannedStartDateTime),
        end: new Date(s.plannedEndDateTime),
        allDay: false,
        avatarUrl: undefined,
      };
    else {
      const consumer = s.consumers[0];
      return {
        id: s.id,
        type: CalendarEventsTypesEnum.event,
        title: consumer.displayName,
        start: new Date(s.plannedStartDateTime),
        end: new Date(s.plannedEndDateTime),
        allDay: false,
        avatarUrl: consumer.avatarUrl,
      };
    }
  });
};

const useProviderCalendar = (profileName: string): ResourceSchedule => {
  const getAppointmentsV2 = useCallback(
    async (startDate: Date, endDate: Date): Promise<CalendarEvent[]> => {
      return mapSessionsToEvents(await getProviderSessions(profileName, startDate, endDate));
    },
    [profileName],
  );

  const scheduler = useScheduler(getAppointmentsV2, () => Promise.resolve([]));

  const getRangeStart = (date: Date): Date => {
    if (scheduler.view === 'month') return dayjs(date).startOf('month').toDate();
    if (scheduler.view === 'week') return dayjs(date).startOf('week').toDate();
    return date;
  };

  const getRangeEnd = (date: Date): Date => {
    if (scheduler.view === 'month') return dayjs(date).endOf('month').toDate();
    if (scheduler.view === 'week') return dayjs(date).endOf('week').toDate();
    return date;
  };

  const onSideCalendarDateSet = async (date: Date): Promise<void> => {
    await scheduler.setAllEventTypes(getRangeStart(date), getRangeEnd(date));
  };

  return { onSideCalendarDateSet, ...scheduler };
};

export default useProviderCalendar;
