import { useState, useMemo, useEffect, useCallback } from 'react';
import { View, dateFnsLocalizer, DateLocalizer, stringOrDate } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import getDay from 'date-fns/getDay';
import { enGB } from 'date-fns/locale';
import { CalendarEvent } from '../models/CalendarEvent';

export interface Scheduler {
  date: Date;
  setDate(date: Date, view?: View): void;
  view: View;
  setView(view: View): void;
  localizer: DateLocalizer;
  onRangeChange(newRange: Date[] | { start: stringOrDate; end: stringOrDate }): Promise<void>;
  events: CalendarEvent[];
  setEvents(events: CalendarEvent[]): void;
  getMonthEvents(): Promise<void>;
  setAllEventTypes(start: Date, end: Date): Promise<void>;
  loading: boolean;
}

export const useScheduler = (
  getEvents: (start: Date, end: Date) => Promise<CalendarEvent[]>,
  getBlockSlots: (start: Date, end: Date, view: View) => Promise<CalendarEvent[]>,
): Scheduler => {
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [config, setConfig] = useState<{
    date: Date;
    view: View;
  }>({
    date: new Date(),
    view: 'month',
  });

  const setAllEventTypes = useCallback(
    async (start: Date, end: Date) => {
      try {
        setLoading(true);
        const calendarEvents = await getEvents(start, end);
        const blocksEvents = await getBlockSlots(start, end, config.view);
        setEvents([...calendarEvents, ...blocksEvents]);
      } finally {
        setLoading(false);
      }
    },
    [getEvents, getBlockSlots, config.view],
  );

  const getMonthEvents = useCallback(async () => {
    const today = new Date();
    await setAllEventTypes(startOfMonth(today), endOfMonth(today));
  }, [setAllEventTypes]);

  const localizer = useMemo(
    () =>
      dateFnsLocalizer({
        format,
        parse,
        startOfWeek: () => startOfWeek(config.date, { weekStartsOn: 1 }),
        getDay,
        locales: { 'en-GB': enGB },
      }),
    [config.date],
  );

  useEffect(() => {
    const initializeApppointments = async () => {
      await getMonthEvents();
      setInitialized(true);
    };

    if (!initialized) {
      initializeApppointments();
    }
  }, [getMonthEvents, initialized]);

  const onRangeChange = async (dateRange: Date[] | { start: Date; end: Date }): Promise<void> => {
    const range = dateRange as { start: Date; end: Date };
    if (range.start && range.end) {
      await setAllEventTypes(range.start, range.end);
    } else {
      const arr = dateRange as Date[];
      await setAllEventTypes(arr[0], arr[arr.length - 1]);
    }
  };

  const setView = (view: View) => setConfig({ date: config.date, view });
  const setDate = (date: Date, view?: View) => {
    setConfig({ view: view ? view : config.view, date });
  };

  return {
    view: config.view,
    setView,
    date: config.date,
    setDate,
    localizer,
    onRangeChange,
    events,
    setEvents,
    getMonthEvents,
    setAllEventTypes,
    loading,
  };
};
