import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

export function useObservableWithProgress<T>(observable: () => Observable<T>): {
  isProcessing: boolean;
  data?: T;
  error?: unknown;
} {
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    setIsProcessing(true);

    const subscription = observable().subscribe({
      next: (next) => setData(next),
      error: (err) => setError(err),
      complete: () => setIsProcessing(false),
    });

    return () => subscription.unsubscribe();
  }, [observable]);

  return {
    isProcessing,
    data,
    error,
  };
}
