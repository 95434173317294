import { createQuery } from '@datorama/akita';
import { BehaviorSubject } from 'rxjs';
import profileStore, { ProfileState } from './profile.store';

export const profileStateQuery = createQuery<ProfileState>(profileStore);

export const currentProfileName$ = new BehaviorSubject(
  profileStateQuery.getValue().currentProfileName,
);
profileStateQuery.select((s) => s.currentProfileName).subscribe(currentProfileName$);

export const currentProfileDisplayName$ = new BehaviorSubject(
  profileStateQuery.getValue().currentProfileDisplayName,
);
profileStateQuery.select((s) => s.currentProfileDisplayName).subscribe(currentProfileDisplayName$);

export const roles$ = new BehaviorSubject(profileStateQuery.getValue().roles);
profileStateQuery.select((s) => s.roles).subscribe(roles$);
