import { t } from '@inovirtue/localization';
import { Avatar } from 'antd';
import classNames from 'classnames';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';
import { Participant } from '../../models/participants';
import './ParticipantsAvatarGroup.scss';

export interface ParticipantsAvatarGroupProps {
  participants: Participant[];
  className: string;
}

export const ParticipantsAvatarGroup = ({
  participants,
  className,
}: ParticipantsAvatarGroupProps): JSX.Element => {
  const { isTabView } = useLayoutContext();
  const participantsAvatarGroupCls = classNames('participants-avatar-group', className);
  return (
    <div className={participantsAvatarGroupCls}>
      <span className='participants-avatar-group__text'>
        {t('chat:avatar-group-participants', { count: participants.length })}
      </span>
      {!isTabView && (
        <Avatar.Group className='participants-avatar-group__avatar-list' maxCount={5}>
          {participants.map((participant, index) => {
            return (
              <Avatar
                className='participants-avatar-group__avatar'
                key={index}
                src={participant.attributes.avatarUrl}
              >
                {participant.attributes.displayName.charAt(0).toUpperCase()}
              </Avatar>
            );
          })}
        </Avatar.Group>
      )}
    </div>
  );
};
