const routes: Record<string, string> = {
  ProfilePreview: '/coaches/:profileName',
  ProvidersList: '/coaches',
  ConsumerEdit: '/clients/:name',
  SignedIn: '/signed-in',
  SignedOut: '/signed-out',
  ProviderCalendar: '/coach/:name/calendar',
  CompaniesList: '/companies',
  CompanyAdd: '/companies/add',
  CompanyDetails: '/companies/:id',
  WorkshopsList: '/workshops',
  WorkshopsBooking: '/workshops/:id/booking',
  WorkshopDetails: '/workshops/:id/details',
  WorkshopDetailsMembers: '/workshops/:id/members',
  WorkshopDetailsSessionsList: '/workshops/:id/sessions',
  WorkshopAddMembers: '/workshops/:id/addMembers',
  WorkshopEditMembers: '/workshops/:id/editMembers',
  WorkshopCreate: '/workshops/create',
  WorkshopEdit: '/workshops/{:id}/edit',
  Employees: '/employees',
  PendingEmployees: '/employees/pending',
  WaitingForCoachEmployees: '/employees/waitingForCoach',
  Chat: '/chat/:sid?',
  InvitationsList: '/invitations',
  VideoCall: '/videocall/:roomName',
  Partners: '/partners',
  PartnersCreate: '/partners/create',
  PartnerEditProviders: '/partners/:id/editProviders',
  PartnerDetailsGeneral: '/partners/:id',
  PartnerDetails: '/partners/:id/details',
  PartnerDetailsProviders: '/partners/:id/providers',
  PartnerEdit: '/partners/:id/edit',
};

export default routes;
