import { useEffect, useMemo, useRef } from 'react';
import {
  selectMessagesByConvoSid,
  selectAllMessages,
  fetchMessages,
} from '../../store/messagesSlice';
import { useConvoDispatch, useConvoSelector } from '../storeHooks/storeHooks';
import { useLayoutContext } from '../useLayoutContext/useLayoutContext';

export const useConversationTimeline = () => {
  const timelineRef = useRef<HTMLInputElement>(null);
  const dispatch = useConvoDispatch();
  const { selectedConvoSid } = useLayoutContext();
  const messageEntity = useConvoSelector(selectMessagesByConvoSid(selectedConvoSid || ''));
  const messages = useMemo(
    () => (messageEntity ? selectAllMessages(messageEntity.messages) : []),
    [messageEntity],
  );

  useEffect(() => {
    if (!selectedConvoSid) return;

    dispatch(fetchMessages(selectedConvoSid));
  }, [selectedConvoSid, dispatch]);

  useEffect(() => {
    const element = timelineRef.current;
    if (!element || !element.scrollHeight) return;
    element.scrollTo({
      top: element.scrollHeight,
    });
  }, [selectedConvoSid, messages]);

  return { timelineRef, messages, convoSid: messageEntity?.sid || '' };
};
