import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';
import { getParticipantDetails } from '../../store/participant';
import { ProfileParticipant } from '../../store/participant/models/profileParticipant.model';

export default function useParticipantDetails(
  participant: Participant,
): ProfileParticipant | undefined {
  const [profileParticipantDetails, setProfileParticipantDetails] = useState<
    ProfileParticipant | undefined
  >();

  useEffect(() => {
    (async () => {
      if (!participant?.identity) return;
      const profile = getParticipantDetails(participant?.identity);
      setProfileParticipantDetails(profile);
    })();
  }, [participant?.identity]);

  return profileParticipantDetails;
}
