import { t } from '@inovirtue/localization';
import { RcFile } from 'antd/lib/upload/interface';
import { useCallback } from 'react';
import { useErrorContext } from '../useErrorContext/useErrorContext';

export const useFileUpload = () => {
  const { setError } = useErrorContext();

  const validateFile = useCallback(
    (file: RcFile): boolean => {
      const maxFileSize = 150;
      const isLessThan150M = file.size / 1024 / 1024 < maxFileSize;

      if (!isLessThan150M) setError(t('chat:errors.exceeded-size', { count: maxFileSize }));

      return isLessThan150M;
    },
    [setError],
  );

  return {
    validateFile,
  };
};
