import React from 'react';
import EmployeeInfo from '../../models/EmployeeInfo';
import { AvatarCollection } from '../AvatarCollection/AvatarCollection';
import { Tag } from 'antd';
import { t } from '@inovirtue/localization';
import useActiveEmployeesCoachAvatars from '../../hooks/useActiveEmployeesCoachAvatars';
import { UserOutlined } from '@ant-design/icons';

const ActiveEmployeesCoachAvatars = ({ employee }: { employee: EmployeeInfo }) => {
  const { avatars } = useActiveEmployeesCoachAvatars(employee);

  return (
    <>
      {avatars ? (
        <AvatarCollection avatars={avatars} />
      ) : (
        <Tag icon={<UserOutlined />} color='orange'>
          {t('company:employees:active-employees-table.no-coach-selected')}
        </Tag>
      )}
    </>
  );
};

export default ActiveEmployeesCoachAvatars;
