import './ProfileAvatar.Component.scss';
import { Avatar, AvatarProps } from 'antd';
import classNames from 'classnames';
import { useCallback } from 'react';
export interface ProfileAvatarProps extends AvatarProps {
  badgeSize?: 'normal' | 'medium';
  showBadge?: boolean;
  className?: string;
  altText?: string;
}

export const ProfileAvatar = ({
  altText,
  className,
  ...avatarProps
}: ProfileAvatarProps): JSX.Element => {
  const profileAvatarCls = classNames('profile-avatar-container', className);
  const getFirstLetters = useCallback(() => {
    if (altText) {
      const [firstChar, secondChar] = altText.split(' ').map((x) => x[0]?.toUpperCase());
      return `${firstChar}${secondChar || ''}`;
    }

    return '';
  }, [altText]);

  return (
    <div className={profileAvatarCls}>
      <Avatar {...avatarProps}>{getFirstLetters()}</Avatar>
    </div>
  );
};
