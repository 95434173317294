import {
  Conversation as TwilioConversation,
  Message as TwilioMessage,
  Media as TwilioMedia,
  Participant as TwilioParticipant,
} from '@twilio/conversations';
import {
  Convo,
  ConversationProperties,
  conversationProperties,
  ConvoPicked,
  ConvoAttributes,
} from '../../models/conversation';
import {
  mediaProperties,
  MediaProperties,
  Message,
  MessagePicked,
  messageProperties,
  MessageProperties,
} from '../../models/message';
import {
  Participant,
  ParticipantAttributes,
  ParticipantPicked,
  participantProperties,
  ParticipantProperties,
} from '../../models/participants';

function extract<Type, Properties extends keyof Type>(
  item: Type,
  props: ReadonlyArray<Properties>,
): Pick<Type, Properties> {
  return props.reduce<Pick<Type, Properties>>((obj, key) => {
    const value = item[key];

    return { ...obj, [key]: value };
  }, {} as Pick<Type, Properties>);
}

function transformConvo(extracted: ConvoPicked): Convo {
  return {
    ...extracted,
    attributes: extracted.attributes as ConvoAttributes,
    dateCreated: extracted.dateCreated?.toISOString() || null,
    dateUpdated: extracted.dateCreated?.toISOString() || null,
    lastMessage: {
      ...extracted.lastMessage,
      dateCreated: extracted.lastMessage?.dateCreated?.toISOString() || null,
    },
  };
}

export function serializeConversation(conversation: TwilioConversation): Convo;
export function serializeConversation(conversation: TwilioConversation[]): Convo[];
export function serializeConversation(
  conversation: TwilioConversation | TwilioConversation[],
): Convo | Convo[] {
  if (!Array.isArray(conversation)) {
    const extracted = extract<TwilioConversation, ConversationProperties>(
      conversation,
      conversationProperties,
    );

    return transformConvo(extracted);
  }

  return conversation.map((conversationItem) => {
    const extracted = extract<TwilioConversation, ConversationProperties>(
      conversationItem,
      conversationProperties,
    );

    return transformConvo(extracted);
  });
}

function transformMessage(extracted: MessagePicked): Message {
  const transformedAttachedMedia =
    extracted.attachedMedia?.map((media) =>
      extract<TwilioMedia, MediaProperties>(media, mediaProperties),
    ) || null;
  return {
    ...extracted,
    dateCreated: extracted.dateCreated?.toISOString() || null,
    dateUpdated: extracted.dateCreated?.toISOString() || null,
    attachedMedia: transformedAttachedMedia,
  };
}

export function serializeMessage(message: TwilioMessage): Message;
export function serializeMessage(message: TwilioMessage[]): Message[];
export function serializeMessage(message: TwilioMessage | TwilioMessage[]): Message | Message[] {
  if (!Array.isArray(message)) {
    const extracted = extract<TwilioMessage, MessageProperties>(message, messageProperties);
    return transformMessage(extracted);
  }

  return message.map((messageItem) => {
    const extracted = extract<TwilioMessage, MessageProperties>(messageItem, messageProperties);
    return transformMessage(extracted);
  });
}

function transformParticipant(extracted: ParticipantPicked): Participant {
  return {
    ...extracted,
    attributes: extracted.attributes as ParticipantAttributes,
  };
}

export function serializeParticipant(participant: TwilioParticipant): Participant;
export function serializeParticipant(participant: TwilioParticipant[]): Participant[];
export function serializeParticipant(
  participant: TwilioParticipant | TwilioParticipant[],
): Participant | Participant[] {
  if (!Array.isArray(participant)) {
    const extracted = extract<TwilioParticipant, ParticipantProperties>(
      participant,
      participantProperties,
    );
    return transformParticipant(extracted);
  }

  return participant.map((participantItem) => {
    const extracted = extract<TwilioParticipant, ParticipantProperties>(
      participantItem,
      participantProperties,
    );
    return transformParticipant(extracted);
  });
}
