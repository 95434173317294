const fileSizeExtensions = ['KB', 'MB'];

export const formatFileSize = (fileSize: number): string => {
  let size = fileSize;
  let i = -1;
  do {
    size /= 1024;
    i++;
  } while (size >= 1024);

  return `${Math.round(size * 10) / 10} ${fileSizeExtensions[i]}`;
};
