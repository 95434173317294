import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { t } from '@inovirtue/localization';
import { useCallback } from 'react';
import { setError } from '../../store';
import { currentProfile$, setParticipantDetails } from '../../store/participant';
import { joinRoom, roomType$ } from '../../store/video';
import { ProfileTypes } from '@inovirtue/profile';

export const useVideoCallJoin = (roomName: string): (() => void) => {
  const currentProfile = useBehaviorSubjectState(currentProfile$);

  const roomType = useBehaviorSubjectState(roomType$);

  const onJoinClick = useCallback(async () => {
    if (!currentProfile?.name) {
      setError(new Error(t('videoCall:call-prep.invalid-profile-error')));
      return;
    }

    setParticipantDetails(currentProfile.name, currentProfile);
    const joinWaitingRoom = currentProfile.type === ProfileTypes.ConsumerProfile;

    await joinRoom(currentProfile?.name, roomName, roomType as string, joinWaitingRoom);
  }, [currentProfile, roomName, roomType]);

  return onJoinClick;
};
