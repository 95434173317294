import {
  Conversation,
  Conversations,
  ConversationsList,
  ConversationsListItem,
  TabPane,
} from '@inovirtue/chat';
import { t } from '@inovirtue/localization';
import { useMediaQuery } from 'react-responsive';
import { toggleIsChatOpen } from '../../store/video';
import { Breakpoints } from '../../styles/breakpoints/breakpoints';

const { Sidebar } = Conversations;
const { Nav, Header, Input, Timeline } = Conversation;
export interface ChatProps {
  convoSid: string;
}

export const Chat = ({ convoSid }: ChatProps) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mdBreak });

  return (
    <Conversations isTabView={true} activeConvoSid={convoSid}>
      <Sidebar>
        <TabPane id='messages' title={t('chat:messages')}>
          <ConversationsList
            renderItem={(convo) => <ConversationsListItem convoSid={convo.sid} />}
          />
        </TabPane>
      </Sidebar>
      <Conversation>
        {!isMobile && <Nav isBack={false} isClose onClose={toggleIsChatOpen} />}
        <Header isBack={false} isClose={isMobile} onClose={toggleIsChatOpen} />
        <Timeline />
        <Input />
      </Conversation>
    </Conversations>
  );
};
