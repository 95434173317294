import { ClockCircleOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { t } from '@inovirtue/localization';

import './WorkshopsBooking.scss';
import { BookSlot } from '@inovirtue/booking';
import { Button, Popover } from '@inovirtue/components';
import SessionCarousel from '../../components/SessionCarousel/SessionCarousel';
import ProvidersSeachSelect from '../../../Providers/components/ProvidersSearchSelect/ProvidersSearchSelect';
import { Form } from 'antd';
import useBookWorkshops from '../../hooks/useBookWorkshops/useBookWorkshops';

import { useHistory } from 'react-router';
import dayjs from 'dayjs';

const WorkshopsBooking = (): JSX.Element => {
  const {
    timeSlots,
    selectCoach,
    getSlots,
    clearCoach,
    onChooseSlot,
    sessionsSlots,
    setActiveSessionSlot,
    confirmBookAction,
    isChecking,
  } = useBookWorkshops();
  const history = useHistory();
  return (
    <Popover
      isFullScreen
      onClose={() => history.goBack()}
      actions={[
        <Button
          key='shedule-session'
          loading={isChecking}
          onClick={confirmBookAction}
          disabled={
            sessionsSlots.filter(
              (el) => el.appoitmentsDetails === undefined || el.error === undefined,
            ).length !== 0 || isChecking
          }>
          {t('group:incomplete-group-bar:schedule-sessions')}
        </Button>,
      ]}>
      <Content className='scheduleMeeting'>
        <>
          <header className='scheduleMeeting-header'>
            <h1 className='scheduleMeeting-header-title'>
              {t('scheduler:workshops:booking-page-title')}
            </h1>
          </header>
          <main className='scheduleMeeting-body'>
            <h2>{t('scheduler:workshops:select-coach')}</h2>

            <Form.Item
              className='invite-employees-content__company-select'
              label={t('scheduler:workshops:select-coach')}
              required>
              <ProvidersSeachSelect changeAction={selectCoach} onClear={clearCoach} />
            </Form.Item>
            <h2>{t('scheduler:workshops:schedule-session')}</h2>
            <p className='scheduleMeeting-header-duration'>
              <ClockCircleOutlined />
              <span className='scheduleMeeting-header-duration__subTitle'>
                {t('scheduler:duration')} 3 {t('common:hours')}
              </span>
            </p>
            <SessionCarousel elements={sessionsSlots} onClickSlot={setActiveSessionSlot} />
            <BookSlot
              backToDate
              timeSlots={timeSlots}
              currentDate={dayjs()}
              description={
                <p className='scheduleMeeting-header-bookInfo'>
                  {t('scheduler:schedulePage-note', { numberOfDays: 90 })}
                </p>
              }
              onChooseSlot={onChooseSlot}
              getTimeSlots={getSlots}
            />
          </main>
        </>
      </Content>
    </Popover>
  );
};

export default WorkshopsBooking;
