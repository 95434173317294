import { createStore, persistState, PersistStateSelectFn } from '@datorama/akita';
import { LocalVideoTrack, Track, VideoBandwidthProfileOptions } from 'twilio-video';
import { BackgroundSettings } from '../../components/VideoProvider/useBackgroundSettings/useBackgroundSettings';
import { SELECTED_BACKGROUND_SETTINGS_KEY } from '../../constants/constants';
import { RoomType } from '../../types';
import { Room } from 'twilio-video';
import { Observable } from 'rxjs';
import { SessionExtendedNotification } from './models/sessionExtendedNotification.model';

const STORE_KEY = 'video';

export interface VideoState {
  room: Room | null;
  hasVideoInputDevices: boolean;
  videoInputDevices: MediaDeviceInfo[];
  selectedVideoInputDeviceId: string;
  localVideoTrack: LocalVideoTrack;
  settings: VideoSettings;
  roomType?: RoomType;
  backgroundSettings: BackgroundSettings | null;
  isScreenSharing: boolean;
  isChatOpen: boolean;
  isSettingsOpen: boolean;
  isBackgroundSelectionOpen: boolean;
  isSubscribedToSession: boolean;
  isDisconnectedByUser: boolean;
  sessionExtendedNotification: Observable<SessionExtendedNotification>;
  fetchServerDate: () => Promise<Date>;
  onRequestToken: (name: string, room: string, roomType: string) => Promise<string>;
  onRedirect: () => void;
  onRejoin?: () => void;
  onSessionJoinSubscribe?: (sessionId: string, participant: string) => Promise<void>;
  onSessionJoinUnsubscribe?: (sessionId: string, participants: string[]) => Promise<void>;
  onSessionExtended?: (sessionId: string, endDate: Date) => Promise<void>;
}

export interface VideoSettings {
  trackSwitchOffMode: VideoBandwidthProfileOptions['trackSwitchOffMode'];
  dominantSpeakerPriority?: Track.Priority;
  bandwidthProfileMode: VideoBandwidthProfileOptions['mode'];
  maxAudioBitrate: string;
  contentPreferencesMode?: 'auto' | 'manual';
  clientTrackSwitchOffControl?: 'auto' | 'manual';
}

type SettingsKeys = keyof VideoSettings;

export interface VideoSettingsAction {
  name: SettingsKeys;
  value: string;
}

const initialSettings: VideoSettings = {
  trackSwitchOffMode: undefined,
  dominantSpeakerPriority: 'standard',
  bandwidthProfileMode: 'collaboration',
  maxAudioBitrate: '16000',
  contentPreferencesMode: 'auto',
  clientTrackSwitchOffControl: 'auto',
};

const initialState: Partial<VideoState> = {
  videoInputDevices: [],
  hasVideoInputDevices: false,
  settings: initialSettings,
  roomType: 'go' as RoomType,
  backgroundSettings: window.localStorage.getItem(
    SELECTED_BACKGROUND_SETTINGS_KEY,
  ) as BackgroundSettings | null,
  isScreenSharing: false,
  isChatOpen: false,
  isBackgroundSelectionOpen: false,
  isSubscribedToSession: false,
};

export const videoStore = createStore<VideoState>(initialState, {
  name: STORE_KEY,
  resettable: true,
});

const selectSettings: PersistStateSelectFn<VideoState> = (state) => ({
  backgroundSettings: state.backgroundSettings,
  selectedVideoInputDeviceId: state.selectedVideoInputDeviceId,
});
selectSettings.storeName = STORE_KEY;
persistState({
  include: [STORE_KEY],
  select: [selectSettings],
  key: 'selected-video-settings',
});

export const inputLabels = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const target: any = {};
  for (const setting in initialSettings) {
    target[setting] = setting as SettingsKeys;
  }
  return target as { [key in SettingsKeys]: string };
})();
