import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { Workshop } from '@inovirtue/admin';
import { message } from 'antd';
import { createWorkshop } from '../api/workshopApi';
import { t } from '@inovirtue/localization';

export const useCreateWorkshop = () => {
  const onSubmit = async (workshop: Workshop) => {
    try {
      enableFullPageLoader();
      const createdGroup = await createWorkshop(workshop);
      message.success(t('save-success'));
      return createdGroup;
    } catch (err) {
      message.error(t('save-error'));
      return null;
    } finally {
      disableFullPageLoader();
    }
  };
  return {
    onSubmit,
  };
};
