import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  Conversation as TwilioConversation,
  Participant as TwilioParticipant,
} from '@twilio/conversations';
import { getConversationParticipants } from '../api/participant';
import { SelectedConvoSid } from '../models/conversation';
import { Participant } from '../models/participants';
import { serializeParticipant } from '../utils/serialize/serialize';
import { assembleConversation, assembleSubscribedConversations } from './conversationsSlice';
import { ConversationsState } from './store';

export interface RoomEntity {
  sid: TwilioConversation['sid'];
  participatns: EntityState<Participant>;
}

export const roomAdapter = createEntityAdapter<RoomEntity>({
  selectId: (entity) => entity.sid,
});
export const participantsAdapter = createEntityAdapter<Participant>({
  selectId: (participant) => participant.sid,
});

export const fetchParticipants = createAsyncThunk(
  'participants/fetchParticipants',
  async (conversation: TwilioConversation) => {
    const participants = await getConversationParticipants(conversation);
    return {
      convoSid: conversation.sid,
      participants,
    };
  },
);

const getRoomEntity = (conversation: TwilioConversation, participants: TwilioParticipant[]) => {
  const convoParticipants = serializeParticipant(participants);
  const emptyAdapterState = participantsAdapter.getInitialState();
  const initialState = participantsAdapter.setAll(emptyAdapterState, convoParticipants);

  return {
    sid: conversation.sid,
    participatns: initialState,
  };
};

const participantsSlice = createSlice({
  name: 'participants',
  initialState: roomAdapter.getInitialState(),
  reducers: {
    addParticipant: (
      state: EntityState<RoomEntity>,
      action: PayloadAction<{ convoSid: string; participant: TwilioParticipant }>,
    ) => {
      const { convoSid, participant } = action.payload;
      const serializedParticipant = serializeParticipant(participant);
      const roomEntity = state.entities[convoSid];
      if (roomEntity) {
        participantsAdapter.addOne(roomEntity.participatns, serializedParticipant);
      }
    },
    upsertParticipant: (
      state: EntityState<RoomEntity>,
      action: PayloadAction<{ convoSid: string; participant: TwilioParticipant }>,
    ) => {
      const { convoSid, participant } = action.payload;
      const serializedParticipant = serializeParticipant(participant);
      const roomEntity = state.entities[convoSid];
      if (roomEntity) {
        participantsAdapter.upsertOne(roomEntity.participatns, serializedParticipant);
      }
    },
    removeParticipant: (
      state: EntityState<RoomEntity>,
      action: PayloadAction<{ convoSid: string; participantSid: TwilioParticipant['sid'] }>,
    ) => {
      const { convoSid, participantSid } = action.payload;
      const roomEntity = state.entities[convoSid];
      if (roomEntity) {
        participantsAdapter.removeOne(roomEntity.participatns, participantSid);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(assembleSubscribedConversations.fulfilled, (state, action) => {
      const { conversations, participants } = action.payload;
      const roomEntities = conversations.items.map<RoomEntity>((conversation) => {
        return getRoomEntity(conversation, participants[conversation.sid]);
      });

      roomAdapter.setAll(state, roomEntities);
    });

    builder.addCase(assembleConversation.fulfilled, (state, action) => {
      const { conversation, participants } = action.payload;
      const roomEntity = getRoomEntity(conversation, participants);

      roomAdapter.addOne(state, roomEntity);
    });
  },
});

export const { addParticipant, upsertParticipant, removeParticipant } = participantsSlice.actions;

export const participantsReducer = participantsSlice.reducer;

const { selectById } = roomAdapter.getSelectors((state: ConversationsState) => state.participants);

const { selectAll } = participantsAdapter.getSelectors();

export const selectAllParticipants = (participantEntity: EntityState<Participant> | undefined) => {
  if (!participantEntity) return [];

  return selectAll(participantEntity);
};

export const selectParticipantsByConvoId = (convoSid: SelectedConvoSid) => {
  if (!convoSid) return () => undefined;

  return createSelector(
    (state: ConversationsState) => state,
    (state) => selectById(state, convoSid),
  );
};
