import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getCompanyContacts } from '../api/companiesApi';
import { Consumer } from '@inovirtue/admin';

export const useCompanyContactList = (companyId: string) => {
  const fetchContacts = useCallback(() => getCompanyContacts(companyId), [companyId]);
  const { data, isProcessing, process } = usePromiseWithProgress<Consumer[]>(fetchContacts);

  const refresh = useCallback(() => {
    process();
  }, [process]);

  return {
    contacts: data,
    isProcessing,
    refresh,
  };
};
