import { getServerTime } from '../../api/sessionsApi';
import { useState, useEffect } from 'react';

export const useServerTime = (): string => {
  const [serverTime, setServerTime] = useState<string>();

  useEffect(() => {
    const fetchServerTime = async () => {
      const time = await getServerTime();
      const dateISOString = new Date(time.serverTime).toISOString();
      setServerTime(dateISOString);
    };
    if (!serverTime) fetchServerTime();
  });

  return serverTime ?? '';
};
