import { ClockCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { t } from '@inovirtue/localization';

export type AwaitingTimeProps = {
  createdAt: string;
};

const ranges = [
  {
    hours: 24,
    type: 'urgent',
    color: '#FAAD14',
    preset: 'orange',
  },
  {
    hours: 48,
    type: 'overdue',
    color: '#FF4D4F',
    preset: 'red',
  },
];

const findRange = (diffInHours: number) => {
  const matchingRanges = ranges.filter((x) => diffInHours >= x.hours);
  if (matchingRanges) {
    return matchingRanges[matchingRanges.length - 1];
  }
};

export const AwaitingTime = ({ createdAt }: AwaitingTimeProps): JSX.Element => {
  const date = dayjs(createdAt);
  const diff = dayjs().diff(date, 'hour');
  const range = findRange(diff);

  const label = range ? (
    <Tag color={range?.preset}>{t(`common:warning.${range?.type}`)}</Tag>
  ) : null;

  return (
    <div style={{ color: range?.color }}>
      <ClockCircleOutlined />
      {t('consumer:waiting-for', { diff })} {label}
    </div>
  );
};
