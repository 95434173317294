import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Conversation as TwilioConversation } from '@twilio/conversations';
import {
  getAllLastReadMessagesIndex,
  getAllUnreadMessages,
  getUnreadMessages,
  setAllMessagesRead,
} from '../api/unreadMessages';
import { LastMessage } from '../models/conversation';
import { LastReadMessageIndex } from '../models/unreadMessages';
import { addMessage } from './messagesSlice';
import { ConversationsState } from './store';

export interface UnreadMessagesEntity {
  sid: TwilioConversation['sid'];
  unreadMessages: number;
}

export interface LastReadMessageIndexEntity {
  sid: TwilioConversation['sid'];
  lastReadMessageIndex: LastReadMessageIndex;
  lastMessageIndex: LastMessage['index'];
}

const unreadMessagesAdapter = createEntityAdapter<UnreadMessagesEntity>({
  selectId: (entity) => entity.sid,
});

const lastReadMessagesIndexAdapter = createEntityAdapter<LastReadMessageIndexEntity>({
  selectId: (entity) => entity.sid,
});

export interface HorizonState {
  unreadMessages: EntityState<UnreadMessagesEntity>;
  lastReadMessagesIndex: EntityState<LastReadMessageIndexEntity>;
}

const initialState: HorizonState = {
  unreadMessages: unreadMessagesAdapter.getInitialState(),
  lastReadMessagesIndex: lastReadMessagesIndexAdapter.getInitialState(),
};

export const fetchHorizon = createAsyncThunk<
  {
    unreadMessagesEntity: UnreadMessagesEntity;
    lastReadMessageIndexEntity: LastReadMessageIndexEntity;
    isConvoFocused: boolean;
  },
  TwilioConversation,
  { state: ConversationsState }
>('horizon/fetchHorizon', async (conversation: TwilioConversation, { getState }) => {
  const { focusedConvoSid } = getState().common;
  const convoSid = conversation.sid;
  const isConvoFocused = convoSid === focusedConvoSid;
  let number = 0;

  if (isConvoFocused) {
    setAllMessagesRead(conversation);
  } else {
    number = await getUnreadMessages(conversation);
  }

  return {
    unreadMessagesEntity: { sid: convoSid, unreadMessages: number },
    lastReadMessageIndexEntity: {
      sid: convoSid,
      lastReadMessageIndex: conversation.lastReadMessageIndex,
      lastMessageIndex: conversation.lastMessage?.index,
    },
    isConvoFocused,
  };
});

export const fetchAllHorizons = createAsyncThunk(
  'horizon/fetchAllHorizons',
  async (conversations: TwilioConversation[]) => {
    const unreadMessagesEntities = await getAllUnreadMessages(conversations);
    const lastReadMessageIndexEntities = await getAllLastReadMessagesIndex(conversations);

    return { unreadMessagesEntities, lastReadMessageIndexEntities };
  },
);

const horizonSlice = createSlice({
  name: 'horizon',
  initialState,
  reducers: {
    updateLastReadMessageIndex: (
      state: HorizonState,
      action: PayloadAction<{ convoSid: TwilioConversation['sid']; index: LastReadMessageIndex }>,
    ) => {
      const { convoSid, index } = action.payload;
      const lastReadMessageIndexEntity = state.lastReadMessagesIndex.entities[convoSid];
      let lastReadMessageIndex = index;

      if (
        index === null &&
        lastReadMessageIndexEntity &&
        lastReadMessageIndexEntity.lastMessageIndex
      ) {
        lastReadMessageIndex = lastReadMessageIndexEntity.lastMessageIndex;
      }

      lastReadMessagesIndexAdapter.updateOne(state.lastReadMessagesIndex, {
        id: convoSid,
        changes: {
          lastReadMessageIndex,
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHorizon.fulfilled, (state, action) => {
      const { unreadMessagesEntity, lastReadMessageIndexEntity, isConvoFocused } = action.payload;

      if (!isConvoFocused) {
        lastReadMessagesIndexAdapter.upsertOne(
          state.lastReadMessagesIndex,
          lastReadMessageIndexEntity,
        );
      }

      unreadMessagesAdapter.upsertOne(state.unreadMessages, unreadMessagesEntity);
    });

    builder.addCase(fetchAllHorizons.fulfilled, (state, action) => {
      const { unreadMessagesEntities, lastReadMessageIndexEntities } = action.payload;

      unreadMessagesAdapter.upsertMany(state.unreadMessages, unreadMessagesEntities);
      lastReadMessagesIndexAdapter.upsertMany(
        state.lastReadMessagesIndex,
        lastReadMessageIndexEntities,
      );
    });

    builder.addCase(addMessage, (state, action) => {
      const { convoSid, message, identity } = action.payload;

      if (message.author === identity) {
        lastReadMessagesIndexAdapter.updateOne(state.lastReadMessagesIndex, {
          id: convoSid,
          changes: {
            lastReadMessageIndex: message.index,
            lastMessageIndex: message.index,
          },
        });
      }

      lastReadMessagesIndexAdapter.updateOne(state.lastReadMessagesIndex, {
        id: convoSid,
        changes: { lastMessageIndex: message.index },
      });
    });
  },
});

export const { updateLastReadMessageIndex } = horizonSlice.actions;

export const horizonReducer = horizonSlice.reducer;

const unreadMessagesSelectors = unreadMessagesAdapter.getSelectors();
const lastReadMessagesIndexSelectors = lastReadMessagesIndexAdapter.getSelectors();

export const selectTotalUnreadMessages = (state: ConversationsState) => {
  const unreadMessages = unreadMessagesSelectors.selectAll(state.horizon.unreadMessages);
  return unreadMessages.reduce((acc, curr) => acc + curr.unreadMessages, 0);
};

export const selectUnreadMessagesBySid = (convoSid: TwilioConversation['sid']) =>
  createSelector(
    (state: ConversationsState) => state.horizon.unreadMessages,
    (state) => unreadMessagesSelectors.selectById(state, convoSid)?.unreadMessages,
  );

export const selectLastReadMessageIndexBySid = (convoSid: TwilioConversation['sid']) =>
  createSelector(
    (state: ConversationsState) => state.horizon.lastReadMessagesIndex,
    (state) => lastReadMessagesIndexSelectors.selectById(state, convoSid)?.lastReadMessageIndex,
  );
