import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Consumer } from '../model/Consumer';

const MAX_MEMBERS_COUNT = 47;

export const useEditWorkshopMembers = (
  allEmployees: Consumer[],
  initialMembers: string[],
  onChanged: (members: Consumer[]) => void,
) => {
  const [currentMembers, setCurrentMembers] = useState<Consumer[]>([]);
  const isInitializedRef = useRef(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (isInitializedRef.current) {
      return;
    }
    setCurrentMembers([
      ...allEmployees.filter((e) => initialMembers.findIndex((x) => x == e.name) > -1),
    ]);
    isInitializedRef.current = true;
  }, [initialMembers, allEmployees]);

  useEffect(() => {
    onChanged(currentMembers);
    setIsValid(currentMembers.length < MAX_MEMBERS_COUNT);
  }, [onChanged, currentMembers]);

  const addMember = useCallback((employee: Consumer) => {
    setCurrentMembers((prevState) => [...prevState, employee]);
  }, []);

  const removeMember = useCallback((employee: Consumer) => {
    setCurrentMembers((prevState) => {
      const indexOfMember = prevState.findIndex((x) => x.name == employee.name);
      return [...prevState.slice(0, indexOfMember), ...prevState.slice(indexOfMember + 1)];
    });
  }, []);

  const employeesToSelect = useMemo(
    () => _.differenceBy(allEmployees, currentMembers, 'name'),
    [allEmployees, currentMembers],
  );

  return {
    currentMembers,
    addMember,
    removeMember,
    employeesToSelect,
    isValid,
  };
};
