import { CompanyContact } from '@inovirtue/company';
import { useCallback } from 'react';
import { deleteInvitation } from '../api/companiesApi';

export const useInvitationDelete = (companyId: string) => {
  const submit = useCallback(
    async (contact: CompanyContact) => {
      await deleteInvitation(companyId, contact.id as string);
    },
    [companyId],
  );

  return {
    submit,
  };
};
