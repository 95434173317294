import React from 'react';
import { Spin, SpinProps } from 'antd';
import './ButtonWithLoader.scss';
import { Button, ButtonProps } from '../Button/Button';
import classNames from 'classnames';

export interface ButtonWithLoaderProps extends Omit<ButtonProps, 'loading'> {
  spinning?: boolean;
  delay?: number;
  innerBtnClassName?: string;
  spinClassName?: string;
  indicator?: SpinProps['indicator'];
}

const mappedSizes: { [Property in keyof ButtonProps['size']]: SpinProps['size'] } = {
  small: 'small',
  middle: 'default',
  large: 'default',
};

export const ButtonWithLoader = ({
  children,
  spinning = false,
  delay,
  indicator,
  size = 'middle',
  className,
  innerBtnClassName,
  spinClassName,
  block,
  ...props
}: ButtonWithLoaderProps): JSX.Element => {
  const wrapperCls = classNames(
    { 'btn-with-loader-wrapper': !block, 'btn-with-loader-wrapper-block': block },
    className,
  );
  const btnCls = classNames('btn-with-loader-inner', innerBtnClassName);

  return (
    <>
      <Spin
        {...{ spinning, delay, indicator }}
        size={mappedSizes[size]}
        wrapperClassName={wrapperCls}
        className={spinClassName}
        data-testid='btn-spinner'
      >
        <Button className={btnCls} {...{ size, block, ...props }}>
          {children}
        </Button>
      </Spin>
    </>
  );
};
