import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';
import {
  removeLocalVideoTrack,
  room$,
  setIsScreenSharing,
  setIsSettingsOpen,
} from '../../store/video';
import { removeLocalAudioTrack } from '../../store/audio';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { useVideoCallDisconnect } from '../useVideoCallDisconnect/useVideoCallDisconnect';
import { useMediaTracksSwitchOff } from '../useMediaTracksSwitchOff/useMediaTracksSwitchOff';

export default function useHandleRoomDisconnection(): void {
  const room = useBehaviorSubjectState(room$);
  const disconnectCurrentSession = useVideoCallDisconnect();
  const { switchOffMediaTracks } = useMediaTracksSwitchOff();

  useEffect(() => {
    if (!room) return;

    const onDisconnected = async (_: Room, error: TwilioError) => {
      switchOffMediaTracks();
      removeLocalAudioTrack();
      removeLocalVideoTrack();
      setIsScreenSharing(false);
      setIsSettingsOpen(false);

      await disconnectCurrentSession(Boolean(error));
    };

    room.on('disconnected', onDisconnected);
    return () => {
      room.off('disconnected', onDisconnected);
    };
  }, [room, disconnectCurrentSession, switchOffMediaTracks]);
}
