import './WorkshopPublishButton.scss';
import { Button, Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Alert } from 'antd';
import { useState } from 'react';
import { Workshop } from '../../model/Workshop';
import { CheckOutlined } from '@ant-design/icons';

export type WorkshopPublishButtonProps = {
  workshop: Workshop;
  visible: boolean;
  enabled: boolean;
  onPublish: (workshop: Workshop) => Promise<void>;
};

export const WorkshopPublishButton = ({
  workshop,
  visible,
  enabled,
  onPublish,
}: WorkshopPublishButtonProps) => {
  const [isModalVisible, setModalVisible] = useState(false);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Button
        disabled={!enabled}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        <CheckOutlined />
        {t('common:publish')}
      </Button>
      <Modal
        contentCls='group-publish-confirm__modal-content'
        title={t('group:confirm-publish.title')}
        visible={isModalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        actions={[
          <Button
            key='cancel'
            onClick={() => {
              setModalVisible(false);
            }}
          >
            {t('group:confirm-publish.button-cancel')}
          </Button>,
          <Button
            key='publish'
            type='primary'
            onClick={async () => {
              await onPublish(workshop);
              setModalVisible(false);
            }}
          >
            {t('group:confirm-publish.button-accept')}
          </Button>,
        ]}
      >
        <Alert type='warning' message={t('group:confirm-publish.alert')} />
        <span className='group-publish-confirm__modal-content__question'>
          {t('group:confirm-publish.question')}
        </span>
      </Modal>
    </>
  );
};
