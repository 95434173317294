import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { room$ } from '../../store/video';
import useLocalVideoToggle from '../useLocalVideoToggle/useLocalVideoToggle';

const useAttachVisibilityHandler = (): void => {
  const room = useBehaviorSubjectState(room$);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const shouldRepublishVideoOnForeground = useRef(false);

  useEffect(() => {
    if (room && isMobile) {
      const handleVisibilityChange = () => {
        // We don't need to unpublish the local video track if it has already been unpublished
        if (document.visibilityState === 'hidden' && isVideoEnabled) {
          shouldRepublishVideoOnForeground.current = true;
          toggleVideoEnabled();

          // Don't publish the local video track if it wasn't published before the app was backgrounded
        } else if (shouldRepublishVideoOnForeground.current) {
          shouldRepublishVideoOnForeground.current = false;
          toggleVideoEnabled();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    } else {
      return;
    }
  }, [isVideoEnabled, room, toggleVideoEnabled]);
};

export default useAttachVisibilityHandler;
