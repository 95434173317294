import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DraftMessages } from '../models/message';
import { ConversationsState } from './store';

const initialState: DraftMessages = {};

const draftMessagesSlice = createSlice({
  name: 'draftMessages',
  initialState,
  reducers: {
    upsertDraftMessage(
      state: DraftMessages,
      action: PayloadAction<{ convoSid: string; message: string }>,
    ) {
      const { convoSid, message } = action.payload;
      state[convoSid] = message;
    },
  },
});

export const { upsertDraftMessage } = draftMessagesSlice.actions;

export const draftMessagesReducer = draftMessagesSlice.reducer;

export const selectDraftMessages = (state: ConversationsState) => state.draftMessages;
