import { createQuery } from '@datorama/akita';
import {
  VideoCallSessionDetailsState,
  videoCallSessionDetailsStore,
} from './video-call-session.store';
import { BehaviorSubject } from 'rxjs';

export const videoCallSessionDetailsQuery = createQuery<VideoCallSessionDetailsState>(
  videoCallSessionDetailsStore,
);

export const videoSessionDetails$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue());
videoCallSessionDetailsQuery.select((s) => s).subscribe(videoSessionDetails$);

export const workshopId$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue()?.workshopId);
videoCallSessionDetailsQuery.select((s) => s?.workshopId).subscribe(workshopId$);

export const sessionId$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue()?.sessionId);
videoCallSessionDetailsQuery.select((s) => s.sessionId).subscribe(sessionId$);

export const sessionAvatarUrl$ = new BehaviorSubject(
  videoCallSessionDetailsQuery.getValue()?.avatarUrl,
);
videoCallSessionDetailsQuery.select((s) => s.avatarUrl).subscribe(sessionAvatarUrl$);

export const sessionDisplayName$ = new BehaviorSubject(
  videoCallSessionDetailsQuery.getValue()?.displayName,
);
videoCallSessionDetailsQuery.select((s) => s.displayName).subscribe(sessionDisplayName$);

export const sessionDescription$ = new BehaviorSubject(
  videoCallSessionDetailsQuery.getValue()?.description,
);
videoCallSessionDetailsQuery.select((s) => s.description).subscribe(sessionDescription$);

export const startDate$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue()?.startDate);
videoCallSessionDetailsQuery.select((s) => s.startDate).subscribe(startDate$);

export const endDate$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue()?.endDate);
videoCallSessionDetailsQuery.select((s) => s.endDate).subscribe(endDate$);

export const leftCall$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue()?.leftCall);
videoCallSessionDetailsQuery.select((s) => s.leftCall).subscribe(leftCall$);

export const chatButton$ = new BehaviorSubject(videoCallSessionDetailsQuery.getValue()?.chatButton);
videoCallSessionDetailsQuery.select((s) => s.chatButton).subscribe(chatButton$);
