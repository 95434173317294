import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const useFullScreenHeight = (isFullScreen: boolean): number | undefined => {
  const isStateInitialized = useRef(false);
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (!(isMobile && isFullScreen)) return;

    const updateHeight = () => setHeight(window.innerHeight);

    if (!isStateInitialized.current) {
      updateHeight();
      isStateInitialized.current = true;
    }

    window.addEventListener('resize', updateHeight);
    window.addEventListener('orientationchange', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('orientationchange', updateHeight);
    };
  }, [isFullScreen]);

  return height;
};
