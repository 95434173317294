import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { CreateInvitationsCommand } from '../model/InvitationsModel';
import { ResendInvitationCommand } from '../model/ResendInvitationModel';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const sendInvitations = async (
  companyId: string,
  invitationList: CreateInvitationsCommand,
): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/companies/${companyId}/invitations`, invitationList);
};

export const resendInvitation = async (
  companyId: string,
  employeeId: string,
  body: ResendInvitationCommand,
): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.put(`/companies/${companyId}/employees/${employeeId}/invitations`, body);
};
