import { Client, ConnectionState } from '@twilio/conversations';
import React, { ReactNode } from 'react';
import { withStoreProvider } from '../hoc/withStoreProvider';
import { useConvoSelector } from '../hooks/storeHooks/storeHooks';
import { useConversationsInit } from '../hooks/useConversationsInit/useConversationsInit';
import { selectConnectionState } from '../store/commonSlice';
import { selectTotalUnreadMessages } from '../store/horizonSlice';

export type ConversationsContextType = {
  client: Client | null;
  connectionState: ConnectionState;
  totalUnreadMessages: number;
};

export const ConversationsContext = React.createContext<ConversationsContextType>({
  client: null,
  connectionState: 'unknown',
  totalUnreadMessages: 0,
});

export interface ConversationsContextProviderProps {
  requestToken: () => Promise<string>;
  children: ReactNode;
}

const ConversationsContextProvider = ({
  requestToken,
  children,
}: ConversationsContextProviderProps): JSX.Element => {
  const connectionState = useConvoSelector(selectConnectionState);
  const totalUnreadMessages = useConvoSelector(selectTotalUnreadMessages);
  const client = useConversationsInit(requestToken);

  return (
    <ConversationsContext.Provider
      value={{
        client,
        connectionState,
        totalUnreadMessages,
      }}>
      {children}
    </ConversationsContext.Provider>
  );
};

export const ConversationsProvider = withStoreProvider<ConversationsContextProviderProps>(
  ConversationsContextProvider,
);
