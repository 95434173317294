import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';
import { reconnectingParticipants$ } from '../../store/participant';

export default function useParticipantIsReconnecting(participant: Participant): boolean {
  const reconnectingParticipants = useBehaviorSubjectState(reconnectingParticipants$);
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    setIsReconnecting(reconnectingParticipants.includes(participant.identity));
  }, [reconnectingParticipants, participant.identity]);

  return isReconnecting;
}
