import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { CreateAccountDto } from '../models/createAccountDto';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getAccountProfileNames = async (accountId: string): Promise<string[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<string[]>(`/accounts/${accountId}/profilenames`);
  return data;
};

export const checkIfAccountExists = async (accountId: string): Promise<boolean> => {
  const axiosInstance = await getApiClientAsync();
  const { status } = await axiosInstance.get(`/accounts/${accountId}`);
  return status === 200;
};

export const createAccount = async (command: CreateAccountDto): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/accounts/backoffice`, command);
};
