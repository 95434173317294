import { t } from '@inovirtue/localization';
import { message } from 'antd';
import { useMemo } from 'react';

export const useRecommendedProvidersHandlers = (
  isProviderAssigned: (providerName: string) => boolean,
  isProviderRemoved: (providerName: string) => boolean,
  isProviderRecommended: (providerName: string) => boolean,
  onRecommendedChange: (checked: boolean, selectedName: string) => void,
) => {
  const onProviderClick = useMemo(
    () => (providerName: string) =>
      (isProviderAssigned(providerName) &&
        message.warning(t('consumer:consumer-edit-validation.provider-already-selected'))) ||
      (isProviderRemoved(providerName) &&
        message.warning(t('consumer:consumer-edit-validation.provider-already-removed'))),
    [isProviderAssigned, isProviderRemoved],
  );

  const onProviderChange = useMemo(
    () => (checked: boolean, providerName: string) =>
      !isProviderAssigned(providerName) &&
      !isProviderRemoved(providerName) &&
      onRecommendedChange(checked, providerName),
    [isProviderAssigned, isProviderRemoved, onRecommendedChange],
  );

  const isProviderChecked = useMemo(
    () => (providerName: string) =>
      isProviderRecommended(providerName) &&
      !isProviderAssigned(providerName) &&
      !isProviderRemoved(providerName),
    [isProviderRecommended, isProviderAssigned, isProviderRemoved],
  );

  return {
    onProviderClick,
    onProviderChange,
    isProviderChecked,
  };
};
