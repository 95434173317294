import { useCompanyAdd } from '../../../../Modules/Companies/hooks/useCompanyAdd';
import { Company } from '../../models/domain/Company';
import CompanyForm from '../CompanyForm/CompanyForm';

type CompanyAddProps = {
  formData?: Company;
};

const CompanyAdd = ({ formData }: CompanyAddProps) => {
  const { onSubmit } = useCompanyAdd();
  return <CompanyForm formData={formData} onSubmit={onSubmit} />;
};

export default CompanyAdd;
