import { useCallback, useEffect, useState } from 'react';
import { getProviders } from '../api/Providers.Api';

const useProviderSerchable = () => {
  const [loadCoaches, setLoadCoaches] = useState(false);
  const [providers, setProviders] = useState<{ name: string; value: string }[]>([]);
  const [selectValue, setSelectValue] = useState<string | undefined>();
  const [showPopupConfirm, setShowPopupConfirm] = useState<boolean>(false);
  const [setTimeoutId, setSetTimeoutId] = useState<NodeJS.Timeout>();

  const getProvidersByName = useCallback(async (name?: string) => {
    const data = await getProviders(0, name);
    setProviders(data.map((provider) => ({ name: provider.displayName, value: provider.name })));
  }, []);

  const confirmRemoveCoach = () => {
    setSelectValue(undefined);
    setShowPopupConfirm(false);
  };

  const cancelRemoveCoach = () => {
    setShowPopupConfirm(false);
  };

  const onChange = (value: string) => {
    setSelectValue(value);
  };

  const onSearch = (value: string) => {
    if (value.trim().length < 3) return;
    if (setTimeoutId) clearTimeout(setTimeoutId);
    setLoadCoaches(true);
    const timeoutId = setTimeout(() => {
      try {
        getProvidersByName(value);
      } finally {
        setLoadCoaches(false);
      }
    }, 1000);
    setSetTimeoutId(timeoutId);
  };

  useEffect(() => {
    setLoadCoaches(true);
    try {
      getProvidersByName();
    } finally {
      setLoadCoaches(false);
    }
    return () => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadCoaches,
    onChange,
    onSearch,
    providers,
    selectValue,
    confirmRemoveCoach,
    cancelRemoveCoach,
    setShowPopupConfirm,
    showPopupConfirm,
  };
};

export default useProviderSerchable;
