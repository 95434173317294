import { Menu } from 'antd';
import { CreateMenuItemAntd } from '../../model/AntdMenuItem';
import './LayoutHeader.scss';
interface LayoutHeaderProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  menuItems?: CreateMenuItemAntd[];
  onNavigate: (path: string) => void;
}

export const LayoutHeader = ({
  title,
  subtitle,
  menuItems,
  onNavigate,
}: LayoutHeaderProps): JSX.Element => {
  return (
    <section className='layout-header'>
      <h1 className='layout-header__title'>{title}</h1>
      <p className='layout-header__description'>{subtitle}</p>
      {menuItems && (
        <Menu className='layout-header__navigation' data-testId='layout-header__navigation'>
          {menuItems.map((el) => (
            <Menu.Item
              key={el.path}
              onClick={() => onNavigate(el.path)}
              icon={el.icon}
              className='layout-header__navigation__element'
            >
              {el.label}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </section>
  );
};
