import { useMemo } from 'react';
import EmployeeInfo from '../models/EmployeeInfo';
import { t } from '@inovirtue/localization';
import { AvatarCollectionObject } from '../components/AvatarCollection/AvatarCollection';

const useActiveEmployeesCoachAvatars = (employee: EmployeeInfo) => {
  const avatars: AvatarCollectionObject[] = useMemo(() => {
    const mappedAvatars =
      employee.coaches && employee.coaches.length > 0
        ? employee.coaches.map((x) => ({
            key: x.name,
            avatarUrl: x.avatarUrl,
            displayName: x.displayName,
          }))
        : [];

    if (employee.isSelectingCoach)
      return [
        ...mappedAvatars,
        {
          key: 'selectingCoach',
          displayName: t('company:employees:active-employees-table.selecting-coach'),
          isIcon: true,
        },
      ];

    return mappedAvatars;
  }, [employee]);

  return { avatars };
};

export default useActiveEmployeesCoachAvatars;
