import { CompanyContact } from '@inovirtue/company';
import { useCallback } from 'react';
import { addInvitation } from '../api/companiesApi';

export const useIntivationAdd = (companyId: string) => {
  const submit = useCallback(
    async (contact: CompanyContact) => {
      await addInvitation(companyId, contact);
    },
    [companyId],
  );

  return {
    submit,
  };
};
