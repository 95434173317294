import { showToast } from '@inovirtue/components';
import { useState } from 'react';

type ProcessAction = {
  isProcessing: boolean;
  process(): Promise<void>;
};

const useProcessAction = (
  actionToProcess: () => Promise<void> | void,
  successMessage?: string,
): ProcessAction => {
  const [isProcessing, setIsProcessing] = useState(false);

  const process = async (): Promise<void> => {
    setIsProcessing(true);
    try {
      await actionToProcess();
      if (successMessage) showToast(successMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isProcessing,
    process,
  };
};

export default useProcessAction;
