import { useCallback } from 'react';
import { addWorkshopMembers } from '../api/workshopApi';

export const useWorkshopMembersAdd = () => {
  const addMembers = useCallback(async (companyId: string, members: string[]) => {
    await addWorkshopMembers(companyId, members);
  }, []);

  return {
    addMembers,
  };
};
