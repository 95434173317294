import { usePromiseWithProgress } from '@inovirtue/hooks';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getConsumer, getProfileInterests } from '../api/Consumers.Api';
import { Consumer } from '@inovirtue/admin';

export const useConsumerDetails = (profileName: string) => {
  const fetchConsumerMemo = useCallback(() => getConsumer(profileName), [profileName]);
  const fetchInterestsMemo = useCallback(() => getProfileInterests(profileName), [profileName]);
  const consumerResponse = usePromiseWithProgress<Consumer>(fetchConsumerMemo);
  const consumerInterestsResponse = usePromiseWithProgress<string[]>(fetchInterestsMemo);
  const [isFound, setIsFound] = useState(true);

  useEffect(() => {
    const axiosError = consumerResponse.error as AxiosError;

    if (axiosError && axiosError.response?.status === 404) {
      setIsFound(false);
    }
  }, [consumerResponse]);

  return {
    consumer: consumerResponse.data,
    interests: consumerInterestsResponse.data,
    isProcessing: consumerInterestsResponse.isProcessing || consumerInterestsResponse.isProcessing,
    isFound,
  };
};
