import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../hooks/useSelectedParticipant/useSelectedParticipant';
import { Participant } from 'twilio-video';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { room$ } from '../../store/video';

export default function useMainParticipant(): Participant {
  const room = useBehaviorSubjectState(room$);
  const selectedParticipant = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant =
    screenShareParticipant !== localParticipant ? screenShareParticipant : null;
  const participant =
    selectedParticipant ||
    remoteScreenShareParticipant ||
    dominantSpeaker ||
    Array.from(room?.participants?.values() ?? [])
      .sort((a, b) => {
        const aLength = Array.from(a.tracks?.values() ?? []).map((x) => x.isTrackEnabled).length;
        const bLength = Array.from(b.tracks?.values() ?? []).map((x) => x.isTrackEnabled).length;
        return aLength - bLength;
      })
      .reverse()[0] ||
    localParticipant;

  return participant;
}
