import { Workshop } from '@inovirtue/admin';
import { TimeSlot } from '@inovirtue/booking';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { ResultsDTO } from '../../api/SchedulerDTO';
import { WorkshopSlotList } from '../../model/SlotModel';
import { createSlots } from '../../utils/createSlot';

const useManageSessionSlots = (workshop?: Workshop) => {
  const [sessionsSlots, setSessionsSlots] = useState<WorkshopSlotList>([]);

  const onChooseSlot = useCallback(
    ({ startDateTime, durationMinutes }: TimeSlot) => {
      const updatedSlots = sessionsSlots.map((el) =>
        el.active
          ? {
              ...el,
              error: false,
              appoitmentsDetails: {
                startDateTime: startDateTime,
                duration: durationMinutes,
                endDateTime: dayjs(startDateTime).add(durationMinutes, 'minute'),
              },
            }
          : el,
      );
      const nextInActiveSlot = updatedSlots.findIndex((el) => !el.appoitmentsDetails);
      setSessionsSlots(updatedSlots.map((el, i) => ({ ...el, active: nextInActiveSlot === i })));
    },
    [sessionsSlots],
  );

  const setActiveSessionSlot = useCallback(
    (slotKey: string) => {
      setSessionsSlots(
        sessionsSlots.map((slot) => ({
          ...slot,
          active: slot.id === slotKey,
        })),
      );
    },
    [sessionsSlots],
  );

  const setErrorToSlot = useCallback(
    (results: ResultsDTO[]) => {
      setSessionsSlots(
        sessionsSlots.map((slot) =>
          results.find((el) => el.id === slot.id) ? { ...slot, error: true } : slot,
        ),
      );
    },
    [sessionsSlots],
  );

  const enableSessionSlots = useCallback(() => {
    setSessionsSlots(
      sessionsSlots.map((slot, i) => ({ ...slot, disabled: false, active: i === 0 })),
    );
  }, [sessionsSlots]);

  const clearSessionsSlots = useCallback(() => {
    setSessionsSlots(
      sessionsSlots.map((slot) => ({ ...slot, appoitmentsDetails: undefined, disabled: true })),
    );
  }, [sessionsSlots]);

  useEffect(() => {
    if (workshop) setSessionsSlots(createSlots(workshop.sessionsInTotal ?? 0));
  }, [workshop]);

  return {
    onChooseSlot,
    setErrorToSlot,
    sessionsSlots,
    enableSessionSlots,
    setActiveSessionSlot,
    clearSessionsSlots,
  };
};

export default useManageSessionSlots;
