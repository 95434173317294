import { List } from 'antd';
import { TimeSlot } from '../../model/booking';
import './SlotsList.scss';
import dayjs from 'dayjs';

export interface SlotsListProps {
  elements: TimeSlot[];
  onClickAction: (slot: TimeSlot) => void;
}
export const SlotsList = ({ elements, onClickAction }: SlotsListProps): JSX.Element => {
  return (
    <List
      className='slotsList'
      bordered
      dataSource={elements}
      renderItem={(item) => (
        <List.Item className='slotsList__item' onClick={() => onClickAction(item)}>
          {dayjs(item.startDateTime).format('HH:mm')}
        </List.Item>
      )}
    />
  );
};
