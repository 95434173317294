import { ReactElement } from 'react';
import { cloneElement, ReactNode, useEffect, useState } from 'react';
import { ConversationsListItemProps } from '../../components/ConversationsListItem/ConversationsListItem';
import { Convo } from '../../models/conversation';
import { selectAllConversations } from '../../store/conversationsSlice';
import { useConvoSelector } from '../storeHooks/storeHooks';

export const useRenderConversationsItems = (renderItem: (conversation: Convo) => ReactNode) => {
  const convos = useConvoSelector(selectAllConversations);
  const [renderedItems, setRenderedItems] = useState<ReactNode>(<></>);

  useEffect(() => {
    const items = convos.map((convo) => {
      const conversationsListItem = renderItem(convo) as ReactElement<
        Pick<ConversationsListItemProps, 'convoSid' | 'type'>
      >;
      return cloneElement(conversationsListItem, {
        key: convo.sid,
        convoSid: convo.sid,
        type: convo.attributes.type,
      });
    });

    setRenderedItems(items);
  }, [convos, renderItem]);

  return { conversationsItems: renderedItems, isConvos: Boolean(convos.length) };
};
