import { useState, useCallback, useEffect } from 'react';
import useProcessAction from '../../Common/hooks/useProcessAction';
import { getConsumer, setAllowedNumberOfSessions } from '../../Consumers/api/Consumers.Api';
import { usePromiseWithProgress } from '@inovirtue/hooks';
import { t } from '@inovirtue/localization';

const useEditEmployeeSessions = (profileName: string) => {
  const [personalSessions, setPersonalSessions] = useState<number>();

  const save = useCallback(() => {
    if (!personalSessions) return;
    return setAllowedNumberOfSessions(profileName, personalSessions);
  }, [profileName, personalSessions]);

  const saveAction = useProcessAction(save, t('common:save-success'));

  const getConsumerDataCallback = useCallback(() => getConsumer(profileName), [profileName]);
  const consumerData = usePromiseWithProgress(getConsumerDataCallback);

  useEffect(() => {
    setPersonalSessions(consumerData.data?.allowedNumberOfSessions);
  }, [consumerData.data]);

  return {
    isProcessing: saveAction.isProcessing || consumerData.isProcessing,
    save: saveAction.process,
    personalSessions,
    setPersonalSessions,
    initialPersonalSessions: consumerData.data?.allowedNumberOfSessions,
  };
};

export default useEditEmployeeSessions;
