import { Button, InputWCharactersLimit, Modal, ModalProps } from '@inovirtue/components';
import { Form, Row, Col } from 'antd';
import { useCallback } from 'react';
import useAddPositions, { UseAddPistionsParams } from '../../hooks/useAddPositions/useAddPositions';
import './AddPositionsModal.scss';

export interface AddPositionsModalProps
  extends Pick<ModalProps, 'onClose' | 'visible'>,
    UseAddPistionsParams {
  cancelLabel: string;
  saveLabel: string;
  addLabel: string;
  modalTitle: string;
  inputLabel: string;
  errorMessage: string;
  positionModalDescription?: string;
}
export const AddPositionsModal = ({
  onClose,
  visible,
  positions,
  getPositions,
  submitNewPositions,
  cancelLabel,
  saveLabel,
  addLabel,
  modalTitle,
  inputLabel,
  errorMessage,
  positionModalDescription,
}: AddPositionsModalProps): JSX.Element => {
  const {
    addPosition,
    newPositions,
    setActualPosition,
    actualPosition,
    submitPositions,
    submitDisabled,
    validate,
    clearAllAction,
  } = useAddPositions({ positions, getPositions, submitNewPositions });
  const closeModalAction = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      clearAllAction();
      onClose(e);
    },
    [clearAllAction, onClose],
  );
  const submitPositionsClick = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      await submitPositions();
      closeModalAction(e);
    },
    [closeModalAction, submitPositions],
  );
  return (
    <Modal
      data-testid='add-positions-modal'
      className='add-positions-modal'
      visible={visible}
      onClose={closeModalAction}
      actions={[
        <Button key='cancel' onClick={closeModalAction}>
          {cancelLabel}
        </Button>,
        <Button key='save' type='primary' onClick={submitPositionsClick} disabled={submitDisabled}>
          {saveLabel}
        </Button>,
      ]}
      title={modalTitle}
    >
      <Row gutter={16} align='middle'>
        {positionModalDescription && (
          <Col span={24}>
            <p className='add-positions-modal__description'>{positionModalDescription}</p>
          </Col>
        )}
        <Col span={20}>
          <Form.Item
            label={inputLabel}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            validateStatus={!validate ? 'error' : ''}
            help={!validate ? errorMessage : ''}
            required
          >
            <InputWCharactersLimit
              status={!validate ? 'error' : ''}
              maxLength={50}
              name='newPosition'
              data-testid='newPosition'
              value={actualPosition}
              onChange={(e) => setActualPosition(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button
            data-testid='addPosition'
            onClick={addPosition}
            className='add-positions-modal__employees-add'
            disabled={actualPosition.length === 0}
          >
            {addLabel}
          </Button>
        </Col>
      </Row>
      {newPositions.length > 0 && (
        <ul className='add-positions-modal__employees-list'>
          {newPositions.map((el) => (
            <li key={el} className='add-positions-modal__employees-list__element'>
              {el}
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};
