import './EmptyList.scss';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { FormOutlined } from '@ant-design/icons';

export interface EmptyListProps {
  onClick: () => void;
}

const EmptyList = ({ onClick }: EmptyListProps): JSX.Element => {
  return (
    <div className='empty-conversations-list'>
      <p className='empty-conversations-list__text'>{t('chat:empty-conversations-list')}</p>
      <Button
        className='empty-conversations-list__btn'
        type='primary'
        icon={<FormOutlined />}
        onClick={onClick}
        data-testid='write-message-btn'>
        {t('chat:write-message')}
      </Button>
    </div>
  );
};

export default EmptyList;
