import './WorkshopStatusLabel.scss';
import { WorkshopStatus } from '../../model/Workshop';
import classNames from 'classnames';
import { t } from '@inovirtue/localization';

export type WorkshopStatusLabelProps = {
  status: WorkshopStatus;
};

export const WorkshopStatusLabel = ({ status }: WorkshopStatusLabelProps) => {
  const workshopStatusCls = classNames('workshop-status-label', {
    'workshop-status-label__deactivated': status === 'Deactivated',
  });

  return <span className={workshopStatusCls}>{t(`group:status.${status.toLowerCase()}`)}</span>;
};
