import * as React from 'react';
import styles from './PartnerGeneralInfo.module.scss';
import { CardField, DetailsCardBase, NameAndDescIcon } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { PartnerDto } from '../../models/PartnerDto';
import ImageUpload from '../ImageUpload/ImageUpload';

interface PartnerGeneralInfoProps {
  partnerDto: PartnerDto;
}

const PartnerGeneralInfo = ({ partnerDto }: PartnerGeneralInfoProps): JSX.Element => {
  return (
    <DetailsCardBase header={t('partners:details.name-and-description')} icon={<NameAndDescIcon />}>
      <div>
        <CardField
          className={styles.partnerGeneralInfo__fieldLabel}
          label={t('partners:details.name-field-label')}
          value={partnerDto.shortName}
        />
        <CardField
          className={styles.partnerGeneralInfo__fieldLabel}
          label={t('partners:details.partner-title-label')}
          value={partnerDto.displayName}
        />
        <CardField
          className={styles.partnerGeneralInfo__fieldLabel}
          label={t('partners:details.partner-description-label')}
          value={partnerDto.description}
        />
        <CardField
          className={styles.partnerGeneralInfo__fieldLabel}
          label={t('partners:details.claim-field-label')}
          value={partnerDto.claim}
        />
        <div className={styles.partnerGeneralInfo__images}>
          <ImageUpload
            imagesSrc={partnerDto.logoUrl}
            disabled
            title={t('partners:details.company-logo')}
            description={t('partners:details.image-requirements')}
            name='logo'
          />
          <ImageUpload
            disabled
            imagesSrc={partnerDto.smallLogoUrl}
            title={t('partners:details.company-logo-small')}
            description={t('partners:details.image-requirements')}
            name='smallLogo'
          />
          <ImageUpload
            disabled
            imagesSrc={partnerDto.backgroundUrl}
            title={t('partners:details.company-banner-image')}
            description={t('partners:details.image-requirements')}
            name='banner'
          />
          <ImageUpload
            disabled
            imagesSrc={partnerDto.numbersAndFactsUrl}
            title={t('partners:details.company-numbers-and-facts')}
            description={t('partners:details.image-requirements')}
            name='numbersAndFacts'
          />
        </div>
      </div>
    </DetailsCardBase>
  );
};

export default PartnerGeneralInfo;
