import { Alert, AlertProps, Modal } from 'antd';
import './Snackbar.Component.scss';

interface SnackbarProps {
  headline: React.ReactNode;
  message: React.ReactNode;
  type?: AlertProps['type'];
  open: boolean;
  showIcon?: boolean;
  handleClose?: () => void;
  centered?: boolean;
  clsModal?: string;
}

const Snackbar = ({
  headline,
  message,
  type,
  open,
  showIcon = true,
  centered = true,
  handleClose,
  clsModal,
}: SnackbarProps): JSX.Element => {
  return (
    <Modal
      centered={centered}
      data-testid='snackBar'
      open={open}
      className={'snack-bar-modal'}
      closable={!!handleClose}
      footer={!handleClose && null}
      wrapClassName={clsModal}
      onCancel={handleClose}
    >
      <div className='snack-bar-modal__container'>
        <Alert
          message={headline}
          description={message}
          type={type}
          showIcon={showIcon}
          closable={!!handleClose}
          onClose={handleClose}
        />
      </div>
    </Modal>
  );
};

export default Snackbar;
