import { Button } from '@inovirtue/components';
import { useScreenShareButton } from '../../../hooks/useScreenShareButton/useScreenShareButton';

export interface ToggleScreenShareButtonProps {
  disabled?: boolean;
  className?: string;
}

const ToggleScreenShareButton = ({
  disabled,
  className,
}: ToggleScreenShareButtonProps): JSX.Element => {
  const { icon, isDisabled, isVisible, toggleScreenShare } = useScreenShareButton();

  return (
    <>
      {isVisible && (
        <Button
          onClick={toggleScreenShare}
          icon={icon}
          size='large'
          shape='circle'
          disabled={disabled || isDisabled}
          className={className}
          data-testid='screen-share-btn'
        ></Button>
      )}
    </>
  );
};

export default ToggleScreenShareButton;
