import {
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { AxiosInstance } from 'axios';
import config from '../../../../config';

const msalInstance = new PublicClientApplication(config.msalConfig.msalAuthConfig);

const createTokenRequest = (instance: IPublicClientApplication): SilentRequest => {
  const [account] = instance.getAllAccounts();
  const request = {
    account: account,
    scopes: config.msalConfig.scopes,
  };
  return request;
};

const getTokenAsync = async (instance: IPublicClientApplication): Promise<string> => {
  const request = createTokenRequest(instance);
  try {
    const tokenResponse = await instance.acquireTokenSilent(request);
    return tokenResponse.accessToken;
  } catch (error: unknown) {
    if (error instanceof InteractionRequiredAuthError) {
      instance.acquireTokenRedirect(request);
    }
    return '';
  }
};

export const attachAuthTokentInterceptor = (axiosInstance: AxiosInstance): void => {
  const [account] = msalInstance.getAllAccounts();
  if (account) {
    axiosInstance.interceptors.request.use(
      async (requestConfig) => {
        const token = await getTokenAsync(msalInstance);
        requestConfig.headers = { ...requestConfig.headers, Authorization: `Bearer ${token}` };
        return requestConfig;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
};
