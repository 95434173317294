import { message } from 'antd';
import { useState, useEffect } from 'react';
import { useCompanies } from '../../Companies/hooks/useCompanies';
import { updateConsumer } from '../../Consumers/api/Consumers.Api';
import { useConsumerDetails } from '../../Consumers/hooks/useConsumerDetails';
import { useConsumerProviders } from './useConsumerProviders';
import { useSessionsAssignedMonthly } from './useSessionsAssignedMonthly';
import { t } from '@inovirtue/localization';
import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { useConsumerActiveNewProviderRequest } from '../../ConsumerRequests/hooks/useConsumerNewProviderRequest';
import { useConsumerAssignedProviders } from './useConsumerAssignedProviers';
import { useConsumerRemovedProviders } from './useConsumerRemovedProviders';

export const useConsumerEdit = (profileName: string) => {
  const activeNewProviderRequest = useConsumerActiveNewProviderRequest(profileName);
  const consumerDetails = useConsumerDetails(profileName);
  const consumerProviders = useConsumerProviders(profileName);
  const companies = useCompanies(consumerDetails.consumer?.companyId);
  const sessionsAssignedMonthly = useSessionsAssignedMonthly(
    consumerDetails.consumer?.allowedNumberOfSessions ?? 0,
    consumerDetails.consumer?.allowedNumberOfGroupSessions ?? 0,
  );
  const consumerAssingedProviders = useConsumerAssignedProviders(profileName);
  const consumerRemovedProviders = useConsumerRemovedProviders(profileName);
  const [submitAttemptValidationFailed, setSubmitAttemptValidationFailed] = useState(false);

  useEffect(() => {
    if (
      [
        consumerDetails.isProcessing,
        consumerProviders.isProcessing,
        companies.isProcessing,
        activeNewProviderRequest.isProcessing,
        consumerAssingedProviders.isProcessing,
        consumerRemovedProviders.isProcessing,
      ].some((x) => x === true)
    ) {
      enableFullPageLoader();
    } else {
      consumerProviders.setCanEditProviders(!!activeNewProviderRequest.data);
      disableFullPageLoader();
    }
  }, [
    consumerDetails,
    consumerProviders,
    companies,
    activeNewProviderRequest,
    consumerAssingedProviders,
    consumerRemovedProviders,
  ]);

  const onSubmit = async () => {
    const isAnyInvalid = [consumerProviders, companies, sessionsAssignedMonthly].some(
      (x) => !x.validationResult.isValid,
    );

    if (isAnyInvalid) {
      setSubmitAttemptValidationFailed(true);
    } else {
      try {
        await updateConsumer(
          profileName,
          sessionsAssignedMonthly.availableSessionCount,
          sessionsAssignedMonthly.availableGroupSessionCount,
          consumerProviders.recommendedProvidersNames,
          companies.selectedCompanyId,
        );
        message.success(t('common:form-success'));
      } catch (err) {
        // TODO handle globally all axios errors
      }
    }
  };

  return {
    consumerDetails,
    consumerProviders,
    activeNewProviderRequest: activeNewProviderRequest.data,
    consumerAssingedProviders,
    consumerRemovedProviders,
    companies,
    sessionsAssignedMonthly,
    submitAttemptValidationFailed,
    onSubmit,
  };
};
