import { useEffect, useState } from 'react';
import ValidationResult from '../models/validationResult';
import { t } from '@inovirtue/localization';

export const useSessionsAssignedMonthly = (
  initialAvailableSessionCount: number,
  initialAvailableGroupSessionCount: number,
) => {
  const [availableSessionCount, setAvailableSessionCount] = useState<number>(0);
  const [availableGroupSessionCount, setAvailableGroupSessionCount] = useState<number>(0);
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    isValid: false,
    validationMessage: t('consumer:consumer-edit-validation.sessions-required'),
  });

  useEffect(
    () => setAvailableSessionCount(initialAvailableSessionCount),
    [initialAvailableSessionCount],
  );

  useEffect(
    () => setAvailableGroupSessionCount(initialAvailableGroupSessionCount),
    [initialAvailableGroupSessionCount],
  );

  useEffect(() => {
    setValidationResult((currentState) => ({
      ...currentState,
      isValid: Boolean(availableSessionCount && availableSessionCount > 0),
    }));
  }, [availableSessionCount, initialAvailableSessionCount]);

  const onAvailableSessionsCountChange = (nextAvailableSessionCount: number) =>
    setAvailableSessionCount(nextAvailableSessionCount);

  const onAvailableGroupSessionsCountChange = (nextAvailableGroupSessionCount: number) =>
    setAvailableGroupSessionCount(nextAvailableGroupSessionCount);

  return {
    availableSessionCount,
    availableGroupSessionCount,
    onAvailableSessionsCountChange,
    onAvailableGroupSessionsCountChange,
    validationResult,
  };
};
