import { useEffect } from 'react';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { initAudioInputDevices, initAudioOutputDevices, localAudioTrack$ } from '../../store/audio';
import { initVideoInputDevices, localVideoTrack$, removeLocalVideoTrack } from '../../store/video';

export default function useRestartAudioTrackOnDeviceChange(): void {
  const localVideoTrack = useBehaviorSubjectState(localVideoTrack$);
  const localAudioTrack = useBehaviorSubjectState(localAudioTrack$);

  useEffect(() => {
    const handleDeviceChange = () => {
      initVideoInputDevices();
      initAudioInputDevices();
      initAudioOutputDevices();

      if (localVideoTrack?.mediaStreamTrack.readyState === 'ended') {
        removeLocalVideoTrack();
      }

      if (localAudioTrack?.mediaStreamTrack.readyState === 'ended') {
        localAudioTrack.disable();
        localAudioTrack.restart({});
      }
    };

    navigator.mediaDevices?.addEventListener('devicechange', handleDeviceChange);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', handleDeviceChange);
    };
  }, [localVideoTrack, localAudioTrack]);
}
