import { Consumer } from '@inovirtue/admin';
import { useQuery } from 'react-query';
import { getEmployees } from '../api/companiesApi';

export const useCompanyEmployees = (companyId?: string) => {
  return useQuery<Consumer[] | undefined>({
    queryKey: ['company-employees', companyId],
    queryFn: async () => {
      if (companyId) {
        return getEmployees(companyId);
      }
    },
    enabled: !!companyId,
  });
};
