import './ConversationsSupport.scss';
import { Avatar } from 'antd';
import { ReactNode } from 'react';
import { ButtonWithLoader } from '@inovirtue/components';

export interface ConversationsSupportProps {
  icon: ReactNode;
  title: string;
  btnText: string;
  isLoading?: boolean;
  onClick: () => void;
}

export const ConversationsSupport = ({
  icon,
  title,
  btnText,
  isLoading,
  onClick,
}: ConversationsSupportProps): JSX.Element => {
  return (
    <article data-testid='conversations-support' className='conversations-support'>
      <Avatar className='conversations-support__avatar' size={40} icon={icon} />
      <h1 className='conversations-support__title'>{title}</h1>
      <ButtonWithLoader
        data-testid='conversations-support-btn'
        innerBtnClassName='conversations-support__btn'
        spinning={isLoading}
        onClick={onClick}>
        {btnText}
      </ButtonWithLoader>
    </article>
  );
};
