import './CompanyAddress.scss';
import { t } from '@inovirtue/localization';

export type CompanyAddressProps = {
  addressCompanyName: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  country: string;
};

export const CompanyAddress = (props: CompanyAddressProps): JSX.Element => {
  return (
    <div className='company-address-details'>
      <div className='company-address__title'>
        <div>{t('company:address')}</div>
      </div>
      <div data-testid='company-address-content' className='company-address__content'>
        <div>{props.addressCompanyName}</div>
        <div>{props.addressLine1}</div>
        <div>{props.addressLine2}</div>
        <div>
          {props.zip} {props.city}
        </div>
        <div>{props.country}</div>
      </div>
    </div>
  );
};
