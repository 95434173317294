import { includes } from 'lodash';
import { useMemo } from 'react';

export const useRecommendedProvidersUtils = (
  assignedProvidersNames: string[],
  recommendedProvidersNames: string[],
  removedProvidersNames: string[],
) => {
  const isProviderAssigned = useMemo(
    () => (providerName: string) => includes(assignedProvidersNames, providerName),
    [assignedProvidersNames],
  );

  const isProviderRecommended = useMemo(
    () => (providerName: string) => includes(recommendedProvidersNames, providerName),
    [recommendedProvidersNames],
  );

  const isProviderRemoved = useMemo(
    () => (providerName: string) => includes(removedProvidersNames, providerName),
    [removedProvidersNames],
  );

  return {
    isProviderAssigned,
    isProviderRecommended,
    isProviderRemoved,
  };
};
