import './ChatSidebar.scss';
import { FormOutlined } from '@ant-design/icons';
import {
  Conversations,
  ConversationsList,
  ConversationsListItem,
  TabPane,
  Tabs,
  useLayoutContext,
} from '@inovirtue/chat';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SearchProfile from '../../../Profile/components/Search/SearchProfile';
import { ChatTabs } from '../../models/chatTabs';
import EmptyList from '../EmptyList/EmptyList';

const { Sidebar } = Conversations;

const ChatSidebar = (): JSX.Element => {
  const [activeTabId, setActiveTabId] = useState<ChatTabs>(ChatTabs.messages);
  const { sid } = useParams<{ sid?: string }>();
  const { setSelectedConvoSid } = useLayoutContext();

  useEffect(() => {
    if (!sid) return;

    setSelectedConvoSid(sid);
  }, [sid, setSelectedConvoSid]);

  return (
    <Sidebar>
      <Tabs activeId={activeTabId}>
        <TabPane
          id={ChatTabs.messages}
          title={t('chat:messages')}
          headerActions={
            <Button
              clear
              size='small'
              onClick={() => setActiveTabId(ChatTabs.newMessage)}
              icon={<FormOutlined className='chat-sidebar__tab-icon' />}
            />
          }>
          <ConversationsList
            placeholder={<EmptyList onClick={() => setActiveTabId(ChatTabs.newMessage)} />}
            renderItem={(convo) => (
              <ConversationsListItem convoSid={convo.sid} isRole isCompanyName />
            )}
          />
        </TabPane>
        <TabPane
          id={ChatTabs.newMessage}
          title={t('chat:new-message')}
          onBack={() => setActiveTabId(ChatTabs.messages)}
          contentCls='chat-sidebar__new-message'>
          <SearchProfile onCreated={() => setActiveTabId(ChatTabs.messages)} />
        </TabPane>
      </Tabs>
    </Sidebar>
  );
};

export default ChatSidebar;
