import { useState, useMemo } from 'react';
import { useCompaniesList } from '../../Companies/hooks/useCompaniesList';
import { FilterValue } from 'antd/lib/table/interface';

const useCompanyFilter = () => {
  const { companies } = useCompaniesList();

  const companiesFilterValues = useMemo(
    () => (companies ? companies.map((c) => ({ text: c.name, value: c.companyId as string })) : []),
    [companies],
  );

  const [companyFilter, setCompanyFilter] = useState({});

  const onFilterChange = (filters: Record<string, FilterValue | null>): void => {
    const companyId = filters?.company?.[0] as string;
    setCompanyFilter(companyId ? { companyId } : {});
  };

  return {
    companiesFilterValues,
    onFilterChange,
    filters: companyFilter as Record<string, string>,
  };
};

export default useCompanyFilter;
