import React from 'react';
import { Image, Typography } from 'antd';
import './OfferCard.Component.scss';
import { Button } from '../Button/Button';
const { Title } = Typography;

export type OfferCardProps = {
  title: string;
  description: string;
  buttonText: string;
  onButtonClick?(): void;
  imageUrl?: string;
};

export const OfferCard = ({
  title,
  description,
  buttonText,
  onButtonClick,
  imageUrl,
}: OfferCardProps): JSX.Element => (
  <div data-testid={title} className='offerTypeCard'>
    {imageUrl && <Image preview={false} src={imageUrl} />}
    <Title level={4}>{title}</Title>
    <p className='offerTypeCardText'>{description}</p>
    <Button data-testid='offerCardButton' onClick={onButtonClick} disabled={!onButtonClick}>
      {buttonText}
    </Button>
  </div>
);
