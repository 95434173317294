import React, { useMemo } from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import { Controller } from 'react-hook-form';
import { t } from '@inovirtue/localization';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import './AddInvitationForm.scss';
import useAddInvitations, {
  UseAddInvitationsParams,
} from '../../hooks/useAddInvitations/useAddInvitations';
import { PositionsList } from '../../model/PositionModel';
import { DepartmentsList } from '@inovirtue/company';

const { Option } = Select;

export interface AddInvitationFormmProps extends UseAddInvitationsParams {
  className?: string;
  positions: PositionsList;
  departments: DepartmentsList;
  positionsDropdownElements?: React.ReactChild;
  departmentsDropdownElements?: React.ReactChild;
  disbaleInputs?: boolean;
}

export const AddInvitationForm = ({
  className,
  positions,
  departments,
  addInvitation,
  invitationsList,
  positionsDropdownElements,
  departmentsDropdownElements,
  disbaleInputs,
}: AddInvitationFormmProps): JSX.Element => {
  const { control, checkEmployeeIdDuplication, checkEmailDuplication, submiAction } =
    useAddInvitations({
      addInvitation,
      invitationsList,
    });

  const diableInputs = useMemo(() => {
    return disbaleInputs === undefined ? false : disbaleInputs;
  }, [disbaleInputs]);
  return (
    <Form layout='vertical' className={`addInvitationForm ${className}`} onFinish={submiAction}>
      <Row gutter={24}>
        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
          <Form.Item label={t('common:label-employeeId')} required>
            <Controller
              name='employeeId'
              control={control}
              rules={{
                required: true,
                validate: checkEmployeeIdDuplication,
              }}
              render={({ field }) => (
                <Input {...field} data-testid='employeeId' required disabled={diableInputs} />
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
          <Form.Item label={t('common:label-first-name')} required>
            <Controller
              name='firstName'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} data-testid='firstName' required disabled={diableInputs} />
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
          <Form.Item label={t('common:label-last-name')} required>
            <Controller
              name='lastName'
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Input {...field} data-testid='lastName' required disabled={diableInputs} />
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
          <Form.Item label={t('common:settings.personal-info.contact-info.email-address')} required>
            <Controller
              name='email'
              control={control}
              rules={{
                required: true,
                pattern: /^\S+@\S+\.\S+$/,
                validate: checkEmailDuplication,
              }}
              render={({ field }) => (
                <Input {...field} data-testid='email' required disabled={diableInputs} />
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
          <Form.Item label={t('cockpit:common.position')} required>
            <Controller
              name='positionId'
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data-testid='positionId'
                  disabled={diableInputs}
                  dropdownClassName='addInvitationForm__dropdown-select'
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      {positionsDropdownElements}
                    </>
                  )}
                >
                  {positions.map((el) => (
                    <Option key={el.id} value={el.id}>
                      {el.name}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
          <Form.Item label={t('common:label-department')} required>
            <Controller
              name='departmentId'
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data-testid='departmentId'
                  disabled={diableInputs}
                  dropdownClassName='addInvitationForm__dropdown-select'
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      {departmentsDropdownElements}
                    </>
                  )}
                >
                  {departments.map((el) => (
                    <Option key={el.id} value={el.id}>
                      {el.name}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className='addInvitationForm__add-button'>
        <Col>
          <Form.Item>
            <Button
              disabled={diableInputs}
              htmlType='submit'
              icon={<PlusOutlined />}
              size='middle'
              data-testid='submitButton'
            >
              {t('common:add')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
