import { useEffect } from 'react';
import { initializeLocalTracks, setIsAcquiringMediaError, setIsInitialized } from '../../store';
import { initAudioInputDevices, initAudioOutputDevices } from '../../store/audio';
import { initVideoInputDevices } from '../../store/video';

export const useDevicesInit = () => {
  useEffect(() => {
    const init = async () => {
      try {
        await initializeLocalTracks();
        await Promise.all([
          initVideoInputDevices(),
          initAudioInputDevices(),
          initAudioOutputDevices(),
        ]);
      } catch {
        setIsAcquiringMediaError(true);
      } finally {
        setIsInitialized(true);
      }
    };

    init();
  }, []);
};
