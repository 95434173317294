import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useCallback, useMemo, useState } from 'react';
import { DepartmentsList } from '../../model/DepartmentModel';

export interface UseAddDepartmentsParams {
  departments: DepartmentsList;
  submitNewDepartments: (newPositions: string[]) => Promise<void>;
  getDepartments: () => Promise<void> | void;
}

const useAddDepartments = ({
  departments: departments,
  submitNewDepartments: submitNewDepartments,
  getDepartments: getDepartments,
}: UseAddDepartmentsParams) => {
  const [newDepartments, setNewDepartments] = useState<string[]>([]);
  const [validate, setValidate] = useState(true);
  const [actualDepartment, setActualDepartment] = useState<string>('');

  const submitDisabled = useMemo(() => {
    return newDepartments.length === 0;
  }, [newDepartments]);

  const addDepartment = useCallback(() => {
    if (
      departments.find((el) => el.name === actualDepartment) ||
      newDepartments.includes(actualDepartment)
    ) {
      setValidate(false);
    } else {
      setNewDepartments([...newDepartments, actualDepartment]);
      setValidate(true);
      setActualDepartment('');
    }
  }, [actualDepartment, setNewDepartments, newDepartments, departments]);

  const submitDepartments = useCallback(async () => {
    try {
      await submitNewDepartments(newDepartments);
      getDepartments();
      showToast(t('common:save-success'));
    } catch (error) {
      showToast(t('errors:unhandled-error'));
    }
  }, [newDepartments, getDepartments, submitNewDepartments]);

  const clearAllAction = () => {
    setValidate(true);
    setNewDepartments([]);
    setActualDepartment('');
  };

  return {
    newDepartments,
    addDepartment,
    validate,
    actualDepartment,
    setActualDepartment,
    submitDepartments,
    submitDisabled,
    clearAllAction,
  };
};

export default useAddDepartments;
