import { createStore, persistState, PersistStateSelectFn } from '@datorama/akita';
import { LocalAudioTrack } from 'twilio-video';

const STORE_KEY = 'audio';

export interface AudioState {
  hasAudioInputDevices: boolean;
  audioInputDevices: MediaDeviceInfo[];
  selectedAudioInputDeviceId: string;
  hasAudioOutputDevices: boolean;
  audioOutputDevices: MediaDeviceInfo[];
  activeSinkId: string;
  localAudioTrack: LocalAudioTrack;
  isAudioEnabled: boolean;
}

const initialState: Partial<AudioState> = {
  isAudioEnabled: false,
  hasAudioInputDevices: false,
  hasAudioOutputDevices: false,
  audioInputDevices: [],
  audioOutputDevices: [],
  activeSinkId: 'default',
};

export const audioStore = createStore<AudioState>(initialState, {
  name: STORE_KEY,
});

const selectSettings: PersistStateSelectFn<AudioState> = (state) => ({
  selectedAudioInputDeviceId: state.selectedAudioInputDeviceId,
  activeSinkId: state.activeSinkId,
});
selectSettings.storeName = STORE_KEY;
persistState({
  include: [STORE_KEY],
  select: [selectSettings],
  key: 'selected-audio-settings',
});
