import { useFlags } from 'launchdarkly-react-client-sdk';
import { useProfileRoles } from '../../../Profile/hooks/useProfileRoles';
import { ROLES } from '../../constants/roleConstants';

export const useChatVisibility = () => {
  const { chatFlag } = useFlags();
  const { roles } = useProfileRoles();

  return {
    isChatVisible: chatFlag && !!roles && roles.some((f) => f.name === ROLES.Consultant),
  };
};
