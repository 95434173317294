import { Collapse, Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import { t } from '@inovirtue/localization';
import Provider from '../../../../Modules/Providers/models/Provider';
import './AssignedProviders.scss';

const { Panel } = Collapse;

type AssignedProvidersProps = {
  providers: Provider[];
};

const AssignedProviders = ({ providers }: AssignedProvidersProps) => (
  <div className='assigned-providers'>
    <Collapse defaultActiveKey={['1']}>
      <Panel header='Active coaches' key='1'>
        <Table
          data-testid='assigned-providers'
          dataSource={providers}
          rowKey='name'
          size='small'
          pagination={false}>
          <Column title={t('common:coach')} dataIndex='displayName' key='displayName' />
          <Column
            title={t('common:expertises')}
            render={(provider: Provider) =>
              provider.interestKeys?.map((interest) => <Tag key={interest}>{interest}</Tag>)
            }
          />
          <Column
            title={t('common:past-sessions')}
            dataIndex='passedSessionCount'
            key='passedSessionCount'
          />
          <Column
            title={t('common:upcoming')}
            dataIndex='futureSessionCount'
            key='futureSessionCount'
          />
        </Table>
      </Panel>
    </Collapse>
  </div>
);

export default AssignedProviders;
