import './EndCallButton.scss';
import { CallEnd } from '@inovirtue/assets';
import { Button } from '@inovirtue/components';
import { useVideoCallDisconnect } from '../../../hooks/useVideoCallDisconnect/useVideoCallDisconnect';
import { setIsDisconnectedByUser } from '../../../store/video';
import classNames from 'classnames';

export interface EndCallButtonProps {
  className?: string;
}

const EndCallButton = ({ className }: EndCallButtonProps): JSX.Element => {
  const disconnectCurrentSession = useVideoCallDisconnect();

  const onClickButton = async () => {
    setIsDisconnectedByUser(true);
    await disconnectCurrentSession();
  };

  const endCallButtonCls = classNames('end-call-button', className);

  return (
    <Button
      className={endCallButtonCls}
      icon={<CallEnd className='end-call-button__icon' />}
      onClick={onClickButton}
    />
  );
};

export default EndCallButton;
