import './PartnerListItem.scss';
import { t } from '@inovirtue/localization';
import { Avatar } from 'antd';
import { useMemo } from 'react';
import { Partner } from '../../model/Partner';
import { WorkshopAvatar } from '../WorkshopAvatar/WorkshopAvatar';

export type PartnerListItemProps = {
  partner: Partner;
};

export const PartnerListItem = ({ partner }: PartnerListItemProps) => {
  const hasProviders = useMemo(() => partner.providers && partner.providers.length > 0, [partner]);
  return (
    <div className='partner-list-item'>
      <div className='partner-list-item__data'>
        <div className='partner-list-item__data-title'>
          <WorkshopAvatar groupName={partner.shortName} />
          <span className='partner-list-item__data-title__group-name'>{partner.shortName}</span>
          {!partner.isActive && (
            <span className='partner-list-item__data-title__deactivated'>
              {t('partners:deactivated')}
            </span>
          )}
        </div>
        {
          <div className='partner-list-item__data__field'>
            <label>
              {t('partners:tabs.providers')} ({partner.providers?.length || 0})
            </label>
            <span>
              {hasProviders ? (
                <Avatar.Group maxCount={5}>
                  {partner.providers?.map((x) => (
                    <Avatar key={`a_${x.name}`} src={x.avatarUrl} />
                  ))}
                </Avatar.Group>
              ) : (
                <div className='partner-list-item__data__field__empty'></div>
              )}
            </span>
          </div>
        }
      </div>
    </div>
  );
};
