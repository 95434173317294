import { Loader } from '@inovirtue/components';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import PartnersViewHeader from '../../../Modules/Partners/components/PartnersViewHeader/PartnersViewHeader';
import { Partner, PartnersList } from '@inovirtue/admin';
import { usePartnersList } from '../../../Modules/Partners/hooks/usePartnersList';
import styles from './PartnersView.module.scss';
import { useHistory } from 'react-router';

const PartnersView = (): JSX.Element => {
  const { push } = useHistory();
  const { partners, isProcessing } = usePartnersList();
  return (
    <BackofficeLayout showHeader={true} headerContent={<PartnersViewHeader />}>
      <div className={styles.partnersContainer}>
        {!isProcessing ? (
          <>
            {partners && (
              <PartnersList
                partners={partners}
                onItemClick={(partner: Partner) => push(`/partners/${partner.id}/details`)}
              />
            )}
          </>
        ) : (
          <Loader spaceSize='large' spinSize='large' />
        )}
      </div>
    </BackofficeLayout>
  );
};

export default PartnersView;
