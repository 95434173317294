import React, { ReactNode } from 'react';
import { useLayout } from '../hooks/useLayout/useLayout';
import { OnConvoSelect, SelectedConvoSid, SetSelectedConvoSid } from '../models/conversation';

export interface LayoutContextType {
  selectedConvoSid: SelectedConvoSid;
  setSelectedConvoSid: SetSelectedConvoSid;
  isTabView: boolean;
  isConversationsList: boolean;
  isConversation: boolean;
}

export const LayoutContext = React.createContext<LayoutContextType | undefined>(undefined);

export interface LayoutProviderProps {
  children: ReactNode;
  isTabView: boolean;
  onConversationSelect?: OnConvoSelect;
  activeConvoSid?: SelectedConvoSid;
}

export const LayoutProvider = ({
  children,
  isTabView,
  onConversationSelect,
  activeConvoSid,
}: LayoutProviderProps): JSX.Element => {
  const { selectedConvoSid, setSelectedConvoSid, isConversation, isConversationsList } = useLayout(
    isTabView,
    onConversationSelect,
    activeConvoSid,
  );

  return (
    <LayoutContext.Provider
      value={{
        isTabView,
        isConversationsList,
        isConversation,
        selectedConvoSid,
        setSelectedConvoSid,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
