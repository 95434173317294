import { t } from '@inovirtue/localization';
import { useVideoCallExpiration } from '../../hooks/useVideoCallExpiration/useVideoCallExpiration';
import Snackbar from '../Snackbar/Snackbar.Component';
import './SessionEndingNotification.scss';

const SessionEndingNotification = (): JSX.Element => {
  const {
    timeLeftMinutes,
    closeSessionOverScheduleNotification,
    showLeftMinutes,
    showIsOverScheduleNotification,
    closeTimeLeftNotification,
  } = useVideoCallExpiration();

  const modalWrapperCls = 'session-ending-notification__modal';

  return (
    <div className='session-ending-notification'>
      {showLeftMinutes && (
        <Snackbar
          type='info'
          headline={
            <div data-testid='leftMinutes'>
              {t('videoCall:call-expiration.time-left-minutes', { minutes: timeLeftMinutes })}
            </div>
          }
          message={''}
          open={true}
          handleClose={closeTimeLeftNotification}
          showIcon={false}
          centered={false}
          clsModal={modalWrapperCls}
        />
      )}
      {showIsOverScheduleNotification && (
        <Snackbar
          type='info'
          headline={
            <div data-testid='sessionOver'>
              {t('videoCall:call-expiration.session-over-schedule')}
            </div>
          }
          message={''}
          handleClose={closeSessionOverScheduleNotification}
          open={true}
          showIcon={false}
          centered={false}
          clsModal={modalWrapperCls}
        />
      )}
    </div>
  );
};

export default SessionEndingNotification;
