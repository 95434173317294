import { CSSProperties, useCallback, useRef } from 'react';
import { Button, VideoCameraOffOutlined } from '@inovirtue/components';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { VideoCameraOutlined } from '@ant-design/icons';

const iconStyle: CSSProperties = { fontSize: '24px', color: 'white' };

export interface ToggleVideoButtonProps {
  disabled?: boolean;
  className?: string;
}

const ToggleVideoButton = ({ disabled, className }: ToggleVideoButtonProps): JSX.Element => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <>
      <Button
        className={className}
        data-testid='toggleVideoButton'
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || disabled}
        icon={
          isVideoEnabled ? (
            <VideoCameraOutlined style={iconStyle} />
          ) : (
            <VideoCameraOffOutlined style={iconStyle} />
          )
        }></Button>
    </>
  );
};

export default ToggleVideoButton;
