import { Provider } from '@inovirtue/admin';
import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getPartnerProviders } from '../api/partnersApi';

export const usePartnerProviders = (partnerId: string) => {
  const fetchProviders = useCallback(() => getPartnerProviders(partnerId), [partnerId]);

  const { data, isProcessing } = usePromiseWithProgress<Provider[]>(fetchProviders);

  return {
    providers: data,
    isProcessing,
  };
};
