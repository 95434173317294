import { useEffect, useState } from 'react';
import { useCompaniesList } from '../../Companies/hooks/useCompaniesList';
import { useCompanyContactList } from '../../Companies/hooks/useCompanyContactList';
import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { useConsultantList } from '../../Companies/hooks/useConsultantList';

export const useWorkshopForm = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [selectedCompanyContactId, setSelectedCompanyContactId] = useState<string | null>('');
  const [selectedConsultantId, setSelectedConsultantId] = useState<string | null>('');
  const { companies, isProcessing: companiesProcessing } = useCompaniesList();
  const { contacts } = useCompanyContactList(selectedCompanyId);
  const { consultants } = useConsultantList();

  useEffect(() => {
    if (companiesProcessing) enableFullPageLoader();
    else disableFullPageLoader();
  }, [companiesProcessing]);

  return {
    selectedCompanyId,
    setSelectedCompanyId,
    selectedCompanyContactId,
    setSelectedCompanyContactId,
    selectedConsultantId,
    setSelectedConsultantId,
    allCompanies: companies,
    allCompanyContacts: contacts,
    allConsultants: consultants,
  };
};
