import { PlusOutlined } from '@ant-design/icons';
import { Form, FormListFieldData, FormListOperation, Input, InputRef, Tag } from 'antd';
import * as React from 'react';
import { useRef, useState } from 'react';

const tagInputStyle: React.CSSProperties = {
  width: 90,
  verticalAlign: 'top',
  marginRight: 10,
};

interface TagInputProps {
  value?: string;
  onChange?: (value: string) => void;
  handleClose: () => void;
}

const TagInput = ({ value, handleClose, onChange }: TagInputProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return isEditMode ? (
    <Input
      size='small'
      style={tagInputStyle}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      onBlur={() => setIsEditMode(false)}
      onPressEnter={() => setIsEditMode(false)}
    />
  ) : (
    <Tag closable style={{ userSelect: 'none' }} onClose={() => handleClose()}>
      <span onDoubleClick={() => setIsEditMode(true)}>{value}</span>
    </Tag>
  );
};

interface TagsInputProps {
  fields: FormListFieldData[];
  operation: FormListOperation;
  className?: string;
}

const TagsInput = ({ fields, operation, className }: TagsInputProps) => {
  const { add, remove } = operation;

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    add(inputValue);
    setInputValue('');
    setInputVisible(false);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const tagPlusStyle: React.CSSProperties = {
    borderStyle: 'dashed',
  };

  return (
    <div className={className}>
      {fields &&
        fields.map((field, index) => (
          <Form.Item {...field} key={field.key}>
            <TagInput handleClose={() => remove(index)} />
          </Form.Item>
        ))}

      {fields.length < 10 &&
        (inputVisible ? (
          <Input
            ref={inputRef}
            type='text'
            size='small'
            style={tagInputStyle}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag style={tagPlusStyle} onClick={showInput}>
            <PlusOutlined /> New Tag
          </Tag>
        ))}
    </div>
  );
};

export default TagsInput;
