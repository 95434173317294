import { Role } from '../../../Modules/Roles/models/role';
import profileStore from './profile.store';

export const setCurrentProfileName = (currentProfileName: string): void =>
  profileStore.update({ currentProfileName });

export const setCurrentProfileDisplayName = (currentProfileDisplayName: string): void =>
  profileStore.update({ currentProfileDisplayName });

export const setRoles = (roles: Role[]): void => profileStore.update({ roles });
