import { useMemo } from 'react';
import { ConvoType, SelectedConvoSid } from '../../models/conversation';
import { selectConversationBySid } from '../../store/conversationsSlice';
import { useConvoSelector } from '../storeHooks/storeHooks';

export const useConversationType = (convoSid: SelectedConvoSid) => {
  const convo = useConvoSelector(selectConversationBySid(convoSid));

  const isOneOnOne = useMemo(() => convo?.attributes.type === ConvoType.OneOnOne, [convo]);
  const isWorkshop = useMemo(() => convo?.attributes.type === ConvoType.Workshop, [convo]);
  const isSupport = useMemo(() => convo?.attributes.type === ConvoType.Support, [convo]);

  return {
    isOneOnOne,
    isWorkshop,
    isSupport,
    type: convo?.attributes.type,
  };
};
