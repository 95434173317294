import './CompanyContractDetails.scss';
import { t } from '@inovirtue/localization';
import { ContractStatus, ContractStatusTag } from '../ContractStatusTag/ContractStatusTag';

export type CompanyContractDetailsProps = {
  contractStartDate: string;
  contractEndDate: string;
  contractStatus: ContractStatus;
};

export const CompanyContractDetails = ({
  contractStartDate,
  contractEndDate,
  contractStatus,
}: CompanyContractDetailsProps): JSX.Element => {
  return (
    <div className='company-contract-details'>
      <div className='company-contract-details__title'>
        <div>{t('company:contract-details')}</div>
      </div>
      <div className='company-contract-details__content'>
        <div className='company-contract-details__content__item'>
          <div className='company-contract-details__content__item__key'>
            {t('company:contract-start')}
          </div>
          <div
            data-testid='company-contract-start-date'
            className='company-contract-details__content__item__value'
          >
            {contractStartDate}
          </div>
        </div>
        <div className='company-contract-details__content__item'>
          <div className='company-contract-details__content__item__key'>
            {t('company:contract-end')}
          </div>
          <div
            data-testid='company-contract-end-date'
            className='company-contract-details__content__item__value'
          >
            {contractEndDate}
          </div>
        </div>
        <div className='company-contract-details__content__item'>
          <div className='company-contract-details__content__item__key'>
            {t('company:contract-status')}
          </div>
          <div
            data-testid='company-contract-status'
            className='company-contract-details__content__item__value'
          >
            <ContractStatusTag status={contractStatus} />
          </div>
        </div>
      </div>
    </div>
  );
};
