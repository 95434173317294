import { Skeleton, Avatar, Divider, Input, Table, Tag } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { t } from '@inovirtue/localization';
import { useProvidersCollection } from '../../hooks/useProvidersCollection';
import { Button } from '@inovirtue/components';
import './Provider.List.scss';
import { EditOutlined } from '@ant-design/icons';
import Provider from '../../models/Provider';
import { useState } from 'react';
import ProfilePreviewModal from '../ProfilePreviewModal/ProfilePreviewModal';
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';

const { Column } = Table;
const { Search } = Input;

const ProvidersList = () => {
  const { providers, hasMore, isProcessing, pageIndex, setPageIndex, searchByText } =
    useProvidersCollection();
  const [selectedProfileName, setSelectedProfileName] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const onViewProfile = (profileName: string) => {
    setSelectedProfileName(profileName);
    setPreviewVisible(true);
  };

  return (
    <div data-testid='providers-list-root' className='providers-list'>
      <div className='providers-list__header'>
        {t('common:providers')}
        <div className='providers-list__search'>
          <Search
            placeholder={t('consumer:search-for-consumers')}
            allowClear
            onSearch={searchByText}
          />
        </div>
      </div>
      <div>
        <InfiniteScroll
          dataLength={providers.length}
          next={() => setPageIndex(pageIndex + 1)}
          hasMore={hasMore}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain>{t('common:no-more-data')}</Divider>}
        >
          <Table
            loading={isProcessing}
            dataSource={providers}
            rowKey='name'
            pagination={false}
            size='small'
          >
            <Column
              title={t('common:name')}
              render={(provider: Provider) => (
                <div className='consumer-list__name'>
                  <Avatar src={provider.avatarUrl} />
                  <span>{provider.displayName}</span>
                </div>
              )}
            />
            <Column
              title={t('common:settings.personal-info.contact-info.email-address')}
              render={(provider: Provider) => (
                <div>
                  <span>{provider.email}</span>
                </div>
              )}
            />
            <Column
              title={t('common:expertises')}
              render={(provider: Provider) =>
                provider.interestKeys?.map((interest, index) => (
                  <Tag key={index}>{t(`tags:tags.${interest}`)}</Tag>
                ))
              }
            />
            <Column
              title={t('onboarding:provider-selection.actions-title')}
              render={(provider: Provider) => (
                <div className='providers-list__actions'>
                  <Button
                    data-testid='profile-view-btn'
                    icon={<EditOutlined />}
                    onClick={() => onViewProfile(provider.name)}
                  >
                    {t('onboarding:provider-selection.view-profile-btn')}
                  </Button>
                  <Button
                    data-testid='profile-link-copy-btn'
                    className='providers-list__actions__copy-btn'
                    icon={<CopyOutlined />}
                    type='text'
                    onClick={async () => {
                      await navigator.clipboard.writeText(provider.profileUrl);
                      message.success(t('common:copied'));
                    }}
                  >
                    {t('onboarding:provider-selection.copy-profile-btn')}
                  </Button>
                </div>
              )}
            />
          </Table>
        </InfiniteScroll>
      </div>
      {!!selectedProfileName && (
        <ProfilePreviewModal
          data-testid='provider-preview-modal-id'
          profileName={selectedProfileName}
          isVisible={previewVisible}
          onClose={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
};

export default ProvidersList;
