import { Conversations, Conversation } from '@inovirtue/chat';
import ChatSidebar from '../ChatSidebar/ChatSidebar';

const { Header, Input, Timeline } = Conversation;

export interface ChatProps {
  isTabView: boolean;
}

const Chat = ({ isTabView }: ChatProps): JSX.Element => {
  return (
    <Conversations isTabView={isTabView}>
      <ChatSidebar />
      <Conversation>
        <Header isRole />
        <Timeline />
        <Input />
      </Conversation>
    </Conversations>
  );
};

export default Chat;
