import './TabPane.scss';
import { ReactNode } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import classNames from 'classnames';

export interface TabPaneProps {
  children: ReactNode;
  id: string;
  title?: string;
  titleSize?: 'default' | 'small';
  headerActions?: ReactNode;
  isActive?: boolean;
  onBack?: () => void;
  contentCls?: string;
}
export const TabPane = ({
  children,
  id,
  title,
  headerActions,
  onBack,
  contentCls,
  isActive = true,
  titleSize = 'default',
}: TabPaneProps): JSX.Element => {
  const tabPaneContentCls = classNames('conversations-tabpane__content', contentCls);
  const tabPaneTitleCls = classNames('conversations-tabpane__title', {
    'conversations-tabpane__title--small': titleSize === 'small',
  });

  return (
    <div
      className='conversations-tabpane'
      role={'tabpanel'}
      tabIndex={isActive ? 0 : -1}
      aria-hidden={!isActive}
      key={id}
      data-testid='conversations-tabpane'>
      {isActive && (
        <>
          <div className='conversations-tabpane__header'>
            {onBack && (
              <Button
                clear
                className='conversations-tabpane__back-btn'
                size='small'
                icon={<ArrowLeftOutlined className='conversations-tabpane__back-icon' />}
                onClick={onBack}
                data-testid='conversations-tabpane-back-btn'
              />
            )}
            <h1 className={tabPaneTitleCls}>{title}</h1>
            {headerActions && (
              <div className='conversations-tabpane__header-content'>{headerActions}</div>
            )}
          </div>
          <div className={tabPaneContentCls}>{children}</div>
        </>
      )}
    </div>
  );
};
