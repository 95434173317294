import { useConversationsContext } from '@inovirtue/chat';
import { useEffect, useState } from 'react';

export const useChat = (uniqueName?: string) => {
  const { client } = useConversationsContext();
  const [convoSid, setConvoSid] = useState<string | undefined>();

  useEffect(() => {
    if (!uniqueName) return;

    const getConvoSid = async () => {
      const convo = await client?.getConversationByUniqueName(uniqueName);
      setConvoSid(convo?.sid);
    };

    getConvoSid();
  }, [client, uniqueName]);

  return convoSid;
};
