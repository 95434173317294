import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { SuccessIcon } from '../../Icons/Success';
import './SuccessPage.Component.scss';

export type SuccessPageProps = {
  heading: string;
  subHeading?: string;
  children?: JSX.Element;
};

export const SuccessPage = ({ heading, subHeading, children }: SuccessPageProps) => (
  <Content className='successView'>
    <div className='successView__image-wrapper'>
      <SuccessIcon />
    </div>
    <p className='successView__heading'>{heading}</p>
    <p className='successView__subheading'>{subHeading}</p>
    <div className='successView__children'>{children}</div>
  </Content>
);
