import axios, { AxiosInstance } from 'axios';
import { attachAuthTokentInterceptor } from './interceptors/authTokenInterceptor';

export const getApiClient = async (baseUrl: string): Promise<AxiosInstance> => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });
  attachAuthTokentInterceptor(axiosInstance);

  return axiosInstance;
};
