import { Alert } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useErrorContext } from '../../hooks/useErrorContext/useErrorContext';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';

export interface ErrorTimelineMessageProps {
  className?: string;
}

export const ErrorTimelineMessage = ({ className }: ErrorTimelineMessageProps): JSX.Element => {
  const { selectedConvoSid } = useLayoutContext();
  const { error, setError } = useErrorContext();

  const clearError = useCallback(() => setError(null), [setError]);

  useEffect(() => {
    clearError();
  }, [selectedConvoSid, clearError]);

  const errorTimelineMessageCls = classNames('error-timeline-message', className);

  return (
    <>
      {error && (
        <Alert
          data-testid='error-timeline-message-alert'
          className={errorTimelineMessageCls}
          message={error}
          type='error'
          closable
          afterClose={clearError}
        />
      )}
    </>
  );
};
