import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { useEffect, useState } from 'react';
import { room$ } from '../../store/video';

export type RoomState = 'disconnected' | 'connected' | 'reconnecting';

export default function useRoomState(): RoomState {
  const room = useBehaviorSubjectState(room$);
  const [state, setState] = useState<RoomState>('disconnected');

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomState);
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    } else return;
  }, [room]);

  return state;
}
