export type StepType =
  | 'CallPrepStep'
  | 'CallWaitingRoomStep'
  | 'CallRoomStep'
  | 'NoAnswerStep'
  | 'CallLeftStep'
  | 'CallEndedStep'
  | 'CallErrorStep'
  | 'CallExpiredStep';

export const Step: { [key in StepType]: StepType } = {
  CallPrepStep: 'CallPrepStep',
  CallWaitingRoomStep: 'CallWaitingRoomStep',
  CallRoomStep: 'CallRoomStep',
  CallLeftStep: 'CallLeftStep',
  NoAnswerStep: 'NoAnswerStep',
  CallEndedStep: 'CallEndedStep',
  CallErrorStep: 'CallErrorStep',
  CallExpiredStep: 'CallExpiredStep',
} as const;
