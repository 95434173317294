import { useMemo } from 'react';
import { Workshop } from '../model/Workshop';

export const useCanDeactivateWorkshop = (workshop?: Workshop) => {
  const canDeactivate = useMemo<boolean>(() => {
    if (!workshop) {
      return false;
    }
    return workshop.status === 'Unpublished' || workshop.status === 'Active';
  }, [workshop]);

  const showDeactivate = useMemo<boolean>(() => {
    if (!workshop) {
      return false;
    }
    return (
      workshop.status === 'Unpublished' ||
      workshop.status === 'Active' ||
      workshop.status === 'Deactivated'
    );
  }, [workshop]);

  return { canDeactivate, showDeactivate };
};
