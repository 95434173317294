import { Table } from 'antd';
import { t } from '@inovirtue/localization';
import { useHistory } from 'react-router';
import { Button } from '@inovirtue/components';
import { EditOutlined } from '@ant-design/icons';
import { Company } from '../../models/domain/Company';
import './CompaniesList.scss';
import { useCompaniesList } from '../../hooks/useCompaniesList';
import moment from 'moment';
import { ContractStatusTag } from '@inovirtue/company';
import routes from '../../../../App/routes/constants/Routes';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { dateFormat } from './../../../../App/constants/date';
const { Column } = Table;

export const CompaniesList = (): JSX.Element => {
  const history = useHistory();
  const { companies, isProcessing } = useCompaniesList();
  const { companyDetailsAccessible } = useFlags();

  return (
    <div className='companies-list'>
      <div className='companies-list__header'>
        <span>{t('common:companies')}</span>
        <Button
          data-testid='company-add'
          type='primary'
          onClick={() => history.push(routes.CompanyAdd)}
        >
          {t('common:add-entity', { entityName: t('common:company') })}
        </Button>
      </div>
      <div>
        <Table
          loading={isProcessing}
          dataSource={companies}
          rowKey='id'
          pagination={false}
          size='small'
        >
          <Column title={t('common:name')} dataIndex='name' />
          <Column
            title={t('common:creation-date')}
            render={(company: Company) => moment(company.creationDate).format(dateFormat)}
          />
          <Column
            title={t('company:contract-start')}
            render={(company: Company) => moment(company.licenseStartDate).format(dateFormat)}
          />
          <Column
            title={t('company:contract-end')}
            render={(company: Company) =>
              company.licenseEndDate ? moment(company.licenseEndDate).format(dateFormat) : ''
            }
          />
          <Column
            title={t('company:contract-status')}
            render={(company: Company) => (
              <span>
                <ContractStatusTag status={company.companyLicenseStatus ?? 'Unknown'} />
              </span>
            )}
          />
          <Column
            title={t('common:edit')}
            render={(company: Company) => (
              <Button
                icon={<EditOutlined />}
                disabled={!companyDetailsAccessible}
                onClick={() => history.push(`/companies/${company.companyId}`)}
              >
                {t('common:edit')}
              </Button>
            )}
          />
        </Table>
      </div>
    </div>
  );
};
