import React, { useCallback, useMemo, useState } from 'react';
import { t } from '@inovirtue/localization';
import { SearchablePaginatedTable } from '@inovirtue/table';
import { getEmployees } from '../api/Employees.Api';
import routes from '../../../App/routes/constants/Routes';
import { EmployeeTablesTabs } from '../components/EmployeeTableTabs/EmployeeTablesTabs';
import { DEFAULT_PAGE_SIZE } from '@inovirtue/table';
import { updateActiveEmployeesTotal } from '../state';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import InviteEmployeesButton from '../../Invitations/components/InviteEmployeesButton/InviteEmployeesButton';
import getActiveEmployeesColumns from './ActiveEmployeesColumns';
import useCompanyFilter from '../hooks/useCompanyFilter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EditEmployeeSessionsModal from './EditEmployeeSessionsModal';

const ActiveEmployees = (): JSX.Element => {
  const { backofficeCompanyFilter, boEditMonthlySessions } = useFlags();
  const { companiesFilterValues, onFilterChange, filters } = useCompanyFilter();
  const [editSessionsProfileName, setEditSessionsProfileName] = useState<string>();

  const onEditSessionClick = (profileName: string) => setEditSessionsProfileName(profileName);

  const cols = useMemo(() => {
    const columns = getActiveEmployeesColumns(
      companiesFilterValues,
      backofficeCompanyFilter,
      onEditSessionClick,
    );

    if (!boEditMonthlySessions) columns.pop();
    return columns;
  }, [companiesFilterValues, backofficeCompanyFilter, boEditMonthlySessions]);

  const getEmployeesCallback = useCallback(
    (pageIndex: number, searchString?: string) =>
      getEmployees(pageIndex, filters, DEFAULT_PAGE_SIZE, searchString),
    [filters],
  );

  return (
    <div data-testid='active-employees-table'>
      <BackofficeLayout showHeader={true} headerContent={<InviteEmployeesButton />}>
        <SearchablePaginatedTable
          tableName={t('company:employees:active-employees-table.title')}
          rowKey='name'
          getEntries={getEmployeesCallback}
          columns={cols as object[]}
          searchInputPlaceHolder={t('company:employees:active-employees-table.search-placeholder')}
          beforeTable={<EmployeeTablesTabs currentTab={routes.Employees} />}
          onDataLoaded={(data) => updateActiveEmployeesTotal(data.total)}
          onFilterChange={onFilterChange}
        />
      </BackofficeLayout>
      {editSessionsProfileName && (
        <EditEmployeeSessionsModal
          visible={!!editSessionsProfileName}
          profileName={editSessionsProfileName}
          onClose={() => setEditSessionsProfileName(undefined)}
        />
      )}
    </div>
  );
};

export default ActiveEmployees;
