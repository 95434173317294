import { PlusOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from '@inovirtue/components';
import { AddDepartmentsModal } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { useState } from 'react';
import { DepartmentsList } from '@inovirtue/admin/dist/model/DepartmentModel';

interface AddDepartmentButtonProps extends ButtonProps {
  companyId: string;
  fetchDepartments: (companyId: string) => Promise<void>;
  departments: DepartmentsList;
  submitDepartments: (newDepartments: string[]) => Promise<void>;
}
const AddDepartmentButton = ({
  companyId,
  departments,
  fetchDepartments,
  submitDepartments,
  ...rest
}: AddDepartmentButtonProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setIsVisible(true)} icon={<PlusOutlined />} {...rest}>
        {t('cockpit:departments:add-new-departments')}
      </Button>
      <AddDepartmentsModal
        addLabel={t('common:add')}
        visible={isVisible}
        departments={departments}
        getDepartments={() => fetchDepartments(companyId)}
        submitNewDepartments={submitDepartments}
        onClose={() => setIsVisible(false)}
        cancelLabel={t('common:cancel')}
        saveLabel={t('common:save')}
        departmentModalDescription={t('cockpit:departments.modal-description')}
        modalTitle={t('cockpit:departments.add-new-departments')}
        inputLabel={t('cockpit:departments:new-department')}
        errorMessage={t('cockpit:departments:department-validation')}
      />
    </>
  );
};

export default AddDepartmentButton;
