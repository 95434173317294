import { useCallback, useMemo } from 'react';
import { Convo } from '../../models/conversation';
import { selectAllParticipants, selectParticipantsByConvoId } from '../../store/participantsSlice';
import { useConvoSelector } from '../storeHooks/storeHooks';

export const useFriendlyName = (convoSid: Convo['sid']) => {
  const participantEntity = useConvoSelector(selectParticipantsByConvoId(convoSid));
  const participants = useMemo(
    () => selectAllParticipants(participantEntity?.participatns),
    [participantEntity],
  );

  const getFriendlyName = useCallback(
    (identity: string | null) => {
      if (!identity) return null;
      const participant = participants.find((element) => element.identity === identity);

      return participant?.attributes.displayName || null;
    },
    [participants],
  );

  return { getFriendlyName };
};
