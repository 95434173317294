import './UploadButton.scss';
import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import classNames from 'classnames';
import { fileContentTypes, imageContentTypes } from '../../constants/contentTypes';
import { useFileUpload } from '../../hooks/useFileUpload/useFileUpload';
import { OnUploadFinish } from '../../models/upload';

export interface UploadButtonProps {
  onUploadFinish: OnUploadFinish;
  className?: string;
}

export const UploadButton = ({ onUploadFinish, className }: UploadButtonProps): JSX.Element => {
  const { validateFile } = useFileUpload();

  const uploadButtonCls = classNames('upload-button', className);

  return (
    <Upload
      className={uploadButtonCls}
      action={onUploadFinish}
      showUploadList={false}
      accept={[...imageContentTypes, ...fileContentTypes].join(',')}
      customRequest={() => {}}
      beforeUpload={validateFile}
      multiple>
      <Button
        data-testid='conversation-btn-attachment'
        className='upload-button__btn'
        size='small'
        icon={<PaperClipOutlined className='upload-button__icon' />}
      />
    </Upload>
  );
};
