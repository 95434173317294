import { getApiClient } from '../../Common/api/apiClient';
import config from '../../../config';
import { getyyyyMMdd } from '@inovirtue/date-utils';
import PersonalAndWorkshopSessionDto from '../models/PersonalAndWorkshopSessionDto';

export const getProviderSessions = async (
  profileName: string,
  fromDate: Date,
  toDate: Date,
): Promise<PersonalAndWorkshopSessionDto[]> => {
  const apiClient = await getApiClient(config.apiAddresses.socialNetworkService);
  const { data } = await apiClient.get<PersonalAndWorkshopSessionDto[]>(
    `/profiles/${profileName}/sessions?startDateTime=${getyyyyMMdd(
      fromDate,
    )}&endDateTime=${getyyyyMMdd(toDate)}`,
  );
  return data;
};
