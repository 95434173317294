export const NameAndDescIcon = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='0.000488281' width='40' height='40' rx='8' fill='#F0F0F0' />
    <g clipPath='url(#clip0_3232_103999)'>
      <g clipPath='url(#clip1_3232_103999)'>
        <path
          d='M31.9357 19.8773L22.4429 10.5238C22.4232 10.504 22.3998 10.4883 22.3741 10.4776C22.3483 10.4669 22.3207 10.4614 22.2929 10.4614C22.265 10.4614 22.2374 10.4669 22.2116 10.4776C22.1859 10.4883 22.1625 10.504 22.1429 10.5238L15.7491 16.8238C15.7089 16.8637 15.686 16.9179 15.6855 16.9746C15.685 17.0312 15.7069 17.0858 15.7464 17.1265L15.7491 17.1291L16.8205 18.1845L13.6813 21.2729C13.641 21.3128 13.6182 21.367 13.6177 21.4237C13.6172 21.4804 13.639 21.5349 13.6786 21.5756L13.6813 21.5782L14.7393 22.6202L9.67411 27.6291H8.21696C8.09911 27.6291 8 27.7256 8 27.8434V29.322C8 29.4398 8.09643 29.5363 8.21429 29.5363H17.4527C17.5089 29.5363 17.5625 29.5148 17.6027 29.4747L19.6411 27.4497L20.7232 28.5157C20.7429 28.5355 20.7663 28.5512 20.792 28.5619C20.8177 28.5726 20.8453 28.5781 20.8732 28.5781C20.9011 28.5781 20.9287 28.5726 20.9544 28.5619C20.9802 28.5512 21.0036 28.5355 21.0232 28.5157L24.1598 25.4193L25.2339 26.4773C25.2536 26.4971 25.277 26.5128 25.3027 26.5235C25.3284 26.5342 25.3561 26.5397 25.3839 26.5397C25.4118 26.5397 25.4394 26.5342 25.4652 26.5235C25.4909 26.5128 25.5143 26.4971 25.5339 26.4773L31.9277 20.1773C32.0188 20.097 32.0188 19.9631 31.9357 19.8773V19.8773ZM16.7268 27.6131H12.4357L16.0357 24.0506L18.1813 26.164L16.7268 27.6131ZM20.8732 25.9497L16.2821 21.4256L18.1196 19.6148L22.7107 24.139L20.8732 25.9497V25.9497ZM25.3866 23.9113L18.3473 16.9765L22.2929 13.0898L29.3321 20.0273L25.3866 23.9113V23.9113Z'
          fill='black'
          fillOpacity='0.85'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3232_103999'>
        <rect width='24' height='24' fill='white' transform='translate(8 8.00049)' />
      </clipPath>
      <clipPath id='clip1_3232_103999'>
        <rect width='24' height='24' fill='white' transform='translate(8 8.00049)' />
      </clipPath>
    </defs>
  </svg>
);
