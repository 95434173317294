import { useHistory } from 'react-router';
import { Button } from '@inovirtue/components';
import { PlusOutlined } from '@ant-design/icons';
import { t } from '@inovirtue/localization';

import './InviteEmployeesButton.scss';
import routes from '../../../../App/routes/constants/Routes';

const InviteEmployeesButton = (): JSX.Element => {
  const history = useHistory();

  return (
    <Button
      type='default'
      className='invite-employees-button'
      data-testid='invite-employees-button'
      icon={<PlusOutlined />}
      onClick={() => history.push(routes.InvitationsList)}
    >
      {t('cockpit:add-employees.add-new-employee')}
    </Button>
  );
};

export default InviteEmployeesButton;
