import { Row, Col } from 'antd';
import { t } from '@inovirtue/localization';
import StepNotice from '../../StepNotice/StepNotice.Component';
import { Button } from '../../Buttons/Button/Button.Component';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { onRedirect, onRejoin } from '../../../store/video';
import { sessionDisplayName$ } from '../../../store/video-call-session';
import './NoAnswerStep.Component.scss';

const { Heading, SubHeading, Actions, Profile } = StepNotice;

const NoAnswerStep = (): JSX.Element => {
  const sessionDisplayName = useBehaviorSubjectState(sessionDisplayName$);

  return (
    <div className='noanswerstep' data-testid='videoNoAnswerStep'>
      <Row>
        <Col>
          <StepNotice>
            <Profile />
            <Heading
              text={t('videoCall:call-noanswer.heading', { userName: sessionDisplayName })}
            />
            <SubHeading
              text={t('videoCall:call-noanswer.subheading', { userName: sessionDisplayName })}
            />
            <Actions>
              <Button type='primary' onClick={onRejoin} className='noanswerstep__button'>
                {t('videoCall:rejoin-btn')}
              </Button>
              <Button type='default' onClick={onRedirect} className='noanswerstep__button'>
                {t('videoCall:dismiss-btn')}
              </Button>
            </Actions>
          </StepNotice>
        </Col>
      </Row>
    </div>
  );
};

export default NoAnswerStep;
