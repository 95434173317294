import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import config from '../../config';
import { resolvedLanguage } from '@inovirtue/localization';

export const SignIn = (): JSX.Element => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect({
      redirectStartPage: '/signed-in',
      scopes: config.msalConfig.scopes,
      prompt: 'login',
      extraQueryParameters: { ui_locales: resolvedLanguage },
    });
  }, [instance]);

  return <></>;
};
