import { RefObject, useEffect, useRef } from 'react';

function useEventListenerLocal<WinEvent extends keyof WindowEventMap>(
  eventName: WinEvent,
  handler: (event: WindowEventMap[WinEvent]) => void,
): void;
function useEventListenerLocal<
  HTMLEvent extends keyof HTMLElementEventMap,
  Element extends HTMLElement = HTMLDivElement,
>(
  eventName: HTMLEvent,
  handler: (event: HTMLElementEventMap[HTMLEvent]) => void,
  element: RefObject<Element>,
): void;

function useEventListenerLocal<
  WinEvent extends keyof WindowEventMap,
  HTMLEvent extends keyof HTMLElementEventMap,
  Element extends HTMLElement | void = void,
>(
  eventName: WinEvent | HTMLEvent,
  handler: (event: WindowEventMap[WinEvent] | HTMLElementEventMap[HTMLEvent] | Event) => void,
  element?: RefObject<Element>,
): void {
  const memoizedHandler = useRef<typeof handler>();
  memoizedHandler.current = handler;

  useEffect(() => {
    const targetElement: Element | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    const eventListener: typeof handler = (event) => {
      if (memoizedHandler.current) {
        memoizedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export const useEventListener = useEventListenerLocal;
