import { useCallback, useEffect, useState } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import {
  hasAudioInputDevices$,
  localAudioTrack$,
  selectedAudioInputDeviceId$,
} from '../../store/audio';
import { useBehaviorSubjectState } from '@inovirtue/hooks';

const useLocalAudioToggle = (): readonly [boolean, () => void, boolean] => {
  const hasAudioInputDevices = useBehaviorSubjectState(hasAudioInputDevices$);
  const localAudioTrack = useBehaviorSubjectState(localAudioTrack$);
  const selectedAudioInputDeviceId = useBehaviorSubjectState(selectedAudioInputDeviceId$);
  const isAudioEnabled = useIsTrackEnabled(localAudioTrack);
  const [isToggleAudioDisabled, setIsToggleAudioDisabled] = useState(true);

  const toggleIsAudioEnabled = useCallback(() => {
    if (!localAudioTrack) return;

    if (localAudioTrack.isEnabled || isToggleAudioDisabled) {
      localAudioTrack.disable();
    } else {
      localAudioTrack.enable();
    }
  }, [localAudioTrack, isToggleAudioDisabled]);

  useEffect(() => {
    setIsToggleAudioDisabled(!hasAudioInputDevices || !selectedAudioInputDeviceId);
  }, [hasAudioInputDevices, selectedAudioInputDeviceId]);

  return [isAudioEnabled, toggleIsAudioEnabled, isToggleAudioDisabled] as const;
};

export default useLocalAudioToggle;
