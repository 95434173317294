import classNames from 'classnames';
import './CalendarCell.styles.scss';

export const CalendarCell = ({
  dayNumber,
  isDisabled,
}: {
  dayNumber: number | string;
  isDisabled: boolean;
}): JSX.Element => {
  const calendarCellClasses = classNames('booking-calendar-cell', {
    'booking-calendar-cell--disabled': isDisabled,
  });
  return <div className={calendarCellClasses}>{dayNumber} </div>;
};
