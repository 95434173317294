import { InputWCharactersLimit } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Button, Form, Input, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useMemo } from 'react';
import { usePartnerForm } from '../../hooks/usePartnerForm';
import { PartnerForm } from '../../models/PartnerForm';
import ImageUpload from '../ImageUpload/ImageUpload';
import TagsInput from '../TagsInput/TagsInput';
import './PartnerForm.module.scss';
import styles from './PartnerForm.module.scss';

interface PartnerFormComponentProps {
  id?: string;
}

const PartnerFormComponent = ({ id }: PartnerFormComponentProps) => {
  const { allTenants, form, onSubmit, isLoading } = usePartnerForm(id);

  const isEditMode = useMemo(() => !!id, [id]);

  return (
    <div data-testid='partner-form'>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={(values) => onSubmit(values as PartnerForm)}
        >
          <Form.Item name='id' style={{ display: 'none' }}>
            <Input type='text' />
          </Form.Item>
          <section className={styles.partnerCreateform__section}>
            <p className={styles.partnerCreateform__sectionHeader}>
              {t('partners:details.name-and-title')}
            </p>
            <Form.Item
              name={'shortName'}
              label={t('partners:details.name-field-label')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('partners:details.name-field-label'),
                  }),
                },
              ]}
            >
              <InputWCharactersLimit
                maxLength={30}
                placeholder={t('partners:details.name-field-placeholder')}
              />
            </Form.Item>
            <Form.Item
              name={'displayName'}
              label={t('partners:details.title-field-label')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('partners:details.title-field-label'),
                  }),
                },
              ]}
            >
              <InputWCharactersLimit
                maxLength={30}
                placeholder={t('partners:details.title-field-placeholder')}
              />
            </Form.Item>
            <Form.Item
              name={'claim'}
              label={t('partners:details.claim-field-label')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('partners:details.claim-field-label'),
                  }),
                },
              ]}
            >
              <TextArea
                placeholder={t('partners:details.claim-field-placeholder')}
                showCount
                maxLength={300}
              />
            </Form.Item>
            <Form.Item
              name={'description'}
              label={t('partners:details.company-info-field-label')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('partners:details.company-info-field-label'),
                  }),
                },
              ]}
            >
              <TextArea
                placeholder={t('partners:details.company-info-field-placeholder')}
                showCount
                maxLength={300}
                rows={4}
              />
            </Form.Item>
            <p className={styles.partnerCreateform__fieldInfo}>
              {t('partners:details.company-info-field-info')}
            </p>

            <div className={styles.partnerCreateform__images}>
              <Form.Item name='logo'>
                <ImageUpload
                  title={t('partners:details.company-logo')}
                  description={t('partners:details.image-requirements')}
                  name='logo'
                />
              </Form.Item>
              <Form.Item name='smallLogo'>
                <ImageUpload
                  title={t('partners:details.company-logo-small')}
                  description={t('partners:details.image-requirements')}
                  name='smallLogo'
                />
              </Form.Item>
              <Form.Item name='background'>
                <ImageUpload
                  title={t('partners:details.company-banner-image')}
                  description={t('partners:details.image-requirements')}
                  name='background'
                />
              </Form.Item>
              <Form.Item name='numbersAndFacts'>
                <ImageUpload
                  title={t('partners:details.company-numbers-and-facts')}
                  description={t('partners:details.image-requirements')}
                  name='numbersAndFacts'
                />
              </Form.Item>
            </div>

            <p className={styles.partnerCreateform__sectionHeader}>
              {t('partners:details.our-mission')}
            </p>
            <span className={styles.partnerCreateform__fieldInfo}>
              {t('partners:details.add-up-to-10')}
            </span>
            <Form.List name='tags'>
              {(fields, operation) => (
                <TagsInput
                  className={styles.partnerCreateform__tags}
                  fields={fields}
                  operation={operation}
                />
              )}
            </Form.List>

            <p className={styles.partnerCreateform__sectionHeader}>{t('partners:details.url')}</p>

            <Form.Item
              name={'name'}
              label={t('partners:details.url-path')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('partners:details.url-path'),
                  }),
                },
              ]}
            >
              <InputWCharactersLimit disabled={isEditMode}
                maxLength={30}
                placeholder={t('partners:details.url-path-placeholder')}
              />
            </Form.Item>

            <Form.Item
              name={'tenant'}
              label={t('partners:details.tenant')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('partners:details.tenant'),
                  }),
                },
              ]}
            >
              <Select disabled={isEditMode}>
                {allTenants.map((tenant) => (
                  <Select.Option key={tenant} value={tenant}>
                    {tenant}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </section>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className={styles.partnerCreateform__submitButton}
            >
              {isEditMode ? t('partners:details.edit-button') : t('partners:details.create-button')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default PartnerFormComponent;
