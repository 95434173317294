import { Redirect, Route, Switch } from 'react-router-dom';
import ProviderCalendar from '../../../Modules/Provider/views/ProviderCalendar.View';
import ConsumerEditView from '../../../Modules/ConsumerEdit/views/ConsumerEdit.View';
import { SignedIn } from '../../components/SignedIn';
import { SignOut } from '../../components/SignOut';
import ProfilePreview from '../../views/ProfilePreview/ProfilePreview';
import ProvidersView from '../../../Modules/Providers/views/Providers.View';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CompaniesList from '../../views/CompaniesList/CompaniesList';
import CompanyAddView from '../../views/CompanyAdd/CompanyAddView';
import routes from '../constants/Routes';
import CompanyDetailsView from '../../views/CompanyDetails/CompanyDetailsView';
import WorkshopsListView from '../../views/WorkshopsListView/WorkshopsListView';
import WorkshopCreateView from '../../views/WorkshopCreateView/WorkshopCreateView';
import PendingEmployees from '../../../Modules/Employees/views/PendingEmployees.View';
import ActiveEmployees from '../../../Modules/Employees/views/ActiveEmployees.View';
import WaitingForCoachEmployees from '../../../Modules/Employees/views/WaitingForCoachEmployees.View';
import WorkshopDetailsGeneral from '../../views/WorkshopDetailsGeneral/WorkshopDetailsGeneral';
import { WorkshopAddMembersView } from '../../views/WorkshopAddMembersView/WorkshopAddMembersView';
import { WorkshopEditMembersView } from '../../views/WorkshopEditMembersView/WorkshopEditMembersView';
import WorkshopDetailsSessionsList from '../../views/WorkshopDetailsSessionsList/WorkshopDetailsSessionsList';
import ChatFullScreen from '../../../Modules/Chat/views/ChatFullScreen';
import WorkshopDetailsMembersView from '../../views/WorkshopDetailsMembersView/WorkshopDetailsMembersView';
import InvitationsList from '../../../Modules/Invitations/views/InviteEmployees/InviteEmployees';
import { WorkshopEditView } from '../../views/WorkshopEditView/WorkshopEditView';
import WorkshopsBooking from '../../../Modules/Booking/views/WorkshopsBooking/WorkshopsBooking';
import VideoCallView from '../../views/VideoCallView/VideoCallView';
import Partners from '../../views/Partners/PartnersView';
import PartnersCreate from '../../views/PartnersCreateView/PartnersCreateView';
import PartnerDetailsGeneralView from '../../views/PartnerDetailsGeneralView/PartnerDetailsGeneralView';
import PartnerProvidersView from '../../views/PartnerProvidersView/PartnerProvidersView';
import { PartnerEditProviderView } from '../../views/PartnerEditProviderView/PartnerEditProviderView';
import PartnerEditView from '../../views/PartnerEditView/PartnerEditView';

const Routing = () => {
  const {
    companyDetailsAccessible,
    groupsListFlag,
    createGroupFlag,
    groupAddMembers,
    groupDetailsFlag,
    groupDetailsSessionsList,
    chatFlag,
    groupEditing,
  } = useFlags();

  return (
    <>
      <Switch>
        <Route exact path={routes.ProfilePreview} component={ProfilePreview} />
        <Route path={routes.ProvidersList} component={ProvidersView} />
        <Route path={routes.ConsumerEdit} component={ConsumerEditView} />
        <Route path={routes.SignedIn} component={SignedIn} />
        <Route path={routes.SignedOut} component={SignOut} />
        <Route path={routes.ProviderCalendar} component={ProviderCalendar} />
        <Route exact path={'/'}>
          <Redirect to={routes.Partners} />
        </Route>
        <Route exact path={routes.CompaniesList} component={CompaniesList} />
        <Route exact path={routes.InvitationsList} component={InvitationsList} />
        <Route exact path={routes.CompanyAdd} component={CompanyAddView} />
        <Route exact path={routes.WorkshopsBooking} component={WorkshopsBooking} />

        {companyDetailsAccessible && (
          <Route path={routes.CompanyDetails} component={CompanyDetailsView} />
        )}
        {createGroupFlag && (
          <Route exact path={routes.WorkshopCreate} component={WorkshopCreateView} />
        )}
        {groupAddMembers && (
          <Route exact path={routes.WorkshopAddMembers} component={WorkshopAddMembersView} />
        )}
        {groupEditing && (
          <Route exact path={routes.WorkshopEditMembers} component={WorkshopEditMembersView} />
        )}
        {groupEditing && <Route exact path={routes.WorkshopEdit} component={WorkshopEditView} />}
        {groupsListFlag && (
          <Route exact path={routes.WorkshopsList} component={WorkshopsListView} />
        )}
        {groupDetailsFlag && (
          <Route path={routes.WorkshopDetails} component={WorkshopDetailsGeneral} />
        )}
        {groupDetailsFlag && (
          <Route path={routes.WorkshopDetailsMembers} component={WorkshopDetailsMembersView} />
        )}
        {groupDetailsSessionsList && (
          <Route
            path={routes.WorkshopDetailsSessionsList}
            component={WorkshopDetailsSessionsList}
          />
        )}
        <Route exact path={routes.Employees} component={ActiveEmployees} />
        <Route exact path={routes.Partners} component={Partners} />
        <Route exact path={routes.PartnersCreate} component={PartnersCreate} />
        <Route path={routes.PartnerDetails} component={PartnerDetailsGeneralView} />
        <Route path={routes.PartnerDetailsProviders} component={PartnerProvidersView} />
        <Route exact path={routes.PartnerEditProviders} component={PartnerEditProviderView} />
        <Route exact path={routes.PartnerEdit} component={PartnerEditView} />
        <Route exact path={routes.PendingEmployees} component={PendingEmployees} />
        <Route exact path={routes.WaitingForCoachEmployees} component={WaitingForCoachEmployees} />
        {chatFlag && <Route exact path={routes.Chat} component={ChatFullScreen} />}
        <Route path={routes.VideoCall} component={VideoCallView} />
      </Switch>
    </>
  );
};

export default Routing;
