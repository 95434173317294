import './TabCategory.scss';
import { ReactNode } from 'react';

export interface TabCategoryProps {
  title: string;
  children: ReactNode;
}

export const TabCategory = ({ title, children }: TabCategoryProps): JSX.Element => {
  return (
    <section className='conversations-tabcategory'>
      <h2 className='conversations-tabcategory__title'>{title}</h2>
      {children}
    </section>
  );
};
