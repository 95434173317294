import { UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import VideoTrack from '../../VideoTrack/VideoTrack.Component';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { localVideoTrack$ } from '../../../store/video';
import useVideoTrackDimensions from '../../../hooks/useVideoTrackDimensions/useVideoTrackDimensions';
import { useCallback, useRef } from 'react';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { localAudioTrack$ } from '../../../store/audio';
import { Button, VideoCameraOffOutlined } from '@inovirtue/components';
import './LocalVideoPreview.scss';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator.Component';
import classNames from 'classnames';
import useHeight from '../../../hooks/useHeight/useHeight';
import { ProfileAvatar } from '../../ProfileAvatar/ProfileAvatar.Component';
import { Breakpoints } from '../../../styles/breakpoints/breakpoints';
import { useMediaQuery } from 'react-responsive';

const LocalVideoPreview = (): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mdBreak });

  const localVideoTrack = useBehaviorSubjectState(localVideoTrack$);
  const localAudioTrack = useBehaviorSubjectState(localAudioTrack$);

  const [isVideoEnabled, toggleIsVideoEnabled, isToggleVideoDisabled] = useLocalVideoToggle();
  const [, toggleAudioEnabled, isToggleAudioDisabled] = useLocalAudioToggle();

  const lastClickTimeRef = useRef(0);

  const dimensions = useVideoTrackDimensions(localVideoTrack) || { width: 16, height: 9 };
  const height = useHeight();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleIsVideoEnabled();
    }
  }, [toggleIsVideoEnabled]);

  const localVideoPreviewCls = classNames('local-video-preview', {
    'local-video-preview--mobile': isMobile,
  });

  return (
    <div className={localVideoPreviewCls}>
      <div
        className='local-video-preview__placeholder'
        style={
          !isMobile
            ? { aspectRatio: `${dimensions?.width} / ${dimensions?.height}` }
            : { height: height }
        }
      >
        {localVideoTrack ? (
          <VideoTrack isLocal track={localVideoTrack} />
        ) : (
          <ProfileAvatar
            className='local-video-preview__placeholder-avatar'
            size={110}
            icon={<UserOutlined />}
          />
        )}
      </div>
      <div className='local-video-preview__buttons'>
        <Button
          className='local-video-preview__button'
          size='large'
          icon={isVideoEnabled ? <VideoCameraOutlined /> : <VideoCameraOffOutlined />}
          shape='circle'
          disabled={isToggleVideoDisabled}
          onClick={toggleVideo}
        />
        <Button
          className='local-video-preview__button'
          size='large'
          icon={<AudioLevelIndicator audioTrack={localAudioTrack} />}
          shape='circle'
          disabled={isToggleAudioDisabled}
          onClick={toggleAudioEnabled}
        />
      </div>
    </div>
  );
};

export default LocalVideoPreview;
