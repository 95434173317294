import './Tabs.scss';
import { Children, cloneElement, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { TabPane, TabPaneProps } from '../TabPane/TabPane';

export interface TabsProps {
  children: ReactNode;
  activeId: string;
}

export const Tabs = ({ children, activeId }: TabsProps): JSX.Element => {
  return (
    <div className='conversations-tabs'>
      {Children.map(children, (child) => {
        const item = child as ReactElement<PropsWithChildren<TabPaneProps>>;

        if (item.type !== TabPane) return null;

        const isActive = activeId === item.props.id;

        return cloneElement(item, { isActive });
      })}
    </div>
  );
};
