import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { Partner, Provider } from '@inovirtue/admin';
import { PartnerDto } from '../models/PartnerDto';
import { TenantDto } from '../models/TenantDto';
import { PartnerForm } from '../models/PartnerForm';
import { uploadImageToBlob, uploadBackgroundToBlob } from '../../Common/api/blobApiClient';
import { getPatchBody } from '../../Common/api/patchBodyBuilder';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getPartners = async (): Promise<Partner[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Partner[]>('/Partners');
  return data;
};

const getPartnerImagesPatch = async (formData: PartnerForm, name: string) => {
  let patchBody: Record<string, unknown> = {};
  if (formData.logo && formData.logo.file) {
    const logoId = await uploadImageToBlob(name, formData.logo.file);
    patchBody = { ...patchBody, logoId };
  }

  if (formData.smallLogo && formData.smallLogo.file) {
    const smallLogoId = await uploadImageToBlob(name, formData.smallLogo.file);
    patchBody = { ...patchBody, smallLogoId };
  }

  if (formData.background && formData.background.file) {
    const backgroundId = await uploadBackgroundToBlob(name, formData.background.file);
    patchBody = { ...patchBody, backgroundId };
  }

  if (formData.numbersAndFacts && formData.numbersAndFacts.file) {
    const numbersAndFactsId = await uploadImageToBlob(name, formData.numbersAndFacts.file);
    patchBody = { ...patchBody, numbersAndFactsId };
  }
  return patchBody;
};
export const addPartner = async (formData: PartnerForm): Promise<string> => {
  const { shortName, displayName, name, description, claim, tenant, tags } = formData;
  const axiosInstance = await getApiClientAsync();

  const body: Record<string, unknown> = {
    displayName,
    shortName,
    name,
    description,
    claim,
    tenant,
    tags,
  };

  const { data } = await axiosInstance.post<{ id: string }>(`/Partners`, body);

  const patchBody = await getPartnerImagesPatch(formData, name);

  if (Object.keys(patchBody).length) {
    const patchItems = getPatchBody(patchBody);
    await axiosInstance.patch(`/profiles/partners/${name}`, patchItems);
  }

  return data.id;
};

export const updatePartner = async (name: string, formData: PartnerForm): Promise<void> => {
  const axiosInstance = await getApiClientAsync();

  let updateBody = await getPartnerImagesPatch(formData, name);

  updateBody = {
    ...updateBody,
    name: formData.name,
    tags: formData.tags,
    description: formData.description,
    claim: formData.claim,
    displayName: formData.displayName,
    shortName: formData.shortName,
  };
  const patchItems = getPatchBody(updateBody);
  await axiosInstance.patch(`/profiles/partners/${name}`, patchItems);
};
export const togglePartnerActiveState = async (name: string, isActive: boolean): Promise<void> => {
  const axiosInstance = await getApiClientAsync();

  const updateBody = {
    isActive: !isActive,
  };
  const patchItems = getPatchBody(updateBody);
  await axiosInstance.patch(`/profiles/partners/${name}`, patchItems);
};

export const getPartnerById = async (partnerId: string): Promise<PartnerDto> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<PartnerDto>(`/partners/${partnerId}/details`);
  return data;
};

export const getAllTenants = async (): Promise<string[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<TenantDto[]>('/Tenants');
  return data.map((t) => t.name);
};

export const getPartnerProviders = async (partnerId: string): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Provider[]>(`/partners/${partnerId}/providers`);
  return data;
};

export const updatePartnerProviders = async (
  partnerId: string,
  providers: string[],
): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.put(`/partners/${partnerId}/providers`, providers);
};
