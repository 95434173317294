import React, { ReactNode } from 'react';
import { message, MessageArgsProps } from 'antd';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';

export type ToastType = MessageArgsProps['type'];

const TOAST_ICONS: { [Property in Exclude<ToastType, undefined>]: JSX.Element } = {
  info: <></>,
  success: <CheckCircleFilled />,
  warning: <ExclamationCircleFilled />,
  error: <CloseCircleFilled />,
  loading: <LoadingOutlined spin />,
};

type ShowToastProps = Partial<Omit<MessageArgsProps, 'content'>>;

export const showToast = (content: ReactNode, args?: ShowToastProps): void => {
  const type = args?.type || 'info';
  message[type]({
    content,
    type,
    icon: TOAST_ICONS[type],
    ...args,
  });
};
