import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { PaginatedResponse } from '@inovirtue/hooks';
import Invitation from '../models/Invitation';
import { DEFAULT_PAGE_SIZE } from '@inovirtue/table';
import EmployeeInfo from '../models/EmployeeInfo';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getInvitations = async (
  pageIndex: number,
  filters: Record<string, string> = {},
  pageSize = DEFAULT_PAGE_SIZE,
  searchString?: string,
): Promise<PaginatedResponse<Invitation>> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<PaginatedResponse<Invitation>>(
    `/companies/invitations`,
    {
      params: {
        pageIndex,
        pageSize,
        searchString,
        ...filters,
      },
    },
  );
  return data;
};

export const getEmployees = async (
  pageIndex: number,
  filters: Record<string, string> = {},
  pageSize = DEFAULT_PAGE_SIZE,
  searchString?: string,
): Promise<PaginatedResponse<EmployeeInfo>> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<PaginatedResponse<EmployeeInfo>>(
    `/companies/employees`,
    {
      params: {
        pageIndex,
        pageSize,
        searchString,
        ...filters,
      },
    },
  );
  return data;
};

export const getWaitingForCoachEmployees = async (
  pageIndex: number,
  filters: Record<string, string> = {},
  pageSize = DEFAULT_PAGE_SIZE,
  searchString?: string,
): Promise<PaginatedResponse<EmployeeInfo>> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<PaginatedResponse<EmployeeInfo>>(
    `/companies/employees/waitingForCoach`,
    {
      params: {
        pageIndex,
        pageSize,
        searchString,
        ...filters,
      },
    },
  );
  return data;
};
