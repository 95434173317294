import { useEffect, useState } from 'react';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import {
  LocalVideoTrack,
  Participant,
  RemoteParticipant,
  RemoteVideoTrack,
  TrackPublication,
} from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import { room$ } from '../../store/video';
import {
  addReconnectingParticipant,
  removeReconnectingParticipant,
  addCameraEnabledParticipant,
  removeCameraEnabledParticipant,
} from '../../store/participant';

export default function useParticipants(): RemoteParticipant[] {
  const room = useBehaviorSubjectState(room$);
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants((prevParticipants) => [
        dominantSpeaker,
        ...prevParticipants.filter((participant) => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    if (room) {
      const handleCameraEnabled = (participant: Participant, publication: TrackPublication) => {
        const track = publication as unknown as LocalVideoTrack | RemoteVideoTrack;
        if (
          participant &&
          track &&
          !track.isSwitchedOff &&
          publication?.kind == 'video' &&
          publication?.trackName?.startsWith('camera') &&
          publication?.isEnabled !== false
        ) {
          addCameraEnabledParticipant(participant?.identity);
        }
      };

      room?.participants?.forEach((p) => p?.videoTracks?.forEach((t) => handleCameraEnabled(p, t)));
      room?.localParticipant?.videoTracks?.forEach((t) =>
        handleCameraEnabled(room?.localParticipant, t),
      );

      const participantConnected = (participant: RemoteParticipant) => {
        participant?.videoTracks?.forEach((t) => handleCameraEnabled(participant, t));
        setParticipants((prevParticipants) => [...prevParticipants, participant]);
      };
      const participantDisconnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
        removeCameraEnabledParticipant(participant.identity);
        removeReconnectingParticipant(participant.identity);
      };
      const participantReconnecting = (participant: RemoteParticipant) =>
        addReconnectingParticipant(participant.identity);
      const participantReconnected = (participant: RemoteParticipant) =>
        removeReconnectingParticipant(participant.identity);
      const trackPublished = (publication: TrackPublication, participant: RemoteParticipant) =>
        handleCameraEnabled(participant, publication);
      const trackUnpublished = (publication: TrackPublication, participant: RemoteParticipant) => {
        const track = publication as unknown as LocalVideoTrack | RemoteVideoTrack;
        if (track && publication?.kind == 'video' && publication?.trackName?.startsWith('camera'))
          removeCameraEnabledParticipant(participant.identity);
      };

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.on('participantReconnecting', participantReconnecting);
      room.on('participantReconnected', participantReconnected);
      room.on('trackPublished', trackPublished);
      room.on('trackUnpublished', trackUnpublished);

      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
        room.off('participantReconnecting', participantReconnecting);
        room.off('participantReconnected', participantReconnected);
        room.off('trackPublished', trackPublished);
        room.off('trackUnpublished', trackUnpublished);
      };
    }
  }, [room]);

  return participants;
}
