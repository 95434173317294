import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

export interface AvailableCoachesCarouselProps<T> {
  className?: string;
  slideClassName?: string;
  elements: T[];
  renderSlideComponents: (props: T) => JSX.Element;
}

export const SwiperCarousel = <T extends { key: string }>({
  className,
  elements,
  slideClassName,
  renderSlideComponents,
}: AvailableCoachesCarouselProps<T>): JSX.Element => {
  return (
    <Swiper
      slidesPerView={'auto'}
      className={`swiper-carousel ${className}`}
      data-testid='swiper-carousel'
    >
      {elements.map((el) => {
        return (
          <SwiperSlide key={el.key} className={slideClassName}>
            {renderSlideComponents(el)}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
