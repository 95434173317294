import { SmileOutlined } from '@ant-design/icons';
import { SendFilled } from '@inovirtue/assets';
import { t } from '@inovirtue/localization';
import { Button, Form, Input } from 'antd';
import { useConversationInput } from '../../hooks/useConversationInput/useConversationInput';
import { UploadButton } from '../UploadButton/UploadButton';
import './ConversationInput.scss';

export const ConversationInput = (): JSX.Element => {
  const {
    inputRef,
    form,
    inputValue,
    onInputFocus,
    onInputBlur,
    onMessageChange,
    onFormSubmit,
    onSendBtnClick,
    onEnterClick,
    onUploadFinish,
  } = useConversationInput();

  return (
    <div className='conversation-input'>
      <Button
        data-testid='conversation-btn-emoji'
        className='conversation-input__btn conversation-input__emoji'
        size='small'
        icon={<SmileOutlined className='conversation-input__icon' />}
      />
      <Form
        className='conversation-input__form'
        form={form}
        autoComplete='off'
        onFinish={onFormSubmit}
        onValuesChange={onMessageChange}
        requiredMark={false}
        tabIndex={0}
        onKeyDown={onEnterClick}
      >
        <Form.Item
          name='message'
          initialValue={''}
          rules={[{ required: true }, { whitespace: true }]}
          noStyle={true}
        >
          <Input.TextArea
            ref={inputRef}
            autoFocus
            data-testid='conversation-message-input'
            className='conversation-input__input'
            size='large'
            autoSize={{ minRows: 1, maxRows: 4 }}
            maxLength={1600}
            placeholder={t('chat:type-placeholder')}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
        </Form.Item>
      </Form>
      {inputValue?.length > 0 ? (
        <Button
          data-testid='conversation-btn-send'
          className='conversation-input__btn'
          size='small'
          icon={<SendFilled className='conversation-input__icon' />}
          onMouseDown={onSendBtnClick}
        />
      ) : (
        <UploadButton className='conversation-input__btn' onUploadFinish={onUploadFinish} />
      )}
    </div>
  );
};
