import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export async function fetchCategoryTags(): Promise<Map<string, string[]>> {
  const axiosInstance = await getApiClientAsync();

  const { data } = await axiosInstance.get<
    [
      {
        key: string;
        displayOrder: number;
        interests: { key: string }[];
      },
    ]
  >(`/interests/categories`);

  const result = new Map<string, string[]>();

  data
    .sort((x, y) => x.displayOrder - y.displayOrder)
    .forEach((k) => {
      result.set(
        k.key,
        k.interests.map((m) => m.key),
      );
    });

  return result;
}
