import {
  addMilliseconds,
  differenceInMinutes,
  differenceInHours,
  format,
  formatDistanceStrict,
  parseISO,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { getSessionById } from '../../api/sessionsApi';
import { useServerTime } from './../useServerTime/useServerTime';
import { Workshop, Session, SessionStatus } from '@inovirtue/admin';

export interface AppointmentInfo {
  startDay: string;
  startTime: string;
  endTime: string;
  timeLeft: string;
  canJoin: boolean;
  canCancelWithoutConsequences: boolean;
  isOngoing: boolean;
  serverTime: string;
  startDate?: Date;
  endDate?: Date;
  workshop?: Workshop;
}

const checkInterval = 1000 * 5;
const joinAvailableBeforeInMinutes = 5;
const freeCancelHoursLimit = 48;

const initialState: AppointmentInfo = {
  startDay: '',
  startTime: '',
  endTime: '',
  timeLeft: '',
  canJoin: false,
  canCancelWithoutConsequences: false,
  isOngoing: false,
  serverTime: '',
};

export const useCanJoinSessionVideoCall = (sessionId: string): AppointmentInfo => {
  const [infoState, setInfoState] = useState<AppointmentInfo>(initialState);
  const [session, setSession] = useState<Session>();
  const serverTime = useServerTime();

  useEffect(() => {
    const fetchSession = async () => {
      setSession(await getSessionById(sessionId));
    };
    if (!session) fetchSession();

    let ticks = 0;
    const refreshTimeLeft = () => {
      if (session && serverTime && session.status !== SessionStatus.Cancelled) {
        const startDate = parseISO(session.plannedStartDateTime.toString());
        const endDate = parseISO(session.plannedEndDateTime.toString());

        const serverDate = addMilliseconds(parseISO(serverTime), ticks * checkInterval);
        const timeLeft = formatDistanceStrict(startDate, serverDate);

        const isFinished = serverDate >= endDate;
        const isOngoing = startDate <= serverDate && !isFinished;

        const canJoin =
          isOngoing ||
          (!isFinished &&
            differenceInMinutes(startDate, serverDate) <= joinAvailableBeforeInMinutes);

        const canCancelWithoutConsequences =
          differenceInHours(startDate, serverDate) >= freeCancelHoursLimit;

        setInfoState((oldState) => ({
          ...oldState,
          startDay: format(startDate, 'EEE, d MMM yyyy'),
          startTime: format(startDate, 'HH:mm'),
          endTime: format(endDate, 'HH:mm'),
          timeLeft,
          canJoin,
          canCancelWithoutConsequences,
          isOngoing,
          serverTime: serverTime,
          startDate: startDate,
          endDate: endDate,
          workshop: session.workshop,
        }));
      } else {
        setInfoState(initialState);
      }
      ticks += 1;
    };

    const intervalTimer = setInterval(() => {
      refreshTimeLeft();
    }, checkInterval);

    refreshTimeLeft();
    return () => clearInterval(intervalTimer);
  }, [session, serverTime, sessionId]);

  return { ...infoState };
};
