import { t } from '@inovirtue/localization';
import { Form, InputRef } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { KeyboardEvent, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { conversationActions } from '../../service/conversationService';
import { setFocusedConversation } from '../../store/commonSlice';
import { selectDraftMessages, upsertDraftMessage } from '../../store/draftMessagesSlice';
import { updateLastReadMessageIndex } from '../../store/horizonSlice';
import { useConvoDispatch, useConvoSelector } from '../storeHooks/storeHooks';
import { useErrorContext } from '../useErrorContext/useErrorContext';
import { useLayoutContext } from '../useLayoutContext/useLayoutContext';
import linkifyHtml from 'linkify-html';

const { useForm, useWatch } = Form;

export interface MessageForm {
  message: string;
}

export const useConversationInput = () => {
  const inputRef = useRef<InputRef>(null);
  const [form] = useForm<MessageForm>();
  const inputValue = useWatch('message', form);

  const dispatch = useConvoDispatch();
  const draftMessages = useConvoSelector(selectDraftMessages);

  const { selectedConvoSid } = useLayoutContext();
  const { setError } = useErrorContext();

  const onFormSubmit = useCallback(
    async ({ message }: MessageForm) => {
      if (!selectedConvoSid) return;

      const formattedMessage = linkifyHtml(message, {
        target: { url: '_blank' },
        rel: 'noopener noreferrer',
      });

      try {
        await conversationActions.sendMessage(selectedConvoSid, formattedMessage);
        dispatch(upsertDraftMessage({ convoSid: selectedConvoSid, message: '' }));
        form.resetFields();
      } catch {
        setError(t('errors:unhandled-error'));
      }
    },
    [form, selectedConvoSid, setError, dispatch],
  );

  const onUploadFinish = useCallback(
    async (file: RcFile) => {
      if (!selectedConvoSid) return Promise.reject();

      dispatch(setFocusedConversation(selectedConvoSid));

      try {
        const index = await conversationActions.sendMessage(selectedConvoSid, file);
        dispatch(updateLastReadMessageIndex({ convoSid: selectedConvoSid, index }));
      } catch {
        setError(t('errors:unhandled-error'));
      }

      return '';
    },
    [selectedConvoSid, setError, dispatch],
  );

  const onSendBtnClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      form.submit();
    },
    [form],
  );

  const onEnterClick = useCallback(
    (e: KeyboardEvent) => {
      if (!e.shiftKey && e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        form.submit();
      }
    },
    [form],
  );

  const onMessageChange = useCallback(
    ({ message }: MessageForm) => {
      if (!selectedConvoSid) return;

      dispatch(upsertDraftMessage({ convoSid: selectedConvoSid, message }));
    },
    [selectedConvoSid, dispatch],
  );

  const onInputFocus = useCallback(async () => {
    if (!selectedConvoSid) return;

    dispatch(setFocusedConversation(selectedConvoSid));
    await conversationActions.setAllMessagesRead(selectedConvoSid);
  }, [dispatch, selectedConvoSid]);

  const onInputBlur = useCallback(() => {
    if (!selectedConvoSid) return;

    dispatch(setFocusedConversation(null));
    dispatch(updateLastReadMessageIndex({ convoSid: selectedConvoSid, index: null }));
  }, [dispatch, selectedConvoSid]);

  useEffect(() => {
    if (!selectedConvoSid) return;

    const messageDraft = draftMessages[selectedConvoSid];

    form.setFieldsValue({
      message: messageDraft || '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConvoSid]);

  useLayoutEffect(() => {
    inputRef.current?.focus({ cursor: 'end' });
  }, [selectedConvoSid]);

  return {
    inputRef,
    form,
    inputValue,
    onInputFocus,
    onInputBlur,
    onMessageChange,
    onSendBtnClick,
    onEnterClick,
    onFormSubmit,
    onUploadFinish,
  };
};
