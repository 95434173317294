import { useEffect } from 'react';
import { Participant } from 'twilio-video';
import { room$ } from '../../store/video';
import { selectedParticipant$, setSelectedParticipant } from '../../store/participant';
import { useBehaviorSubjectState } from '@inovirtue/hooks';

export default function useSelectedParticipant(): Participant | null {
  const room = useBehaviorSubjectState(room$);
  const selectedParticipant = useBehaviorSubjectState(selectedParticipant$);

  useEffect(() => {
    if (room) {
      const onDisconnect = () => setSelectedParticipant(null);
      const handleParticipantDisconnected = (participant: Participant) => {
        setSelectedParticipant(selectedParticipant === participant ? null : selectedParticipant);
      };

      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room, selectedParticipant]);

  return selectedParticipant;
}
