import { Modal } from '@inovirtue/components';
import { useHistory } from 'react-router';
import routes from '../../../App/routes/constants/Routes';
import PartnerAdd from '../../../Modules/Partners/components/PartnerAdd/PartnerAdd';

const PartnersCreateView = (): JSX.Element => {
  const { push } = useHistory();
  return (
    <Modal isFullScreen={true} visible={true} onClose={() => push(routes.Partners)}>
      <PartnerAdd />
    </Modal>
  );
};

export default PartnersCreateView;
