import { useHistory } from 'react-router';
import EmployeeInfo from '../../models/EmployeeInfo';
import { Button } from 'antd';
import { t } from '@inovirtue/localization';

const AssignCoachButton = ({ employee }: { employee: EmployeeInfo }): JSX.Element => {
  const history = useHistory();

  return (
    <Button
      data-testid='assign-coach-button'
      shape='round'
      onClick={() => history.push(`/clients/${employee.name}`)}
    >
      {t('company:employees:waiting-for-coach-employees-table.assign-button')}
    </Button>
  );
};

export default AssignCoachButton;
