import { Conversation, Participant } from '@twilio/conversations';

const getAllParticipants = async (conversations: Conversation[]) => {
  const requests = conversations.map(
    async (conversation: Conversation): Promise<[string, Participant[]]> => {
      const participants = await conversation.getParticipants();
      return [conversation.sid, participants];
    },
  );
  const entries = await Promise.all(requests);

  return Object.fromEntries(entries);
};

type ParticipantList = {
  [k: string]: Participant[];
};

export function getConversationParticipants(conversation: Conversation): Promise<Participant[]>;
export function getConversationParticipants(conversation: Conversation[]): Promise<ParticipantList>;
export function getConversationParticipants(
  conversation: Conversation | Conversation[],
): Promise<Participant[]> | Promise<ParticipantList> {
  if (!Array.isArray(conversation)) return conversation.getParticipants();

  return getAllParticipants(conversation);
}
