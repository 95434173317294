import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getWorkshops } from '../api/workshopApi';
import { Workshop } from '@inovirtue/admin';
import { WorkshopListFilterFields } from '../WorkshopListFilter/WorkshopListFilter';
import { useHistory } from 'react-router';

export const useWorkshopList = (companyId?: string) => {
  const fetchCompanies = useCallback(() => getWorkshops(companyId), [companyId]);
  const { data, isProcessing } = usePromiseWithProgress<Workshop[]>(fetchCompanies);

  const { replace } = useHistory();
  const updateUrl = (fields: WorkshopListFilterFields) => {
    const { companyId: companyIdField } = fields;
    if (companyIdField) {
      const params = new URLSearchParams({ companyId: companyIdField });
      replace({ pathname: location.pathname, search: params.toString() });
    } else {
      replace({ pathname: location.pathname });
    }
  };

  return {
    workshops: data,
    isProcessing,
    updateUrl,
  };
};
