import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { Layout } from 'antd';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useChat } from '../../../hooks/useChat/useChat';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import { isChat$ } from '../../../store';
import { isChatOpen$, room$ } from '../../../store/video';
import { workshopId$ } from '../../../store/video-call-session';
import { Breakpoints } from '../../../styles/breakpoints/breakpoints';
import { Chat } from '../../Chat/Chat';
import MenuBar from '../../MenuBar/MenuBar.Component';
import ReconnectingNotification from '../../ReconnectingNotification/ReconnectingNotification.Component';
import RecordingNotifications from '../../RecordingNotifications/RecordingNotifications.Component';
import Room from '../../Room/Room.Component';
import SessionEndingNotification from '../../SessionEndingNotification/SessionEndingNotification';
import './CallRoomStep.Component.scss';
const { Content, Sider } = Layout;

const CallRoomStep = (): JSX.Element => {
  const room = useBehaviorSubjectState(room$);
  const isChat = useBehaviorSubjectState(isChat$);
  const workshopId = useBehaviorSubjectState(workshopId$);
  const isChatOpen = useBehaviorSubjectState(isChatOpen$);
  const convoSid = useChat(workshopId);
  const roomState = useRoomState();
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mdBreak });

  useEffect(() => {
    return () => {
      room?.disconnect();
    };
  }, [room]);

  return (
    <>
      {roomState !== 'disconnected' && (
        <div className='callroomstep'>
          <Layout>
            <ReconnectingNotification />
            <RecordingNotifications />
            <SessionEndingNotification />
            <Content style={{ overflow: 'hidden', width: !isChatOpen || !isMobile ? 'auto' : '0' }}>
              <Room />
              <MenuBar convoSid={convoSid} />
            </Content>
            {isChat && convoSid && (
              <Sider width={isMobile ? '100%' : 375} collapsedWidth={0} collapsed={!isChatOpen}>
                <Chat convoSid={convoSid} />
              </Sider>
            )}
          </Layout>
        </div>
      )}
    </>
  );
};

export default CallRoomStep;
