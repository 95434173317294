import { Button, InputWCharactersLimit, Modal, ModalProps } from '@inovirtue/components';
import { Form, Row, Col } from 'antd';
import { useCallback } from 'react';
import useAddDepartments, {
  UseAddDepartmentsParams,
} from '../../hooks/useAddDepartments/useAddDepartments';
import './AddDepartmentsModal.scss';

export interface AddDepartmentsModalProps
  extends Pick<ModalProps, 'onClose' | 'visible'>,
    UseAddDepartmentsParams {
  cancelLabel: string;
  saveLabel: string;
  addLabel: string;
  modalTitle: string;
  inputLabel: string;
  errorMessage: string;
  departmentModalDescription?: string;
}
export const AddDepartmentsModal = ({
  onClose,
  visible,
  departments,
  getDepartments,
  submitNewDepartments,
  cancelLabel,
  saveLabel,
  addLabel,
  modalTitle,
  inputLabel,
  errorMessage,
  departmentModalDescription,
}: AddDepartmentsModalProps): JSX.Element => {
  const {
    addDepartment,
    newDepartments,
    setActualDepartment,
    actualDepartment,
    submitDepartments,
    submitDisabled,
    validate,
    clearAllAction,
  } = useAddDepartments({ departments, getDepartments, submitNewDepartments });
  const closeModalAction = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      clearAllAction();
      onClose(e);
    },
    [clearAllAction, onClose],
  );
  const submitDepartmentsClick = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      await submitDepartments();
      closeModalAction(e);
    },
    [closeModalAction, submitDepartments],
  );
  return (
    <Modal
      data-testid='add-departments-modal'
      className='add-departments-modal'
      visible={visible}
      onClose={closeModalAction}
      actions={[
        <Button key='cancel' onClick={closeModalAction}>
          {cancelLabel}
        </Button>,
        <Button
          key='save'
          type='primary'
          onClick={submitDepartmentsClick}
          disabled={submitDisabled}
        >
          {saveLabel}
        </Button>,
      ]}
      title={modalTitle}
    >
      <Row gutter={16} align='middle'>
        {departmentModalDescription && (
          <Col span={24}>
            <p className='add-departments-modal__description'>{departmentModalDescription}</p>
          </Col>
        )}
        <Col span={20}>
          <Form.Item
            label={inputLabel}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            validateStatus={!validate ? 'error' : ''}
            help={!validate ? errorMessage : ''}
            required
          >
            <InputWCharactersLimit
              status={!validate ? 'error' : ''}
              maxLength={50}
              name='newDepartment'
              data-testid='newDepartment'
              value={actualDepartment}
              onChange={(e) => setActualDepartment(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button
            data-testid='addDepartment'
            onClick={addDepartment}
            className='add-departments-modal__departments-add'
            disabled={actualDepartment.length === 0}
          >
            {addLabel}
          </Button>
        </Col>
      </Row>
      {newDepartments.length > 0 && (
        <ul className='add-departments-modal__departments-list'>
          {newDepartments.map((el) => (
            <li key={el} className='add-departments-modal__departments-list__element'>
              {el}
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};
