import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { Workshop } from '@inovirtue/admin';
import { message } from 'antd';
import { updateWorkshop } from '../api/workshopApi';
import { t } from '@inovirtue/localization';

export const useEditWorkshop = () => {
  const onSubmit = async (workshop: Workshop) => {
    try {
      enableFullPageLoader();
      const updatedGroup = await updateWorkshop(workshop.id, {
        name: workshop.name,
        description: workshop.description,
        companyContactId: workshop.companyContactId,
        consultantId: workshop.consultantId,
      });
      message.success(t('save-success'));
      return updatedGroup;
    } catch (err) {
      message.error(t('save-error'));
      return null;
    } finally {
      disableFullPageLoader();
    }
  };
  return {
    onSubmit,
  };
};
