import { useMemo } from 'react';
import { Table, BaseTableProps } from '../components/Table/Table';
import { usePaginatedTableWithSearch } from '../hooks/usePaginatedTableWithSearch';
import { useOnDataLoadedAction } from '../hooks/useOnDataLoadedAction';
import { PaginatedResponse } from '@inovirtue/hooks';
import { DEFAULT_PAGE_SIZE } from '../consts/pagination';

export interface SearchablePaginatedTableProps<T> extends BaseTableProps<T> {
  getEntries: (pageIndex: number, searchText?: string) => Promise<PaginatedResponse<T>>;
  searchInputPlaceHolder: string;
  onDataLoaded?(data: PaginatedResponse<T>): void | Promise<void>;
  showLoader?: boolean;
}

export const SearchablePaginatedTable = <T extends object>({
  tableName,
  columns,
  rowKey,
  getEntries,
  searchInputPlaceHolder,
  onDataLoaded,
  beforeTable,
  onFilterChange,
  showLoader,
}: SearchablePaginatedTableProps<T>): JSX.Element => {
  const { onSearch, isProcessing, entries, pageIndex, setPageIndex, total, bareResponse } =
    usePaginatedTableWithSearch(getEntries);

  useOnDataLoadedAction(onDataLoaded, bareResponse);

  const searchSettings = useMemo(
    () => ({ onSearch: onSearch, searchInputPlaceholder: searchInputPlaceHolder }),
    [onSearch, searchInputPlaceHolder],
  );

  return (
    <Table
      tableName={tableName}
      columns={columns}
      rowKey={rowKey}
      isProcessing={isProcessing || !!showLoader}
      entries={entries}
      pagination={{
        pageIndex,
        setPageIndex,
        total,
        pageSize: DEFAULT_PAGE_SIZE,
      }}
      searchSettings={searchSettings}
      beforeTable={beforeTable}
      onFilterChange={onFilterChange}
    />
  );
};
