import './ChatMenuLabel.scss';
import { useConversationsContext } from '@inovirtue/chat';
import { t } from '@inovirtue/localization';
import { Badge } from 'antd';

const ChatMenuLabel = (): JSX.Element => {
  const { totalUnreadMessages } = useConversationsContext();

  return (
    <div className='chat-menu-label'>
      <span>{t('chat:messages')}</span>
      <Badge count={totalUnreadMessages} />
    </div>
  );
};

export default ChatMenuLabel;
