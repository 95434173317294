import { Consumer } from '@inovirtue/admin';
import { useQuery } from 'react-query';
import { getWorkshopMembersByIdAsync } from '../api/workshopApi';

export const useWorkshopMembers = (workshopId: string) => {
  return useQuery<Consumer[] | undefined>({
    queryKey: ['workshop-members', workshopId],
    queryFn: async () => {
      if (workshopId) {
        return getWorkshopMembersByIdAsync(workshopId);
      }
    },
    enabled: !!workshopId,
  });
};
