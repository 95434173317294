import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Calendar, Button } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { ResourceSchedule } from '../../hooks/useProviderCalendar';
import dayjs, { Dayjs } from 'dayjs';
import './SmallCalendar.styles.scss';

export type SmallCalendarProps = Pick<
  ResourceSchedule,
  'view' | 'date' | 'setDate' | 'onSideCalendarDateSet'
> & {
  className?: string;
};

const SmallCalendar = ({
  date,
  view,
  setDate,
  onSideCalendarDateSet,
  className,
}: SmallCalendarProps): JSX.Element => {
  const [insideDate, setInsideDate] = useState(dayjs(new Date()));

  useEffect(() => {
    setInsideDate(dayjs(date));
  }, [date]);

  const onMonthChange = useCallback(
    (value: Dayjs, shift: number) => {
      const month = value.month();
      const newValue = value.clone();
      newValue.month(parseInt(String(month + shift), 10));
      setInsideDate(newValue);
    },
    [setInsideDate],
  );

  return (
    <Calendar
      className={`smallCalendar ${className ? className : ''}`}
      value={dayjs(insideDate)}
      fullscreen={false}
      onSelect={(newDate) => {
        const dt = newDate.toDate();
        setDate(dt, view !== 'month' ? view : 'day');
        onSideCalendarDateSet(dt);
      }}
      headerRender={({ value }) => {
        return (
          <div className='smallCalendar-header'>
            <Button
              className='smallCalendar-header-button'
              icon={<LeftOutlined />}
              onClick={() => {
                onMonthChange(value as Dayjs, -1);
              }}
            />
            <p className='smallCalendar-header-month'> {value.format('MMMM YYYY')}</p>
            <Button
              className='smallCalendar-header-button'
              icon={<RightOutlined />}
              onClick={() => {
                onMonthChange(value as Dayjs, 1);
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default SmallCalendar;
