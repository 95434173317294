import React from 'react';

import { SwiperCarousel } from '@inovirtue/components';
import SessionTile from '../SessionTile/SessionTile';
import './SessionCarousel.scss';
import { WorkshopSlotList } from '../../model/SlotModel';

export interface AvailableCoachesCarouselProps {
  className?: string;
  elements: WorkshopSlotList;
  onClickSlot: (slotId: string) => void;
}

const SessionCarousel = ({
  className,
  elements,
  onClickSlot,
}: AvailableCoachesCarouselProps): JSX.Element => {
  return (
    <SwiperCarousel
      slideClassName='sessions-slots-carousel__element'
      elements={elements}
      renderSlideComponents={(el) => {
        return <SessionTile {...el} onClickActions={onClickSlot} />;
      }}
      className={`sessions-slots-carousel ${className}`}
    />
  );
};

export default SessionCarousel;
