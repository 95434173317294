import { DEFAULT_VIDEO_CONSTRAINTS } from '../constants/constants';
import { getDeviceInfo, isPermissionDenied } from '../utils';
import { Step, StepType } from './models/Step.model';
import { VideoCallOptions, videoCallStore } from './video-call.store';
import Video, {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  TwilioError,
} from 'twilio-video';
import { videoStore, selectedVideoInputDeviceId$ } from './video';
import { audioStore, selectedAudioInputDeviceId$ } from './audio';
import { v4 as uuidv4 } from 'uuid';

const REDIRECT_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

export const setVideoCallOptions = (options: VideoCallOptions): void => {
  videoCallStore.update((prev) => ({
    ...prev,
    options,
  }));
};

export const setError = (error: TwilioError | Error | null): void => {
  videoCallStore.update((oldState) => ({
    ...oldState,
    error: error,
    step: Step.CallErrorStep,
  }));
};

export const setStep = (step: StepType): void => {
  videoCallStore.update((prev) => ({
    ...prev,
    step: step,
  }));
};

export const setIsAcquiringMediaError = (isAcquiringMediaError: boolean): void => {
  videoCallStore.update((prev) => ({
    ...prev,
    isAcquiringMediaError,
  }));
};

export const setIsConnecting = (isConnecting: boolean): void => {
  videoCallStore.update((prev) => ({
    ...prev,
    isConnecting,
  }));
};

export const setIsInitialized = (isInitialized: boolean): void => {
  videoCallStore.update((prev) => ({
    ...prev,
    isInitialized,
  }));
};

export const initializeLocalTracks = async (): Promise<void> => {
  const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } =
    await getDeviceInfo();

  if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();

  const selectedAudioInputDeviceId = selectedAudioInputDeviceId$.getValue();
  const selectedVideoInputDeviceId = selectedVideoInputDeviceId$.getValue();

  const hasSelectedAudioDevice = audioInputDevices.some(
    (device) => selectedAudioInputDeviceId && device.deviceId === selectedAudioInputDeviceId,
  );
  const hasSelectedVideoDevice = videoInputDevices.some(
    (device) => selectedVideoInputDeviceId && device.deviceId === selectedVideoInputDeviceId,
  );

  const isCameraPermissionDenied = await isPermissionDenied('camera' as PermissionName);
  const isMicrophonePermissionDenied = await isPermissionDenied('microphone' as PermissionName);

  const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
  const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

  const localTrackConstraints = {
    video: shouldAcquireVideo && {
      ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, unknown>),
      name: `camera-${uuidv4()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoInputDeviceId ?? '' } }),
    },
    audio:
      shouldAcquireAudio &&
      (hasSelectedAudioDevice
        ? { deviceId: { exact: selectedAudioInputDeviceId ?? '' } }
        : hasAudioInputDevices),
  };

  return Video.createLocalTracks(localTrackConstraints as CreateLocalTrackOptions).then(
    (tracks) => {
      const newVideoTrack = tracks.find((track) => track.kind === 'video') as LocalVideoTrack;
      const newAudioTrack = tracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
      if (newVideoTrack) {
        videoStore.update((prev) => ({
          ...prev,
          localVideoTrack: newVideoTrack,
          selectedVideoInputDeviceId: newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? '',
        }));
      }
      if (newAudioTrack) {
        audioStore.update((prev) => ({
          ...prev,
          localAudioTrack: newAudioTrack,
          selectedAudioInputDeviceId: newAudioTrack.mediaStreamTrack.getSettings().deviceId ?? '',
        }));
      }

      if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
        const error = new Error();
        error.name = 'NotAllowedError';
        throw error;
      }

      if (isCameraPermissionDenied) {
        throw new Error('CameraPermissionsDenied');
      }

      if (isMicrophonePermissionDenied) {
        throw new Error('MicrophonePermissionsDenied');
      }
    },
  );
};

export const onAcceptCall = async (roomName: string) => {
  window.open(`${REDIRECT_URL}/videoCall/${roomName}`);
};

export const setIsChat = async (isChat: boolean) => {
  videoCallStore.update((prev) => ({
    ...prev,
    options: {
      ...prev.options,
      isChat,
    },
  }));
};
