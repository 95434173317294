import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import dayjs from 'dayjs';
import { ConfirmBookingBody, ValidateSlotsDTO, ValidateSlotsResultsDTO } from './SchedulerDTO';
import { Slot } from '@inovirtue/booking';

const formatDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export const getProviderTimeSlots = async (
  providerName: string,
  startDate: Date,
): Promise<Slot[]> => {
  const apiClient = await getApiClient(`${config.apiAddresses.schedulingService}`);
  const { data } = await apiClient.get<Slot[]>(`/v2/Providers/${providerName}/timeslots`, {
    params: {
      startDate: formatDate(startDate),
      timezoneOffset: -new Date().getTimezoneOffset(),
      timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      endDate: formatDate(dayjs(startDate).endOf('month').toDate()),
      bookingType: 'Workshop',
      padding: 15,
      duration: 180,
    },
  });
  return data;
};

export const confirmBooking = async (body: ConfirmBookingBody): Promise<void> => {
  const apiClient = await getApiClient(`${config.apiAddresses.schedulingService}`);
  await apiClient.post<void>(`/v2/Booking/bookWorkshopSessions`, {
    timezoneOffset: -new Date().getTimezoneOffset(),
    ...body,
  });
};
export const validateSlots = async (body: ValidateSlotsDTO): Promise<ValidateSlotsResultsDTO> => {
  const apiClient = await getApiClient(`${config.apiAddresses.schedulingService}`);
  const { data } = await apiClient.post<ValidateSlotsResultsDTO>('/v2/Booking/validateTimeSlots', {
    bookingType: 'Workshop',
    timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezoneOffset: -new Date().getTimezoneOffset(),
    duration: 180,
    padding: 15,
    ...body,
  });
  return data;
};
