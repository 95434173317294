import React from 'react';
import { TableTabs } from '@inovirtue/table';
import useEmployeesTablesTabs from '../../hooks/useEmployeeTablesTabs';

import './EmployeeTablesTabs.scss';

export const EmployeeTablesTabs = ({ currentTab }: { currentTab: string }): JSX.Element => {
  const { navigate, tabs } = useEmployeesTablesTabs(currentTab);

  return <TableTabs navigateAction={navigate} currentTab={currentTab} tabs={tabs} />;
};
