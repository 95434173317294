import { CompanyContact } from '@inovirtue/company';
import { range } from 'lodash';
import moment from 'moment';
import { rest } from 'msw';
import { Workshop, Session, NewProviderRequestDto } from '@inovirtue/admin';

export const handlers = [
  rest.get('*/Profiles/providers', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          name: 'dummy.name',
          displayName: 'dummy.displayname',
          description: 'some desc',
          avatarUrl: null,
          onlineStatus: 'Available',
          type: 'ProviderProfile',
        },
        {
          name: 'dummy.name2',
          displayName: 'dummy.displayname2',
          description: 'some desc',
          avatarUrl: null,
          onlineStatus: 'Available',
          type: 'ProviderProfile',
        },
        {
          name: 'dummy.name3',
          displayName: 'dummy.displayname3',
          description: 'some desc',
          avatarUrl: null,
          onlineStatus: 'Available',
          type: 'ProviderProfile',
        },
        {
          name: 'dummy.name4',
          displayName: 'dummy.displayname4',
          description: 'some desc',
          avatarUrl: null,
          onlineStatus: 'Available',
          type: 'ProviderProfile',
        },
      ]),
    );
  }),
  rest.get('*/Profiles/consumers/:name', (req, res, ctx) => {
    const { name } = req.params;

    if (name === '123')
      return res(
        ctx.status(200),
        ctx.json({
          name: 'dummy.name',
          displayName: 'dummy.displayname',
          avatarUrl:
            'https://images.unsplash.com/photo-1561495376-dc9c7c5b8726?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGFicmFkb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
          companyId: null,
          allowedNumberOfSessions: 3,
        }),
      );
    else if (name === 'not-found-name') {
      return res(ctx.status(404));
    }
  }),
  rest.get('*/Profiles/providers/interests', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          displayName: 'Testing cosmos',
          name: 'testing.cosmos',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'base.test.profile.2',
          name: 'base.test.profile.2',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'engineer magnetic e-commerce 2',
          name: 'engineer.magnetic.e-commerce.2',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'envisioneer efficient relation',
          name: 'envisioneer.efficient.relation',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'transform 24/365 convergence 1',
          name: 'transform.24365.convergence.1',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'engineer web-enabled synergies',
          name: 'engineer.web-enabled.synergies',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'deploy dot-com action-items 17',
          name: 'deploy.dot-com.action-items.17',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'brand intuitive niches 265wwww',
          name: 'brand.intuitive.niches.265wwww',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'add.profile.flow',
          name: 'add.profile.flow',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'logged.consumer.add.new',
          name: 'logged.consumer.add.new',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'profile.after.create.new',
          name: 'profile.after.create.new',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'logged.provider.add.profile',
          name: 'logged.provider.add.profile',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'new.temp.profile',
          name: 'new.temp.profile',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'Coach1',
          name: 'coach1',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'provider test 2',
          name: 'provider.test.2',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'deliver holistic solutions 953',
          name: 'deliver.holistic.solutions.953',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'repurpose cross-media interfac',
          name: 'repurpose.cross-media.interfac',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'strategize global blockchains',
          name: 'strategize.global.blockchains',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'productize dot-com mindshare 6',
          name: 'productize.dot-com.mindshare.6',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
        {
          displayName: 'architect global e-tailers 227',
          name: 'architect.global.e-tailers.227',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
          profileUrl: 'mock profile url',
        },
      ]),
    );
  }),
  rest.get('*/Profiles/:profileName/interests', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(['mit hunde spatzieren', 'ich mag music', 'ich bin 27 yahre alt', 'bayern munich']),
    );
  }),
  rest.get('*/Profiles/:profileName/recommendations', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          displayName: 'base.test.profile.2',
          name: 'base.test.profile.2',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'engineer magnetic e-commerce 2',
          name: 'engineer.magnetic.e-commerce.2',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'transform 24/365 convergence 1',
          name: 'transform.24365.convergence.1',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'engineer web-enabled synergies',
          name: 'engineer.web-enabled.synergies',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'deploy dot-com action-items 17',
          name: 'deploy.dot-com.action-items.17',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },

        {
          displayName: 'add.profile.flow',
          name: 'add.profile.flow',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'logged.consumer.add.new',
          name: 'logged.consumer.add.new',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'profile.after.create.new',
          name: 'profile.after.create.new',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'logged.provider.add.profile',
          name: 'logged.provider.add.profile',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'new.temp.profile',
          name: 'new.temp.profile',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'Coach1',
          name: 'coach1',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'provider test 2',
          name: 'provider.test.2',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'deliver holistic solutions 953',
          name: 'deliver.holistic.solutions.953',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'repurpose cross-media interfac',
          name: 'repurpose.cross-media.interfac',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'strategize global blockchains',
          name: 'strategize.global.blockchains',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'productize dot-com mindshare 6',
          name: 'productize.dot-com.mindshare.6',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
        {
          displayName: 'architect global e-tailers 227',
          name: 'architect.global.e-tailers.227',
          interestKeys: [
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
            'dummy',
            'dummy inter',
          ],
          passedSessionCount: 12,
          futureSessionCount: 3,
        },
      ]),
    );
  }),
  rest.get('*/Profiles/:profileName/provider/selected', (req, res, ctx) => {
    const dummyCompanies = range(4).map((i) => ({
      companyId: i.toString(),
      name: `fake_${i}`,
      displayName: `fake_displayname_${i}`,
      passedSessionCount: i * 5,
      futureSessionCount: i * 7,
      interestKeys: ['fake_interest', 'other_interest'],
    }));
    return res(ctx.status(200), ctx.json(dummyCompanies));
  }),
  rest.post('*/Companies', (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.put('*/Companies/:companyId', (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get('*/Companies', (req, res, ctx) => {
    const dummyCompanies = range(20).map((i) => ({
      companyId: i.toString(),
      name: `fake_${i}`,
      addressLine1: `line1_${i}`,
      addressLine2: `line2_${i}`,
      country: `country_${i}`,
      city: `city_${i}`,
      postalCode: `postal_${i}`,
      licenseStartDate: moment(new Date()).subtract(i, 'month').toString(),
      licenseEndDate: moment(new Date()).add(i, 'month').toString(),
      status: 'active',
      creationDate: moment(new Date())
        .subtract(2 * i, 'month')
        .toString(),
    }));
    return res(ctx.status(200), ctx.json(dummyCompanies));
  }),
  rest.get('*/Companies/:id', (req, res, ctx) => {
    const dummyCompany = {
      companyId: 1,
      name: 'fake',
      addressLine1: 'line1',
      addressLine2: 'line2',
      country: 'country',
      city: 'city',
      postalCode: 'postal',
      licenseStartDate: moment(new Date()).subtract(1, 'month').toString(),
      licenseEndDate: moment(new Date()).add(1, 'month').toString(),
      status: 'active',
      creationDate: moment(new Date()).subtract(2, 'month').toString(),
    };
    return res(ctx.status(200), ctx.json(dummyCompany));
  }),
  rest.get('*/Companies/backoffice/:companyId/contacts', (req, res, ctx) => {
    const fakeContacts: CompanyContact[] = range(20).map((i) => ({
      id: i.toString(),
      firstName: `fake_${i}`,
      lastName: `fake_lastname_${i}`,
      phoneNumber: `fake_phone_${i}`,
      positionId: `fake_position_${i}`,
      email: `fake_email_${i}`,
    }));
    return res(ctx.status(200), ctx.json(fakeContacts));
  }),
  rest.get('*/Requests/:profileName/newProvider/active', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(<NewProviderRequestDto>{
        id: '123',
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        whenCreated: '2022-05-11T07:26:13.661Z',
      }),
    );
  }),
  rest.post('*/Workshops', (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get('*/Workshops', (req, res, ctx) => {
    const fakeGroups: Workshop[] = range(20).map((i) => ({
      id: i.toString(),
      name: `name.${i}`,
      description: `description.${i}`,
      sessionsInTotal: i * 4,
      companyContactId: `companyContactId.${i}`,
      selectedCompanyId: `selectedCompanyId.${i}`,
      selectedCompanyName: `selectedCompanyName.${i}`,
      status: 'Unpublished',
    }));
    return res(ctx.status(200), ctx.json(fakeGroups));
  }),
  rest.get('*/Workshops/:id', (req, res, ctx) => {
    const id = req.params.id;
    const fakeGroup: Workshop = {
      id: id.toString(),
      name: `name.${id}`,
      description: `description.${id}`,
      sessionsInTotal: 4,
      companyContactId: `companyContactId.${id}`,
      selectedCompanyId: `selectedCompanyId.${id}`,
      selectedCompanyName: `selectedCompanyName.${id}`,
      status: 'Unpublished',
    };
    return res(ctx.status(200), ctx.json(fakeGroup));
  }),
  rest.get('*/Workshops/:id/sessions', (req, res, ctx) => {
    const fakeSessions: Session[] = range(20).map((i) => ({
      id: i.toString(),
      plannedStartDateTime: new Date(),
      plannedEndDateTime: new Date(new Date().getDate() + 1),
      canJoin: false,
      joinVideoCall: async () => {},
      isChargeable: false,
    }));
    return res(ctx.status(200), ctx.json(fakeSessions));
  }),
  rest.get('*/companies/employees/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
];
