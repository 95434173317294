import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { getProfileRoles } from '../api/profiles';
import { currentProfileName$, roles$, setRoles } from '../store';

export const useProfileRoles = () => {
  const { chatFlag } = useFlags();
  const currentProfileName = useBehaviorSubjectState(currentProfileName$);
  const roles = useBehaviorSubjectState(roles$);

  useEffect(() => {
    const fetchProfileRoles = async () => {
      const profileRoles = await getProfileRoles(currentProfileName);
      setRoles(profileRoles);
    };

    if (chatFlag && currentProfileName) fetchProfileRoles();
  }, [chatFlag, currentProfileName]);

  return {
    roles,
  };
};
