import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  Conversation as TwilioConversation,
  Message as TwilioMessage,
} from '@twilio/conversations';
import { Convo } from '../models/conversation';
import { Message } from '../models/message';
import { conversationActions } from '../service/conversationService';
import { messageActions } from '../service/messageService';
import { serializeMessage } from '../utils/serialize/serialize';
import { messagesComparer } from '../utils/sort/sort';
import { assembleConversation, assembleSubscribedConversations } from './conversationsSlice';
import { ConversationsState } from './store';

export interface RoomEntity {
  sid: TwilioConversation['sid'];
  messages: EntityState<Message>;
  hasPrevPage?: boolean;
}

export const roomAdapter = createEntityAdapter<RoomEntity>({
  selectId: (entity) => entity.sid,
});
export const messagesAdapter = createEntityAdapter<Message>({
  selectId: (message) => message.sid,
  sortComparer: messagesComparer,
});

export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async (convoSid: Convo['sid']) => {
    const messages = await conversationActions.getMessages(convoSid);
    messageActions.upsertMany(messages.items);

    return { convoSid, messages: messages.items, hasPrevPage: messages.hasPrevPage };
  },
);

export const fetchPrevMessages = createAsyncThunk(
  'messages/fetchPrevMessages',
  async (convoSid: Convo['sid']) => {
    const messages = await conversationActions.getPrevMessages(convoSid);
    messageActions.upsertMany(messages.items);

    return { convoSid, messages: messages.items, hasPrevPage: messages.hasPrevPage };
  },
);

const messagesSlice = createSlice({
  name: 'messages',
  initialState: roomAdapter.getInitialState(),
  reducers: {
    addMessage: (
      state: EntityState<RoomEntity>,
      action: PayloadAction<{ convoSid: string; message: TwilioMessage; identity: string }>,
    ) => {
      const { convoSid, message } = action.payload;
      const roomEntity = state.entities[convoSid];
      if (roomEntity) {
        const serializedMessage = serializeMessage(message);
        messageActions.add(message);
        messagesAdapter.addOne(roomEntity.messages, serializedMessage);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      const { convoSid, messages, hasPrevPage } = action.payload;
      const roomEntity = state.entities[convoSid];
      if (roomEntity) {
        const serializedMessages = serializeMessage(messages);
        roomEntity.hasPrevPage = hasPrevPage;
        messagesAdapter.upsertMany(roomEntity.messages, serializedMessages);
      }
    });

    builder.addCase(fetchPrevMessages.fulfilled, (state, action) => {
      const { convoSid, messages, hasPrevPage } = action.payload;
      const roomEntity = state.entities[convoSid];
      if (roomEntity) {
        const serializedMessages = serializeMessage(messages);
        roomEntity.hasPrevPage = hasPrevPage;
        messagesAdapter.upsertMany(roomEntity.messages, serializedMessages);
      }
    });

    builder.addCase(assembleSubscribedConversations.fulfilled, (state, action) => {
      const { conversations } = action.payload;
      const roomEntities = conversations.items.map<RoomEntity>((conversation) => ({
        sid: conversation.sid,
        messages: messagesAdapter.getInitialState(),
      }));

      roomAdapter.addMany(state, roomEntities);
    });

    builder.addCase(assembleConversation.fulfilled, (state, action) => {
      const { conversation } = action.payload;
      const roomEntity: RoomEntity = {
        sid: conversation.sid,
        messages: messagesAdapter.getInitialState(),
      };

      roomAdapter.addOne(state, roomEntity);
    });
  },
});

export const { addMessage } = messagesSlice.actions;

export const messagesReducer = messagesSlice.reducer;

const { selectById } = roomAdapter.getSelectors((state: ConversationsState) => state.messages);

export const { selectAll: selectAllMessages } = messagesAdapter.getSelectors();

export const selectMessagesByConvoSid = (convoSid: string) => {
  return createSelector(
    (state: ConversationsState) => state,
    (state) => selectById(state, convoSid),
  );
};

export const selectHasPrevPageByConvoSid = (convoSid: string) => {
  return createSelector(
    (state: ConversationsState) => state,
    (state) => selectById(state, convoSid)?.hasPrevPage,
  );
};
