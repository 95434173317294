import { Row, Col } from 'antd';
import { t } from '@inovirtue/localization';
import StepNotice from '../../StepNotice/StepNotice.Component';
import { Button } from '../../Buttons/Button/Button.Component';
import { onRedirect, onRejoin } from '../../../store/video';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { canRejoin$ } from '../../../store';
import { leftCall$ } from '../../../store/video-call-session';
import './CallLeftStep.Component.scss';

const { Heading, SubHeading, Actions, Profile } = StepNotice;

const CallLeftStep = (): JSX.Element => {
  const canRejoin = useBehaviorSubjectState(canRejoin$);
  const leftCall = useBehaviorSubjectState(leftCall$);

  return (
    <Row className='callleftstep' data-testid='videoCallLeftStep'>
      <Col>
        <StepNotice>
          <Profile />
          <Heading text={t('videoCall:call-left.heading')} />
          {leftCall ? (
            leftCall
          ) : (
            <>
              {canRejoin && <SubHeading text={t('videoCall:call-left.subheading')} />}
              <Actions>
                {canRejoin ? (
                  <>
                    <Button type='primary' onClick={onRejoin} className='callleftstep__button'>
                      {t('videoCall:rejoin-btn')}
                    </Button>
                    <Button type='default' onClick={onRedirect} className='callleftstep__button'>
                      {t('videoCall:dismiss-btn')}
                    </Button>
                  </>
                ) : (
                  <Button type='primary' onClick={onRedirect} className='callleftstep__button'>
                    {t('videoCall:homepage-btn')}
                  </Button>
                )}
              </Actions>
            </>
          )}
        </StepNotice>
      </Col>
    </Row>
  );
};

export default CallLeftStep;
