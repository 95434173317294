import { Select } from 'antd';
import { t } from '@inovirtue/localization';
import {
  activeSinkId$,
  audioOutputDevices$,
  hasAudioOutputDevices$,
  setActiveSinkId,
} from '../../../store/audio';
import './AudioOutputList.Component.scss';
import { isInitialized$ } from '../../../store';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import DeviceLabel from '../DeviceLabel/DeviceLabel';

const { Option } = Select;

export interface AudioOutputListProps {
  className?: string;
}

const AudioOutputList = ({ className }: AudioOutputListProps): JSX.Element => {
  const isInitialized = useBehaviorSubjectState(isInitialized$);
  const audioOutputDevices = useBehaviorSubjectState(audioOutputDevices$);
  const hasAudioOutputDevices = useBehaviorSubjectState(hasAudioOutputDevices$);
  const activeSinkId = useBehaviorSubjectState(activeSinkId$);

  const value = hasAudioOutputDevices
    ? activeSinkId
    : t('videoCall:call-prep.audiooutput-default-device');

  return (
    <div {...{ className }}>
      <DeviceLabel text={t('videoCall:device.speakers')} />
      <Select
        onChange={setActiveSinkId}
        value={value}
        placeholder={t('videoCall:call-prep.audiooutput-placeholder')}
        disabled={!hasAudioOutputDevices || !isInitialized}
        className='audiooutputlist'
        data-testid='audiooutput-select'
      >
        {audioOutputDevices.map((device) => (
          <Option key={device.deviceId} value={device.deviceId}>
            {device.label || t('videoCall:call-prep.audiooutput-default-device')}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default AudioOutputList;
