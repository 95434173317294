import { Button } from 'antd';
import { useChatButton } from '../../../hooks/useChatButton/useChatButton';

export interface ToggleChatButtonProps {
  convoSid: string;
}

export const ToggleChatButton = ({ convoSid }: ToggleChatButtonProps): JSX.Element => {
  const { icon, onClick } = useChatButton(convoSid);

  return (
    <Button
      data-testid='chat-button'
      className='chat-button'
      size='large'
      shape='circle'
      onClick={onClick}
      icon={icon}
    />
  );
};
