import { t } from '@inovirtue/localization';
import InputOutputDevices from '../DeviceSelection/InputOutputDevices/InputOutputDevices';
import { Typography } from 'antd';
import { Popover } from '@inovirtue/components';
import { useSettingsButton } from '../../hooks/useSettingsButton/useSettingsButton';
import './SettingsSider.scss';

const { Title } = Typography;

const SettingsSider = (): JSX.Element => {
  const { toggleSettings } = useSettingsButton();

  return (
    <div className='settings-sider'>
      <Popover
        isCloseIcon
        onClose={toggleSettings}
        isBorder={false}
        contentCls={'settings-sider__content'}
        title={
          <Title className='settings-sider__heading' level={2}>
            {t('videoCall:device.device-settings')}
          </Title>
        }
      >
        <InputOutputDevices hideVideoPreview />
      </Popover>
    </div>
  );
};

export default SettingsSider;
