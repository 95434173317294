import './IncompleteWorkshopBar.scss';
import { Alert, Space } from 'antd';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useMemo } from 'react';

export type IncompleteWorkshopBarProps = {
  showAddMembers?: boolean;
  showScheduleSessions?: boolean;
  onAddMembersClick?: () => void;
  onScheduleSessionsClick?: () => void;
};

export const IncompleteWorkshopBar = ({
  showAddMembers = false,
  showScheduleSessions = false,
  onAddMembersClick,
  onScheduleSessionsClick,
}: IncompleteWorkshopBarProps) => {
  const message = useMemo(() => {
    if (showAddMembers && showScheduleSessions) {
      return t('group:incomplete-group-bar.title.all');
    } else if (showAddMembers) {
      return t('group:incomplete-group-bar.title.members');
    } else if (showScheduleSessions) {
      return t('group:incomplete-group-bar.title.sessions');
    }
    return '';
  }, [showAddMembers, showScheduleSessions]);

  if (!showAddMembers && !showScheduleSessions) {
    return null;
  }

  return (
    <Alert
      data-testid='incomplete-group-bar'
      className='incomplete-group-bar'
      message={message}
      showIcon
      type='warning'
      action={
        <Space>
          {showAddMembers && (
            <Button
              data-testid='incomplete-group-add-members-button'
              size='small'
              ghost
              onClick={() => onAddMembersClick?.()}
            >
              {t('group:incomplete-group-bar.add-members')}
            </Button>
          )}
          {showScheduleSessions && (
            <Button
              data-testid='incomplete-group-schedule-sessions-button'
              size='small'
              ghost
              onClick={() => onScheduleSessionsClick?.()}
            >
              {t('group:incomplete-group-bar.schedule-sessions')}
            </Button>
          )}
        </Space>
      }
    />
  );
};
