import { t } from '@inovirtue/localization';
import { Alert, Button, Col, Row } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { generatePath, useHistory, useParams } from 'react-router';
import PartnerGeneralInfo from '../../../Modules/Partners/components/PartnerGeneralInfo/PartnerGeneralInfo';
import PartnerMission from '../../../Modules/Partners/components/PartnerMission/PartnerMission';
import PartnerUrl from '../../../Modules/Partners/components/PartnerUrl/PartnerUrl';
import usePartnerDetails from '../../hooks/usePartnerDetails';
import routes from '../../routes/constants/Routes';
import PartnerDetailsView from '../PartnerDetailsView/PartnerDetailsView';
import styles from './PartnerDetailsGeneralView.module.scss';

const PartnerDetailsGeneralView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const { partner } = usePartnerDetails(id);

  return (
    <PartnerDetailsView partner={partner} currentTab='details'>
      <div className={styles.partnerDetailsView}>
        {partner && (
          <>
            {!partner.isActive && (
              <Alert
                className={styles.partnerDetailsView__inactiveAlert}
                message={t('partners:deactivated-status-info')}
                type='warning'
                showIcon
              />
            )}
            <div className={styles.partnerDetailsView__editBar}>
              <div className={styles.partnerDetailsView__editBar_text}>
                {t('partners:details.edit-text')}
              </div>
              <div>
                <Button onClick={() => push(generatePath(routes.PartnerEdit, { id }))}>
                  {t('partners:details.edit-btn-text')}
                </Button>
              </div>
            </div>
            <Row gutter={12} className={styles.partnerDetailsView__cardsContainer}>
              <Col span={12}>
                <PartnerGeneralInfo partnerDto={partner} />
              </Col>
              <Col span={12}>
                <PartnerMission tags={partner.tags} />
                <PartnerUrl partner={partner} />
              </Col>
            </Row>
          </>
        )}

        {!partner && <Typography>Partner with id: {id}, not found</Typography>}
      </div>
    </PartnerDetailsView>
  );
};

export default PartnerDetailsGeneralView;
