import { useCallback, useMemo, useState } from 'react';
import { getBlobFile } from '../../api/media';
import { saveAs } from 'file-saver';
import { useErrorContext } from '../useErrorContext/useErrorContext';
import { t } from '@inovirtue/localization';
import { messageActions } from '../../service/messageService';

export const useMedia = (messageSid: string) => {
  const attachedMedia = useMemo(() => messageActions.getAttachedMedia(messageSid), [messageSid]);
  const { setError } = useErrorContext();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadMedia = useCallback(async () => {
    if (!attachedMedia?.length || isDownloading) return;
    const media = attachedMedia[0];

    try {
      setIsDownloading(true);
      const blob = await getBlobFile(media);
      saveAs(blob, media.filename || media.sid);
    } catch {
      setError(t('errors:unhandled-error'));
    } finally {
      setIsDownloading(false);
    }
  }, [attachedMedia, setError, isDownloading]);

  return { downloadMedia, isDownloading };
};
