import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useEffect, useMemo, useState } from 'react';
import { getProvidersWithInterests } from '../api/Providers.Api';
import Provider from '../models/Provider';

export const useProvidersCollection = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [searchString, setSearchString] = useState<string>();
  const fetchProvidersMemo = useMemo(
    () => () => getProvidersWithInterests(pageIndex, searchString),
    [pageIndex, searchString],
  );
  const { isProcessing, data } = usePromiseWithProgress<Provider[]>(fetchProvidersMemo);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  useEffect(() => {
    setHasMore(!!data && data.length > 0);
    if (data) setProviders((currentState) => [...currentState, ...data]);
  }, [data]);

  const searchByText = (value: string) => {
    setProviders([]);
    setSearchString(value);
  };

  return {
    providers,
    hasMore,
    isProcessing,
    pageIndex,
    setPageIndex,
    searchByText,
  };
};
