import { CompanyContact } from '@inovirtue/company';
import moment from 'moment';
import { Consumer } from '@inovirtue/admin';
import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { Company } from '../models/domain/Company';
import { AddCompanyContactCommandDto } from '../models/dto/AddCompanyContactCommandDto';
import { CompanyDto } from '../models/dto/CompanyDto';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getCompanies = async (): Promise<Company[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Company[]>('/Companies');
  return data;
};

export const addCompany = async (formData: CompanyDto): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/Companies`, formData);
};

export const updateCompany = async (formData: CompanyDto): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.put(`/Companies/${formData.companyId}`, formData);
};

export const getCompanyById = async (companyId: string): Promise<Company> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Company>(`/companies/${companyId}`);

  return {
    ...data,
    licenseStartDate: data.licenseStartDate ? moment(data.licenseStartDate) : undefined,
    licenseEndDate: data.licenseEndDate ? moment(data.licenseEndDate) : undefined,
  };
};

export const getCompanyContacts = async (companyId: string): Promise<Consumer[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Consumer[]>(`/Companies/${companyId}/contacts`);
  return [...data];
};

export const getCompanyInvitations = async (companyId: string): Promise<CompanyContact[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<CompanyContact[]>(`/Companies/${companyId}/invitations`);
  return [...data];
};

export const addInvitation = async (companyId: string, formData: CompanyContact): Promise<void> => {
  const axiosInstance = await getApiClientAsync();

  const request: AddCompanyContactCommandDto = {
    inviteRedirectUrl: `${config.otherApps.companyCockpit}/signUp/companyContact`,
    invitations: [formData],
  };
  await axiosInstance.post(`/companies/${companyId}/invitations/manager`, request);
};

export const updateInvitation = async (
  companyId: string,
  formData: CompanyContact,
): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.put(`/Companies/${companyId}/invitations/${formData.id}`, formData);
};

export const deleteInvitation = async (companyId: string, contactId: string): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.delete(`/Companies/${companyId}/invitations/${contactId}`);
};

export const getEmployees = async (companyId: string): Promise<Consumer[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get(`/Companies/${companyId}/employees`);
  return [...data];
};

export const getConsultants = async (): Promise<Consumer[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get(`/roles/NWOW.BackOffice.Consultant/profiles`);
  return [...data];
};
