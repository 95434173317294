import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { getActiveNewProviderRequest } from '../api/ConsumerRequests.Api';
import { NewProviderRequest } from '../models/domain/NewProviderRequest';

export const useConsumerActiveNewProviderRequest = (profileName: string) => {
  const { multipleCoaches } = useFlags();

  const fetchNewProviderRequest = useCallback(
    () => getActiveNewProviderRequest(profileName),
    [profileName],
  );

  const fetchNewProviderRequestEmpty = useCallback(
    () => new Promise<NewProviderRequest | undefined>((resolve) => resolve(undefined)),
    [],
  );

  const { data, isProcessing } = usePromiseWithProgress<NewProviderRequest | undefined>(
    multipleCoaches ? fetchNewProviderRequest : fetchNewProviderRequestEmpty,
  );

  return { data, isProcessing };
};
