import { useMemo } from 'react';
import { Message } from '../../models/message';
import { formatFileSize } from '../../utils/formatFileSize/formatFileSize';
import { getFileExtension } from '../../utils/getFileExtension/getFileExtension';

export type MessageDetails =
  | {
      type: 'text';
    }
  | {
      type: 'file';
      contentType: string;
      extension: string | null;
      fileName: string | null;
      fileSize: string;
    }
  | {
      type: 'image';
      contentType: string;
      fileName: string | null;
      fileSize: string;
    };

export const useMessageDetails = ({ type, attachedMedia }: Message) => {
  const messageDetails: MessageDetails = useMemo(() => {
    if (type === 'text') return { type: 'text' };

    const media = attachedMedia?.[0];

    if (type === 'media' && media && media.contentType.includes('image')) {
      return {
        type: 'image',
        contentType: media.contentType,
        fileName: media.filename,
        fileSize: formatFileSize(media.size),
      };
    }

    if (type === 'media' && media) {
      const extension = getFileExtension(media.filename);
      return {
        type: 'file',
        contentType: media.contentType,
        extension,
        fileName: media.filename,
        fileSize: formatFileSize(media.size),
      };
    }

    return { type: 'text' };
  }, [type, attachedMedia]);

  return messageDetails;
};
