import { useCallback, useEffect, useState } from 'react';
import { Provider } from '../model/Provider';

export const useEditPartnerProviders = (
  initialProviders: Provider[],
  onChanged: (providers: Provider[]) => void,
) => {
  const [currentProviders, setCurrentProviders] = useState<Provider[]>([]);

  useEffect(() => {
    setCurrentProviders(initialProviders);
  }, [initialProviders]);

  useEffect(() => {
    onChanged(currentProviders);
  }, [onChanged, currentProviders]);

  const addProvider = useCallback((provider: Provider) => {
    setCurrentProviders((prevState) => [
      provider,
      ...prevState.filter((x) => x.name !== provider.name),
    ]);
  }, []);

  const removeProvider = useCallback((provider: Provider) => {
    setCurrentProviders((prevState) => {
      const indexOfMember = prevState.findIndex((x) => x.name == provider.name);
      return [...prevState.slice(0, indexOfMember), ...prevState.slice(indexOfMember + 1)];
    });
  }, []);

  return {
    currentProviders,
    addProvider,
    removeProvider,
  };
};
