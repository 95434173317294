import { Conversation } from '@twilio/conversations';
import { LastReadMessageIndexEntity, UnreadMessagesEntity } from '../store/horizonSlice';

export const getUnreadMessages = async (conversation: Conversation) => {
  if (conversation.lastReadMessageIndex === null) return conversation.getMessagesCount();

  return (await conversation.getUnreadMessagesCount()) ?? 0;
};

export const getAllUnreadMessages = async (conversations: Conversation[]) => {
  const requests = conversations.map(
    async (conversation: Conversation): Promise<UnreadMessagesEntity> => {
      const count = await getUnreadMessages(conversation);
      return { sid: conversation.sid, unreadMessages: count };
    },
  );
  return Promise.all(requests);
};

export const setAllMessagesRead = (conversation: Conversation) => {
  return conversation.setAllMessagesRead();
};

export const getLastReadMessageIndex = (conversation: Conversation) => {
  return conversation.lastReadMessageIndex;
};

export const getAllLastReadMessagesIndex = (conversations: Conversation[]) => {
  return conversations.map<LastReadMessageIndexEntity>((conversation) => ({
    sid: conversation.sid,
    lastReadMessageIndex: conversation.lastReadMessageIndex,
    lastMessageIndex: conversation.lastMessage?.index,
  }));
};
