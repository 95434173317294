import getGroupedCategorySelectedTags from '../helpers/getGroupedCategoryTags';
import { ProfileCategoryTagType, ProfileCategoryTagTypes } from '../models/ProfileTags';
import { UserProfile } from '../models/UserProfile';
import useProfileType from './useProfileType';

const useProfileTagList = (
  userProfile: UserProfile,
  allCategoryTags: Map<string, string[]>,
  groupByCategories: ProfileCategoryTagType[],
): Map<string, string[]> => {
  const { isConsumer } = useProfileType(userProfile);
  const selectedTags = userProfile?.tags ?? [];

  const otherTagsCategory = isConsumer
    ? ProfileCategoryTagTypes.interests
    : ProfileCategoryTagTypes.expertise;

  const result = getGroupedCategorySelectedTags(allCategoryTags, selectedTags, groupByCategories);
  const otherTags = selectedTags.filter(
    (f) =>
      !Array.from(result.values())
        .flatMap((m) => m)
        .includes(f),
  );
  if (otherTags.length > 0) result.set(otherTagsCategory, otherTags);
  return result;
};

export default useProfileTagList;
