import './WorkshopSessionsList.scss';
import { List } from 'antd';
import { Session } from '../../model/Session';
import { t } from '@inovirtue/localization';
import { ScheduleOutlined } from '@ant-design/icons';
import { formatOrDefault } from '@inovirtue/date-utils';
import { useMemo } from 'react';
import { Button } from '@inovirtue/components';

export type WorkshopSessionsListProps = {
  sessions: Session[];
};

export const WorkshopSessionsList = ({ sessions }: WorkshopSessionsListProps) => {
  const data = useMemo(
    () =>
      sessions.map((x, i) => {
        return {
          sessionId: x.id,
          title: `${t('group:group-sessions-list.session')} ${i + 1}`,
          description: `${formatOrDefault(
            x.plannedStartDateTime,
            'eeee, MM.dd.yy | h:mm aaa - ',
          )}${formatOrDefault(x.plannedEndDateTime, 'h:mm aaa')}`,
          canJoin: x.canJoin,
          joinVideoCall: x.joinVideoCall,
        };
      }),
    [sessions],
  );

  return (
    <div className='group-sessions-list'>
      <div className='group-sessions-list__header'>
        <ScheduleOutlined className='group-sessions-list__header__icon' />
        <span className='group-sessions-list__header__text'>
          {t('group:group-sessions-list.title')}
        </span>
      </div>
      <List
        className='group-sessions-list__list-element'
        itemLayout='horizontal'
        data-testid='group-sessions-list-element'
        dataSource={data}
        renderItem={(item) => {
          return (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.description} />
              {item.canJoin && (
                <Button onClick={() => item.joinVideoCall(item.sessionId)}>Join session</Button>
              )}
            </List.Item>
          );
        }}
      />
    </div>
  );
};
