import { useCallback, useMemo, useState } from 'react';
import { SlotsListModel, TimeSlot } from '../../model/booking';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs, { Dayjs } from 'dayjs';

const formatDate = (date: Dayjs) => dayjs(date).format('YYYY-MM-DD');

const checkIfIsAfterNoonOrMorning = (slot: TimeSlot): boolean => {
  const timeSlot = dayjs(slot.startDateTime);
  return timeSlot.hour() < 12 || (timeSlot.hour() === 12 && timeSlot.minute() === 0);
};

const useGetTimeSlots = (daySlots: SlotsListModel) => {
  const [morningSlots, setMorningSLots] = useState<TimeSlot[]>([]);
  const [afternoonSlots, setAfternoonSlots] = useState<TimeSlot[]>([]);

  const availableDays = useMemo(() => daySlots.map((el) => formatDate(dayjs(el.date))), [daySlots]);

  const clearTimeSlots = () => {
    setAfternoonSlots([]);
    setMorningSLots([]);
  };
  const selectTimeSlots = useCallback(
    (day: Dayjs) => {
      clearTimeSlots();
      const slots = daySlots.find((el) => formatDate(dayjs(el.date)) === formatDate(day));

      if (!slots) return;
      const morning = slots.timeSlots.filter((el) => checkIfIsAfterNoonOrMorning(el));
      const afternoon = slots.timeSlots.filter((el) => !checkIfIsAfterNoonOrMorning(el));
      setMorningSLots(morning);
      setAfternoonSlots(afternoon);
    },
    [daySlots],
  );

  return {
    clearTimeSlots,
    selectTimeSlots,
    availableDays,
    morningSlots,
    afternoonSlots,
  };
};

export default useGetTimeSlots;
