import { DataIcon, DetailsCardBase } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { Tag } from 'antd';
interface PartnerMissionProps {
  tags?: string[];
}

const PartnerMission = ({ tags }: PartnerMissionProps): JSX.Element => {
  return (
    <DetailsCardBase header={t('partners:details.our-mission')} icon={<DataIcon />}>
      <div>{tags && tags.map((tag) => <Tag key={tag}>{tag}</Tag>)}</div>
    </DetailsCardBase>
  );
};

export default PartnerMission;
