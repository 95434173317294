import { Button } from 'antd';
import { t } from '@inovirtue/localization';
import usePartnerActivateToggle from '../../hooks/usePartnerActivateToggle';

interface PartnerActivateButtonProps {
  isActive: boolean;
  name: string;
  id: string;
}

const PartnerActivateButton = ({ isActive, name, id }: PartnerActivateButtonProps): JSX.Element => {
  const handleToggle = usePartnerActivateToggle(isActive, name, id);
  return (
    <Button onClick={handleToggle}>
      {isActive ? t('partners:deactivate-btn') : t('partners:activate-btn')}
    </Button>
  );
};

export default PartnerActivateButton;
