import { createQuery } from '@datorama/akita';
import { VideoState, videoStore } from './video.store';
import { BehaviorSubject } from 'rxjs';

export const videoQuery = createQuery<VideoState>(videoStore);

export const selectedVideoInputDeviceId$ = new BehaviorSubject(
  videoQuery.getValue().selectedVideoInputDeviceId,
);
videoQuery.select((s) => s.selectedVideoInputDeviceId).subscribe(selectedVideoInputDeviceId$);

export const videoInputDevices$ = new BehaviorSubject(videoQuery.getValue().videoInputDevices);
videoQuery.select((s) => s.videoInputDevices).subscribe(videoInputDevices$);

export const hasVideoInputDevices$ = new BehaviorSubject(
  videoQuery.getValue().hasVideoInputDevices,
);
videoQuery.select((s) => s.hasVideoInputDevices).subscribe(hasVideoInputDevices$);

export const localVideoTrack$ = new BehaviorSubject(videoQuery.getValue().localVideoTrack);
videoQuery.select((s) => s.localVideoTrack).subscribe(localVideoTrack$);

export const settings$ = new BehaviorSubject(videoQuery.getValue().settings);
videoQuery.select((s) => s.settings).subscribe(settings$);

export const roomType$ = new BehaviorSubject(videoQuery.getValue().roomType);
videoQuery.select((s) => s.roomType).subscribe(roomType$);

export const backgroundSettings$ = new BehaviorSubject(videoQuery.getValue().backgroundSettings);
videoQuery.select((s) => s.backgroundSettings).subscribe(backgroundSettings$);

export const room$ = new BehaviorSubject(videoQuery.getValue().room);
videoQuery.select((s) => s.room).subscribe(room$);

export const isScreenSharing$ = new BehaviorSubject(videoQuery.getValue().isScreenSharing);
videoQuery.select((s) => s.isScreenSharing).subscribe(isScreenSharing$);

export const isChatOpen$ = new BehaviorSubject(videoQuery.getValue().isChatOpen);
videoQuery.select((s) => s.isChatOpen).subscribe(isChatOpen$);

export const isSettingsOpen$ = new BehaviorSubject(videoQuery.getValue().isSettingsOpen);
videoQuery.select((s) => s.isSettingsOpen).subscribe(isSettingsOpen$);

export const isBackgroundSelectionOpen$ = new BehaviorSubject(
  videoQuery.getValue().isBackgroundSelectionOpen,
);
videoQuery.select((s) => s.isBackgroundSelectionOpen).subscribe(isBackgroundSelectionOpen$);

export const isDisconnectedByUser$ = new BehaviorSubject(
  videoQuery.getValue().isDisconnectedByUser,
);
videoQuery.select((s) => s.isDisconnectedByUser).subscribe(isDisconnectedByUser$);
