import { globalStateStore } from './global-state.store';

export const enableFullPageLoader = (): void =>
  globalStateStore.update((oldState) => ({
    ...oldState,
    showFullPageLoader: true,
  }));

export const disableFullPageLoader = (): void =>
  globalStateStore.update((oldState) => ({
    ...oldState,
    showFullPageLoader: false,
  }));