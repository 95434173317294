import { useCallback, useRef } from 'react';

export const useTestAudio = (url: string): (() => void) => {
  const { current: audio } = useRef(new Audio(url));

  const toggle = useCallback(() => {
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [audio]);

  return toggle;
};
