import { createQuery } from '@datorama/akita';
import { BehaviorSubject } from 'rxjs';
import { AudioState, audioStore } from './audio.store';

export const audioQuery = createQuery<AudioState>(audioStore);

export const selectedAudioInputDeviceId$ = new BehaviorSubject(
  audioQuery.getValue().selectedAudioInputDeviceId,
);
audioQuery.select((s) => s.selectedAudioInputDeviceId).subscribe(selectedAudioInputDeviceId$);

export const audioInputDevices$ = new BehaviorSubject(audioQuery.getValue().audioInputDevices);
audioQuery.select((s) => s.audioInputDevices).subscribe(audioInputDevices$);

export const hasAudioInputDevices$ = new BehaviorSubject(
  audioQuery.getValue().hasAudioInputDevices,
);
audioQuery.select((s) => s.hasAudioInputDevices).subscribe(hasAudioInputDevices$);

export const audioOutputDevices$ = new BehaviorSubject(audioQuery.getValue().audioOutputDevices);
audioQuery.select((s) => s.audioOutputDevices).subscribe(audioOutputDevices$);

export const hasAudioOutputDevices$ = new BehaviorSubject(
  audioQuery.getValue().hasAudioOutputDevices,
);
audioQuery.select((s) => s.hasAudioOutputDevices).subscribe(hasAudioOutputDevices$);

export const activeSinkId$ = new BehaviorSubject(audioQuery.getValue().activeSinkId);
audioQuery.select((s) => s.activeSinkId).subscribe(activeSinkId$);

export const localAudioTrack$ = new BehaviorSubject(audioQuery.getValue().localAudioTrack);
audioQuery.select((s) => s.localAudioTrack).subscribe(localAudioTrack$);

export const isAudioEnabled$ = new BehaviorSubject(audioQuery.getValue().isAudioEnabled);
audioQuery.select((s) => s.isAudioEnabled).subscribe(isAudioEnabled$);
