import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { Consumer, Workshop, Session } from '@inovirtue/admin';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const createWorkshop = async (formData: Workshop): Promise<Workshop> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.post(`/Workshops`, formData);
  return data;
};

export const getWorkshops = async (companyId?: string): Promise<Workshop[]> => {
  const axiosInstance = await getApiClientAsync();
  const params = new URLSearchParams();
  if (companyId) {
    params.append('companyId', companyId);
  }
  const { data } = await axiosInstance.get<Workshop[]>('/Workshops', { params });
  return data;
};

export const getWorkshopById = async (id: string): Promise<Workshop> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Workshop>(`/Workshops/${id}`);
  return data;
};

export const getWorkshopMembersByIdAsync = async (workshopId: string): Promise<Consumer[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Consumer[]>(`/Workshops/${workshopId}/members`);

  return data;
};

export const getWorkshopSessions = async (id: string): Promise<Session[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Session[]>(`/Workshops/${id}/sessions`);
  return data;
};

export const addWorkshopMembers = async (workshopId: string, members: string[]): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/Workshops/${workshopId}/members`, members);
};

export const updateWorkshopMembers = async (
  workshopId: string,
  members: string[],
): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.put(`/Workshops/${workshopId}/members`, members);
};

export const publishWorkshop = async (workshopId: string): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/Workshops/${workshopId}/publish`);
};

export const deactivateWorkshop = async (workshopId: string): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/Workshops/${workshopId}/deactivate`);
};

export const updateWorkshop = async (
  workshopId: string,
  workshop: Partial<Workshop>,
): Promise<Workshop> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.put(`/Workshops/${workshopId}`, workshop);
  return data;
};
