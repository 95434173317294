import { useEffect } from 'react';

export const useOnDataLoadedAction = <T>(
  onDataLoaded?: (data: T) => Promise<void> | void,
  bareResponse?: T,
) => {
  useEffect(() => {
    if (bareResponse) onDataLoaded?.(bareResponse);
  }, [onDataLoaded, bareResponse]);
};
