import { ComponentType } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store/store';

// eslint-disable-next-line @typescript-eslint/ban-types
export function withStoreProvider<T extends {}>(
  WrappedComponent: ComponentType<T>,
): React.ComponentType<T> {
  const ComponentWithReduxProvider = (props: T): JSX.Element => (
    <Provider store={store}>
      <WrappedComponent {...props}></WrappedComponent>
    </Provider>
  );
  return ComponentWithReduxProvider;
}
