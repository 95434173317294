import './ParticipantInfo.Component.scss';
import { LocalAudioTrack, Participant, RemoteAudioTrack } from 'twilio-video';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator.Component';
import PinIcon from './PinIcon/PinIcon.Component';
import { ScreenShareIcon } from '@inovirtue/components';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks.Component';
import useParticipantDetails from '../../hooks/useParticipantDetails/useParticipantDetails';
import { hasCameraEnabled, setSelectedParticipant } from '../../store/participant';
import classNames from 'classnames';
import { t } from '@inovirtue/localization';
import { UserOutlined } from '@ant-design/icons';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar.Component';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../styles/breakpoints/breakpoints';
import { useMemo } from 'react';

interface ParticipantInfoProps {
  participant: Participant;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  shouldHideParticipant?: boolean;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
}

const ParticipantInfo = ({
  participant,
  isSelected,
  isLocalParticipant,
  shouldHideParticipant,
  videoOnly,
  enableScreenShare,
}: ParticipantInfoProps): JSX.Element => {
  const publications = usePublications(participant);
  const participantDetails = useParticipantDetails(participant);
  const isNameVisible = useMediaQuery({ minWidth: Breakpoints.lgBreak });

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const isVideoEnabled = hasCameraEnabled(participant.identity);
  const isScreenShareEnabled = publications.find((p) => p.trackName.includes('screen'));
  const hasAnyTracksEnabled = publications.some((s) => s.isTrackEnabled);
  const isVideoTrackVisible =
    !isParticipantReconnecting &&
    ((!!videoOnly && (isVideoEnabled || isScreenShareEnabled)) ||
      (!videoOnly && hasAnyTracksEnabled));
  const isAvatarVisible = !isVideoEnabled || !isVideoTrackVisible;

  const selectParticipant = (p: Participant) => {
    if (!isLocalParticipant) setSelectedParticipant(!isSelected ? p : null);
  };

  const participantContainerCls = classNames('participant-info', {
    'participant-info--hidden': shouldHideParticipant,
    'participant-info--video-enabled': isVideoEnabled,
  });

  const displayName = useMemo(() => {
    return participantDetails?.displayName ?? participant?.identity;
  }, [participant?.identity, participantDetails?.displayName]);

  return (
    <div
      className={participantContainerCls}
      onClick={() => {
        selectParticipant(participant);
      }}
    >
      {isVideoTrackVisible && (
        <div className='participant-info__video-track'>
          <ParticipantTracks
            participant={participant}
            videoOnly={videoOnly}
            enableScreenShare={enableScreenShare}
            isLocalParticipant={isLocalParticipant}
          />
        </div>
      )}
      {isAvatarVisible && (
        <div className='participant-info__avatar'>
          <div className='participant-info__avatar__name'>{displayName}</div>
          <ProfileAvatar
            src={participantDetails?.avatarUrl}
            size={{ xs: 40, sm: 40, md: 40, lg: 80, xl: 80, xxl: 80 }}
            icon={<UserOutlined />}
          />
        </div>
      )}
      {!isParticipantReconnecting && (
        <div className='participant-info__buttons'>
          <div className='participant-info__buttons__icon'>
            <AudioLevelIndicator audioTrack={audioTrack} />
          </div>
          {isSelected && (
            <div className='participant-info__buttons__icon'>
              <PinIcon />
            </div>
          )}
          {isScreenShareEnabled && (
            <div className='participant-info__buttons__icon'>
              <ScreenShareIcon />
            </div>
          )}
          {isNameVisible && isVideoEnabled && (
            <div className='participant-info__buttons__icon'>{displayName}</div>
          )}
        </div>
      )}
      {isParticipantReconnecting && (
        <div className='participant-info__reconnecting'>{t('videoCall:reconnecting')}</div>
      )}
    </div>
  );
};

export default ParticipantInfo;
