import './WorkshopsListView.scss';
import { Workshop, WorkshopsList } from '@inovirtue/admin';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import { WorkshopsListHeader } from '../../../Modules/Workshops/components/WorkshopsListHeader/WorkshopsListHeader';
import { useWorkshopList } from '../../../Modules/Workshops/hooks/useWorkshopList';
import { useHistory, useLocation } from 'react-router';
import { WorkshopListFilter } from '../../../Modules/Workshops/WorkshopListFilter/WorkshopListFilter';
import { Loader } from '@inovirtue/components';
import { useFlags } from 'launchdarkly-react-client-sdk';

const WorkshopsListView = () => {
  const { push } = useHistory();
  const { groupListFiltering } = useFlags();
  const query = new URLSearchParams(useLocation().search);
  const companyIdParam = query.get('companyId') as string;
  const { workshops, isProcessing, updateUrl } = useWorkshopList(companyIdParam);

  return (
    <BackofficeLayout headerContent={<WorkshopsListHeader />} showHeader>
      <div data-testid='workshops-list-view' className={'workshops-list-view'}>
        {groupListFiltering && (
          <WorkshopListFilter
            companyId={companyIdParam}
            onChanged={(fields) => updateUrl(fields)}
          />
        )}
        <div className='workshops-list-view__content'>
          {workshops &&
            (!isProcessing ? (
              <WorkshopsList
                groups={workshops}
                showCompany={true}
                onItemClick={(workshop: Workshop) => push(`/workshops/${workshop.id}/details`)}
              />
            ) : (
              <Loader spaceSize='large' spinSize='large' />
            ))}
        </div>
      </div>
    </BackofficeLayout>
  );
};

export default WorkshopsListView;
