import { Alert, InputNumber } from 'antd';
import './AssignedMonthlySessions.scss';
import { t } from '@inovirtue/localization';
import ValidationResult from '../../models/validationResult';
import { useFlags } from 'launchdarkly-react-client-sdk';

type AssignMonthlySessionsProps = {
  availableSessionCount?: number;
  availableGroupSessionCount?: number;
  onAvailableSessionsCountChange: (nextValue: number) => void;
  onAvailableGroupSessionsCountChange: (nextValue: number) => void;
  validationResult: ValidationResult;
  showValidationErrors: boolean;
};

const AssignMonthlySessions = ({
  availableSessionCount,
  availableGroupSessionCount,
  onAvailableSessionsCountChange,
  onAvailableGroupSessionsCountChange,
  validationResult,
  showValidationErrors,
}: AssignMonthlySessionsProps) => {
  const { numberOfGroupSessionsFlag } = useFlags();
  return (
    <div className='monthlySessions' data-testid='monthlySessions'>
      <div className='monthlySessions__section'>
        <p className='monthlySessions__heading'>{t('consumer:assign-monthly-sessions')}</p>
        <p className='monthlySessions__subheading'>
          {t('consumer:assign-monthly-sessions-subheading')}
        </p>
        <InputNumber
          className='monthlySessions__input'
          data-testid='monthlySessions__input'
          min={0}
          max={1000}
          value={availableSessionCount}
          onChange={(x) => onAvailableSessionsCountChange(x ?? 0)}
        />
      </div>
      {numberOfGroupSessionsFlag && (
        <div className='monthlySessions__section'>
          <p className='monthlySessions__heading'>{t('consumer:assign-monthly-group-sessions')}</p>
          <p className='monthlySessions__subheading'>
            {t('consumer:assign-monthly-group-sessions-subheading')}
          </p>
          <InputNumber
            className='monthlySessions__input'
            data-testid='monthlyGroupSessions__input'
            min={0}
            max={1000}
            value={availableGroupSessionCount}
            onChange={(x) => onAvailableGroupSessionsCountChange(x ?? 0)}
          />
        </div>
      )}
      {showValidationErrors && !validationResult.isValid && (
        <Alert
          className='monthlySessions__validation'
          message={validationResult.validationMessage}
          type='error'
          showIcon
        />
      )}
    </div>
  );
};

export default AssignMonthlySessions;
