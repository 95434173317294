import { Alert, Select } from 'antd';
import { t } from '@inovirtue/localization';
import {
  hasVideoInputDevices$,
  selectedVideoInputDeviceId$,
  setLocalVideoTrack,
  videoInputDevices$,
} from '../../../store/video';
import './VideoInputList.Component.scss';
import { isAcquiringMediaError$, isInitialized$ } from '../../../store';
import classNames from 'classnames';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import DeviceLabel from '../DeviceLabel/DeviceLabel';

const { Option } = Select;

export interface VideoInputListProps {
  className?: string;
}

const VideoInputList = ({ className }: VideoInputListProps): JSX.Element => {
  const isInitialized = useBehaviorSubjectState(isInitialized$);
  const isAcquiringMediaError = useBehaviorSubjectState(isAcquiringMediaError$);
  const videoInputDevices = useBehaviorSubjectState(videoInputDevices$);
  const hasVideoInputDevices = useBehaviorSubjectState(hasVideoInputDevices$);
  const selectedVideoInputDeviceId = useBehaviorSubjectState(selectedVideoInputDeviceId$);

  const value = hasVideoInputDevices
    ? selectedVideoInputDeviceId
    : t('videoCall:call-prep.nodevice-placeholder');

  const isErrorVisible = isInitialized && !hasVideoInputDevices;
  const errorMessage = isAcquiringMediaError
    ? t('videoCall:call-prep.videoinput-acquiring-error')
    : t('videoCall:call-prep.videoinput-error');
  const videoInputListCls = classNames('videoinputlist', {
    'videoinputlist--error': isErrorVisible,
  });

  return (
    <div {...{ className }}>
      <DeviceLabel text={t('videoCall:device.camera')} />
      <Select
        onChange={setLocalVideoTrack}
        value={value}
        placeholder={t('videoCall:call-prep.videoinput-placeholder')}
        disabled={!hasVideoInputDevices || !isInitialized}
        className={videoInputListCls}
        data-testid='videoinput-select'
      >
        {videoInputDevices.map((device) => (
          <Option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </Option>
        ))}
      </Select>
      {isErrorVisible && (
        <Alert
          className='videoinputlist__error'
          description={errorMessage}
          type='error'
          data-testid='videoinput-select-error'
        />
      )}
    </div>
  );
};

export default VideoInputList;
