import { useContext } from 'react';
import { LayoutContext } from '../../contexts/LayoutContext';

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('Please provide conversations layout context.');
  }

  return context;
};
