import './ConversationAvatar.scss';
import { HeartFilled, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import classNames from 'classnames';
import { useMemo } from 'react';
import { ConvoType } from '../../models/conversation';
import { getInitials } from '../../utils/getInitials/getInitials';

export interface ConversationAvatarProps {
  title: string;
  avatarUrl?: string;
  type?: ConvoType;
  className?: string;
}

export const ConversationAvatar = ({
  title,
  avatarUrl,
  type,
  className,
}: ConversationAvatarProps): JSX.Element => {
  const isWorkshop = useMemo(() => type === ConvoType.Workshop, [type]);
  const isSupport = useMemo(() => type === ConvoType.Support, [type]);

  const initials = useMemo(() => {
    return getInitials(title);
  }, [title]);
  const icon = useMemo(() => (isSupport ? <HeartFilled /> : <UserOutlined />), [isSupport]);

  const conversationAvatarCls = classNames(
    'conversation-avatar',
    {
      'conversation-avatar--workshop': type === ConvoType.Workshop,
      'conversation-avatar--support': type === ConvoType.Support,
    },
    className,
  );

  return (
    <Avatar
      className={conversationAvatarCls}
      src={avatarUrl}
      size={40}
      {...(!isWorkshop || !initials ? { icon } : {})}
    >
      {initials}
    </Avatar>
  );
};
