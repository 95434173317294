import { Button as Btn, ButtonProps as BtnProps, Spin, SpinProps } from 'antd';
import classNames from 'classnames';
import './Button.scss';

export interface ButtonProps extends Omit<BtnProps, 'loading'> {
  spinning?: boolean;
  delay?: number;
  innerBtnClassName?: string;
  spinClassName?: string;
  indicator?: SpinProps['indicator'];
}

type MappedSizes = {
  [Property in Exclude<BtnProps['size'], undefined>]: Exclude<SpinProps['size'], undefined>;
};

const mappedSizes: MappedSizes = {
  small: 'small',
  middle: 'default',
  large: 'default',
};

export const Button = ({
  children,
  spinning = false,
  delay,
  indicator,
  size = 'middle',
  className,
  innerBtnClassName,
  spinClassName,
  block,
  ...props
}: ButtonProps): JSX.Element => {
  const wrapperCls = classNames('button', { ['button--block']: block }, className);
  const btnCls = classNames(
    'button__inner',
    { ['button__inner--primary']: props.type == 'primary' },
    { ['button__inner--text']: props.type == 'text' },
    innerBtnClassName,
  );

  return (
    <>
      <Spin
        {...{ spinning, delay, indicator }}
        size={mappedSizes[size]}
        wrapperClassName={wrapperCls}
        className={spinClassName}
        data-testid='custom-btn'
      >
        <Btn className={btnCls} {...{ size, block, ...props }}>
          {children}
        </Btn>
      </Spin>
    </>
  );
};
