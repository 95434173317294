import './WorkshopDetailsView.scss';
import {
  TabKey,
  WorkshopDetailsTabs,
  LayoutHeader,
  Workshop,
  useCanPublishWorkshop,
  useCanJoinWorkshopChat,
  WorkshopPublishButton,
  WorkshopChatButton,
  useCanDeactivateWorkshop,
  WorkshopDeactivateButton,
  WarningBar,
} from '@inovirtue/admin';
import { Loader, showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useHistory } from 'react-router';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import { useWorkshopDeactivate } from '../../../Modules/Workshops/hooks/useWorkshopDeactivate';
import { useWorkshopPublish } from '../../../Modules/Workshops/hooks/useWorkshopPublish';
import routes from '../../routes/constants/Routes';
import { useWorkshopChatButton } from '../../../Modules/Chat/hooks/useWorkshopChatButton/useWorkshopChatButton';

type WorkshopDetailsViewProps = {
  currentTab: TabKey;
  workshop?: Workshop;
  children?: React.ReactElement;
  refreshRequest?: () => void;
};

const WorkshopDetailsView = ({
  currentTab,
  children,
  workshop,
  refreshRequest,
}: WorkshopDetailsViewProps) => {
  const { push } = useHistory();
  const { canPublish, showPublish } = useCanPublishWorkshop(workshop);
  const { canJoinGroupChat, showJoinGroupChat } = useCanJoinWorkshopChat(workshop);
  const { publish } = useWorkshopPublish();
  const { canDeactivate, showDeactivate } = useCanDeactivateWorkshop(workshop);
  const { deactivate } = useWorkshopDeactivate();
  const onWorkshopChatBtnClick = useWorkshopChatButton();

  const content = workshop && children ? children : <Loader spaceSize='large' spinSize='large' />;
  return (
    <BackofficeLayout
      headerContent={
        <>
          <LayoutHeader
            title={
              <div className='workshops-list-view__header'>
                <span className='workshops-list-view__header__text'>
                  {workshop?.name || t('common:groups')}
                </span>
                <div className='workshops-list__actions'>
                  {workshop && (
                    <WorkshopDeactivateButton
                      workshop={workshop}
                      visible={showDeactivate}
                      enabled={canDeactivate}
                      onDeactivate={async (w: Workshop) => {
                        await deactivate(w.id);
                        push(routes.WorkshopsList);
                        showToast(t('group:toast.workshop-deactivated'));
                      }}
                    />
                  )}
                  {workshop && (
                    <WorkshopPublishButton
                      workshop={workshop}
                      visible={showPublish}
                      enabled={canPublish}
                      onPublish={async (g: Workshop) => {
                        await publish(g.id);
                        showToast(t('group:toast.workshop-published'));
                        refreshRequest?.();
                      }}
                    />
                  )}
                  {workshop && (
                    <WorkshopChatButton
                      onClick={() => onWorkshopChatBtnClick(workshop.id)}
                      visible={showJoinGroupChat}
                      disabled={!canJoinGroupChat}
                    />
                  )}
                </div>
              </div>
            }
            subtitle={
              workshop?.status === 'Deactivated' ? (
                <WarningBar
                  highlitedText={t('group:warning.workshop-deactivated.highlited-text')}
                  text={t('group:warning.workshop-deactivated.text')}
                  level='warning'
                />
              ) : (
                t('group:group-details-subheader')
              )
            }
            onNavigate={push}
          />
          {workshop && (
            <WorkshopDetailsTabs id={workshop.id} currentTab={currentTab} onChange={push} />
          )}
        </>
      }
      showHeader>
      {content}
    </BackofficeLayout>
  );
};

export default WorkshopDetailsView;
