import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { t } from '@inovirtue/localization';
import { sessionAvatarUrl$, sessionDisplayName$ } from '../../store/video-call-session';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar.Component';
import './WaitingRoomPlaceholder.Component.scss';

const WaitingRoomPlaceholder = (): JSX.Element => {
  const sessionDisplayName = useBehaviorSubjectState(sessionDisplayName$);
  const sessionAvatarUrl = useBehaviorSubjectState(sessionAvatarUrl$);

  return (
    <>
      <div className='waitingroom-placeholder__participantBadge'>{sessionDisplayName}</div>
      <div className='waitingroom-placeholder'>
        <ProfileAvatar src={sessionAvatarUrl} size={200} altText={sessionDisplayName} />
        <div className='waitingroom-placeholder__details'>
          <span>{t('videoCall:call-waitingroom.heading', { userName: sessionDisplayName })}</span>
          <br />
          <span>
            {t('videoCall:call-waitingroom.description', { userName: sessionDisplayName })}
          </span>
        </div>
      </div>
    </>
  );
};

export default WaitingRoomPlaceholder;
