import { useConvoSelector } from '../../hooks/storeHooks/storeHooks';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';
import { selectAllParticipants, selectParticipantsByConvoId } from '../../store/participantsSlice';
import { ConversationBadge } from '../ConversationBadge/ConversationBadge';
import './ConversationHeader.scss';
import { useMemo } from 'react';
import { ParticipantsAvatarGroup } from '../ParticipantsAvatarGroup/ParticipantsAvatarGroup';
import { useConversationDetails } from '../../hooks/useConversationDetails/useConversationDetails';
import { ConversationAvatar } from '../ConversationAvatar/ConversationAvatar';
import { useConversationType } from '../../hooks/useConversationType/useConversationType';
import { NavButton, NavButtonProps } from '../NavButton/NavButton';
import classNames from 'classnames';

export interface ConversationsHeaderProps extends NavButtonProps {
  isActive?: boolean;
  isRole?: boolean;
  isCompanyName?: boolean;
}

export const ConversationHeader = ({
  isActive = true,
  isRole,
  isCompanyName,
  isBack = true,
  ...navButtonProps
}: ConversationsHeaderProps): JSX.Element => {
  const { selectedConvoSid, isTabView } = useLayoutContext();
  const { title, role, companyName, avatarUrl } = useConversationDetails(selectedConvoSid);
  const { type, isWorkshop } = useConversationType(selectedConvoSid);

  const participantEntity = useConvoSelector(selectParticipantsByConvoId(selectedConvoSid));
  const participants = useMemo(
    () => selectAllParticipants(participantEntity?.participatns),

    [participantEntity],
  );

  const conversationHeaderCls = classNames('conversation-header', {
    'conversation-header--tabview': isTabView,
  });

  return (
    <article className={conversationHeaderCls}>
      {isTabView && (
        <NavButton className='conversation-header__btn' {...{ isBack, ...navButtonProps }} />
      )}
      <ConversationAvatar {...{ title: title || '', type, avatarUrl }} />
      <div className='conversation-header__header'>
        <h1 className='conversation-header__title'>{title}</h1>
        <ConversationBadge
          className='conversation-header__badge'
          isActive={isActive}
          {...(isRole && role ? { role } : {})}
          {...(isCompanyName && companyName ? { companyName } : {})}
        />
        {isWorkshop && (
          <ParticipantsAvatarGroup
            className='conversation-header__avatar-group'
            participants={participants}
          />
        )}
      </div>
    </article>
  );
};
