import { Convo } from '../../models/conversation';
import { Message } from '../../models/message';

export const conversationsComparer = (convoA: Convo, convoB: Convo) => {
  const convoDateA = convoA.lastMessage?.dateCreated || convoA.dateUpdated;
  const convoDateB = convoB.lastMessage?.dateCreated || convoB.dateUpdated;

  if (convoDateA === null) return 1;
  if (convoDateB === null) return -1;

  return new Date(convoDateB).getTime() - new Date(convoDateA).getTime();
};

export const messagesComparer = (msgA: Message, msgB: Message) => {
  return msgA.index - msgB.index;
};
