import { useObservableWithProgress } from '@inovirtue/hooks';
import { UserProfile } from '@inovirtue/profile';
import { useCallback, useEffect, useState } from 'react';
import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { getProviderProfileWithInterests } from '../api/Providers.Api';

export const useProviderDetails = (profileName: string) => {
  const fetchProviderCallback = useCallback(
    () => getProviderProfileWithInterests(profileName),
    [profileName],
  );
  const { isProcessing, data } = useObservableWithProgress<UserProfile>(fetchProviderCallback);
  const [provider, setProvider] = useState<UserProfile>();

  useEffect(() => {
    if (isProcessing) enableFullPageLoader();
    else disableFullPageLoader();
  }, [isProcessing]);

  useEffect(() => {
    setProvider(data);
  }, [data]);

  return {
    provider,
    isProcessing,
  };
};
