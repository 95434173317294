import { Modal } from '@inovirtue/components';
import { useHistory } from 'react-router';
import CreateGroup from '../../../Modules/Workshops/components/CreateWorkshop/CreateWorkshop';
import routes from '../../routes/constants/Routes';

const WorkshopCreateView = () => {
  const history = useHistory();

  return (
    <div data-testid={'create-group-view'}>
      <Modal isFullScreen={true} visible={true} onClose={() => history.push(routes.WorkshopsList)}>
        <CreateGroup />
      </Modal>
    </div>
  );
};

export default WorkshopCreateView;
