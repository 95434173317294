import React from 'react';
import { GeneralErrorIcon } from '../../Icons/GeneralErrorIcon';
import { NotFoundIcon } from '../../Icons/NotFoundIcon';
import './ErrorMessage.scss';

type ErrorMessageProps = {
  status: number;
};

const getErrorMessageIcon = (status: number) => {
  switch (status) {
    case 404:
      return <NotFoundIcon />;
    default:
      return <GeneralErrorIcon />;
  }
};

export const ErrorMessage = ({ status }: ErrorMessageProps) => (
  <div className='errorMessage' data-testid={status}>
    {getErrorMessageIcon(status)}
    <p className='errorMessage__heading'>Oops! A problem occured whilst loading the page</p>
    <p className='errorMessage__subheading'>
      Try refreshing the page or check your network connection
    </p>
  </div>
);
