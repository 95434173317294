import config from '../../../config';
import { Department } from '@inovirtue/company';
import { getApiClient } from '../../Common/api/apiClient';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getDepartments = async (companyId: string): Promise<Department[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Department[]>(`/companies/${companyId}/departments`);
  return data;
};

export const saveDepartments = async (companyId: string, departments: string[]): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/companies/${companyId}/departments`, departments);
};
