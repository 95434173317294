import './WorkshopEditMembers.scss';
import { Alert, Avatar, Space, Tag } from 'antd';
import { useEditWorkshopMembers } from '../../hooks/useEditWorkshopMembers';
import { WorkshopMemberSelect } from '../WorkshopMemberSelect/WorkshopMemberSelect';
import { Consumer } from '../../model/Consumer';
import { CloseCircleFilled } from '@ant-design/icons';
import { t } from '@inovirtue/localization';

export type WorkshopEditMembersProps = {
  allEmployees?: Consumer[];
  initialMembers?: string[];
  onChanged: (employees: Consumer[]) => void;
};

export const WorkshopEditMembers = ({
  allEmployees = [],
  initialMembers = [],
  onChanged,
}: WorkshopEditMembersProps) => {
  const { employeesToSelect, currentMembers, removeMember, addMember, isValid } =
    useEditWorkshopMembers(allEmployees, initialMembers, onChanged);
  return (
    <div className='edit-group-members'>
      <div className='edit-group-members__add'>
        <WorkshopMemberSelect employees={employeesToSelect} onSelect={addMember} canAdd={isValid} />
      </div>
      {!isValid && (
        <Alert
          className='edit-group-members__limit-reached'
          message={t('group:edit-group-members.limit-reached')}
          type='info'
        />
      )}
      <div className='edit-group-members__list'>
        {currentMembers.map((e) => (
          <Tag className='edit-group-members__item' key={e.name}>
            <Space>
              <Avatar size='small' src={e.avatarUrl} />
              {e.displayName}
              <CloseCircleFilled
                onClick={() => {
                  removeMember(e);
                }}
              />
            </Space>
          </Tag>
        ))}
      </div>
    </div>
  );
};
