import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import commonEn from './locales/en/common.json';
import commonDe from './locales/de/common.json';
import profileEn from './locales/en/profile.json';
import profileDe from './locales/de/profile.json';
import errorsEn from './locales/en/errors.json';
import errorsDe from './locales/de/errors.json';
import landingEn from './locales/en/landing.json';
import landingDe from './locales/de/landing.json';
import studioEn from './locales/en/studio.json';
import studioDe from './locales/de/studio.json';
import watchingEn from './locales/en/watching.json';
import watchingDe from './locales/de/watching.json';
import notificationsEn from './locales/en/notifications.json';
import notificationsDe from './locales/de/notifications.json';
import videoCallEn from './locales/en/videoCall.json';
import videoCallDe from './locales/de/videoCall.json';
import schedulerEn from './locales/en/scheduler.json';
import schedulerDe from './locales/de/scheduler.json';
import subscriptionsEn from './locales/en/subscriptions.json';
import subscriptionsDe from './locales/de/subscriptions.json';
import tagsEn from './locales/en/tags.json';
import tagsDe from './locales/de/tags.json';
import onboardingEn from './locales/en/onboarding.json';
import onboardingDe from './locales/de/onboarding.json';
import homeEn from './locales/en/home.json';
import homeDe from './locales/de/home.json';
import consumerDe from './locales/de/consumer.json';
import consumerEn from './locales/en/consumer.json';
import marketingDe from './locales/de/marketing.json';
import marketingEn from './locales/en/marketing.json';
import companyEn from './locales/en/company.json';
import companyDe from './locales/de/company.json';
import cockpitEn from './locales/en/cockpit.json';
import cockpitDe from './locales/de/cockpit.json';
import chatEn from './locales/en/chat.json';
import chatDe from './locales/de/chat.json';
import tableEn from './locales/en/table.json';
import tableDe from './locales/de/table.json';
import groupEn from './locales/en/group.json';
import groupDe from './locales/de/group.json';
import partnersDe from './locales/de/partners.json';
import partnersEn from './locales/en/partners.json';

const i18n = i18next.use(LanguageDetector).use(initReactI18next);

i18n.init({
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
  },
  resources: {
    en: {
      common: commonEn,
      profile: profileEn,
      errors: errorsEn,
      landing: landingEn,
      studio: studioEn,
      watching: watchingEn,
      notifications: notificationsEn,
      videoCall: videoCallEn,
      scheduler: schedulerEn,
      subscriptions: subscriptionsEn,
      tags: tagsEn,
      onboarding: onboardingEn,
      home: homeEn,
      consumer: consumerEn,
      marketing: marketingEn,
      company: companyEn,
      cockpit: cockpitEn,
      chat: chatEn,
      table: tableEn,
      group: groupEn,
      partners: partnersEn,
    },
    de: {
      common: commonDe,
      profile: profileDe,
      errors: errorsDe,
      landing: landingDe,
      studio: studioDe,
      watching: watchingDe,
      notifications: notificationsDe,
      videoCall: videoCallDe,
      scheduler: schedulerDe,
      subscriptions: subscriptionsDe,
      tags: tagsDe,
      onboarding: onboardingDe,
      home: homeDe,
      consumer: consumerDe,
      marketing: marketingDe,
      company: companyDe,
      cockpit: cockpitDe,
      chat: chatDe,
      table: tableDe,
      group: groupDe,
      partners: partnersDe,
    },
  },
  ns: ['common', 'consumer'],
  defaultNS: 'common',
});

export const { t, changeLanguage, resolvedLanguage } = i18n;
export default i18n;
