import { Provider } from '@inovirtue/admin';
import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import usePartnerDetails from '../../../App/hooks/usePartnerDetails';
import routes from '../../../App/routes/constants/Routes';

import { usePartnerProviders } from './usePartnerProviders';
import { usePartnerProvidersUpdate } from './usePartnerProvidersUpdate';

export const usePartnerEditProviders = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { partner, isLoading: partnerIsLoading } = usePartnerDetails(id);
  const [selectedProviders, setSelectedProviders] = useState<Provider[]>([]);

  const { providers, isProcessing: providersAreLoading } = usePartnerProviders(id);

  const navigateBack = useCallback(
    () => push(generatePath(routes.PartnerDetailsProviders, { id })),
    [id, push],
  );

  const updateSelectedMembers = useCallback(
    (newMembers) => setSelectedProviders([...newMembers]),
    [],
  );

  const isLoading = useMemo(
    () => partnerIsLoading && providersAreLoading,
    [partnerIsLoading, providersAreLoading],
  );

  const { updateProviders } = usePartnerProvidersUpdate();

  const saveChanges = useCallback(async () => {
    try {
      await updateProviders(
        id,
        selectedProviders.map((x) => x.name),
      );
      showToast(t('common:save-success'));
      navigateBack();
    } catch (error) {
      showToast(t('errors:unhandled-error'));
    }
  }, [id, selectedProviders, navigateBack, updateProviders]);

  return {
    partner,
    isLoading,
    providers,
    selectedProviders,
    updateSelectedMembers,
    saveChanges,
    navigateBack,
  };
};
