import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useBorderScroll = (): [boolean, MutableRefObject<null>, MutableRefObject<null>] => {
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    const onScroll: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setIsBorderVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(onScroll, {
      root: container,
      rootMargin: '1% 0px -101% 0px',
      threshold: 0,
    });
    if (content) observer.observe(content);

    return () => {
      if (content) observer.unobserve(content);
    };
  }, []);

  return [isBorderVisible, contentRef, containerRef];
};

export default useBorderScroll;
