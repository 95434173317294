import { useCallback, useEffect, useState } from 'react';
import { Invitation, Invitations } from '@inovirtue/admin';
import { Department, Position } from '@inovirtue/company';
import { getPositions, savePositions } from '../../../Positions/api/Position.api';
import { showToast } from '@inovirtue/components';
import { t } from 'i18next';
import { sendInvitations } from '../../api/invitationsApi';
import config from '../../../../config';
import { useHistory } from 'react-router';
import routes from '../../../../App/routes/constants/Routes';
import {
  getDepartments,
  saveDepartments,
} from '../../../../Modules/Departments/api/Department.api';

const useInvite = () => {
  const [choosenCompany, setChoosenCompany] = useState<string>();
  const [invitationList, setInvitationList] = useState<Invitations>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const history = useHistory();

  const onClose = useCallback(() => {
    setInvitationList([]);
    history.push(routes.Employees);
  }, [setInvitationList, history]);

  const fetchPositions = useCallback(async (companyId: string) => {
    try {
      const data = await getPositions(companyId);
      setPositions(data);
    } catch (err) {
      showToast(t('errors:unhandled-error'));
    }
  }, []);

  const fetchDepartments = useCallback(async (companyId: string) => {
    try {
      const data = await getDepartments(companyId);
      setDepartments(data);
    } catch (err) {
      showToast(t('errors:unhandled-error'));
    }
  }, []);

  const submitSendInvitations = useCallback(async () => {
    try {
      await sendInvitations(choosenCompany as string, {
        invitations: invitationList,
        inviteRedirectUrl: `${config.otherApps.customerApp}/sign-up/consumer`,
      });
      showToast(t('common:save-success'));
      onClose();
    } catch (err) {
      showToast(t('errors:unhandled-error'));
    }
  }, [invitationList, choosenCompany, onClose]);

  const submitPositions = useCallback(
    async (newPositions: string[]) => {
      try {
        await savePositions(choosenCompany as string, newPositions);
        showToast(t('common:save-success'));
      } catch (error) {
        showToast(t('errors:unhandled-error'));
      }
    },
    [choosenCompany],
  );

  const submitDepartments = useCallback(
    async (newDepartments: string[]) => {
      try {
        await saveDepartments(choosenCompany as string, newDepartments);
        showToast(t('common:save-success'));
      } catch (error) {
        showToast(t('errors:unhandled-error'));
      }
    },
    [choosenCompany],
  );

  const addInvitation = useCallback(
    (inv: Invitation) => {
      setInvitationList([...invitationList, inv]);
    },
    [invitationList],
  );

  const removeInvitation = useCallback(
    (inv: Invitation) => {
      setInvitationList(invitationList.filter((el) => inv.email !== el.email));
    },
    [invitationList],
  );

  useEffect(() => {
    if (!choosenCompany) return;

    fetchPositions(choosenCompany);
    fetchDepartments(choosenCompany);
  }, [choosenCompany, fetchDepartments, fetchPositions]);

  return {
    choosenCompany,
    setChoosenCompany,
    invitationList,
    addInvitation,
    removeInvitation,
    positions,
    departments,
    submitSendInvitations,
    fetchPositions,
    submitPositions,
    fetchDepartments,
    submitDepartments,
    onClose,
    clearInvitationsList: () => setInvitationList([]),
  };
};

export default useInvite;
