import { Position } from '@inovirtue/company';
import { showToast } from '@inovirtue/components';
import { usePromiseWithProgress } from '@inovirtue/hooks';
import { t } from 'i18next';
import { useCallback } from 'react';
import { getPositions, savePositions } from '../api/Position.api';

export const usePositions = (companyId: string) => {
  const fetchPositions = useCallback(() => getPositions(companyId), [companyId]);
  const positionsResponse = usePromiseWithProgress<Position[]>(fetchPositions);
  const submitPositions = useCallback(
    async (newPositions: string[]) => {
      try {
        await savePositions(companyId as string, newPositions);
        showToast(t('common:save-success'));
      } catch (error) {
        showToast(t('errors:unhandled-error'));
      }
    },
    [companyId],
  );
  return {
    submitPositions,
    ...positionsResponse,
  };
};
