import { useQuery } from 'react-query';
import { getSessionParticipants } from '../../api/sessionsApi';

export const useSessionParticipants = (sessionId: string) => {
  const { data: sessionParticipants, ...rest } = useQuery(
    [`sessionParticipants_${sessionId}`],
    () => getSessionParticipants(sessionId),
    {
      enabled: !!sessionId,
      refetchOnWindowFocus: false,
    },
  );

  return { sessionParticipants, ...rest };
};
