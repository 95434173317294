import { useEffect, useRef, useState } from 'react';
import { Client, ConnectionState, Conversation, Participant } from '@twilio/conversations';
import { RequestToken } from '../../models/token';
import { useToken } from '../useToken/useToken';
import { useConvoDispatch, useConvoSelector } from '../storeHooks/storeHooks';
import {
  selectConnectionState,
  selectIdentity,
  setConnectionState,
  setIdentity,
} from '../../store/commonSlice';
import {
  assembleConversation,
  assembleSubscribedConversations,
  upsertConversation,
} from '../../store/conversationsSlice';
import { addMessage } from '../../store/messagesSlice';
import {
  addParticipant,
  removeParticipant,
  upsertParticipant,
} from '../../store/participantsSlice';
import { fetchHorizon } from '../../store/horizonSlice';

export const useConversationsInit = (requestToken: RequestToken) => {
  const isIdentityInitilized = useRef<boolean>(false);
  const [client, setClient] = useState<Client | null>(null);
  const connectionState = useConvoSelector(selectConnectionState);

  const { token, getToken } = useToken(requestToken);

  const dispatch = useConvoDispatch();
  const identity = useConvoSelector(selectIdentity);

  useEffect(() => {
    if (!token) return;

    setClient(new Client(token));
  }, [token]);

  useEffect(() => {
    if (!client) return;

    client.on('connectionStateChanged', (state: ConnectionState) =>
      dispatch(setConnectionState(state)),
    );

    client.on('tokenExpired', getToken);

    client.on('conversationAdded', (conversation: Conversation) => {
      dispatch(assembleConversation(conversation));
      dispatch(fetchHorizon(conversation));
    });

    client.on('conversationUpdated', ({ conversation }: { conversation: Conversation }) => {
      dispatch(upsertConversation(conversation));
      dispatch(fetchHorizon(conversation));
    });

    client.on('messageAdded', (message) => {
      dispatch(addMessage({ convoSid: message.conversation.sid, message, identity }));
    });

    client.on('participantJoined', (participant: Participant) => {
      dispatch(addParticipant({ convoSid: participant.conversation.sid, participant }));
    });
    client.on('participantUpdated', ({ participant, updateReasons }) => {
      if (updateReasons.includes('attributes')) {
        dispatch(upsertParticipant({ convoSid: participant.conversation.sid, participant }));
      }
    });
    client.on('participantLeft', (participant: Participant) => {
      dispatch(
        removeParticipant({
          convoSid: participant.conversation.sid,
          participantSid: participant.sid,
        }),
      );
    });

    client.on('userUpdated', ({ user }) => {
      if (!isIdentityInitilized.current) {
        dispatch(setIdentity(user.identity));
        isIdentityInitilized.current = true;
      }
    });

    return () => {
      client.removeAllListeners();
    };
  }, [client, getToken, dispatch, identity]);

  useEffect(() => {
    if (!client || connectionState !== 'connected') return;

    dispatch(assembleSubscribedConversations(client));
  }, [client, connectionState, dispatch]);

  return client;
};
