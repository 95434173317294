import React from 'react';
import { Avatar, Tooltip } from 'antd';

import './AvatarCollection.scss';
import { UserOutlined } from '@ant-design/icons';

export type AvatarCollectionObject = {
  key: string;
  avatarUrl?: string;
  displayName: string;
  isIcon?: boolean;
};

export type AvatarCollectionProps = {
  avatars: AvatarCollectionObject[];
};

export const AvatarCollection = ({ avatars }: AvatarCollectionProps): JSX.Element => {
  return (
    <Avatar.Group>
      {avatars.map((m) => (
        <Tooltip key={m.key} placement='top' title={m.displayName}>
          <Avatar
            src={m.avatarUrl}
            icon={<UserOutlined className={m.isIcon ? 'avatar-collection__avatar-icon' : ''} />}
            className={m.isIcon ? 'avatar-collection__avatar-icon-background' : ''}
          />
          {avatars.length === 1 && (
            <span className='avatar-collection__name'>{avatars[0].displayName}</span>
          )}
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};
