import { TimeSlot } from '@inovirtue/booking';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useWorkshopDetails } from '../../../Workshops/hooks/useWorkshopDetails';
import useConfirmBooking from '../useConfirmBooking/useConfirmBooking';
import useGetTimeSlots from '../useGetTimeSlosts/useGetTimeSlots';
import useManageSessionSlots from '../useManageSessionSlots/useManageSessionSlots';

const useBookWorkshops = () => {
  const [coachName, setCoachName] = useState<string>();
  const { id } = useParams<{ id: string }>();
  const { workshop } = useWorkshopDetails(id);
  const { timeSlots, getTimeSlots, setTimeSlots } = useGetTimeSlots();
  const {
    sessionsSlots,
    setActiveSessionSlot,
    clearSessionsSlots,
    enableSessionSlots,
    onChooseSlot,
    setErrorToSlot,
  } = useManageSessionSlots(workshop);

  const { confirmBookingSlots, isChecking } = useConfirmBooking(
    id,
    coachName as string,
    sessionsSlots,
  );

  const clearCoach = () => {
    setCoachName(undefined);
    setTimeSlots([]);
    clearSessionsSlots();
  };

  const selectCoach = useCallback(
    (coach: string) => {
      setCoachName(coach);
      enableSessionSlots();
    },
    [enableSessionSlots, setCoachName],
  );

  const chooseTimeSlot = useCallback(
    (slot: TimeSlot) => {
      setTimeSlots(
        timeSlots.map((el) =>
          new Date(el.date as unknown as string).toDateString() ===
          new Date(slot.startDateTime as unknown as string).toDateString()
            ? {
                ...el,
                timeSlots: el.timeSlots.filter((timeSlot) => {
                  return timeSlot.startDateTime !== slot.startDateTime;
                }),
              }
            : el,
        ),
      );
      onChooseSlot(slot);
    },
    [onChooseSlot, timeSlots, setTimeSlots],
  );

  const confirmBookAction = async () => {
    const data = await confirmBookingSlots();
    if (data) setErrorToSlot(data);
  };

  useEffect(() => {
    if (coachName) getTimeSlots(coachName)();
  }, [coachName, getTimeSlots]);

  return {
    timeSlots,
    sessionsSlots,
    isChecking,
    clearCoach,
    selectCoach,
    onChooseSlot: chooseTimeSlot,
    setActiveSessionSlot,
    getSlots: getTimeSlots(coachName as string),
    confirmBookAction,
  };
};

export default useBookWorkshops;
