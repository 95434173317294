import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import { Calendar, CalendarProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import useControlCallendar from '../../hooks/useControlCallendar/useControlCallendar';
import { CalendarCell } from '../CalendarCell/CalendarCell';
import './BookCalendarComponent.scss';

export interface BookCalendarComponentProps extends CalendarProps<Date> {
  onDaySelect: (date: Dayjs) => void;
  onMontChange: (date: Dayjs) => void | Promise<void>;
  availableDays: string[];
  availableMonthsAhead?: number;
  currentDate?: Dayjs;
}
export const BookCalendarComponent = ({
  onDaySelect,
  onMontChange,
  availableDays,
  availableMonthsAhead,
  locale,
  currentDate,
}: BookCalendarComponentProps): JSX.Element => {
  const { monthsDiff, insideDate, onMonthChange } = useControlCallendar(onMontChange, currentDate);
  const isRightArrowVisible = !availableMonthsAhead || availableMonthsAhead > monthsDiff;

  return (
    <Calendar
      className='bookCalendar-component'
      value={insideDate}
      cellRender={(date) => {
        const dayDate = dayjs(date as Dayjs).format('YYYY-MM-DD');
        const isDisabled = !availableDays.includes(dayDate);

        return <CalendarCell dayNumber={dayjs(date as Dayjs).format('D')} isDisabled={isDisabled} />;
      }}
      fullscreen={false}
      locale={locale}
      onSelect={(newDate) => {
        const dayDate = dayjs(newDate as Dayjs).format('YYYY-MM-DD');
        const isDisabled = !availableDays.includes(dayDate);
        if (!availableDays.length || isDisabled) return;
        onDaySelect(newDate as Dayjs);
      }}
      headerRender={({ value }) => {
        return (
          <div className='bookCalendar-component-header'>
            <div style={{ width: '10%' }}>
              {!dayjs().isSame(value as Dayjs, 'month') && (
                <Button
                  data-testid='previous-month'
                  shape='square'
                  className='bookCalendar-component-header-button'
                  icon={<LeftOutlined />}
                  onClick={() => {
                    onMonthChange(value as Dayjs, -1);
                  }}
                />
              )}
            </div>
            <div className='bookCalendar-component-header-month' style={{ width: '80%' }}>
              {dayjs(value as Dayjs).format('MMMM YYYY')}
            </div>
            <div style={{ width: '10%' }}>
              {isRightArrowVisible && (
                <Button
                  data-testid='next-month'
                  shape='square'
                  className='bookCalendar-component-header-button'
                  icon={<RightOutlined />}
                  onClick={() => {
                    onMonthChange(value as Dayjs, 1);
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
