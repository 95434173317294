import { Tag, Typography } from 'antd';
import getTagTranslation from '../../helpers/getTagTranslation';
import './TagList.scss';

const { Title } = Typography;

export interface TagListProps {
  title: string;
  tags: string[];
}

const TagList = ({ title, tags }: TagListProps): JSX.Element => {
  return (
    <div className='tag-list'>
      <Title className='tag-list__title' level={2}>
        {title}
      </Title>
      <div className='tag-list__container'>
        {tags.map((tag) => (
          <Tag className='tag-list__tag' key={tag}>
            {getTagTranslation(tag)}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default TagList;
