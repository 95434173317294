import React, { useCallback, useMemo, useState } from 'react';
import { t } from '@inovirtue/localization';
import { Dropdown, Menu, Tag } from 'antd';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { DEFAULT_PAGE_SIZE, SearchablePaginatedTable } from '@inovirtue/table';
import Invitation from '../models/Invitation';
import { getInvitations } from '../api/Employees.Api';
import { dateFormat } from '../const/formats';
import { EmployeeTablesTabs } from '../components/EmployeeTableTabs/EmployeeTablesTabs';
import routes from '../../../App/routes/constants/Routes';
import { updatePendingEmployeesTotal } from '../state';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import useCompanyFilter from '../hooks/useCompanyFilter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import InviteEmployeesButton from '../../Invitations/components/InviteEmployeesButton/InviteEmployeesButton';
import './PendingEmployees.scss';
import { MoreOutlined } from '@ant-design/icons';
import { resendInvitation } from '../../../Modules/Invitations/api/invitationsApi';
import config from '../../../config';
import { showToast } from '@inovirtue/components';
import { Invitation as InvitationDto } from '@inovirtue/admin';

const getColumns = (
  companiesMemo: { text: string | undefined; value: string }[],
  companyFilterFlag: boolean,
  onResendInvite: (_companyId: string, _invitation: InvitationDto) => Promise<void>,
): ColumnsType<Invitation> => [
  {
    title: t('company:employees:invitations-table.name'),
    key: 'name',
    render: (invitation: Invitation) => (
      <div>
        <div className='pending-employees__columns__name__name'>
          {invitation.firstName} {invitation.lastName}
        </div>
        <div className='pending-employees__columns__name__email'>{invitation.email}</div>
      </div>
    ),
    width: 300,
  },
  {
    title: t('company:employees:invitations-table.company'),
    key: 'company',
    render: (invitation: Invitation) => (
      <div>
        <span>{invitation.company?.name}</span>
      </div>
    ),
    filters: companyFilterFlag ? companiesMemo : undefined,
    filterMultiple: false,
    filterSearch: true,
  },
  {
    title: t('company:employees:invitations-table.position'),
    key: 'position',
    render: (invitation: Invitation) => (
      <div>
        <span>{invitation.position?.name}</span>
      </div>
    ),
  },
  {
    title: t('company:employees:invitations-table.invite-sent'),
    key: 'inviteSent',
    render: (invitation: Invitation) => (
      <div className='pending-employees__columns__invite-sent'>
        {invitation.lastDateSent && dayjs(new Date(invitation.lastDateSent)).format(dateFormat)}
      </div>
    ),
  },
  {
    title: t('company:employees:invitations-table.pending-since'),
    key: 'pendingSince',
    render: (invitation: Invitation) => (
      <Tag color='orange'>
        {invitation.whenCreated &&
          t('company:employees:invitations-table.pending-tag-label', {
            daysCount: dayjs().diff(dayjs(new Date(invitation.whenCreated), 'day')),
          })}
      </Tag>
    ),
  },
  {
    key: 'actions',
    render: (invitation: Invitation) => (
      <div>
        <Dropdown
          arrow
          placement='bottomRight'
          overlay={
            <Menu
              items={[
                {
                  label: t('company:employees:invitations-table:invite-resend'),
                  key: `resendInvite:${invitation.email}`,
                  onClick: async () => {
                    await onResendInvite(invitation.company?.companyId ?? '', {
                      employeeId: '',
                      firstName: invitation.firstName ?? '',
                      lastName: invitation.lastName ?? '',
                      email: invitation.email ?? '',
                      positionId: invitation.position?.id ?? '',
                      departmentId: invitation.department?.id ?? '',
                    });
                  },
                },
              ]}
            />
          }>
          <MoreOutlined />
        </Dropdown>
      </div>
    ),
    width: 100,
  },
];

const PendingEmployees = (): JSX.Element => {
  const { backofficeCompanyFilter } = useFlags();
  const { companiesFilterValues, onFilterChange, filters } = useCompanyFilter();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const getInvitationsCallback = useCallback(
    (pageIndex: number, searchString?: string) =>
      getInvitations(pageIndex, filters, DEFAULT_PAGE_SIZE, searchString),
    [filters],
  );

  const refreshList = useCallback(() => {
    onFilterChange({ company: [''] });
  }, [onFilterChange]);

  const onResendInvite = useCallback(
    async (companyId: string, invitation: InvitationDto) => {
      try {
        setShowLoader(true);
        await resendInvitation(companyId, invitation?.email ?? '', {
          invitation,
          inviteRedirectUrl: `${config.otherApps.customerApp}/sign-up/consumer`,
        });
        refreshList();
        showToast(t('company:employees:invitations-table.invite-sent'));
      } catch (error) {
        showToast(t('errors:unhandled-error'));
      } finally {
        setShowLoader(false);
      }
    },
    [refreshList],
  );

  const cols = useMemo(
    () => getColumns(companiesFilterValues, backofficeCompanyFilter, onResendInvite),
    [companiesFilterValues, backofficeCompanyFilter, onResendInvite],
  );

  return (
    <div data-testid='pending-employees-table'>
      <BackofficeLayout showHeader={true} headerContent={<InviteEmployeesButton />}>
        <SearchablePaginatedTable
          tableName={t('company:employees:invitations-table.title')}
          rowKey='id'
          getEntries={getInvitationsCallback}
          columns={cols as object[]}
          searchInputPlaceHolder={t('company:employees:invitations-table.search-placeholder')}
          beforeTable={<EmployeeTablesTabs currentTab={routes.PendingEmployees} />}
          onDataLoaded={(data) => updatePendingEmployeesTotal(data.total)}
          onFilterChange={onFilterChange}
          showLoader={showLoader}
        />
      </BackofficeLayout>
    </div>
  );
};

export default PendingEmployees;
