import classNames from 'classnames';
import './ConversationBadge.scss';
import { t } from '@inovirtue/localization';

export interface ConversationBadgeProps {
  companyName?: string | null;
  role?: string | null;
  isActive: boolean;
  className?: string;
}

export const ConversationBadge = ({
  companyName,
  role,
  isActive,
  className,
}: ConversationBadgeProps): JSX.Element => {
  const conversationBadgeCls = classNames(
    'conversation-badge',
    {
      'conversation-badge--inActive': !isActive,
      'conversation-badge--company': companyName,
      'conversation-badge--role': !companyName && role,
    },
    className,
  );

  if (!isActive) return <span className={conversationBadgeCls}>{t('chat:inactive')}</span>;

  if (companyName) return <span className={conversationBadgeCls}>{companyName}</span>;

  if (role) return <span className={conversationBadgeCls}>{role}</span>;

  return <></>;
};
