import './PartnerEditProviders.scss';
import { Avatar, Space, Tag } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useEditPartnerProviders } from '../../hooks/useEditPartnerProviders';
import { Provider } from '../../model/Provider';
import { ProviderSelect, ProviderSelectProps } from '../ProviderSelect/PartnerProviderSelect';
import { Partner } from '../../model/Partner';

export type PartnerEditProvidersProps = {
  partner: Partner;
  initialProviders?: Provider[];
  providers: Provider[];
  onSearchProvider: ProviderSelectProps['onSearchProvider'];
  onDropdownVisibleChange?: ProviderSelectProps['onDropdownVisibleChange'];
  onChanged: (providers: Provider[]) => void;
};

export const PartnerEditProviders = ({
  partner,
  initialProviders = [],
  providers,
  onSearchProvider,
  onDropdownVisibleChange,
  onChanged,
}: PartnerEditProvidersProps) => {
  const { currentProviders, removeProvider, addProvider } = useEditPartnerProviders(
    initialProviders,
    onChanged,
  );
  return (
    <div className='partner-edit-providers'>
      <div className='partner-edit-providers__add'>
        <ProviderSelect
          tenantName={partner.tenant}
          providers={providers}
          onSearchProvider={onSearchProvider}
          onSelect={addProvider}
          onDropdownVisibleChange={onDropdownVisibleChange}
        />
      </div>
      <div className='partner-edit-providers__list'>
        {currentProviders.map((e) => (
          <Tag className='partner-edit-providers__item' key={e.name}>
            <Space>
              <Avatar size='small' src={e.avatarUrl} />
              {e.displayName}
              <CloseCircleFilled
                onClick={() => {
                  removeProvider(e);
                }}
              />
            </Space>
          </Tag>
        ))}
      </div>
    </div>
  );
};
