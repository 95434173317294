import config from '../../../config';
import { Position } from '@inovirtue/company';
import { PositionDto } from '../../Companies/models/dto/PositionDto';
import { getApiClient } from '../../Common/api/apiClient';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getPositions = async (companyId: string): Promise<Position[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<PositionDto[]>(`/companies/${companyId}/positions`);
  return data;
};

export const savePositions = async (companyId: string, positions: string[]): Promise<void> => {
  const axiosInstance = await getApiClientAsync();
  await axiosInstance.post(`/companies/${companyId}/positions`, positions);
};
