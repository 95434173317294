import React, { FC } from 'react';
import { Space, Spin } from 'antd';
import { SpaceSize } from 'antd/lib/space';
import { SpinSize } from 'antd/lib/spin';

export type LoaderProps = {
  spaceSize: SpaceSize;
  spinSize: SpinSize;
};

export const Loader: FC<LoaderProps> = ({ spaceSize, spinSize }) => (
  <Space size={spaceSize}>
    <Spin size={spinSize} data-testid='loader' />
  </Space>
);
