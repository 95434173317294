import React, { useCallback, useMemo } from 'react';
import { t } from '@inovirtue/localization';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { DEFAULT_PAGE_SIZE, SearchablePaginatedTable } from '@inovirtue/table';
import { getWaitingForCoachEmployees } from '../api/Employees.Api';
import { dateFormat } from '../const/formats';
import { EmployeeTablesTabs } from '../components/EmployeeTableTabs/EmployeeTablesTabs';
import routes from '../../../App/routes/constants/Routes';
import { updateWaitingForCoachEmployeesTotal } from '../state';
import { AwaitingTime } from '../../Consumers/components/AwaitingTime/AwaitingTime.Component';
import EmployeeInfo from '../models/EmployeeInfo';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AssignCoachButton from '../components/AssignButton/AssignCoachButton';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import useCompanyFilter from '../hooks/useCompanyFilter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import InviteEmployeesButton from '../../Invitations/components/InviteEmployeesButton/InviteEmployeesButton';

import './WaitingForCoachEmployees.scss';

const getColumns = (
  companiesMemo: { text: string | undefined; value: string }[],
  companyFilterFlag: boolean,
): ColumnsType<EmployeeInfo> => [
  {
    title: t('company:employees:waiting-for-coach-employees-table.name'),
    key: 'name',
    render: (employee: EmployeeInfo) => (
      <div className='waiting-for-employees__columns-name'>
        <Avatar size='small' src={employee.avatarUrl} icon={<UserOutlined />} />
        <div className='waiting-for-employees__columns-name-container'>
          <div className='waiting-for-employees__columns-name-container-name'>
            {employee.displayName}
          </div>
          <div className='waiting-for-employees__columns-name-container-email'>
            {employee.email}
          </div>
        </div>
      </div>
    ),
    width: 300,
  },
  {
    title: t('company:employees:waiting-for-coach-employees-table.company'),
    key: 'company',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.company?.name}</span>
      </div>
    ),
    filters: companyFilterFlag ? companiesMemo : undefined,
    filterMultiple: false,
    filterSearch: true,
  },
  {
    title: t('company:employees:waiting-for-coach-employees-table.position'),
    key: 'position',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.position?.name}</span>
      </div>
    ),
  },
  {
    title: t('company:employees:waiting-for-coach-employees-table.signup-date'),
    key: 'signupDate',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{dayjs(new Date(employee.whenCreated)).format(dateFormat)}</span>
      </div>
    ),
  },
  {
    title: t('company:employees:waiting-for-coach-employees-table.waiting-for-coach'),
    key: 'pendingSince',
    render: (employee: EmployeeInfo) =>
      employee.lastNewProviderRequest ? (
        <AwaitingTime createdAt={employee.lastNewProviderRequest.whenCreated} />
      ) : null,
  },
  {
    title: t('company:employees:waiting-for-coach-employees-table.action-column-label'),
    key: 'actions',
    render: (employee: EmployeeInfo) => (
      <div className='waiting-for-employees__columns__actions'>
        <AssignCoachButton employee={employee} />
      </div>
    ),
  },
];

const WaitingForCoachEmployees = (): JSX.Element => {
  const { backofficeCompanyFilter } = useFlags();
  const { companiesFilterValues, onFilterChange, filters } = useCompanyFilter();
  const cols = useMemo(
    () => getColumns(companiesFilterValues, backofficeCompanyFilter),
    [companiesFilterValues, backofficeCompanyFilter],
  );

  const getWaitingForCoachEmployeesCallback = useCallback(
    (pageIndex: number, searchString?: string) =>
      getWaitingForCoachEmployees(pageIndex, filters, DEFAULT_PAGE_SIZE, searchString),
    [filters],
  );

  return (
    <div data-testid='waiting-for-coach-employees-table'>
      <BackofficeLayout showHeader={true} headerContent={<InviteEmployeesButton />}>
        <SearchablePaginatedTable
          tableName={t('company:employees:waiting-for-coach-employees-table.title')}
          rowKey='id'
          getEntries={getWaitingForCoachEmployeesCallback}
          columns={cols as object[]}
          searchInputPlaceHolder={t(
            'company:employees:waiting-for-coach-employees-table.search-placeholder',
          )}
          beforeTable={<EmployeeTablesTabs currentTab={routes.WaitingForCoachEmployees} />}
          onDataLoaded={(data) => updateWaitingForCoachEmployeesTotal(data.total)}
          onFilterChange={onFilterChange}
        />
      </BackofficeLayout>
    </div>
  );
};

export default WaitingForCoachEmployees;
