import React, { useCallback } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select, Button } from 'antd';
import { ToolbarProps, NavigateAction, View } from 'react-big-calendar';
import { isMobile } from 'react-device-detect';

const { Option } = Select;

import './BigCalendarToolbar.Component.styles.scss';

const views: View[] = ['month', 'week', 'day'];

interface BigCalendarToolbarProps extends ToolbarProps {
  todayTranslation: string;
  viewsTranslations: { [keyn in View as Exclude<View, 'agenda' | 'work_week'>]: string };
}

export const BigCalendarToolbar = ({
  label,
  onNavigate,
  onView,
  view,
  todayTranslation,
  viewsTranslations,
}: BigCalendarToolbarProps): JSX.Element => {
  const navigate = useCallback(
    (action: NavigateAction, date?: Date) => onNavigate(action, date),
    [onNavigate],
  );
  const changeView = useCallback((viewType: View) => onView(viewType), [onView]);
  const filterCalendarViews = useCallback((calendarViews: View[]) => {
    return !isMobile
      ? calendarViews
      : calendarViews.filter((el) => (!isMobile ? true : el !== 'week'));
  }, []);

  const showToday = () => {
    navigate('TODAY');
    changeView('day');
  };

  return (
    <div className='bigCalendar-toolbar'>
      <Button onClick={showToday} className='bigCalendar-toolbar-today'>
        {todayTranslation}
      </Button>
      <div className='bigCalendar-toolbar-controll-wrapper'>
        <Button
          className='bigCalendar-toolbar-nav'
          icon={<LeftOutlined />}
          onClick={() => navigate('PREV')}
        />
        <Button
          className='bigCalendar-toolbar-nav'
          icon={<RightOutlined />}
          onClick={() => navigate('NEXT')}
        />
        <h1 className='bigCalendar-toolbar-label'>{label}</h1>
      </div>
      <Select
        defaultValue='month'
        style={{ width: 120 }}
        onChange={changeView}
        value={view}
        className='bigCalendar-toolbar-view'
      >
        {filterCalendarViews(views).map((el) => (
          <Option key={el} value={el} className='bigCalendar-toolbar-view-option'>
            {viewsTranslations[el]}
          </Option>
        ))}
      </Select>
    </div>
  );
};
