import { useCallback } from 'react';
import { usePaginatedCollection, useSearchableCallback, PaginatedResponse } from '@inovirtue/hooks';

export const usePaginatedTableWithSearch = <T>(
  getEntries: (pageIndex: number, searchText?: string) => Promise<PaginatedResponse<T>>,
) => {
  const searchablePaginatedCallback = useCallback(
    (searchString?: string) => (pageIndex: number) => getEntries(pageIndex, searchString),
    [getEntries],
  );

  const { setSearchString, searchCallback } = useSearchableCallback(searchablePaginatedCallback);

  const paginatedCollection = usePaginatedCollection(searchCallback);

  const onSearch = (searchText: string) => {
    paginatedCollection.setPageIndex(0);
    setSearchString(searchText);
  };

  return { ...paginatedCollection, onSearch };
};
