import { Modal } from '@inovirtue/components';
import { useEffect, useState } from 'react';
import './DeviceCheck.scss';
import classNames from 'classnames';
import { useDevicesInit } from '../../hooks/useDevicesInit/useDevicesInit';
import InputOutputDevices from '../DeviceSelection/InputOutputDevices/InputOutputDevices';
import { t } from '@inovirtue/localization';
import { useMediaTracksSwitchOff } from '../../hooks/useMediaTracksSwitchOff/useMediaTracksSwitchOff';

export interface DeviceCheckProps {
  onClose: () => void;
}

export const DeviceCheck = ({ onClose }: DeviceCheckProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(true);
  const { switchOffMediaTracks } = useMediaTracksSwitchOff();
  useDevicesInit();

  useEffect(() => {
    return () => {
      switchOffMediaTracks();
    };
  }, [switchOffMediaTracks]);

  const deviceCheckCls = classNames('device-check');

  return (
    <>
      <Modal
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        afterClose={onClose}
        isFooterDivider
        isCloseIcon={true}
        title={t('videoCall:call-prep.peripherals')}
        contentCls='device-check__content'
        modalWrapCls='device-check__wrapper'
      >
        <InputOutputDevices className={deviceCheckCls} />
      </Modal>
    </>
  );
};
