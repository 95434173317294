import { CompanyContact, CompanyContacts } from '@inovirtue/company';
import { useCompanyContactModal } from '../../hooks/useCompanyContactModal';
import { useCompanyInvitationsList } from '../../hooks/useCompanyInvitationsList';
import { CompanyContactModal } from '../CompanyContactModal/CompanyContactModal';

export type CompanyContactsContainerProps = {
  companyId: string;
};

export const CompanyContactsContainer = ({ companyId }: CompanyContactsContainerProps) => {
  const { contacts, isProcessing, refresh, canDelete } = useCompanyInvitationsList(companyId);
  const {
    mode,
    data,
    showAddContactModal,
    showEditContactModal,
    showDeleteContactModal,
    submit,
    closeModal,
  } = useCompanyContactModal();

  return (
    <>
      {!isProcessing ? (
        <CompanyContacts
          contacts={contacts}
          isEditable={true}
          canAdd={true}
          canEdit={true}
          canDelete={canDelete}
          canResendInvite={false}
          showStatus={true}
          onEditClick={(contact) => showEditContactModal(companyId, contact)}
          onAddContact={() => showAddContactModal(companyId)}
          onDeleteClick={(contact) => showDeleteContactModal(companyId, contact)}
        />
      ) : null}
      <CompanyContactModal
        mode={mode}
        companyId={companyId}
        data={data}
        onClose={closeModal}
        onSubmit={async (contact: CompanyContact) => {
          await submit(contact);
          refresh();
        }}
      />
    </>
  );
};
