import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionState } from '@twilio/conversations';
import { FocusedConvoSid } from '../models/conversation';
import { assembleSubscribedConversations } from './conversationsSlice';
import { ConversationsState } from './store';

export interface CommonState {
  connectionState: ConnectionState;
  isInitialized: boolean;
  identity: string;
  focusedConvoSid: FocusedConvoSid;
}

const initialState: CommonState = {
  connectionState: 'unknown',
  isInitialized: false,
  identity: '',
  focusedConvoSid: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setConnectionState(state: CommonState, action: PayloadAction<ConnectionState>) {
      state.connectionState = action.payload;
    },
    setIdentity(state: CommonState, action: PayloadAction<string>) {
      state.identity = action.payload;
    },
    setFocusedConversation(state: CommonState, action: PayloadAction<FocusedConvoSid>) {
      state.focusedConvoSid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(assembleSubscribedConversations.fulfilled, (state) => {
      state.isInitialized = true;
    });
  },
});

export const selectIdentity = (state: ConversationsState) => state.common.identity;
export const selectIsInitialized = (state: ConversationsState) => state.common.isInitialized;
export const selectConnectionState = (state: ConversationsState) => state.common.connectionState;

export const { setConnectionState, setIdentity, setFocusedConversation } = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
