import ConsumerBar from '../../../Consumers/components/ConsumerBar/ConsumerBar';
import AssignedCompany from '../AssignedCompany/AssignedCompany';
import AssignMonthlySessions from '../AssignedMonthlySessions/AssignedMonthlySessions';
import { t } from '@inovirtue/localization';
import { Button } from '@inovirtue/components';
import { useConsumerEdit } from '../../hooks/useConsumerEditHook';
import { ErrorMessage } from '@inovirtue/components';
import './ConsumerEditRoot.scss';
import RecommendedProviders from '../RecommendedProviders/RecommendedProviders';
import AssignedProviders from '../AssignedProviders/AssignedProviders';
import { useFlags } from 'launchdarkly-react-client-sdk';

type ConsumerEditRootProps = {
  profileName: string;
};

const ConsumerEditRoot = ({ profileName }: ConsumerEditRootProps) => {
  const { assignedProviders } = useFlags();
  const {
    consumerDetails,
    consumerProviders,
    companies,
    sessionsAssignedMonthly,
    submitAttemptValidationFailed,
    activeNewProviderRequest,
    consumerAssingedProviders,
    consumerRemovedProviders,
    onSubmit,
  } = useConsumerEdit(profileName);

  if (!consumerDetails.isFound) return <ErrorMessage status={404} />;

  return (
    <div className='consumer-edit' data-testid='consumer-edit'>
      <ConsumerBar {...consumerDetails} newProviderRequest={activeNewProviderRequest} />
      {assignedProviders && (
        <AssignedProviders providers={consumerAssingedProviders.assignedProviders} />
      )}
      <AssignedCompany {...companies} showValidationErrors={submitAttemptValidationFailed} />
      <AssignMonthlySessions
        {...sessionsAssignedMonthly}
        showValidationErrors={submitAttemptValidationFailed}
      />
      <RecommendedProviders
        {...consumerProviders}
        interests={consumerDetails.interests}
        assignedProvidersNames={consumerAssingedProviders.assignedProviders.map((x) => x.name)}
        removedProvidersNames={consumerRemovedProviders.providers.map((x) => x.name)}
        canEdit={consumerProviders.canEditProviders}
        showValidationErrors={submitAttemptValidationFailed}
      />
      <Button className='consumer-edit__submit' onClick={onSubmit}>
        {t('common:Save')}
      </Button>
    </div>
  );
};

export default ConsumerEditRoot;
