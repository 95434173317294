import { useMemo } from 'react';
import { Message } from '../../models/message';
import { formatRelativeDate } from '../../utils/formatRelativeDate';
import './TimelineDate.scss';

export interface TimelineDateProps {
  date: Message['dateCreated'];
}

export const TimelineDate = ({ date }: TimelineDateProps): JSX.Element => {
  const formattedDate = useMemo(() => date && formatRelativeDate(date), [date]);
  return <>{formattedDate && <div className='timeline-date'>{formattedDate}</div>}</>;
};
