import { Alert, Button, message, Switch, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import { t } from '@inovirtue/localization';
import Provider from '../../../Providers/models/Provider';
import ValidationResult from '../../models/validationResult';
import './RecommendedProviders.scss';
import { EditOutlined } from '@ant-design/icons';
import ProfilePreviewModal from '../../../Providers/components/ProfilePreviewModal/ProfilePreviewModal';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecommendedProvidersUtils } from '../../hooks/useRecommendedProvidersUtils';
import { useRecommendedProvidersHandlers } from '../../hooks/useRecommendedProvidersHandlers';
import FilterCoachesForm from '../FilterCoaches/FilterCoaches';
import useFilterProviders from '../../hooks/useFilterProviders';

type RecommendedProvidersProps = {
  interests?: string[];
  providers: Provider[];
  recommendedProvidersNames: string[];
  assignedProvidersNames: string[];
  removedProvidersNames: string[];
  onRecommendedChange: (checked: boolean, selectedName: string) => void;
  validationResult: ValidationResult;
  showValidationErrors: boolean;
  canEdit?: boolean;
};

const RecommendedProviders = ({
  interests,
  providers,
  recommendedProvidersNames,
  assignedProvidersNames,
  removedProvidersNames,
  onRecommendedChange,
  validationResult,
  showValidationErrors,
  canEdit,
}: RecommendedProvidersProps) => {
  const { multipleCoaches, assignedProviders } = useFlags();
  const [selectedProfileName, setSelectedProfileName] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const { isLoading, filteredProviders, onFilterProviders } = useFilterProviders(
    providers,
    interests,
  );

  const onViewProfile = (profileName: string) => {
    setSelectedProfileName(profileName);
    setPreviewVisible(true);
  };

  const { isProviderAssigned, isProviderRecommended, isProviderRemoved } =
    useRecommendedProvidersUtils(
      assignedProvidersNames,
      recommendedProvidersNames,
      removedProvidersNames,
    );

  const { onProviderClick, onProviderChange, isProviderChecked } = useRecommendedProvidersHandlers(
    isProviderAssigned,
    isProviderRemoved,
    isProviderRecommended,
    onRecommendedChange,
  );

  const getProviderStatus = (provider: Provider) => {
    if (isProviderAssigned(provider.name)) {
      return <Tag color='blue'>{t('consumer:provider-selection.active')}</Tag>;
    }
    if (isProviderRemoved(provider.name)) {
      return <Tag color='pink'>{t('consumer:provider-selection.removed')}</Tag>;
    }
    return null;
  };

  return (
    <div className='recommendedProviders' data-testid='recommendedProviders'>
      <p className='monthlySessions__heading'>{t('consumer:assing-providers-heading')}</p>
      <p className='monthlySessions__subheading'>
        {multipleCoaches
          ? t('consumer:multipleCoaches_assing-providers-subheading')
          : t('consumer:assing-providers-subheading')}
      </p>
      {!validationResult.isValid && showValidationErrors && (
        <Alert
          className='recommendedProviders__validation'
          message={validationResult.validationMessage}
          type='error'
          showIcon
        />
      )}
      <FilterCoachesForm onFilterChange={onFilterProviders} />
      <div className='recommendedProviders__results-count'>
        {t('consumer:coach-filters:filter-result-count', { count: filteredProviders?.length })}
      </div>
      <Table
        dataSource={filteredProviders}
        rowKey='name'
        size='small'
        pagination={false}
        loading={isLoading}
      >
        <Column
          title={t('common:coach')}
          key='displayName'
          render={(provider: Provider) => (
            <>
              {provider.displayName}
              {multipleCoaches && (provider.numberOfTimesRecommended as number) > 0 && (
                <Tooltip
                  placement='topLeft'
                  title={t('consumer:number-of-times-recommended')}
                  arrowPointAtCenter
                >
                  <span className='recommendedProviders__provider_consumer-count'>
                    ({provider.numberOfTimesRecommended})
                  </span>
                </Tooltip>
              )}
            </>
          )}
        />
        <Column
          title={t('common:expertises')}
          render={(provider: Provider) =>
            provider.interestKeys?.map((interest, index) => <Tag key={index}>{interest}</Tag>)
          }
        />
        <Column
          title={t('common:past-sessions')}
          dataIndex='passedSessionCount'
          key='passedSessionCount'
        />
        <Column
          title={t('common:upcoming')}
          dataIndex='futureSessionCount'
          key='futureSessionCount'
        />
        <Column
          title={t('consumer:provider-selection.status')}
          render={(provider: Provider) => getProviderStatus(provider)}
        ></Column>
        <Column
          title={t('common:recommend-coach')}
          render={(provider: Provider) =>
            assignedProviders ? (
              multipleCoaches ? (
                <Switch
                  data-testid='recommended-switch'
                  defaultChecked={false}
                  disabled={!canEdit}
                  onClick={() => onProviderClick(provider.name)}
                  checked={isProviderChecked(provider.name)}
                  onChange={(checked) => onProviderChange(checked, provider.name)}
                />
              ) : (
                <Switch
                  data-testid='recommended-switch'
                  defaultChecked={false}
                  onClick={() =>
                    isProviderAssigned(provider.name) &&
                    message.warning(
                      t('consumer:consumer-edit-validation.provider-already-selected'),
                    )
                  }
                  checked={
                    isProviderRecommended(provider.name) && !isProviderAssigned(provider.name)
                  }
                  onChange={(checked) =>
                    !isProviderAssigned(provider.name) &&
                    onRecommendedChange(checked, provider.name)
                  }
                />
              )
            ) : multipleCoaches ? (
              <Switch
                data-testid='recommended-switch'
                defaultChecked={false}
                disabled={!canEdit}
                checked={isProviderRecommended(provider.name) && !isProviderAssigned(provider.name)}
                onChange={(checked) => onRecommendedChange(checked, provider.name)}
              />
            ) : (
              <Switch
                data-testid='recommended-switch'
                defaultChecked={false}
                checked={isProviderRecommended(provider.name) && !isProviderAssigned(provider.name)}
                onChange={(checked) => onRecommendedChange(checked, provider.name)}
              />
            )
          }
        ></Column>
        <Column
          title={t('onboarding:provider-selection.view-profile-btn')}
          render={(provider: Provider) => (
            <Button icon={<EditOutlined />} onClick={() => onViewProfile(provider.name)}>
              {t('onboarding:provider-selection.view-profile-btn')}
            </Button>
          )}
        />
      </Table>
      {!!selectedProfileName && (
        <ProfilePreviewModal
          profileName={selectedProfileName}
          isVisible={previewVisible}
          onClose={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
};

export default RecommendedProviders;
