import { useParams } from 'react-router';
import './CompanyDetailsView.scss';
import CompanyDetailsFacade from '../../../Modules/Companies/components/CompanyDetailsFacade/CompanyDetailsFacade';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CompanyContactsContainer } from '../../../Modules/Companies/components/CompanyContactsContainer/CompanyContactsContainer';
import BackofficeLayout from '../../../Layout/BackofficeLayout';

const CompanyDetailsViewContent = () => {
  const { manageCompanyContacts } = useFlags();

  const { id } = useParams<{ id: string }>();

  return manageCompanyContacts ? (
    <div className='company-details-dashboard'>
      <div className='company-details-view' data-testid='company-details'>
        <CompanyDetailsFacade companyId={id} />
      </div>
      <div className='company-contacts'>
        <CompanyContactsContainer companyId={id} />
      </div>
    </div>
  ) : (
    <div className='company-details-view' data-testid='company-details'>
      <CompanyDetailsFacade companyId={id} />
    </div>
  );
};

const CompanyDetailsView = () => {
  return (
    <BackofficeLayout showHeader={false}>
      <CompanyDetailsViewContent />
    </BackofficeLayout>
  );
};

export default CompanyDetailsView;
