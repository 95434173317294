import { useMemo } from 'react';
import useSettingsToggle from '../../components/VideoProvider/useSettingsToggle/useSettingsToggle';
import { SettingOutlined } from '@ant-design/icons';

export const useSettingsButton = () => {
  const toggleSettings = useSettingsToggle();

  const icon = useMemo(() => <>{<SettingOutlined />}</>, []);

  return { icon, toggleSettings };
};
