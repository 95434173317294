import { ProfileTypes, UserProfile } from '../models/UserProfile';

interface ProfiletTypeInfo {
  isConsumer: boolean;
  isProvider: boolean;
}

const useProfileType = (userProfile: UserProfile | undefined): ProfiletTypeInfo => {
  return {
    isProvider: userProfile?.type === ProfileTypes.ProviderProfile,
    isConsumer: userProfile?.type === ProfileTypes.ConsumerProfile,
  };
};

export default useProfileType;
