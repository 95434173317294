import { useMessageDetails } from '../../hooks/useMessageDetails/useMessageDetails';
import { MessageBaseProps } from '../../models/message';
import { FileMessage } from '../FileMessage/FileMessage';
import { ImageMessage } from '../ImageMessage/ImageMessage';
import { TextMessage } from '../TextMessage/TextMessage';

export type MessageFactoryProps = MessageBaseProps;

export const MessageFactory = (props: MessageFactoryProps): JSX.Element => {
  const details = useMessageDetails(props.message);

  switch (details.type) {
    case 'text':
      return <TextMessage {...props} />;
    case 'file': {
      const { fileName, fileSize, extension } = details;
      return <FileMessage {...props} {...{ fileName, fileSize, extension }} />;
    }
    case 'image':
      return <ImageMessage {...props} />;
  }
};
