import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Session } from '@inovirtue/admin';
import { onAcceptCall } from '@inovirtue/video-call';

export interface SessionsInfo {
  sessions: Session[];
}

const checkInterval = 1000 * 5;
const joinAvailableBeforeInMinutes = 5;

const initialState: SessionsInfo = {
  sessions: [],
};

export const useSessionsInfo = (sessions?: Session[], serverTime?: string): SessionsInfo => {
  const [infoState, setInfoState] = useState<SessionsInfo>(initialState);

  useEffect(() => {
    let ticks = 0;
    const refreshTimeLeft = () => {
      if (sessions && sessions.length > 0 && serverTime) {
        sessions.forEach((session) => {
          const startDate = dayjs(session.plannedStartDateTime);
          const endDate = dayjs(session.plannedEndDateTime);

          const serverDate = dayjs(dayjs(serverTime).add(ticks * checkInterval, 'millisecond'));

          const isFinished = serverDate >= endDate;
          const isOngoing = startDate <= serverDate && !isFinished;

          const canJoin =
            isOngoing ||
            (!isFinished && startDate.diff(serverDate, 'minute') <= joinAvailableBeforeInMinutes);

          session.canJoin = canJoin;
          session.joinVideoCall = async (sessionId: string) => {
            await onAcceptCall(sessionId);
          };
        });

        setInfoState((oldState) => ({
          ...oldState,
          sessions,
        }));
      } else {
        setInfoState(initialState);
      }
      ticks += 1;
    };

    const intervalTimer = setInterval(() => {
      refreshTimeLeft();
    }, checkInterval);

    refreshTimeLeft();
    return () => clearInterval(intervalTimer);
  }, [sessions, serverTime]);

  return { ...infoState };
};
