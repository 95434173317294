import { t } from '@inovirtue/localization';
import { useCreateWorkshop } from '../../hooks/useCreateWorkshop';
import WorkshopForm from '../WorkshopForm/WorkshopForm';
import WorkshopFormHeader from '../WorkshopFormHeader/WorkshopFormHeader';

const CreateWorkshop = () => {
  const { onSubmit } = useCreateWorkshop();
  return (
    <WorkshopForm
      onSubmit={onSubmit}
      submitText={t('group:create-group-submit')}
      formHeader={
        <WorkshopFormHeader
          headerText={t('group:create-group-header.main')}
          subheaderText={t('group:create-group-header.sub')}
        />
      }
    />
  );
};

export default CreateWorkshop;
