import { LayoutHeader } from '@inovirtue/admin';
import { Loader } from '@inovirtue/components';
import { useHistory } from 'react-router';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import PartnerActivateButton from '../../../Modules/Partners/components/PartnerActivateButton/PartnerActivateButton';
import {
  PartnerDetailsTabs,
  PartnerTabKey,
} from '../../../Modules/Partners/components/PartnerDetailsTabs/PartnerDetailsTabs';
import { PartnerDto } from '../../../Modules/Partners/models/PartnerDto';
import './PartnerDetailsView.scss';

type PartnerDetailsViewProps = {
  currentTab: PartnerTabKey;
  partner?: PartnerDto;
  children?: React.ReactElement;
};

const PartnerDetailsView = ({
  children,
  partner,
  currentTab,
}: PartnerDetailsViewProps): JSX.Element => {
  const { push } = useHistory();
  const content = partner && children ? children : <Loader spaceSize='large' spinSize='large' />;

  return (
    <BackofficeLayout
      showHeader
      headerContent={
        <>
          <LayoutHeader
            title={
              <div className='workshops-list-view__header'>
                <span className='workshops-list-view__header__text'>{partner?.name}</span>

                <div className='workshops-list__actions'>
                  {partner && (
                    <PartnerActivateButton
                      id={partner.id}
                      name={partner.name}
                      isActive={partner.isActive}
                    />
                  )}
                </div>
              </div>
            }
            onNavigate={push}
          />
          {partner && (
            <PartnerDetailsTabs id={partner.id} currentTab={currentTab} onChange={push} />
          )}
        </>
      }
    >
      {content}
    </BackofficeLayout>
  );
};

export default PartnerDetailsView;
