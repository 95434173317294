import { useObservableWithProgress } from '@inovirtue/hooks';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { from, forkJoin, map } from 'rxjs';
import {
  getConsumerRecommendedProviders,
  getProvidersWithInterests,
} from '../../Providers/api/Providers.Api';
import Provider from '../../Providers/models/Provider';
import ValidationResult from '../models/validationResult';
import { t } from '@inovirtue/localization';
import { getProvidersForConsumer } from '../../Consumers/api/Consumers.Api';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useConsumerProviders = (profileName: string) => {
  const { multipleCoaches } = useFlags();
  const [pageIndex, setPageIndex] = useState(0);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [recommendedProvidersNames, setRecommendedProvidersNames] = useState<string[]>([]);
  const [canEditProviders, setCanEditProviders] = useState(false);

  const [validationResult, setValidationResult] = useState<ValidationResult>({
    isValid: false,
    validationMessage: t('consumer:consumer-edit-validation.providers-required'),
  });

  useEffect(() => {
    setValidationResult({
      isValid: false,
      validationMessage: multipleCoaches
        ? t('consumer:consumer-edit-validation.multipleCoaches_providers-required')
        : t('consumer:consumer-edit-validation.providers-required'),
    });
  }, [multipleCoaches]);

  const fetchConsumerProvidersMemo = useMemo(() => {
    const providersFunc = multipleCoaches
      ? getProvidersForConsumer(profileName, pageIndex)
      : getProvidersWithInterests(pageIndex);
    return () =>
      forkJoin([
        from(getConsumerRecommendedProviders(profileName)).pipe(
          map((p) => p.map(({ name }) => name)),
        ),
        from(providersFunc),
      ]).pipe(map(([recommended, providersPage]) => ({ recommended, providersPage })));
  }, [pageIndex, profileName, multipleCoaches]);
  const { data, isProcessing } = useObservableWithProgress(fetchConsumerProvidersMemo);

  useEffect(() => {
    if (data) {
      const { providersPage, recommended } = data;
      setRecommendedProvidersNames(recommended);
      setProviders((currentState) => [...currentState, ...providersPage]);
      setHasMore(!!providersPage && providersPage.length > 0);
    } else setHasMore(false);
  }, [data]);

  const validateRecommendedProvider = useMemo(() => {
    return multipleCoaches
      ? () =>
          canEditProviders
            ? recommendedProvidersNames.length >= 1 && recommendedProvidersNames.length <= 3
            : true
      : () => recommendedProvidersNames.length == 3;
  }, [multipleCoaches, recommendedProvidersNames, canEditProviders]);

  useEffect(() => {
    setValidationResult((currentState) => ({
      ...currentState,
      isValid: validateRecommendedProvider(),
    }));
  }, [recommendedProvidersNames, validateRecommendedProvider]);

  const appendRecommendedName = (selectedName: string) =>
    setRecommendedProvidersNames((previousState: string[]) => [...previousState, selectedName]);

  const removeRecommendedName = (selectedName: string) =>
    setRecommendedProvidersNames((previousState: string[]) =>
      _.without(previousState, selectedName),
    );

  const onRecommendedChange = (checked: boolean, selectedName: string) => {
    if (checked) appendRecommendedName(selectedName);
    else removeRecommendedName(selectedName);
  };

  return {
    providers,
    recommendedProvidersNames,
    isProcessing,
    hasMore,
    setPageIndex,
    setCanEditProviders,
    onRecommendedChange,
    validationResult,
    canEditProviders,
  };
};
