import { useBehaviorSubjectState } from '@inovirtue/hooks';
import classNames from 'classnames';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import { room$ } from '../../store/video';
import ParticipantList from '../ParticipantList/ParticipantList.Component';
import WaitingRoomPlaceholder from '../WaitingRoomPlaceholder/WaitingRoomPlaceholder.Component';
import './WaitingRoom.Component.scss';

const WaitingRoom = (): JSX.Element => {
  const room = useBehaviorSubjectState(room$);

  const localParticipant = room?.localParticipant;
  const participants = useParticipants();

  const roomCls = classNames('room', {
    'room--single': localParticipant && participants.length === 1,
    'room--workshop': localParticipant && participants.length >= 2,
  });

  return (
    <div className={roomCls}>
      <WaitingRoomPlaceholder />
      {localParticipant && (
        <ParticipantList
          className='room__participant-list'
          localParticipant={localParticipant}
          participants={participants}
        />
      )}
    </div>
  );
};

export default WaitingRoom;
