import { createStore } from '@datorama/akita';
import { Participant } from 'twilio-video';
import { ProfileParticipant } from './models/profileParticipant.model';

const STORE_KEY = 'participant';

export interface ParticipantState {
  selectedParticipant: Participant | null;
  screenShareParticipant: Participant | null;
  profileParticipants: Map<string, ProfileParticipant>;
  currentProfile: ProfileParticipant;
  reconnectingParticipants: string[];
  cameraEnabledParticipants: string[];
}

const initialState: Partial<ParticipantState> = {
  profileParticipants: new Map(),
  screenShareParticipant: null,
  selectedParticipant: null,
  currentProfile: {} as ProfileParticipant,
  reconnectingParticipants: [],
  cameraEnabledParticipants: [],
};

export const participantStore = createStore<ParticipantState>(initialState, {
  name: STORE_KEY,
  resettable: true,
});
