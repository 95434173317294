import { forkJoin, from, map, Observable } from 'rxjs';
import { getProfileInterests } from '../../../Modules/Consumers/api/Consumers.Api';
import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import Provider from '../models/Provider';
import { UserProfile } from '@inovirtue/profile';
import { isEmpty } from 'lodash';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getProviders = async (
  pageIndex: number,
  searchString?: string,
): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();

  const { data } = await axiosInstance.get<Provider[]>(`/Profiles/providers`, {
    params: {
      pageIndex,
      searchString,
      pageSize: config.pagination.pageSize,
    },
  });
  return data;
};

export const getTenantProviders = async (
  pageIndex: number,
  searchString?: string,
  tenantName?: string,
): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();

  const { data } = await axiosInstance.get<{ results: Provider[] }>(`/v3/profiles/providers`, {
    params: {
      pageIndex,
      searchString,
      pageSize: config.pagination.pageSize,
      tenantName,
    },
  });

  return data.results;
};

export const getProvidersWithInterests = async (
  pageIndex: number,
  searchString?: string,
): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();
  const params: {
    pageIndex: number;
    pageSize: number;
    searchString?: string;
    onlyWithOnboardingDone: boolean;
  } = {
    pageIndex,
    pageSize: 1000,
    onlyWithOnboardingDone: true,
  };

  if (!isEmpty(searchString)) params.searchString = searchString;

  const { data } = await axiosInstance.get<Provider[]>(`/Profiles/providers/interests`, {
    params,
  });
  return data;
};

export const getConsumerRecommendedProviders = async (profileName: string): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Provider[]>(`/Profiles/${profileName}/recommendations`);
  return data;
};

export const getConsumerAssignedProviders = async (profileName: string): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Provider[]>(
    `/Profiles/${profileName}/provider/selected`,
  );
  return data;
};

export const getConsumerRemovedProviders = async (profileName: string): Promise<Provider[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Provider[]>(`/Profiles/${profileName}/provider/removed`);
  return data;
};

const getProviderProfile = async (profileName: string): Promise<UserProfile> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<UserProfile>(`/Profiles/providers/${profileName}`);
  return data;
};

export const getProviderProfileWithInterests = (profileName: string): Observable<UserProfile> =>
  forkJoin([from(getProviderProfile(profileName)), from(getProfileInterests(profileName))]).pipe(
    map(([provider, interests]) => ({ tags: interests, ...provider })),
  );
