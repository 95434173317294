import { useMsal } from '@azure/msal-react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { ComponentType, useEffect } from 'react';
import config from '../../config';
import App from '../views/App/App';

const AppWithFeatureFlags = () => {
  const { instance } = useMsal();
  const [activeUser] = instance.getAllAccounts();
  const ldClient = useLDClient();

  useEffect(() => {
    const identifyUser = async (accountId: string) => {
      if (ldClient)
        await ldClient.identify({
          key: accountId,
        });
    };

    if (activeUser) identifyUser(activeUser.homeAccountId);
  }, [activeUser, ldClient]);
  return <App />;
};

const AppFactory = () =>
  withLDProvider({
    clientSideID: config.launchDarkly.clientSideID,
    deferInitialization: true,
    user: { anonymous: true, key: 'anonymous-user' },
    reactOptions: { useCamelCaseFlagKeys: true },
  })(AppWithFeatureFlags as ComponentType<unknown>);

export default AppFactory;
