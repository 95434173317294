import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/AvatarContext';
import { useCallback } from 'react';

export type WorkshopAvatarProps = {
  groupName: string;
  avatarCls?: string;
  size?: AvatarSize;
};

export const WorkshopAvatar = ({ avatarCls, groupName, size = 'default' }: WorkshopAvatarProps) => {
  const getFirstLetters = useCallback(() => {
    const [firstChar, secondChar] = groupName.split(' ').map((x) => x[0]?.toUpperCase());
    return `${firstChar}${secondChar || ''}`;
  }, [groupName]);
  return (
    <Avatar size={size} className={avatarCls}>
      {getFirstLetters()}
    </Avatar>
  );
};
