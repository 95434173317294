import { t } from '@inovirtue/localization';
import { ContactsIcon } from '../../icons/ContactsIcon';
import { Workshop } from '../../model/Workshop';
import { CardField } from '../CardField/CardField';
import { DetailsCardBase } from '../DetailsCardBase/DetailsCardBase';

export type WorkshopContactsProps = {
  workshop: Workshop;
};

export const WorkshopContacts = ({ workshop }: WorkshopContactsProps) => {
  return (
    <DetailsCardBase
      header={t('group:group-contacts-section.section-title')}
      icon={<ContactsIcon />}
    >
      <>
        <CardField
          placement='vertical'
          label={t('group:group-contacts-section.selected-consultant-input-title')}
          value={workshop.consultantName || ''}
        />
        <CardField
          placement='vertical'
          label={t('group:group-contacts-section.selected-company-contant-input-title')}
          value={workshop.companyContactName || ''}
        />
      </>
    </DetailsCardBase>
  );
};
