import { Row, Col } from 'antd';
import { t } from '@inovirtue/localization';
import StepNotice from '../../StepNotice/StepNotice.Component';
import { Button } from '../../Buttons/Button/Button.Component';
import { onRedirect } from '../../../store/video';
import './CallEndedStep.Component.scss';

const { Heading, Actions, Profile } = StepNotice;

const CallEndedStep = (): JSX.Element => {
  return (
    <div className='callendedstep' data-testid='videoCallEndedStep'>
      <Row>
        <Col>
          <StepNotice>
            <Profile />
            <Heading text={t('videoCall:call-ended.heading')} />
            <Actions>
              <Button type='primary' onClick={onRedirect} className='callendedstep__button'>
                {t('videoCall:homepage-btn')}
              </Button>
            </Actions>
          </StepNotice>
        </Col>
      </Row>
    </div>
  );
};

export default CallEndedStep;
