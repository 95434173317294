import { useConvoSelector } from '../../hooks/storeHooks/storeHooks';
import { useConversationDetails } from '../../hooks/useConversationDetails/useConversationDetails';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';
import { ConvoType } from '../../models/conversation';
import { selectUnreadMessagesBySid } from '../../store/horizonSlice';
import { ConversationsItem } from '../ConversationsItem/ConversationsItem';

export interface ConversationsListItemProps {
  convoSid: string;
  isRole?: boolean;
  isCompanyName?: boolean;
  type?: ConvoType;
}

export const ConversationsListItem = ({
  convoSid,
  isRole,
  isCompanyName,
  type,
}: ConversationsListItemProps): JSX.Element => {
  const { selectedConvoSid, setSelectedConvoSid } = useLayoutContext();
  const unreadMessages = useConvoSelector(selectUnreadMessagesBySid(convoSid));
  const { title, role, companyName, avatarUrl, lastMessageDate } = useConversationDetails(convoSid);

  const onSelect = () => {
    setSelectedConvoSid(convoSid);
  };

  return (
    <ConversationsItem
      title={title || ''}
      avatarUrl={avatarUrl}
      unreadMessages={unreadMessages}
      lastMessageDate={lastMessageDate}
      isSelected={selectedConvoSid === convoSid}
      onClick={onSelect}
      type={type}
      {...(isRole ? { role } : {})}
      {...(isCompanyName ? { companyName } : {})}
    />
  );
};
