import './WorkshopForm.scss';
import { Form, Input, Button, Select, InputNumber } from 'antd';
import { t } from '@inovirtue/localization';
import { generatePath, useHistory } from 'react-router';
import { Workshop } from '@inovirtue/admin';
import routes from '../../../../App/routes/constants/Routes';
import TextArea from 'antd/lib/input/TextArea';
import { useWorkshopForm } from '../../hooks/useWorkshopForm';
import { InputWCharactersLimit } from '@inovirtue/components';
import { FormMode } from '../../../Common/model/FormMode';
import { useEffect } from 'react';

type WorkshopFormProps = {
  mode?: FormMode;
  onSubmit: (formData: Workshop) => Promise<Workshop | null>;
  submitText: string;
  formData?: Workshop;
  formHeader?: JSX.Element;
};

const WorkshopForm = ({
  mode = FormMode.Add,
  onSubmit,
  submitText,
  formData,
  formHeader,
}: WorkshopFormProps) => {
  const history = useHistory();
  const {
    selectedCompanyId,
    setSelectedCompanyId,
    selectedCompanyContactId,
    selectedConsultantId,
    setSelectedCompanyContactId,
    setSelectedConsultantId,
    allCompanies,
    allCompanyContacts,
    allConsultants,
  } = useWorkshopForm();

  useEffect(() => {
    if (formData && formData.selectedCompanyId) {
      setSelectedCompanyId(formData.selectedCompanyId);
    }
  }, [formData, setSelectedCompanyId]);

  const [form] = Form.useForm<Workshop>();

  return (
    <div data-testid='group-form' className='group-form'>
      {formHeader}
      <Form
        initialValues={formData}
        form={form}
        layout={'vertical'}
        onFinish={async (values) => {
          const createdGroup = await onSubmit(values as Workshop);
          if (createdGroup) {
            history.push(generatePath(routes.WorkshopDetails, { id: createdGroup.id }));
          }
        }}
      >
        <Form.Item name='id' style={{ display: 'none' }}>
          <Input type='text' />
        </Form.Item>
        <section className='form-section'>
          <p className='form-section__header'>
            {t('group:name-and-description-section.section-title')}
          </p>
          <Form.Item
            name={'name'}
            label={t('group:name-and-description-section.name-input-title')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('group:name-and-description-section.name-input-title'),
                }),
              },
            ]}
          >
            <InputWCharactersLimit data-testid={'nameInput'} maxLength={30} />
          </Form.Item>
          <Form.Item
            name={'description'}
            label={t('group:name-and-description-section.description-input-title')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('group:name-and-description-section.description-input-title'),
                }),
              },
            ]}
          >
            <TextArea showCount maxLength={300} data-testid='descriptionInput' rows={4} />
          </Form.Item>
        </section>
        <section className='form-section'>
          <p className='form-section__header'>
            {t('group:selected-company-section.section-title')}
          </p>
          <p className='form-section__subheader'>
            {t('group:selected-company-section.selected-company-input-hint')}
          </p>
          <Form.Item
            name={'selectedCompanyId'}
            label={t('group:selected-company-section.selected-company-input-title')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('group:selected-company-section.selected-company-input-title'),
                }),
              },
            ]}
          >
            <Select
              data-testid='selectedCompanyIdInput'
              value={selectedCompanyId}
              disabled={mode === FormMode.Edit}
              onChange={(id) => {
                setSelectedCompanyContactId(null);
                setSelectedCompanyId(id);
                form.setFieldsValue({ companyContactId: '' });
              }}
            >
              {allCompanies?.map((x) => (
                <Select.Option key={x.companyId} value={x.companyId}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </section>
        <section className='form-section'>
          <p className='form-section__header'>
            {t('group:session-settings-section.section-title')}
          </p>
          <p className='form-section__subheader'>
            {t('group:session-settings-section.group-sessions-total-input-hint')}
          </p>
          <Form.Item
            name={'sessionsInTotal'}
            label={t('group:session-settings-section.group-sessions-total-input-title')}
            rules={[
              {
                required: true,
                type: 'number',
                min: 1,
                message: t('group:session-settings-section.group-sessions-total-input-min-error'),
              },
            ]}
          >
            <InputNumber disabled={mode === FormMode.Edit} data-testid='sessionsInTotalInput' />
          </Form.Item>
        </section>
        <section className='form-section'>
          <p className='form-section__header'>{t('group:group-contacts-section.section-title')}</p>
          <p className='form-section__subheader'>
            {t('group:group-contacts-section.selected-company-contant-input-hint')}
          </p>
          <Form.Item
            name={'consultantId'}
            label={t('group:group-contacts-section.selected-consultant-input-title')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('group:group-contacts-section.selected-consultant-input-title'),
                }),
              },
            ]}
          >
            <Select
              data-testid='consultantId'
              value={selectedConsultantId}
              allowClear
              placeholder={t('group:group-contacts-section.selected-consultant-input-placeholder')}
              onChange={setSelectedConsultantId}
            >
              {allConsultants?.map((x) => (
                <Select.Option key={`cons_{x.name}`} value={x.name}>
                  {x.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={'companyContactId'}
            label={'Company contact'}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('group:group-contacts-section.selected-company-contant-input-title'),
                }),
              },
            ]}
          >
            <Select
              data-testid='companyContactId'
              value={selectedCompanyContactId}
              allowClear
              onChange={setSelectedCompanyContactId}
            >
              {allCompanyContacts?.map((x) => (
                <Select.Option key={x.name} value={x.name}>
                  {x.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </section>
        <Form.Item>
          <Button
            className='group-form__submit'
            data-testid='submit'
            htmlType='submit'
            type='primary'
          >
            {submitText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WorkshopForm;
