import { t } from '@inovirtue/localization';
import { LoadingOutlined } from '@ant-design/icons';
import Snackbar from '../Snackbar/Snackbar.Component';
import './ReconnectingNotification.Component.scss';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const ReconnectingNotification = (): JSX.Element => {
  const roomState = useRoomState();

  return (
    <Snackbar
      type='warning'
      headline={
        <div className='reconnecting-header'>
          <div>{t('videoCall:call-error.connection-lost')}</div>
          <LoadingOutlined />
        </div>
      }
      message={t('videoCall:call-error.connection-lost-message')}
      open={roomState === 'reconnecting'}
    />
  );
};

export default ReconnectingNotification;
