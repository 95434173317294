import { secondsToTime } from '@inovirtue/date-utils';
import { useEffect, useState } from 'react';
import './ConnectionTime.Component.scss';

interface ConnectionTimeProps {
  disableTimer?: boolean;
}

const ConnectionTime = ({ disableTimer = false }: ConnectionTimeProps): JSX.Element => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!disableTimer) {
      const startDate = new Date();
      const myInterval = setInterval(() => {
        setSeconds((new Date().getTime() - startDate.getTime()) / 1000);
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }
  }, [disableTimer]);

  return (
    <div data-testid='videCallConnectionTime' className='video-call-connection-time'>
      {secondsToTime(seconds)}
    </div>
  );
};

export default ConnectionTime;
