import { useParams, generatePath, useHistory } from 'react-router';
import { PartnerProvidersList } from '@inovirtue/admin';
import usePartnerDetails from '../../hooks/usePartnerDetails';
import PartnerDetailsView from '../PartnerDetailsView/PartnerDetailsView';
import { usePartnerProviders } from '../../../Modules/Partners/hooks/usePartnerProviders';
import { Loader } from '@inovirtue/components';
import routes from '../../routes/constants/Routes';
import { useCallback } from 'react';

const PartnerProvidersView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { partner } = usePartnerDetails(id);
  const { providers, isProcessing } = usePartnerProviders(id);
  const { push } = useHistory();

  const toEditProvider = useCallback(() => {
    push(generatePath(routes.PartnerEditProviders, { id }));
  }, [push, id]);

  return (
    <PartnerDetailsView partner={partner} currentTab='providers'>
      {!isProcessing && providers ? (
        <PartnerProvidersList data={providers} onEditClick={toEditProvider} />
      ) : (
        <Loader spaceSize='large' spinSize='large' />
      )}
    </PartnerDetailsView>
  );
};

export default PartnerProvidersView;
