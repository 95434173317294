import { Role } from '../../../Modules/Roles/models/role';
import config from '../../../config';
import { getApiClient } from '../../Common/api/apiClient';
import { SearchProfileItem } from '../models/SearchProfileItem';

const getApiClientAsync = async () => getApiClient(config.apiAddresses.socialNetworkService);

export const getProfileRoles = async (profileName: string): Promise<Role[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<Role[]>(`/profiles/${profileName}/roles`);
  return data;
};

export const searchProfile = async (displayName: string): Promise<SearchProfileItem[]> => {
  const axiosInstance = await getApiClientAsync();
  const { data } = await axiosInstance.get<SearchProfileItem[]>(
    `/profiles?displayName=${displayName}`,
  );
  return data;
};
