import { useContext } from 'react';
import {
  ConversationsContext,
  ConversationsContextType,
} from '../../contexts/ConversationsContext';

export const useConversationsContext = (): ConversationsContextType => {
  const context = useContext(ConversationsContext);

  if (!context) {
    throw new Error('Please provide conversations context.');
  }

  return context;
};
