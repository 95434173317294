import { Modal, ButtonWithLoader } from '@inovirtue/components';
import useEditEmployeeSessions from '../hooks/useEditEmployeeSessions';
import { Spin, InputNumber } from 'antd';
import { t } from '@inovirtue/localization';

import './EditEmployeeSessionsModal.scss';

type EditEmployeeSessionsModalProps = {
  visible: boolean;
  profileName: string;
  onClose(): void;
};

const EditEmployeeSessionsModal = ({
  visible,
  profileName,
  onClose,
}: EditEmployeeSessionsModalProps): JSX.Element => {
  const { isProcessing, save, setPersonalSessions, personalSessions, initialPersonalSessions } =
    useEditEmployeeSessions(profileName);

  const onSaveClick = async () => {
    await save();
    onClose();
  };

  return (
    <Modal
      title={t('consumer:set-sessions-modal:title')}
      visible={visible}
      onClose={onClose}
      actions={[
        <ButtonWithLoader
          spinning={isProcessing}
          type='primary'
          key='saveBtn'
          disabled={personalSessions === initialPersonalSessions}
          onClick={onSaveClick}
        >
          {t('common:save')}
        </ButtonWithLoader>,
      ]}
    >
      <Spin spinning={isProcessing}>
        <div className='edit-sessions-modal__description'>
          {t('consumer:set-sessions-modal:text')}
        </div>
        <div>
          <div className='edit-sessions-modal__field-label'>
            {t('consumer:set-sessions-modal:personal-sessions')}
          </div>
          {initialPersonalSessions && (
            <InputNumber
              defaultValue={initialPersonalSessions}
              min={0}
              onChange={(num) => setPersonalSessions(num ?? 0)}
            />
          )}
          <span className='edit-sessions-modal__after-input'>
            {t('consumer:set-sessions-modal:sessions')}
          </span>
        </div>
      </Spin>
    </Modal>
  );
};

export default EditEmployeeSessionsModal;
