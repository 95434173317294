import { useCallback } from 'react';
import { deactivateWorkshop } from '../api/workshopApi';

export const useWorkshopDeactivate = () => {
  const deactivate = useCallback(async (workshopId: string) => {
    try {
      await deactivateWorkshop(workshopId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    deactivate,
  };
};
