import { useMemo } from 'react';
import { useCompaniesList } from '../../Companies/hooks/useCompaniesList';

export const useWorkshopsListFilter = () => {
  const { companies, isProcessing: isFetchingCompanies } = useCompaniesList();

  const isInitialized = useMemo(() => {
    return isFetchingCompanies == false;
  }, [isFetchingCompanies]);

  return { companies, isInitialized };
};
