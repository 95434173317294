import './CompanyContactModal.scss';
import { CompanyContact } from '@inovirtue/company';
import { Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { FormMode } from '../../../Common/model/FormMode';
import { CompanyContactDeleteForm } from '../CompanyContactDeleteForm/CompanyContactDeleteForm';
import { CompanyContactForm } from '../CompanyContactForm/CompanyContactForm';
export type CompanyContactModalProps = {
  companyId: string;
  data?: CompanyContact;
  onClose: () => void;
  onSubmit: (c: CompanyContact) => void;
  mode: FormMode;
};

type ModalLabels = {
  title?: string;
  subTitle?: string;
  saveLabel?: string;
};

const labels: { [key in FormMode]: ModalLabels } = {
  [FormMode.Add]: {
    title: t('company:contacts.create-contact.title'),
    subTitle: t('company:contacts.create-contact.sub-title'),
    saveLabel: t('company:contacts:send-invite'),
  },
  [FormMode.Edit]: {
    title: t('company:contacts.edit-contact.title'),
    subTitle: t('company:contacts.edit-contact.sub-title'),
    saveLabel: t('common:save'),
  },
  [FormMode.Delete]: {
    title: t('company:contacts.delete-contact-title'),
  },
  [FormMode.None]: {},
};

export const CompanyContactModal = ({
  mode,
  companyId,
  data,
  onClose,
  onSubmit,
}: CompanyContactModalProps) => {
  return (
    <Modal
      visible={mode !== FormMode.None}
      onClose={() => onClose?.()}
      destroyOnClose={true}
      title={labels[mode].title}
    >
      {mode == FormMode.Delete ? (
        data && (
          <CompanyContactDeleteForm
            contact={data}
            onDelete={(c: CompanyContact) => {
              onSubmit(c);
              onClose();
            }}
          />
        )
      ) : (
        <div className='company-contact-modal'>
          <div className='company-contact-modal__sub-title'>{labels[mode].subTitle}</div>
          <CompanyContactForm
            companyId={companyId}
            formData={data}
            saveButtonLabel={labels[mode].saveLabel}
            onSubmit={async (c: CompanyContact) => {
              onSubmit(c);
              onClose();
            }}
          />
        </div>
      )}
    </Modal>
  );
};
