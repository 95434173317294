import { Avatar, List } from 'antd';
import { Button } from '@inovirtue/components';
import './AddedInvitationsList.scss';
import { Invitation, Invitations } from '../../model/InvitationsModel';
import { Position } from '../../model/PositionModel';
import { Department } from '../../model/DepartmentModel';

export interface AddedInvitationsListProps {
  className?: string;
  title: string;
  removeAction: (invitation: Invitation) => void;
  employeesList: Invitations;
  removeLabel: string;
  emptyListText: string;
  positions: Position[];
  departments: Department[];
}

export const AddedInvitationsList = ({
  className,
  employeesList,
  removeAction,
  removeLabel,
  title,
  emptyListText,
  positions,
  departments,
}: AddedInvitationsListProps): JSX.Element => {
  return (
    <section className={`added-invitations-list ${className}`}>
      <h2 className='added-invitations-list__title'>{title}</h2>
      {employeesList.length > 0 ? (
        <List
          className='added-invitations-list__list'
          itemLayout='horizontal'
          dataSource={employeesList}
          renderItem={(item, index) => (
            <List.Item key={item.email} className='added-invitations-list__list-item'>
              <Avatar
                shape='square'
                size='large'
                className='added-invitations-list__list-item__avatar'
              >
                {index + 1}
              </Avatar>
              <div className='added-invitations-list__list-item__element bold'>
                {item.employeeId}
              </div>
              <div className='added-invitations-list__list-item__element bold'>
                {item.firstName}
              </div>
              <div className='added-invitations-list__list-item__element bold'>{item.lastName}</div>
              <div className='added-invitations-list__list-item__element'>{item.email}</div>
              <div className='added-invitations-list__list-item__element'>
                {positions.find((f) => f.id == item.positionId)?.name}
              </div>
              <div className='added-invitations-list__list-item__element'>
                {departments.find((f) => f.id == item.departmentId)?.name}
              </div>
              <Button
                className='added-invitations-list__list-item__action'
                onClick={() => removeAction(item)}
              >
                {removeLabel}
              </Button>
            </List.Item>
          )}
        />
      ) : (
        <p className='added-invitations-list__empty'>{emptyListText}</p>
      )}
    </section>
  );
};
