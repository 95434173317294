import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ProfilePreviewModal from '../../../Modules/Providers/components/ProfilePreviewModal/ProfilePreviewModal';

const ProfilePreview = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { profileName } = useParams<{ profileName: string }>();
  const history = useHistory();

  useEffect(() => {
    setIsVisible(true);
  }, []);
  
  return (
    <ProfilePreviewModal
      profileName={profileName}
      afterClose={history.goBack}
      onClose={() => setIsVisible(false)}
      isVisible={isVisible}
    />
  );
};

export default ProfilePreview;
