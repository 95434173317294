import './WorkshopGeneralInfo.scss';
import { t } from '@inovirtue/localization';
import { NameAndDescIcon } from '../../icons/NameAndDescIcon';
import { Workshop } from '../../model/Workshop';
import { CardField } from '../CardField/CardField';
import { WorkshopAvatar } from '../WorkshopAvatar/WorkshopAvatar';
import { DetailsCardBase } from '../DetailsCardBase/DetailsCardBase';

export type WorkshopGeneralInfoProps = {
  workshop: Workshop;
};

export const WorkshopGeneralInfo = ({ workshop }: WorkshopGeneralInfoProps) => {
  return (
    <DetailsCardBase
      header={t('group:name-and-description-section.section-title')}
      icon={<NameAndDescIcon />}
    >
      <div className='group-general-info__content'>
        <WorkshopAvatar avatarCls='group-general-info__avatar' groupName={workshop.name} />
        <CardField
          label={t('group:name-and-description-section.name-input-title')}
          value={workshop.name}
        />
        <CardField
          label={t('group:name-and-description-section.description-input-title')}
          value={workshop.description}
        />
      </div>
    </DetailsCardBase>
  );
};
