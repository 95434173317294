import { Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useHistory, useParams } from 'react-router';
import routes from '../../../App/routes/constants/Routes';
import PartnerFormComponent from '../../../Modules/Partners/components/PartnerForm/PartnerForm';
import styles from './PartnerEditView.module.scss';

const PartnerEditView = (): JSX.Element => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <Modal isFullScreen={true} visible={true} onClose={() => push(routes.Partners)}>
      <>
        <h1>{t('partners:details.edit-header')}</h1>
        <h2 className={styles.editPartner__description}>
          {t('partners:details.create-description')}
        </h2>
        <PartnerFormComponent id={id} />
      </>
    </Modal>
  );
};

export default PartnerEditView;
