import {
  VideoCallSessionDetailsState,
  videoCallSessionDetailsStore,
} from './video-call-session.store';

export const setVideoCallSessionDetails = (details: VideoCallSessionDetailsState): void => {
  videoCallSessionDetailsStore.update((prev) => ({
    ...prev,
    ...details,
  }));
};
