import './ImageMessage.scss';
import { useImage } from '../../hooks/useImage/useImage';
import { MessageBaseProps } from '../../models/message';
import { Message } from '../Message/Message';

export type MediaMessageProps = MessageBaseProps;

export const ImageMessage = ({
  message,
  convoSid,
  isSameAsPrev,
}: MediaMessageProps): JSX.Element => {
  const { fileUrl } = useImage(message.sid);

  return (
    <Message
      isSameAsPrev={isSameAsPrev}
      convoSid={convoSid}
      author={message.author}
      dateCreated={message.dateCreated}
      contentCls='conversation-image-message__content'>
      {fileUrl && (
        <img
          src={fileUrl}
          className='conversation-image-message__image'
          data-testid='conversation-image-message'
        />
      )}
    </Message>
  );
};
