import { getDeviceInfo } from '../../utils';
import { audioStore } from './audio.store';
import { localAudioTrack$ } from '.';

export const initAudioInputDevices = async (): Promise<void> => {
  const { audioInputDevices, hasAudioInputDevices } = await getDeviceInfo();

  audioStore.update((prev) => {
    const hasSelectedAudioDevice = audioInputDevices.some(
      ({ deviceId }) => deviceId === prev.selectedAudioInputDeviceId,
    );
    const selectedAudioInputDeviceId = hasSelectedAudioDevice
      ? prev.selectedAudioInputDeviceId
      : undefined;

    return {
      ...prev,
      audioInputDevices: audioInputDevices,
      hasAudioInputDevices: hasAudioInputDevices,
      selectedAudioInputDeviceId,
    };
  });
};

export const initAudioOutputDevices = async (): Promise<void> => {
  const { audioOutputDevices, hasAudioOutputDevices } = await getDeviceInfo();
  audioStore.update((prev) => ({
    ...prev,
    audioOutputDevices: audioOutputDevices,
    hasAudioOutputDevices: hasAudioOutputDevices,
  }));
};

export const setLocalAudioTrack = (deviceId: string): void => {
  localAudioTrack$.getValue()?.restart({ deviceId: { exact: deviceId } });
  audioStore.update((prev) => ({
    ...prev,
    selectedAudioInputDeviceId: deviceId,
  }));
};

export const removeLocalAudioTrack = (): void => {
  const audioTrack = localAudioTrack$.getValue();

  if (audioTrack) {
    audioTrack.stop();
    audioStore.update((prev) => ({
      ...prev,
      localAudioTrack: undefined,
    }));
  }
};

export const setActiveSinkId = async (sinkId: string): Promise<void> => {
  audioStore.update((prev) => ({
    ...prev,
    activeSinkId: sinkId,
  }));
};

export const toggleIsAudioEnabled = (): void => {
  audioStore.update((prev) => ({
    ...prev,
    isAudioEnabled: !prev.isAudioEnabled,
  }));
};
