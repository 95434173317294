import Video from 'twilio-video';
import { t } from '@inovirtue/localization';
import { canJoin$, isConnecting$, roomName$ } from '../../store';
import './DeviceSelectionMobile.Component.scss';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview.Component';
import UnsupportedBrowserWarning from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning.Component';
import classNames from 'classnames';
import { useVideoCallJoin } from '../../hooks/useVideoCallJoin/useVideoCallJoin';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { Button } from '../Buttons/Button/Button.Component';
import { PhoneOutlined } from '@ant-design/icons';

export interface DeviceSelectionMobileProps {
  className?: string;
}

const DeviceSelectionMobile = ({ className }: DeviceSelectionMobileProps): JSX.Element => {
  const isConnecting = useBehaviorSubjectState(isConnecting$);
  const roomName = useBehaviorSubjectState(roomName$);
  const canJoin = useBehaviorSubjectState(canJoin$);
  const onJoinClick = useVideoCallJoin(roomName);

  const containerClass = classNames('device-selection-mobile', className);

  return (
    <>
      <div className={containerClass} data-testid='local-video-preview'>
        <LocalVideoPreview />
        <Button
          className='device-selection-mobile__join-button'
          spinning={isConnecting}
          disabled={!canJoin}
          type='primary'
          size='large'
          icon={<PhoneOutlined />}
          key='join-now'
          onClick={onJoinClick}
          data-testid='deviceselection-join-btn'
        >
          {t('videoCall:join-btn')}
        </Button>
        {!Video.isSupported && (
          <UnsupportedBrowserWarning
            className='device-selection-mobile__warning'
            data-testid='unsupported-browser-warning'
          />
        )}
      </div>
    </>
  );
};

export default DeviceSelectionMobile;
