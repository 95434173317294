import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { Modal } from '@inovirtue/components';
import { useHistory, useParams } from 'react-router';
import { Calendar } from 'react-big-calendar';
import useProviderCalendar from '../hooks/useProviderCalendar';
import { SchedulerEvent } from '../components/Calendar/SchedulerEvent';
import { BigCalendarToolbar } from '@inovirtue/components';
import { isTablet } from 'react-device-detect';
import SmallCalendar from '../components/SmallCalendar/SmallCalendar';
import ProfileNavBar from '../components/ProfileNavBar/ProfileNavBar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './ProviderCalendar.View.scss';
import { t } from '@inovirtue/localization';

const ProviderCalendar = () => {
  const { name } = useParams<{ name: string }>();
  const {
    date,
    localizer,
    setDate,
    onRangeChange,
    setView,
    view,
    events,
    onSideCalendarDateSet,
    loading,
  } = useProviderCalendar(name);
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <Modal
      visible={isVisible}
      onClose={() => setIsVisible(false)}
      afterClose={() => history.push('/coaches')}
      headerLeft={<ProfileNavBar profileName={name} />}
      isFullScreen
    >
      <Row gutter={{ md: 16, lg: 24 }}>
        <Col xxl={4} className='gutter-row providerCalendar-sidebar-column'>
          {!isTablet && (
            <SmallCalendar
              date={date}
              setDate={setDate}
              view={view}
              onSideCalendarDateSet={onSideCalendarDateSet}
            />
          )}
        </Col>
        <Col xs={24} md={24} lg={16} xl={16} xxl={16} className='gutter-row'>
          <Spin spinning={loading} size='large' delay={500}>
            <Calendar
              className={`providerCalendar ${view === 'day' ? 'providerCalendar-day-view' : ''}`}
              components={{
                event: SchedulerEvent,
                toolbar: (props) => (
                  <BigCalendarToolbar
                    {...props}
                    todayTranslation={t('scheduler:today')}
                    viewsTranslations={{
                      week: t('scheduler:week'),
                      month: t('scheduler:month'),
                      day: t('scheduler:day'),
                    }}
                  />
                ),
              }}
              date={date}
              localizer={localizer}
              view={view}
              events={events}
              formats={{
                timeGutterFormat: 'HH:mm',
              }}
              onNavigate={setDate}
              onRangeChange={onRangeChange}
              onView={setView}
              popup
            />
          </Spin>
        </Col>
      </Row>
    </Modal>
  );
};

export default ProviderCalendar;
