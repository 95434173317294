import './VideoCallSteps.Component.scss';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import StepFactory from './StepFactory/StepFactory';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { step$ } from '../../store/video-call.query';
import { Step } from '../../store/models/Step.model';
import { isSettingsOpen$ } from '../../store/video';
import SettingsSider from '../SettingsSider/SettingsSider';

export interface VideoCallStepsProps {
  className?: string;
}

const VideoCallSteps = ({ className }: VideoCallStepsProps): JSX.Element => {
  const roomState = useRoomState();
  const step = useBehaviorSubjectState(step$);
  const isSettingsOpen = useBehaviorSubjectState(isSettingsOpen$);

  const videoCallStepsCls = classNames('videocallsteps', className, {
    videocallsteps__callprep: step === Step.CallPrepStep,
    'videocallsteps--active': roomState !== 'disconnected',
    'videocallsteps--mobile': isMobile,
  });

  return (
    <div className={videoCallStepsCls}>
      <StepFactory {...{ step }} />
      {isSettingsOpen && <SettingsSider />}
    </div>
  );
};

export default VideoCallSteps;
