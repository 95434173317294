import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import './Popover.Component.scss';
import { PopoverContext } from './PopoverContext';
import { PopoverProps } from './Popover.model';
import PopoverHeader from './PopoverHeader/PopoverHeader.Component';
import { PopoverFooter } from './PopoverFooter/PopoverFooter.Component';
import useBorderScroll from './hooks/useBorderScroll';
import { useFullScreenHeight } from './hooks/useFullScreenHeight/useFullScreenHeight';
import { useExternalFooter } from './hooks/useExternalFooter';

export const Popover = ({
  title,
  headerLeft,
  headerRight,
  children,
  actions,
  isBack,
  isCloseIcon = true,
  onClose,
  onBack,
  isFullScreen = false,
  isBorder = true,
  isHeaderDivider,
  isFooterDivider,
  className,
  contentCls,
}: PopoverProps): JSX.Element => {
  const { isExternalFooter, footerHeight, footerRef } = useExternalFooter();
  const [isBorderVisible, contentRef, containerRef] = useBorderScroll();
  const fullScreenHeight = useFullScreenHeight(isFullScreen);

  const isHeaderVisible = (isCloseIcon && onClose) || headerLeft || title || headerRight;
  const isFooterVisible = !!actions?.length && !isExternalFooter;

  const popoverStyle: CSSProperties = fullScreenHeight ? { height: fullScreenHeight } : {};

  const popoverCls = classnames(['popover', className], {
    'popover--fullscreen': isFullScreen,
    'popover--border': isBorder && !isFullScreen,
  });
  const popoverContentCls = classnames(['popover__content', contentCls]);

  if (
    process.env.NODE_ENV !== 'production' &&
    !!actions &&
    !!footerHeight &&
    console !== undefined
  ) {
    console.error('Actions prop and external footer cannot be used at the same time!');
  }

  return (
    <PopoverContext.Provider
      value={{
        title,
        headerLeft,
        headerRight,
        actions,
        isBack,
        onBack,
        isCloseIcon,
        onClose,
        isHeaderDivider: isHeaderDivider || isBorderVisible,
        isFooterDivider,
        footerRef,
      }}
    >
      <div style={popoverStyle} className={popoverCls}>
        {isHeaderVisible && <PopoverHeader />}
        <div
          style={{ marginBottom: footerHeight ?? 0 }}
          className={popoverContentCls}
          ref={containerRef}
        >
          <div className='popover__content-inner' ref={contentRef}>
            {children}
          </div>
        </div>
        {isFooterVisible && <PopoverFooter />}
      </div>
    </PopoverContext.Provider>
  );
};

Popover.Footer = PopoverFooter as typeof PopoverFooter;
