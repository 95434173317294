import { Media } from '@twilio/conversations';
import { useEffect, useMemo, useState } from 'react';
import { getMediaTempUrl } from '../../api/media';
import { messageActions } from '../../service/messageService';

export const useImage = (messageSid: string) => {
  const attachedMedia = useMemo(() => messageActions.getAttachedMedia(messageSid), [messageSid]);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async (media: Media) => {
      const url = await getMediaTempUrl(media);
      setFileUrl(url);
    };

    if (!attachedMedia?.length) return;

    fetch(attachedMedia[0]);
  }, [attachedMedia]);

  return { fileUrl };
};
