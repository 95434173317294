import { WorkshopSlotList } from '../model/SlotModel';
import { v4 as uuidv4 } from 'uuid';

export const createSlots = (slots: number): WorkshopSlotList =>
  Array.from({ length: slots }, (_, i) => i + 1).map((el) => {
    const elId = uuidv4();
    return {
      number: el,
      id: elId,
      key: elId,
      disabled: true,
      active: false,
      date: undefined,
    };
  });
