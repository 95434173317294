import { Avatar, Col, Row, Tag, Typography } from 'antd';
import { Consumer } from '@inovirtue/admin';
import './ConsumerBar.scss';
import { t } from '@inovirtue/localization';
import { NewProviderRequest } from '../../../ConsumerRequests/models/domain/NewProviderRequest';
import { useFlags } from 'launchdarkly-react-client-sdk';

type ConsumerBarProps = {
  consumer?: Consumer;
  interests?: string[];
  newProviderRequest?: NewProviderRequest;
};

const { Paragraph } = Typography;

const ConsumerBar = ({ consumer, interests, newProviderRequest }: ConsumerBarProps) => {
  const { multipleCoaches } = useFlags();

  return (
    <div className={'consumerbar'}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <Avatar size={'large'} src={consumer?.avatarUrl} />
          <p className={'consumerbar__name'}>{consumer?.displayName}</p>
          <Paragraph className={'email'} copyable>
            {consumer?.email}
          </Paragraph>
        </Col>
      </Row>
      {multipleCoaches && newProviderRequest ? (
        <Row>
          <Col
            span={18}
            data-testid='consumerbar-new-provider-request-bar'
            className='consumerbar__new-provider-request'
          >
            <span className='consumerbar__new-provider-request__caption'>
              {t('consumer:request-coach.title')}
            </span>
            <p data-testid='consumerbar-new-provider-request-message'>
              {newProviderRequest?.message}
            </p>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col span={18}>
          <p className={'consumerbar__interests'}>{t('common:interests')}</p>
          {interests?.map((interest, index) => (
            <Tag key={index}>{t(`tags:tags.${interest}`)}</Tag>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default ConsumerBar;
