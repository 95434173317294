import { useCallback } from 'react';
import { updatePartnerProviders } from '../api/partnersApi';

export const usePartnerProvidersUpdate = () => {
  const updateProviders = useCallback(async (partnerId: string, providers: string[]) => {
    await updatePartnerProviders(partnerId, providers);
  }, []);

  return {
    updateProviders,
  };
};
