import { List } from 'antd';
import { Workshop } from '../../model/Workshop';
import { WorkshopListItem } from '../WorkshopListItem/WorkshopListItem';

export type WorkshopsListProps = {
  groups: Workshop[];
  showCompany?: boolean;
  onItemClick: (g: Workshop) => void;
};

export const WorkshopsList = ({ groups, showCompany = false, onItemClick }: WorkshopsListProps) => {
  return (
    <List
      className='groups-list'
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      dataSource={groups}
      renderItem={(workshop) => (
        <List.Item className='groups-list__item' onClick={() => onItemClick(workshop)}>
          <WorkshopListItem showCompany={showCompany} workshop={workshop} />
        </List.Item>
      )}
    />
  );
};
