import { createEntityStore } from '@datorama/akita';
import { Role } from '../../../Modules/Roles/models/role';

const STORE_KEY = 'profile';

export type ProfileState = {
  currentProfileName: string;
  currentProfileDisplayName: string;
  roles: Role[];
};

const initialState: ProfileState = {
  currentProfileName: '',
  currentProfileDisplayName: '',
  roles: [],
};

const profileStore = createEntityStore<ProfileState>(initialState, {
  name: STORE_KEY,
});

export default profileStore;
