import './WorkshopDeactivateButton.scss';
import { Button, Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useState } from 'react';
import { Workshop } from '../../model/Workshop';
import { DeleteOutlined } from '@ant-design/icons';

export type WorkshopDeactivateButtonProps = {
  workshop: Workshop;
  visible: boolean;
  enabled: boolean;
  onDeactivate: (workshop: Workshop) => Promise<void>;
};

export const WorkshopDeactivateButton = ({
  workshop,
  visible,
  onDeactivate,
  enabled,
}: WorkshopDeactivateButtonProps) => {
  const [isModalVisible, setModalVisible] = useState(false);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Button
        disabled={!enabled}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        <DeleteOutlined /> {t('common:deactivate')}
      </Button>
      <Modal
        contentCls='workshop-deactivate-confirm__modal-content'
        title={t('group:confirm-deactivate.title')}
        visible={isModalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        actions={[
          <Button
            key='deactivate'
            type='primary'
            className='workshop-deactivate-confirm__modal-content__accept'
            onClick={async () => {
              await onDeactivate(workshop);
              setModalVisible(false);
            }}
          >
            {t('group:confirm-deactivate.button-accept')}
          </Button>,
        ]}
      >
        <span className='workshop-deactivate-confirm__modal-content__question'>
          {t('group:confirm-deactivate.question')}
        </span>
      </Modal>
    </>
  );
};
