export const ImageExtensionEnum = {
  Png: '.png',
  Jpg: '.jpg',
  Jpeg: '.jpeg',
  Gif: '.gif',
} as const;

export const imageExtensions = Object.values(ImageExtensionEnum);

export const FileExtensionEnum = {
  Pdf: '.pdf',
  Word: '.docx',
  Excel: '.xlsx',
  PowerPoint: '.pptx',
  Txt: '.txt',
  Zip: '.zip',
  Mp3: '.mp3',
} as const;

export const fileExtensions = Object.values(FileExtensionEnum);
