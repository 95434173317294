import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { error$, isConnecting$ } from '../../../store';
import StepNotice from '../../StepNotice/StepNotice.Component';
import { t } from '@inovirtue/localization';
import { onRedirect, onRejoin } from '../../../store/video';
import { Button } from '../../Buttons/Button/Button.Component';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import './CallErrorStep.Component.scss';

const { Actions, Heading, Content, SubHeading, Profile } = StepNotice;

const CallErrorStep = (): JSX.Element => {
  const isConnecting = useBehaviorSubjectState(isConnecting$);
  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);
  const [error, setError] = useState<TwilioError | Error | null>(useBehaviorSubjectState(error$));

  const onDismissErrorClick = () => {
    setError(null);
    onRedirect();
  };

  useEffect(() => {
    const sub = error$.subscribe((e: TwilioError | Error | null) => {
      const { code } = e || {};
      setErrorCode(code);
    });

    return () => sub?.unsubscribe();
  }, [error]);

  return (
    <>
      <div className='callerrorstep' data-testid='videoCallErrorStep'>
        <Row>
          <Col>
            <StepNotice>
              <Profile />
              <Heading text={t('videoCall:call-error.heading')} />
              <SubHeading text={t('videoCall:call-error.subheading')} />
              <Content>
                {Boolean(errorCode) && (
                  <div className='callerrorstep__error-code'>
                    {t('videoCall:call-error.error-code', { errorCode })}
                  </div>
                )}
              </Content>
              <Actions>
                <Button
                  spinning={isConnecting}
                  type='primary'
                  key='rejoin-now'
                  onClick={onRejoin}
                  className='callerrorstep__button'
                >
                  {t('videoCall:rejoin-btn')}
                </Button>
                <Button
                  type='default'
                  className='callerrorstep__button'
                  onClick={onDismissErrorClick}
                >
                  {t('videoCall:dismiss-btn')}
                </Button>
              </Actions>
            </StepNotice>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CallErrorStep;
