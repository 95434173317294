import EmployeeInfo from '../models/EmployeeInfo';
import { t } from '@inovirtue/localization';
import { ColumnsType } from 'antd/lib/table';
import { formatOrDefault } from '@inovirtue/date-utils';
import dayjs from 'dayjs';
import { dateFormat } from '../const/formats';
import { Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined, MoreOutlined } from '@ant-design/icons';
import ActiveEmployeesCoachAvatars from '../components/ActiveEmployeesCoachAvatars/ActiveEmployeesCoachAvatars';

import './ActiveEmployeesColumns.scss';

const getActiveEmployeesColumns = (
  companiesMemo: { text: string | undefined; value: string }[],
  companyFilterFlag: boolean,
  onEditSessionClick: (name: string) => void,
): ColumnsType<EmployeeInfo> => [
  {
    title: t('company:employees:active-employees-table.name'),
    key: 'name',
    render: (employee: EmployeeInfo) => (
      <div className='active-employees-list-columns-name'>
        <Avatar size='small' src={employee.avatarUrl} icon={<UserOutlined />} />
        <div className='active-employees-list-columns-name-container'>
          <div>{employee.displayName}</div>
          <div>{employee.email}</div>
        </div>
      </div>
    ),
    fixed: 'left',
    width: 280,
  },
  {
    title: t('company:employees:active-employees-table.company'),
    key: 'company',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.company?.name}</span>
      </div>
    ),
    fixed: 'left',
    width: 100,
    filters: companyFilterFlag ? companiesMemo : undefined,
    filterMultiple: false,
    filterSearch: true,
  },
  {
    title: t('company:employees:active-employees-table.position'),
    key: 'position',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.position?.name}</span>
      </div>
    ),
    fixed: 'left',
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.coach'),
    key: 'coach',
    render: (employee: EmployeeInfo) => <ActiveEmployeesCoachAvatars employee={employee} />,
    fixed: 'left',
    width: 150,
  },
  {
    title: t('company:employees:active-employees-table.signup-date'),
    key: 'signupDate',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{dayjs(new Date(employee.whenCreated)).format(dateFormat)}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-assigned'),
    key: 'signupDate',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.sessionsSummary.assigned}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-booked'),
    key: 'booked',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.sessionsSummary.booked}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-completed'),
    key: 'completed',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.sessionsSummary.completed}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-not-attended'),
    key: 'notAttended',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.sessionsSummary.notAttended}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-cancelled-charged'),
    key: 'cancelledCharged',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.sessionsSummary.cancelledCharged}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-cancelled-not-charged'),
    key: 'cancelledNoCharge',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{employee.sessionsSummary.cancelledNoCharge}</span>
      </div>
    ),
    width: 100,
  },
  {
    title: t('company:employees:active-employees-table.sessions-last-session'),
    key: 'lastSession',
    render: (employee: EmployeeInfo) => (
      <div>
        <span>{formatOrDefault(employee.sessionsSummary.lastSession, dateFormat)}</span>
      </div>
    ),
    width: 100,
  },
  {
    key: 'actions',
    render: (employee: EmployeeInfo) => (
      <div>
        <Dropdown
          arrow
          placement='bottomRight'
          overlay={
            <Menu
              items={[
                {
                  label: t('consumer:set-sessions-modal:menu-button'),
                  key: `editSessions:${employee.name}`,
                  onClick: () => onEditSessionClick(employee.name),
                },
              ]}
            />
          }>
          <MoreOutlined />
        </Dropdown>
      </div>
    ),
    width: 100,
  },
];

export default getActiveEmployeesColumns;
