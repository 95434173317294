import {
  Conversation as TwilioConversation,
  LastMessage as TwilioLastMessage,
} from '@twilio/conversations';
import { Override } from './override';

export enum ConvoType {
  OneOnOne = 'OneOnOne',
  Workshop = 'Workshop',
  Support = 'Support',
}

export type SelectedConvoSid = TwilioConversation['sid'] | null;

export type SetSelectedConvoSid = (convoSid: SelectedConvoSid) => void;

export type OnConvoSelect = SetSelectedConvoSid;

export type FocusedConvoSid = SelectedConvoSid;

export type LastMessage = Override<TwilioLastMessage, { dateCreated: string | null }>;

export type ConvoAttributes = {
  type: ConvoType;
};

export type ConvoPicked = Pick<
  TwilioConversation,
  | 'attributes'
  | 'sid'
  | 'createdBy'
  | 'dateCreated'
  | 'dateUpdated'
  | 'friendlyName'
  | 'lastMessage'
  | 'lastReadMessageIndex'
  | 'limits'
  | 'uniqueName'
>;

export type Convo = Override<
  ConvoPicked,
  {
    attributes: ConvoAttributes;
    dateCreated: string | null;
    dateUpdated: string | null;
    lastMessage: LastMessage;
  }
>;

export type ConversationProperties = keyof Convo;

export const conversationProperties: ReadonlyArray<ConversationProperties> = [
  'attributes',
  'sid',
  'createdBy',
  'dateCreated',
  'dateUpdated',
  'friendlyName',
  'lastMessage',
  'lastReadMessageIndex',
  'limits',
  'uniqueName',
] as const;
