import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { setStep } from '../../store';
import { Step } from '../../store/models/Step.model';
import { currentProfile$ } from '../../store/participant';
import { isDisconnectedByUser$, onSessionJoinUnsubscribe, room$ } from '../../store/video';
import { sessionId$ } from '../../store/video-call-session';

export type VideoCallDisconnectType = (isError?: boolean) => Promise<void>;

export const useVideoCallDisconnect = (): VideoCallDisconnectType => {
  const room = useBehaviorSubjectState(room$);
  const isDisconnectedByUser = useBehaviorSubjectState(isDisconnectedByUser$);
  const currentProfile = useBehaviorSubjectState(currentProfile$);
  const sessionId = useBehaviorSubjectState(sessionId$);

  const disconnectCurrentSession = useCallback(
    async (isError?: boolean) => {
      try {
        await onSessionJoinUnsubscribe(sessionId, [currentProfile?.name]);
      } finally {
        if (isError) {
          setStep(Step.CallErrorStep);
        } else if (isDisconnectedByUser) {
          setStep(Step.CallLeftStep);
        } else {
          setStep(Step.CallEndedStep);
        }
        room?.disconnect();
      }
    },
    [sessionId, currentProfile?.name, isDisconnectedByUser, room],
  );

  return disconnectCurrentSession;
};
