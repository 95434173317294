import { Alert, Select } from 'antd';
import { Company } from '../../../Companies/models/domain/Company';
import './AssignedCompany.scss';
import { t } from '@inovirtue/localization';
import ValidationResult from '../../models/validationResult';

type AssignedCompanyProps = {
  companies?: Company[];
  selectedCompanyId?: string;
  onChange: (nextCompanyId: string) => void;
  validationResult: ValidationResult;
  showValidationErrors: boolean;
};

const AssignedCompany = ({
  companies,
  selectedCompanyId,
  onChange,
  validationResult,
  showValidationErrors,
}: AssignedCompanyProps) => (
  <div className='companyAssignment' data-testid='companyAssignment'>
    <p className='companyAssignment__heading'>{t('consumer:assign-company')}</p>
    <p className='companyAssignment__subheading'>{t('consumer:assign-company-subheading')}</p>
    <Select
      value={selectedCompanyId}
      className='companyAssignment__select'
      onChange={onChange}
      disabled
    >
      {companies?.map((company) => (
        <Select.Option key={company.companyId} value={company.companyId}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
    {showValidationErrors && !validationResult.isValid && (
      <Alert
        className='companyAssignment__validation'
        message={validationResult.validationMessage}
        type='error'
        showIcon
      />
    )}
  </div>
);

export default AssignedCompany;
