import { Typography } from 'antd';
import { UserProfile } from '../../models/UserProfile';
import AboutMe from '../AboutMe/AboutMe';
import Icebreaker from '../Icebreaker/Icebreaker';
import { LanguageList, Language } from '@inovirtue/components';
import TagList from '../TagList/TagList';
import { t } from '@inovirtue/localization';
import Philosophy from '../Philosophy/Philosophy';
import './ProviderProfile.scss';
import { useMediaQuery } from 'react-responsive';
import { ProfileCategoryTagTypes } from '../../models/ProfileTags';
import useProfileTagsList from '../../hooks/useProfileTagList';

const { Title, Paragraph } = Typography;
export interface ProviderProfileProps {
  userProfile: UserProfile;
  categories: Map<string, string[]>;
}

export const ProviderProfile = ({ userProfile, categories }: ProviderProfileProps): JSX.Element => {
  // TODO: move js breakpoints to external package
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const profileTags = useProfileTagsList(userProfile, categories, [
    'additionalLanguages',
    'expertise',
  ]);
  const languages = profileTags.get(ProfileCategoryTagTypes.additionalLanguages);
  const expertise = profileTags.get(ProfileCategoryTagTypes.expertise);

  return (
    <>
      <div className='profile-preview__header'>
        <div className='profile-preview__header-content'>
          {languages && <LanguageList languages={languages as Language[]} />}
          <Title className='profile-preview__title' level={1}>
            {t('profile:preview.greeting', { name: userProfile.displayName })}
          </Title>
          <Paragraph className='profile-preview__shortbio'>{userProfile.shortBio}</Paragraph>
          {isDesktop && (
            <Philosophy
              className='profile-preview__philosophy'
              description={userProfile.philosophy}
            />
          )}
        </div>
        <div
          className='profile-preview__avatar'
          style={{
            backgroundImage: `url(${userProfile.avatarUrl})`,
          }}
        />
      </div>
      <div className='profile-preview__content'>
        <div className='profile-preview__col-left'>
          {!isDesktop && (
            <Philosophy
              className='profile-preview__philosophy'
              description={userProfile.philosophy}
            />
          )}
          <AboutMe rows={5} description={userProfile.aboutMe} />
          <Icebreaker icebreaker={userProfile.icebreaker} />
        </div>
        <div className='profile-preview__col-right'>
          {expertise && <TagList title={t('profile:preview.titles.expertise')} tags={expertise} />}
        </div>
      </div>
    </>
  );
};
