import './ConversationsList.scss';
import { ReactNode } from 'react';
import { useRenderConversationsItems } from '../../hooks/useRenderConversationsItems/useRenderConversationsItems';
import { Convo } from '../../models/conversation';
import { Skeleton } from 'antd';
import { useConvoSelector } from '../../hooks/storeHooks/storeHooks';
import { selectIsInitialized } from '../../store/commonSlice';

export interface ConversationsListProps {
  renderItem: (conversation: Convo) => ReactNode;
  placeholder?: ReactNode;
}

export const ConversationsList = ({
  renderItem,
  placeholder,
}: ConversationsListProps): JSX.Element => {
  const isInitialized = useConvoSelector(selectIsInitialized);
  const { isConvos, conversationsItems } = useRenderConversationsItems(renderItem);

  return (
    <Skeleton
      className='conversations-list-skeleton'
      paragraph={{ rows: 4, width: '100%' }}
      loading={!isInitialized}
      active
      data-testid='conversations-list-skeleton'
    >
      {isConvos ? <div className='conversations-list'>{conversationsItems}</div> : placeholder}
    </Skeleton>
  );
};
