import { Conversation, Message, Paginator } from '@twilio/conversations';
import { RcFile } from 'antd/lib/upload';
import { getMessages, sendMediaMessage, sendTextMessage } from '../api/messages';
import { setAllMessagesRead } from '../api/unreadMessages';

type Sid = Conversation['sid'];

type ConversationEntity = Map<Sid, Conversation>;

const arrayToMap = (conversations: Conversation[]): ConversationEntity =>
  new Map(conversations.map((conversation) => [conversation.sid, conversation]));

class ConversationService {
  private static instance: ConversationService;

  private conversations: ConversationEntity = new Map();

  conversationsPaginator?: Omit<Paginator<Conversation>, 'items'>;

  messagePaginators: Map<Sid, Omit<Paginator<Message>, 'items'>> = new Map();

  constructor() {
    if (ConversationService.instance) {
      return ConversationService.instance;
    }

    ConversationService.instance = this;
  }

  setAll(conversations: Paginator<Conversation>) {
    const { items, ...paginateProps } = conversations;
    this.conversationsPaginator = paginateProps;
    this.conversations = arrayToMap(items);
  }

  addConversation(conversation: Conversation) {
    this.conversations.set(conversation.sid, conversation);
  }

  upsertConversation(conversation: Conversation) {
    this.conversations.set(conversation.sid, conversation);
  }

  async getMessages(convoSid: Sid): Promise<Paginator<Message>> {
    const conversation = this.conversations.get(convoSid);
    if (!conversation) return Promise.reject();

    const { items, ...newPaginator } = await getMessages(conversation);
    const paginator = this.messagePaginators.get(convoSid);

    if (!paginator) {
      this.messagePaginators.set(convoSid, newPaginator);
      return { items, ...newPaginator };
    }

    return { items, ...newPaginator, hasPrevPage: paginator.hasPrevPage };
  }

  async getPrevMessages(convoSid: Sid): Promise<Paginator<Message>> {
    const paginator = this.messagePaginators.get(convoSid);
    if (!paginator || !paginator.hasPrevPage) return Promise.reject();

    const { items, ...newPaginator } = await paginator.prevPage();
    this.messagePaginators.set(convoSid, newPaginator);

    return { items, ...newPaginator };
  }

  sendMessage(sid: Sid, body: string | RcFile): Promise<number> {
    const conversation = this.conversations.get(sid);
    if (!conversation) return Promise.reject();

    if (typeof body === 'string') {
      return sendTextMessage(conversation, body);
    }

    return sendMediaMessage(conversation, body);
  }

  setAllMessagesRead(sid: Sid): Promise<number> {
    const conversation = this.conversations.get(sid);
    if (!conversation) return Promise.reject();

    return setAllMessagesRead(conversation);
  }
}

export const conversationActions = new ConversationService();
