import './WorkshopEditMembersView.scss';
import { WorkshopEditMembers } from '@inovirtue/admin';
import { Button, Loader, Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useWorkshopEditMembers } from '../../hooks/useWorkshopEditMembers';

export const WorkshopEditMembersView = () => {
  const { navigateBack, updateSelectedMembers, saveChanges, employees, members, loaded } =
    useWorkshopEditMembers();

  return (
    <Modal
      isFullScreen={true}
      visible={true}
      onClose={navigateBack}
      contentCls='workshop-add-members-view__modal-content'
    >
      <div className='workshop-add-members-view'>
        <h1>{t('group:edit-group-members.title')}</h1>
        {loaded ? (
          <>
            <WorkshopEditMembers
              initialMembers={members?.map((x) => x.name)}
              allEmployees={employees}
              onChanged={updateSelectedMembers}
            />
            <Button
              className='workshop-add-members-view__save'
              type='primary'
              onClick={saveChanges}
            >
              {t('group:edit-group-members.save-members')}
            </Button>
          </>
        ) : (
          <Loader spaceSize={'middle'} spinSize={'default'} />
        )}
      </div>
    </Modal>
  );
};
