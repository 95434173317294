import AudioInputList from '../AudioInputList/AudioInputList.Component';
import AudioOutputList from '../AudioOutputList/AudioOutputList.Component';
import VideoInputList from '../VideoInputList/VideoInputList.Component';
import { t } from '@inovirtue/localization';
import { Button } from '@inovirtue/components';
import { SoundOutlined } from '@ant-design/icons';
import './InputOutputDevices.scss';
import LocalVideoPreview from '../LocalVideoPreview/LocalVideoPreview.Component';
import testSound from './test-sound.mp3';
import { useTestAudio } from '../../../hooks/useTestAudio/useTestAudio';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

export interface InputOutputDevicesProps {
  className?: string;
  hideVideoPreview?: boolean;
}

const InputOutputDevices = ({
  className,
  hideVideoPreview,
}: InputOutputDevicesProps): JSX.Element => {
  const toggleTestSound = useTestAudio(testSound);
  const containerClass = classNames('input-output-selection', className);
  const inputClass = classNames('input-output-selection__inputs', {
    ['input-output-selection__inputs--fullWidth']: hideVideoPreview,
  });

  return (
    <div className={containerClass}>
      <div className='input-output-selection__controlls'>
        <div className={inputClass}>
          <VideoInputList {...{ isMobile }} className='input-output-selection__select' />
          <AudioInputList className='input-output-selection__select' />
          <AudioOutputList className='input-output-selection__select-output' />
          <Button
            icon={<SoundOutlined />}
            onClick={toggleTestSound}
            data-testid='test-sound-btn'
            className='input-output-selection__testBtn'
          >
            {t('videoCall:test-btn')}
          </Button>
        </div>
        {!hideVideoPreview && <LocalVideoPreview />}
      </div>
    </div>
  );
};

export default InputOutputDevices;
