import { ReactNode } from 'react';
import { NavButton, NavButtonProps } from '../NavButton/NavButton';
import './ConversationNav.scss';

export interface ConversationNavProps extends NavButtonProps {
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
}

export const ConversationNav = ({
  headerLeft,
  headerRight,
  ...navButtonProps
}: ConversationNavProps): JSX.Element => {
  return (
    <nav className='conversation-nav'>
      <div className='conversation-nav__left'>
        <NavButton {...navButtonProps} />
        {headerLeft}
      </div>
      <div className='conversation-nav__right'>{headerRight}</div>
    </nav>
  );
};
