import { env } from '../env';

const {
  REACT_APP_API_GATEWAY,
  REACT_APP_AAD_APP_CLIENT,
  REACT_APP_AAD_APP_API,
  REACT_APP_AAD_TENANT_ID,
  REACT_APP_COMPANY_COCKPIT_URL,
  REACT_APP_CUSTOMER_URL,
  REACT_APP_LAUNCH_DARKLY_CLIENT,
} = env;

const apiGatewayBaseUrl = REACT_APP_API_GATEWAY;

const apiAddresses = {
  socialNetworkService: REACT_APP_API_GATEWAY,
  schedulingService: REACT_APP_API_GATEWAY,
  communicationService: REACT_APP_API_GATEWAY,
};

const pagination = {
  pageSize: 20,
};

const apiMocks = {
  enabled: false,
};

const msalAuthConfig = {
  auth: {
    clientId: REACT_APP_AAD_APP_CLIENT,
    authority: `https://login.microsoftonline.com/${REACT_APP_AAD_TENANT_ID}`,
    redirectUri: '/signed-in',
  },

  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

const msalScopes = [
  `api://${REACT_APP_AAD_APP_API}/BackOffice.Write`,
  `api://${REACT_APP_AAD_APP_API}/BackOffice.Read`,
];

const msalConfig = {
  msalAuthConfig,
  scopes: msalScopes,
};

const launchDarkly = {
  clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT,
};

const otherApps = {
  companyCockpit: REACT_APP_COMPANY_COCKPIT_URL,
  customerApp: REACT_APP_CUSTOMER_URL,
};

const twilioChatConfig = {
  twilioTokenServerUrl: `${apiAddresses.communicationService}/Chat`,
};

const twilioVideoConfig = {
  twilioTokenServerUrl: `${apiGatewayBaseUrl}/VideoCall`,
  twilioConversationServiceDisabled: true,
};

const config = {
  apiAddresses,
  pagination,
  msalConfig,
  apiMocks,
  launchDarkly,
  otherApps,
  twilioChatConfig,
  twilioVideoConfig,
};

export default config;
