import { useEffect, useMemo, useRef, useState } from 'react';
import { OnConvoSelect, SelectedConvoSid } from '../../models/conversation';
import { selectIsInitialized } from '../../store/commonSlice';
import { selectConversationIds } from '../../store/conversationsSlice';
import { useConvoSelector } from '../storeHooks/storeHooks';

export const useLayout = (
  isTabView: boolean,
  onConversationSelect?: OnConvoSelect,
  activeConvoSid?: SelectedConvoSid,
) => {
  const isInitialized = useConvoSelector(selectIsInitialized);
  const onConversationSelectRef = useRef(onConversationSelect);
  const [selectedConvoSid, setSelectedConvoSid] = useState<SelectedConvoSid>(null);
  const convoSids = useConvoSelector(selectConversationIds);

  useEffect(() => {
    if (isInitialized && activeConvoSid) setSelectedConvoSid(activeConvoSid);

    if (!isInitialized || selectedConvoSid || convoSids.length === 0 || isTabView) return;

    setSelectedConvoSid(String(convoSids[0]));
  }, [isInitialized, convoSids, selectedConvoSid, setSelectedConvoSid, isTabView, activeConvoSid]);

  useEffect(() => {
    const convoSid = activeConvoSid !== undefined ? activeConvoSid : selectedConvoSid;
    onConversationSelectRef.current?.(convoSid);
  }, [activeConvoSid, selectedConvoSid]);

  const isConversation = useMemo(
    () => !!selectedConvoSid && convoSids.includes(selectedConvoSid),
    [selectedConvoSid, convoSids],
  );

  const isConversationsList = useMemo(
    () => !isConversation || !isTabView,
    [isConversation, isTabView],
  );

  return {
    selectedConvoSid: activeConvoSid || selectedConvoSid,
    setSelectedConvoSid,
    isConversationsList,
    isConversation,
  };
};
