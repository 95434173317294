import IcebreakerItem from './IcebreakerItem/IcebreakerItem';
import './Icebreaker.scss';
import { t } from '@inovirtue/localization';
import { Typography } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { UserProfile } from '../../models/UserProfile';
import { AccomplishmentSvg, GratefulSvg, ReadSvg, WordsSvg } from '@inovirtue/assets';

const { Text } = Typography;

export interface IcebreakerProps {
  icebreaker: UserProfile['icebreaker'];
}

const Icebreaker = ({ icebreaker }: IcebreakerProps): JSX.Element => {
  const descriptiveWords = (
    <div className='icebreaker__descriptive-words'>
      {icebreaker?.descriptiveWords.map((word) => (
        <Text key={uuidv4()}>{word}</Text>
      ))}
    </div>
  );

  return (
    <div className='icebreaker'>
      <IcebreakerItem
        icon={<GratefulSvg />}
        title={t('profile:preview.icebreaker.grateful-for')}
        description={icebreaker?.greatfulFor}
      />
      <IcebreakerItem
        icon={<WordsSvg />}
        title={t('profile:preview.icebreaker.descriptive-words')}
        description={descriptiveWords}
      />
      <IcebreakerItem
        icon={<AccomplishmentSvg />}
        title={t('profile:preview.icebreaker.accomplishment')}
        description={icebreaker?.accomplishments}
      />
      <IcebreakerItem
        icon={<ReadSvg />}
        title={t('profile:preview.icebreaker.recentlyRead')}
        description={icebreaker?.recentlyRead}
      />
    </div>
  );
};

export default Icebreaker;
