import { CompanyContact } from '@inovirtue/company';
import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback, useMemo } from 'react';
import { getCompanyInvitations } from '../api/companiesApi';

export const useCompanyInvitationsList = (companyId: string) => {
  const fetchContacts = useCallback(() => getCompanyInvitations(companyId), [companyId]);
  const { data, isProcessing, process } = usePromiseWithProgress<CompanyContact[]>(fetchContacts);
  const canDelete = useMemo(() => data && data?.length > 1, [data]);
  const refresh = useCallback(() => {
    process();
  }, [process]);

  return {
    contacts: data,
    isProcessing,
    refresh,
    canDelete,
  };
};
