import './MarketingButtonWithLoader.scss';
import classNames from 'classnames';
import React from 'react';
import {
  ButtonWithLoader,
  ButtonWithLoaderProps,
} from '../ButtonWithLoader/ButtonWithLoader.component';

export type MarketingButtonWithLoaderProps = Omit<ButtonWithLoaderProps, 'type'>;

export const MarketingButtonWithLoader = ({
  className,
  children,
  ...buttonProps
}: MarketingButtonWithLoaderProps): JSX.Element => {
  const marketingButtonCls = classNames('marketing-button', className);

  return (
    <ButtonWithLoader type='primary' className={marketingButtonCls} {...buttonProps}>
      {children}
    </ButtonWithLoader>
  );
};
