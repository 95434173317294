import { createStore } from '@datorama/akita';
import { ReactNode } from 'react';

const STORE_KEY = 'videoCallSessionDetailsStore';

export interface VideoCallSessionDetailsState {
  sessionId: string;
  startDate: string;
  endDate: string | null;
  workshopId?: string;
  avatarUrl?: string;
  displayName?: string;
  description?: string;
  leftCall?: ReactNode;
  chatButton?: ReactNode;
}

const initialState: VideoCallSessionDetailsState = {
  sessionId: '',
  startDate: '',
  endDate: null,
};

export const videoCallSessionDetailsStore = createStore<VideoCallSessionDetailsState>(
  initialState,
  {
    name: STORE_KEY,
  },
);
