import { Modal } from '@inovirtue/components';
import { ProviderProfile } from '@inovirtue/profile';
import { Spin } from 'antd';
import { useCategoryTags } from '../../../../Modules/ProfileTags/hooks/useCategoryTags';
import { useProviderDetails } from '../../hooks/useProviderDetails';
import './ProviderPreviewModal.scss';

type ProfilePreviewModalProps = {
  profileName: string;
  onClose: () => void;
  afterClose?: () => void;
  isVisible: boolean;
};

const ProfilePreviewModal = ({
  profileName,
  onClose,
  afterClose,
  isVisible,
}: ProfilePreviewModalProps): JSX.Element => {
  const { provider } = useProviderDetails(profileName);
  const { categoryTags, isProcessing } = useCategoryTags();

  return (
    <Modal
      visible={isVisible}
      onClose={onClose}
      afterClose={afterClose}
      isFullScreen
      isFooterDivider
      contentCls='provider-preview-modal__content'
    >
      {isProcessing && <Spin />}
      {!isProcessing && provider && categoryTags && (
        <ProviderProfile userProfile={provider} categories={categoryTags} />
      )}
    </Modal>
  );
};

export default ProfilePreviewModal;
