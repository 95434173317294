import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { localAudioTrack$ } from '../../store/audio';
import { localVideoTrack$, room$ } from '../../store/video';

export interface CameraSwitchOffType {
  switchOffMediaTracks: () => void;
}

export const useMediaTracksSwitchOff = (): CameraSwitchOffType => {
  const room = useBehaviorSubjectState(room$);
  const localVideoTrack = useBehaviorSubjectState(localVideoTrack$);
  const localAudioTrack = useBehaviorSubjectState(localAudioTrack$);

  const switchOffMediaTracks = useCallback(() => {
    localVideoTrack?.stop();
    localAudioTrack?.stop();

    if (!room?.localParticipant) return;

    const tracks = Array.from(room?.localParticipant?.tracks?.values()) ?? [];
    const mediaTracks = tracks?.filter(
      (p) =>
        p.trackName.includes('screen') ||
        (p.trackName.startsWith('camera') && p.kind === 'video') ||
        p.kind === 'audio',
    );
    mediaTracks.forEach((mediaTrack) => {
      if (!mediaTrack?.track) return;
      room.localParticipant.unpublishTrack(mediaTrack?.track);
      room.localParticipant.emit('trackUnpublished', mediaTracks);
      (mediaTrack?.track as unknown as MediaStreamTrack).stop();
    });
  }, [localVideoTrack, localAudioTrack, room?.localParticipant]);

  return { switchOffMediaTracks };
};
