import { Conversation } from '@twilio/conversations';
import { RcFile } from 'antd/lib/upload';

export const getMessages = (conversation: Conversation) => conversation.getMessages();

export const sendTextMessage = (conversation: Conversation, body: string) => {
  return conversation.sendMessage(body);
};

export const sendMediaMessage = (conversation: Conversation, file: RcFile) => {
  const fileData = new FormData();
  fileData.set(file.name, file, file.name);
  return conversation.sendMessage(fileData);
};
