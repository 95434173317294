import { Workshop } from './Workshop';

export enum SessionStatus {
  Booked = 'Booked',
  Cancelled = 'Cancelled',
}

export type Session = {
  id: string;
  plannedStartDateTime: Date;
  plannedEndDateTime: Date;
  canJoin: boolean;
  joinVideoCall: (sessionId: string) => void;
  isChargeable: boolean;
  workshop?: Workshop;
  status?: SessionStatus;
};
