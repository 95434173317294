import { useMemo } from 'react';
import { MessageBaseProps } from '../../models/message';
import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { Message } from '../Message/Message';

export type TextMessageProps = MessageBaseProps;

export const TextMessage = ({ message, convoSid, isSameAsPrev }: TextMessageProps): JSX.Element => {
  const htmlMarkup = useMemo(() => createMarkup(message?.body ?? ''), [message.body]);

  return (
    <Message
      isSameAsPrev={isSameAsPrev}
      convoSid={convoSid}
      author={message.author}
      dateCreated={message.dateCreated}>
      <span data-testid='text-message-body' dangerouslySetInnerHTML={htmlMarkup} />
    </Message>
  );
};
