import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useEffect, useMemo, useState } from 'react';
import { fetchCategoryTags } from '../api/Providers.Api';

export interface CategoryTagsType {
  categoryTags: Map<string, string[]>;
  isProcessing: boolean;
}

export const useCategoryTags = (): CategoryTagsType => {
  const [categoryTags, setCategoryTags] = useState<Map<string, string[]>>(
    new Map<string, string[]>(),
  );

  const fetchCategoryTagsMemo = useMemo(() => () => fetchCategoryTags(), []);

  const { data, isProcessing } =
    usePromiseWithProgress<Map<string, string[]>>(fetchCategoryTagsMemo);

  useEffect(() => {
    if (data) setCategoryTags(data);
  }, [data]);

  return { categoryTags, isProcessing };
};
