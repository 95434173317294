import './PartnerEditProviderView.scss';
import { PartnerEditProviders } from '@inovirtue/admin';
import { Button, Loader, Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useSearchProvider } from '../../../Modules/Provider/hooks/useSearchProvider';
import { usePartnerEditProviders } from '../../../Modules/Partners/hooks/usePartnerEditProviders';
import { debounce } from 'lodash';

export const PartnerEditProviderView = () => {
  const {
    providers: initialProviders,
    partner,
    isLoading,
    navigateBack,
    updateSelectedMembers,
    saveChanges,
  } = usePartnerEditProviders();
  const { providers, searchProviders } = useSearchProvider();
  return (
    <Modal
      isFullScreen={true}
      visible={true}
      onClose={navigateBack}
      contentCls='partner-edit-provider-view__modal-content'
    >
      <div className='partner-edit-provider-view'>
        <h1>{t('partners:actions.edit-providers')}</h1>
        {!isLoading && partner ? (
          <>
            <PartnerEditProviders
              partner={partner}
              providers={providers}
              initialProviders={initialProviders}
              onChanged={updateSelectedMembers}
              onSearchProvider={debounce(searchProviders, 500)}
            />
            <Button
              className='partner-edit-provider-view__save'
              type='primary'
              onClick={saveChanges}
            >
              {t('common:save')}
            </Button>
          </>
        ) : (
          <Loader spaceSize='large' spinSize='large' />
        )}
      </div>
    </Modal>
  );
};
