import { useEffect } from 'react';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { setError } from '../../store';
import { room$ } from '../../store/video';

export default function useHandleTrackPublicationFailed(): void {
  const room = useBehaviorSubjectState(room$);
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', setError);
      return () => {
        room.localParticipant.off('trackPublicationFailed', setError);
      };
    } else {
      return;
    }
  }, [room]);
}
