import { Form, Input, Button, DatePicker } from 'antd';
import './CompanyForm.scss';
import { t } from '@inovirtue/localization';
import { Company } from '../../models/domain/Company';
import { useHistory } from 'react-router';
import { dateFormat } from '../../../../App/constants/date';

type CompanyFormProps = {
  onSubmit: (formData: Company) => void;
  formData?: Company;
};

const CompanyForm = ({ onSubmit, formData }: CompanyFormProps) => {
  const history = useHistory();
  return (
    <div data-testid='company-form'>
      <Form
        initialValues={formData}
        layout={'vertical'}
        onFinish={async (values) => {
          await onSubmit(values as Company);
          history.push('/companies');
        }}
      >
        <Form.Item name='companyId' style={{ display: 'none' }}>
          <Input type='text' />
        </Form.Item>
        <section className='form-section'>
          <p className='form-section__header'>{t('company:information')}</p>
          <Form.Item
            name={'name'}
            label={t('company:name')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', { fieldName: t('company:name') }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </section>
        <section className='form-section'>
          <p className='form-section__header'>{t('company:address')}</p>
          <Form.Item
            name={['addressLine1']}
            label={t('common:address.address-line-1')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('common:address.address-line-1'),
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={['addressLine2']} label={t('common:address.address-line-2')}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['postalCode']}
            label={t('common:address.postal-code')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('common:address.postal-code'),
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['city']}
            label={t('common:address.town-or-city')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('common:address.town-or-city'),
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['country']}
            label={t('common:address.country')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('common:address.country'),
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </section>
        <section className='form-section'>
          <p className='form-section__header'>{t('company:contract-details')}</p>
          <Form.Item
            name={['licenseStartDate']}
            label={t('company:contract-start')}
            rules={[
              {
                required: true,
                message: t('validation-message.field-required', {
                  fieldName: t('company:contract-start'),
                }),
              },
            ]}
          >
            <DatePicker className='form-section__date-picker' format={dateFormat} />
          </Form.Item>
          <Form.Item name={['licenseEndDate']} label={t('company:contract-end')}>
            <DatePicker className='form-section__date-picker' format={dateFormat} />
          </Form.Item>
        </section>
        <Form.Item>
          <Button data-testid='submit' htmlType='submit' type='primary'>
            {t('common:save')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompanyForm;
