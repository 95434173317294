import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useCallback, useMemo, useState } from 'react';
import { PositionsList } from '../../model/PositionModel';
export interface UseAddPistionsParams {
  positions: PositionsList;
  submitNewPositions: (newPositions: string[]) => Promise<void>;
  getPositions: () => Promise<void> | void;
}

const useAddPositions = ({ positions, submitNewPositions, getPositions }: UseAddPistionsParams) => {
  const [newPositions, setNewPositions] = useState<string[]>([]);
  const [validate, setValidate] = useState(true);
  const [actualPosition, setActualPosition] = useState<string>('');

  const submitDisabled = useMemo(() => {
    return newPositions.length === 0;
  }, [newPositions]);

  const addPosition = useCallback(() => {
    if (
      positions.find((el) => el.name === actualPosition) ||
      newPositions.includes(actualPosition)
    ) {
      setValidate(false);
    } else {
      setNewPositions([...newPositions, actualPosition]);
      setValidate(true);
      setActualPosition('');
    }
  }, [actualPosition, setNewPositions, newPositions, positions]);

  const submitPositions = useCallback(async () => {
    try {
      await submitNewPositions(newPositions);
      getPositions();
      showToast(t('common:save-success'));
    } catch (error) {
      showToast(t('errors:unhandled-error'));
    }
  }, [newPositions, getPositions, submitNewPositions]);

  const clearAllAction = () => {
    setValidate(true);
    setNewPositions([]);
    setActualPosition('');
  };

  return {
    newPositions,
    addPosition,
    validate,
    actualPosition,
    setActualPosition,
    submitPositions,
    submitDisabled,
    clearAllAction,
  };
};

export default useAddPositions;
