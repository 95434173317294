import React, { CSSProperties } from 'react';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { Popover } from '../Popover/Popover.Component';
import { PopoverProps } from '../Popover/Popover.model';
import classNames from 'classnames';
import './Modal.Component.scss';
import { useMediaQuery } from 'react-responsive';
import { PopoverFooter } from '../Popover/PopoverFooter/PopoverFooter.Component';

export interface ModalProps
  extends PopoverProps,
    Pick<
      AntModalProps,
      'afterClose' | 'bodyStyle' | 'mask' | 'maskClosable' | 'destroyOnClose' | 'width'
    > {
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  modalCls?: string;
  modalWrapCls?: string;
  isAnimationDisabled?: boolean;
}

export const Modal = ({
  children,
  visible,
  afterClose,
  bodyStyle,
  mask,
  maskClosable,
  destroyOnClose = true,
  isFullScreen: isModalFullScreen,
  isBorder = false,
  isAnimationDisabled = false,
  className: popoverCls,
  modalCls,
  modalWrapCls,
  width,
  style,
  ...props
}: ModalProps): JSX.Element => {
  const isFullScreen = useMediaQuery({ maxWidth: 576 }) ? true : isModalFullScreen;

  const className = classNames({ 'popover-modal__popover': !isFullScreen }, popoverCls);

  const antModalCls = classNames(['popover-modal', modalCls], {
    'popover-modal--fullscreen': isFullScreen,
    'popover-modal--animation-disabled': isAnimationDisabled,
  });

  const defaultWidth = width ? width : 520;
  const modalWidth = isFullScreen ? 'auto' : defaultWidth;

  const maskStyle: CSSProperties | undefined = isFullScreen
    ? { background: 'transparent' }
    : undefined;

  return (
    <AntModal
      className={antModalCls}
      style={style}
      wrapClassName={modalWrapCls}
      footer={null}
      closable={false}
      onCancel={props.onClose}
      width={modalWidth}
      {...{ visible, afterClose, bodyStyle, mask, maskClosable, destroyOnClose, maskStyle }}
    >
      <Popover {...props} {...{ isBorder, isFullScreen, className }}>
        {children}
      </Popover>
    </AntModal>
  );
};

Modal.Footer = PopoverFooter as typeof PopoverFooter;
