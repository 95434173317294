import React, { useMemo } from 'react';
import { AppLayout } from '@inovirtue/admin';
import { UnorderedListOutlined, MessageOutlined } from '@ant-design/icons';
import { t } from '@inovirtue/localization';
import routes from '../App/routes/constants/Routes';
import { generatePath, useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useChatVisibility } from '../Modules/Chat/hooks/useChatVisibility/useChatVisibility';
import { BackofficeLogo } from './BackofficeLogo/BackofficeLogo';
import ChatMenuLabel from '../Modules/Chat/components/ChatMenuLabel/ChatMenuLabel';

export interface BackofficeLayoutProps {
  children: React.ReactElement;
  headerContent?: React.ReactNode;
  showHeader?: boolean;
}
const BackofficeLayout = ({
  children,
  headerContent,
  showHeader = true,
}: BackofficeLayoutProps): JSX.Element => {
  const history = useHistory();
  const { groupsListFlag } = useFlags();
  const { isChatVisible } = useChatVisibility();

  const menuItems = useMemo(
    () => [
      { label: t('common:coaches'), path: routes.ProvidersList, icon: <UnorderedListOutlined /> },
      { label: t('common:companies'), path: routes.CompaniesList, icon: <UnorderedListOutlined /> },
      ...(groupsListFlag
        ? [
            {
              label: t('group:module-name'),
              path: routes.WorkshopsList,
              icon: <UnorderedListOutlined />,
            },
          ]
        : []),
      {
        label: t('common:employees'),
        path: routes.Employees,
        icon: <UnorderedListOutlined />,
      },
      ...(isChatVisible
        ? [
            {
              label: <ChatMenuLabel />,
              path: generatePath(routes.Chat),
              icon: <MessageOutlined />,
            },
          ]
        : []),
      {
        label: t('partners:label'),
        path: routes.Partners,
        icon: <UnorderedListOutlined />,
      },
    ],
    [groupsListFlag, isChatVisible],
  );
  return (
    <AppLayout
      headerContent={headerContent}
      showHeader={showHeader}
      onLogout={() => history.push(routes.SignedOut)}
      onNavigate={history.push}
      menuItems={menuItems}
      logo={<BackofficeLogo />}
    >
      {children}
    </AppLayout>
  );
};

export default BackofficeLayout;
