import { PlusOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from '@inovirtue/components';
import { AddPositionsModal } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';

import { useState } from 'react';
import { PositionsList } from '@inovirtue/admin/dist/model/PositionModel';

interface AddPositionButtonProps extends ButtonProps {
  companyId: string;
  fetchPositions: (companyId: string) => Promise<void>;
  positions: PositionsList;
  submitPositions: (newPositions: string[]) => Promise<void>;
}
const AddPositionButton = ({
  companyId,
  positions,
  fetchPositions,
  submitPositions,
  ...rest
}: AddPositionButtonProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setIsVisible(true)} icon={<PlusOutlined />} {...rest}>
        {t('cockpit:positions:add-new-postions')}
      </Button>
      <AddPositionsModal
        addLabel={t('common:add')}
        visible={isVisible}
        positions={positions}
        getPositions={() => fetchPositions(companyId)}
        submitNewPositions={submitPositions}
        onClose={() => setIsVisible(false)}
        cancelLabel={t('common:cancel')}
        saveLabel={t('common:save')}
        positionModalDescription={t('cockpit:positions.modal-description')}
        modalTitle={t('cockpit:positions.add-new-postions')}
        inputLabel={t('cockpit:positions:new-position')}
        errorMessage={t('cockpit:positions:position-validation')}
      />
    </>
  );
};

export default AddPositionButton;
