import Chat from '../components/Chat/Chat';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import { LayoutHeader } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { useHistory } from 'react-router';
import './ChatFullScreen.scss';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../Styles/breakpoints/breakpoints';

const chatBreakpoint = Number(Breakpoints.mdBreak.slice(0, -2)) + 200;

const ChatFullScreen = (): JSX.Element => {
  const { push } = useHistory();
  const isTabView = useMediaQuery({ maxWidth: chatBreakpoint });

  return (
    <div className='chat-fullscreen' data-testid='chat-view'>
      <BackofficeLayout
        headerContent={<LayoutHeader title={t('chat:messages')} onNavigate={push} />}
        showHeader={false}>
        <Chat isTabView={isTabView} />
      </BackofficeLayout>
    </div>
  );
};

export default ChatFullScreen;
