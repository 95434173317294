import React, { ReactNode } from 'react';
import {
  Danish,
  Dutch,
  English,
  French,
  German,
  Italian,
  Polish,
  Portugese,
  Russian,
  Spanish,
  Turkish,
} from '@inovirtue/assets';
import { Language } from '../model/Language.model';

export const mappedLanguageIcons: { [key in Language]: ReactNode } = {
  french: <French />,
  english: <English />,
  spanish: <Spanish />,
  portugese: <Portugese />,
  danish: <Danish />,
  dutch: <Dutch />,
  italian: <Italian />,
  polish: <Polish />,
  russian: <Russian />,
  turkish: <Turkish />,
  german: <German />,
};
