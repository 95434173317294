import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

export interface ErrorContextType {
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
}

export const ErrorContext = React.createContext<ErrorContextType | undefined>(undefined);

export interface ErrorProviderProps {
  children: ReactNode;
}

export const ErrorProvider = ({ children }: ErrorProviderProps): JSX.Element => {
  const [error, setError] = useState<string | null>(null);

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
};
