import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { BookCalendarComponent } from '../BookCalendarComponent/BookCalendarComponent';
import useGetTimeSlots from '../../hooks/useGetTimeSlosts/useGetTimeSlots';
import { SlotsList } from '../SlotsList/SlotsList';
import './BookSlot.scss';
import { t } from '@inovirtue/localization';
import { SlotsListModel, TimeSlot } from '../../model/booking';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

export enum ViewState {
  date = 'date',
  time = 'time',
}

export interface BookSlotProps {
  description: React.ReactNode;
  currentDate: Dayjs;
  onChooseSlot: (slot: TimeSlot) => Promise<void> | void;
  timeSlots: SlotsListModel;
  getTimeSlots: (startDate: Dayjs) => Promise<void> | void;
  backToDate?: boolean;
  availableMonthsAhead?: number;
}
export const BookSlot = ({
  description,
  currentDate,
  onChooseSlot,
  timeSlots,
  getTimeSlots,
  backToDate,
  availableMonthsAhead,
}: BookSlotProps) => {
  const [mode, setMode] = useState<ViewState>(ViewState.date);
  const [choosenDay, setChoosenDay] = useState<Dayjs>();

  const { selectTimeSlots, availableDays, morningSlots, afternoonSlots, clearTimeSlots } =
    useGetTimeSlots(timeSlots);

  const handleModeChange = (e: RadioChangeEvent) => {
    if (e.target.value === ViewState.date) {
      clearTimeSlots();
      setChoosenDay(undefined);
    }
    setMode(e.target.value);
  };

  const onDaySelect = (date: Dayjs) => {
    setChoosenDay(date);
    selectTimeSlots(date);
    setMode(ViewState.time);
  };

  const onMonthChange = (date: Dayjs) => {
    getTimeSlots(dayjs().isSame(date, 'month') ? dayjs() : date.startOf('month'));
  };

  const chooseSlot = (slot: TimeSlot) => {
    onChooseSlot(slot);
    if (backToDate) setMode(ViewState.date);
  };

  useEffect(() => {
    if (choosenDay) selectTimeSlots(choosenDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlots]);

  return (
    <section className='book-slot'>
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        buttonStyle='solid'
        className='book-slot__control'>
        <Radio.Button value={ViewState.date} disabled={!timeSlots.length} data-testid='radio-date'>
          {t('common:date')}
        </Radio.Button>
        <Radio.Button value={ViewState.time} disabled={!timeSlots.length} data-testid='radio-time'>
          {t('common:time')}
        </Radio.Button>
      </Radio.Group>
      {mode === ViewState.date && (
        <>
          {description}
          <BookCalendarComponent
            currentDate={currentDate}
            onDaySelect={onDaySelect}
            onMontChange={onMonthChange}
            availableDays={availableDays}
            availableMonthsAhead={availableMonthsAhead}
          />
        </>
      )}
      {mode === ViewState.time && (
        <section className='book-slot__timeSlots'>
          {choosenDay && (
            <p className='book-slot__timeSlots__title'>{choosenDay.format('dddd, D MMMM')}</p>
          )}
          <div className='book-slot__timeSlots__item'>
            <p
              className={classNames(
                'book-slot__timeSlots__title',
                'book-slot__timeSlots__title__timeofday',
              )}>
              {t('common:morning')}
            </p>
            <SlotsList onClickAction={chooseSlot} elements={morningSlots} />
          </div>
          <div className='book-slot__timeSlots__item'>
            <p
              className={classNames(
                'book-slot__timeSlots__title',
                'book-slot__timeSlots__title__timeofday',
              )}>
              {t('common:afternoon')}
            </p>
            <SlotsList onClickAction={chooseSlot} elements={afternoonSlots} />
          </div>
        </section>
      )}
    </section>
  );
};
