import { useEffect, useState } from 'react';
import { Session } from '@inovirtue/admin';
import { getServerTime } from '../../api/sessionsApi';
import { useSessionsInfo } from '../useSessionsInfo/useSessionsInfo';

export interface SessionVideoCallType {
  isInitialized: boolean;
  serverTime?: string;
  sessionsInfo: Session[];
}

export const useSessionVideoCall = (sessions?: Session[]): SessionVideoCallType => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [serverTime, setServerTime] = useState<string>();
  const { sessions: sessionsInfo } = useSessionsInfo(sessions, serverTime);

  useEffect(() => {
    const fetchServerTime = async () => {
      try {
        const time = await getServerTime();
        const dateISOString = new Date(time.serverTime).toISOString();
        setServerTime(dateISOString);
      } finally {
        setIsInitialized(true);
      }
    };
    if (sessions) fetchServerTime();
    else setIsInitialized(true);
  }, [sessions, isInitialized]);

  return {
    isInitialized,
    serverTime,
    sessionsInfo,
  };
};
