export const getFileExtension = (fileName: string | null): string | null => {
  if (!fileName) return null;

  const regex = /\.[0-9a-z]+$/i;
  const extension = regex.exec(fileName)?.[0];

  if (extension) return extension;

  return null;
};
