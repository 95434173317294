import './DeviceLabel.scss';

interface DeviceLabelProps {
  text: string;
}

const DeviceLabel = ({ text }: DeviceLabelProps): JSX.Element => {
  return <p className='deviceLabel'>{text}</p>;
};

export default DeviceLabel;
