import { ScreenShareIcon } from '@inovirtue/components';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { ProfileTypes } from '@inovirtue/profile';
import { useMemo } from 'react';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useScreenShareToggle from '../../components/VideoProvider/useScreenShareToggle/useScreenShareToggle';
import { currentProfile$ } from '../../store/participant';
import { isScreenSharing$ } from '../../store/video';

export const useScreenShareButton = () => {
  const toggleScreenShare = useScreenShareToggle();
  const screenShareParticipant = useScreenShareParticipant();
  const isSharingScreen = useBehaviorSubjectState(isScreenSharing$);
  const currentProfile = useBehaviorSubjectState(currentProfile$);
  const isScreenShareSupported = !!(
    navigator?.mediaDevices && navigator?.mediaDevices?.getDisplayMedia
  );
  const isDisabled = !!screenShareParticipant;

  const isVisible = useMemo(
    () =>
      (currentProfile?.type === ProfileTypes.ProviderProfile ||
        currentProfile?.type === ProfileTypes.ConsumerProfile) &&
      isScreenShareSupported &&
      !isSharingScreen,
    [currentProfile?.type, isScreenShareSupported, isSharingScreen],
  );

  const icon = useMemo(() => <>{<ScreenShareIcon />}</>, []);

  return { icon, isVisible, isDisabled, toggleScreenShare };
};
