import './WorkshopDetailsSessionsList.scss';
import { generatePath, useHistory, useParams } from 'react-router';
import { useWorkshopSessionsList } from '../../../Modules/Workshops/hooks/useWorkshopSessionsList';
import WorkshopDetailsView from '../WorkshopDetailsView/WorkshopDetailsView';
import { WorkshopSessionsList, IncompleteWorkshopBar } from '@inovirtue/admin';
import { useWorkshopDetails } from '../../../Modules/Workshops/hooks/useWorkshopDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';
import routes from '../../routes/constants/Routes';
import { useEffect } from 'react';
import { useSessionVideoCall } from '../../../Modules/Sessions/hooks/useSessionVideoCall/useSessionVideoCall';

const WorkshopDetailsSessionsList = () => {
  const { id } = useParams<{ id: string }>();
  const { workshop, hasMembers, hasScheduledSessions, canEdit, refresh } = useWorkshopDetails(id);
  const { sessions } = useWorkshopSessionsList(id);
  const { push } = useHistory();
  const { groupEditing, backofficeGroupCall } = useFlags();
  const { sessionsInfo } = useSessionVideoCall(sessions);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid='group-details-view'>
      <WorkshopDetailsView
        workshop={workshop}
        currentTab='sessions'
        refreshRequest={() => refresh()}
      >
        <>
          {groupEditing && canEdit && (
            <IncompleteWorkshopBar
              showAddMembers={!hasMembers}
              showScheduleSessions={!hasScheduledSessions}
              onScheduleSessionsClick={() => push(generatePath(routes.WorkshopsBooking, { id }))}
              onAddMembersClick={() => push(generatePath(routes.WorkshopAddMembers, { id }))}
            />
          )}
          {sessions && (
            <WorkshopSessionsList
              sessions={backofficeGroupCall == true ? sessionsInfo : sessions}
            />
          )}
        </>
      </WorkshopDetailsView>
    </div>
  );
};

export default WorkshopDetailsSessionsList;
