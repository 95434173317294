import classNames from 'classnames';
import { ReactNode, useEffect } from 'react';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll/useInfiniteScroll';

export interface InfiniteScrollProps {
  children: ReactNode;
  dataLength: number;
  margin?: string;
  inverse?: boolean;
  className?: string;
  hasMore: boolean;
  fetchMore: () => void;
}

export const InfiniteScroll = ({
  children,
  margin,
  dataLength,
  inverse = false,
  className,
  hasMore,
  fetchMore,
}: InfiniteScrollProps): JSX.Element => {
  const { rootRef, targetRef, scrollToRef, fetchTriger } = useInfiniteScroll(margin, dataLength);

  useEffect(() => {
    const isIntersecting = fetchTriger?.isIntersecting;
    if (!isIntersecting || !hasMore || !dataLength) return;

    fetchMore();
  }, [fetchTriger?.isIntersecting, hasMore, fetchMore, dataLength]);

  const infiniteScrollCls = classNames('infinite-scroll', className);

  return (
    <div className={infiniteScrollCls} ref={rootRef}>
      {inverse && <div className='infinite-scroll__trigger' ref={targetRef}></div>}
      <div className='infinite-scroll__inner'>{children}</div>
      {!inverse && <div className='infinite-scroll__trigger' ref={targetRef}></div>}
      {inverse && <div className='infinite-scroll__trigger' ref={scrollToRef}></div>}
    </div>
  );
};
