import { PlusOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useHistory } from 'react-router';
import routes from '../../../../App/routes/constants/Routes';
import styles from './PartnersViewHeader.module.scss';

const PartnersViewHeader = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className={styles.partnersViewHeader}>
      <div>
        <h1 className={styles.partnersViewHeader__title}>{t('partners:label')}</h1>
        <h2 className={styles.partnersViewHeader__description}>
          {t('partners:header.description')}
        </h2>
      </div>
      <Button
        type='default'
        className={styles.partnersViewHeader__button}
        icon={<PlusOutlined />}
        onClick={() => history.push(routes.PartnersCreate)}
      >
        {t('partners:header.create-button')}
      </Button>
    </div>
  );
};

export default PartnersViewHeader;
