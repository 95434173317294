import { CompanyContact } from '@inovirtue/company';
import { useMemo, useState } from 'react';
import { FormMode } from '../../Common/model/FormMode';
import { useIntivationAdd } from './useIntivationAdd';
import { useInvitationDelete } from './useInvitationDelete';
import { useInvitationEdit } from './useInvitationEdit';

export const useCompanyContactModal = () => {
  const [mode, setMode] = useState(FormMode.None);
  const [data, setData] = useState<CompanyContact>();
  const [companyId, setCompanyId] = useState('');

  const { submit: submitEdit } = useInvitationEdit();
  const { submit: submitAdd } = useIntivationAdd(companyId);
  const { submit: submitDelete } = useInvitationDelete(companyId);

  const submit = useMemo(
    () => (c: CompanyContact) => {
      switch (mode) {
        case FormMode.Add:
          return submitAdd(c);
        case FormMode.Edit:
          return submitEdit(companyId, c);
        case FormMode.Delete:
          return submitDelete(c);
        default:
          return () => undefined;
      }
    },
    [mode, companyId, submitAdd, submitEdit, submitDelete],
  );

  const showAddContactModal = (companId: string) => {
    setCompanyId(companId);
    setMode(FormMode.Add);
  };

  const showEditContactModal = (companId: string, contact: CompanyContact) => {
    setCompanyId(companId);
    setData(contact);
    setMode(FormMode.Edit);
  };

  const showDeleteContactModal = (companId: string, contact: CompanyContact) => {
    setCompanyId(companId);
    setData(contact);
    setMode(FormMode.Delete);
  };

  const closeModal = () => {
    setData(undefined);
    setMode(FormMode.None);
  };

  return {
    showAddContactModal,
    showEditContactModal,
    showDeleteContactModal,
    closeModal,
    mode,
    data,
    submit,
  };
};
