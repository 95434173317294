import { useMemo, useEffect } from 'react';
import { t } from '@inovirtue/localization';
import { Badge } from 'antd';
import { useHistory } from 'react-router';
import routes from '../../../App/routes/constants/Routes';
import {
  updateActiveEmployeesTotal,
  updatePendingEmployeesTotal,
  updateWaitingForCoachEmployeesTotal,
  activeEmployeesTotal$,
  pendingEmployeesTotal$,
  waitingForCoachEmployeesTotal$,
} from '../state';
import { getEmployees, getInvitations, getWaitingForCoachEmployees } from '../api/Employees.Api';
import { PaginatedResponse, useBehaviorSubjectState } from '@inovirtue/hooks';

const loadTotal = async <T extends object>(
  getData: (
    pageIndex: number,
    filters: Record<string, string>,
    pageSize?: number,
    searchString?: string,
  ) => Promise<PaginatedResponse<T>>,
  updateTotal: (newTotal: number) => void,
): Promise<void> => {
  const data = await getData(0, {}, 0);
  updateTotal(data.total);
};

const useEmployeesTablesTabs = (currentTab: string) => {
  const history = useHistory();
  const activeEmployeesTotal = useBehaviorSubjectState(activeEmployeesTotal$);
  const pendingEmployeesTotal = useBehaviorSubjectState(pendingEmployeesTotal$);
  const waitingForCoachEmployeesTotal = useBehaviorSubjectState(waitingForCoachEmployeesTotal$);

  useEffect(() => {
    if (!activeEmployeesTotal && currentTab !== routes.Employees)
      loadTotal(getEmployees, updateActiveEmployeesTotal);

    if (!pendingEmployeesTotal && currentTab !== routes.PendingEmployees)
      loadTotal(getInvitations, updatePendingEmployeesTotal);

    if (!waitingForCoachEmployeesTotal && currentTab !== routes.WaitingForCoachEmployees)
      loadTotal(getWaitingForCoachEmployees, updateWaitingForCoachEmployeesTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: routes.Employees,
        label: t('company:employees:tables-tabs.active-employees-tab-label', {
          numberOfEmployees: activeEmployeesTotal,
        }),
      },
      {
        key: routes.PendingEmployees,
        label: (
          <>
            {t('company:employees:tables-tabs.pending-employees-tab-label')}
            <span className='employeeTablesTabs__badge'>
              <Badge count={pendingEmployeesTotal} />
            </span>
          </>
        ),
      },
      {
        key: routes.WaitingForCoachEmployees,
        label: (
          <>
            {t('company:employees:tables-tabs.waiting-for-coach-tab-label')}
            <span className='employeeTablesTabs__badge'>
              <Badge count={waitingForCoachEmployeesTotal} />
            </span>
          </>
        ),
      },
    ],
    [activeEmployeesTotal, pendingEmployeesTotal, waitingForCoachEmployeesTotal],
  );

  return { navigate: history.push, tabs };
};
export default useEmployeesTablesTabs;
