import { Media, Message } from '@twilio/conversations';

type MessageSid = Message['sid'];

type MessageEntity = Map<MessageSid, Message>;

class MessageService {
  private static instance: MessageService;

  private messages: MessageEntity = new Map();

  constructor() {
    if (MessageService.instance) {
      return MessageService.instance;
    }

    MessageService.instance = this;
  }

  upsertMany(messages: Message[]) {
    messages.forEach((message) => {
      this.messages.set(message.sid, message);
    });
  }

  add(message: Message) {
    this.messages.set(message.sid, message);
  }

  getAttachedMedia(sid: MessageSid): Media[] | null {
    return this.messages.get(sid)?.attachedMedia || null;
  }
}

export const messageActions = new MessageService();
