import { Message } from '../../models/message';
import { isSameDay, parseISO } from 'date-fns';

export const isSameAuthor = (message: Message, prevMessage: Message | undefined): boolean => {
  return Boolean(!prevMessage || message.author === prevMessage.author);
};

export const isSameDayMessage = (message: Message, prevMessage: Message | undefined): boolean => {
  const messageDate = message.dateCreated;
  const prevMessageDate = prevMessage?.dateCreated;
  return Boolean(
    !prevMessageDate || !messageDate || isSameDay(parseISO(prevMessageDate), parseISO(messageDate)),
  );
};
