import { Alert, Select } from 'antd';
import { t } from '@inovirtue/localization';
import {
  audioInputDevices$,
  hasAudioInputDevices$,
  selectedAudioInputDeviceId$,
  setLocalAudioTrack,
} from '../../../store/audio';
import './AudioInputList.Component.scss';
import { isAcquiringMediaError$, isInitialized$ } from '../../../store';
import classNames from 'classnames';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import DeviceLabel from '../DeviceLabel/DeviceLabel';

const { Option } = Select;

export interface AudioInputListProps {
  className?: string;
}

const AudioInputList = ({ className }: AudioInputListProps): JSX.Element => {
  const isInitialized = useBehaviorSubjectState(isInitialized$);
  const isAcquiringMediaError = useBehaviorSubjectState(isAcquiringMediaError$);
  const audioInputDevices = useBehaviorSubjectState(audioInputDevices$);
  const hasAudioInputDevices = useBehaviorSubjectState(hasAudioInputDevices$);
  const selectedAudioInputDeviceId = useBehaviorSubjectState(selectedAudioInputDeviceId$);

  const value = hasAudioInputDevices
    ? selectedAudioInputDeviceId
    : t('videoCall:call-prep.nodevice-placeholder');

  const isErrorVisible = isInitialized && !hasAudioInputDevices;
  const errorMessage = isAcquiringMediaError
    ? t('videoCall:call-prep.audioinput-acquiring-error')
    : t('videoCall:call-prep.audioinput-error');
  const audioInputListCls = classNames('audioinputlist', {
    'audioinputlist--error': isErrorVisible,
  });

  return (
    <div {...{ className }}>
      <DeviceLabel text={t('videoCall:device.microphone')} />
      <Select
        onChange={setLocalAudioTrack}
        value={value}
        placeholder={t('videoCall:call-prep.audioinput-placeholder')}
        disabled={!hasAudioInputDevices || !isInitialized}
        className={audioInputListCls}
        data-testid='audioinput-select'
      >
        {audioInputDevices.map((device) => (
          <Option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </Option>
        ))}
      </Select>
      {isErrorVisible && (
        <Alert
          className='audioinputlist__error'
          description={errorMessage}
          type='error'
          data-testid='audioinput-select-error'
        />
      )}
    </div>
  );
};

export default AudioInputList;
