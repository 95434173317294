import { List } from 'antd';
import { Partner } from '../../model/Partner';
import { PartnerListItem } from '../PartnerListItem/PartnerListItem';

export type PartnersListProps = {
  partners: Partner[];
  onItemClick: (partner: Partner) => void;
};

export const PartnersList = ({ partners, onItemClick }: PartnersListProps) => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      dataSource={partners}
      renderItem={(partner) => (
        <List.Item onClick={() => onItemClick(partner)}>
          <PartnerListItem partner={partner} />
        </List.Item>
      )}
    />
  );
};
