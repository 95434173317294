import './WorkshopDetailsGeneral.scss';
import {
  WorkshopData,
  WorkshopGeneralInfo,
  WorkshopContacts,
  IncompleteWorkshopBar,
  WorkshopCoach,
} from '@inovirtue/admin';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generatePath, useHistory, useParams } from 'react-router';
import { useWorkshopDetails } from '../../../Modules/Workshops/hooks/useWorkshopDetails';
import routes from '../../routes/constants/Routes';
import WorkshopDetailsView from '../WorkshopDetailsView/WorkshopDetailsView';

const WorkshopDetailsGeneral = () => {
  const { id } = useParams<{ id: string }>();
  const { workshop, hasMembers, hasScheduledSessions, refresh, canEdit } = useWorkshopDetails(id);
  const { push } = useHistory();
  const { groupEditing } = useFlags();

  return (
    <div className='group-details-general' data-testid='group-details-view'>
      {
        <WorkshopDetailsView
          workshop={workshop}
          currentTab='details'
          refreshRequest={() => refresh()}
        >
          {workshop && (
            <>
              {groupEditing && canEdit && (
                <IncompleteWorkshopBar
                  showAddMembers={!hasMembers}
                  showScheduleSessions={!hasScheduledSessions}
                  onScheduleSessionsClick={() =>
                    push(generatePath(routes.WorkshopsBooking, { id }))
                  }
                  onAddMembersClick={() => push(generatePath(routes.WorkshopAddMembers, { id }))}
                />
              )}
              {groupEditing && canEdit && (
                <div className='group-details-general__edit-bar'>
                  <div className='group-details-general__edit-bar__text'>
                    {t('group:group-details-edit.text')}
                  </div>
                  <div className='group-details-general__edit-bar__action'>
                    <Button onClick={() => push(generatePath(routes.WorkshopEdit, { id }))}>
                      {t('group:group-details-edit.button')}
                    </Button>
                  </div>
                </div>
              )}
              <Row gutter={12} className='cards-container'>
                <Col span={12}>
                  <WorkshopGeneralInfo workshop={workshop} />
                  <WorkshopData workshop={workshop} showCompany={true} />
                </Col>
                <Col span={12}>
                  {workshop.provider && <WorkshopCoach provider={workshop.provider} />}
                  <WorkshopContacts workshop={workshop} />
                </Col>
              </Row>
            </>
          )}
        </WorkshopDetailsView>
      }
    </div>
  );
};

export default WorkshopDetailsGeneral;
