import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { getProviderTimeSlots } from '../../api/schedulerApi';
import { SlotsListModel } from '@inovirtue/booking';

export const formatDate = (date: Date) => dayjs(date).format('yyyy-MM-DD');

const useGetTimeSlots = () => {
  const [timeSlots, setTimeSlots] = useState<SlotsListModel>([]);

  const firstAvailableDay = useMemo(() => dayjs().add(1, 'day').startOf('day'), []);

  const availableDays = useMemo(() => {
    return timeSlots.map((el) => formatDate(new Date(dayjs(el.date).toDate())));
  }, [timeSlots]);

  const getTimeSlots = useCallback(
    (profileName: string) =>
      async (startDate = firstAvailableDay) => {
        const startingDate = startDate.isSame(firstAvailableDay, 'month')
          ? firstAvailableDay
          : startDate;
        const data = await getProviderTimeSlots(profileName, startingDate.toDate());
        setTimeSlots(data);
      },
    [firstAvailableDay],
  );

  return {
    setTimeSlots,
    timeSlots,
    getTimeSlots,
    availableDays,
    firstAvailableDay,
  };
};

export default useGetTimeSlots;
