import { t } from '@inovirtue/localization';
import { Tag } from 'antd';
import { CompanyContactStatus } from '../../models/CompanyContact';

export type CompanyContactStatusTagProps = {
  status: CompanyContactStatus;
};
export const CompanyContactStatusTag = ({ status }: CompanyContactStatusTagProps) => (
  <Tag color={status == 'Active' ? 'green' : 'orange'}>
    {t(`company:contacts.status.${status.toLowerCase()}`)}
  </Tag>
);
