import { Media as TwilioMedia, Message as TwilioMessage } from '@twilio/conversations';
import { Override } from './override';

export type Messages = Record<string, TwilioMessage[]>;

export type DraftMessages = Record<string, string>;

export interface MessageBaseProps {
  message: Message;
  convoSid: string;
  isSameAsPrev: boolean;
}

export type Media = Pick<TwilioMedia, 'category' | 'contentType' | 'filename' | 'sid' | 'size'>;

export type MediaProperties = keyof Media;

export const mediaProperties: ReadonlyArray<MediaProperties> = [
  'category',
  'contentType',
  'filename',
  'sid',
  'size',
] as const;

export type MessagePicked = Pick<
  TwilioMessage,
  | 'attachedMedia'
  | 'author'
  | 'body'
  | 'dateCreated'
  | 'dateUpdated'
  | 'index'
  | 'lastUpdatedBy'
  | 'participantSid'
  | 'sid'
  | 'type'
>;

export type Message = Override<
  MessagePicked,
  {
    attachedMedia: Media[] | null;
    dateCreated: string | null;
    dateUpdated: string | null;
  }
>;

export type MessageProperties = keyof Message;

export const messageProperties: ReadonlyArray<MessageProperties> = [
  'attachedMedia',
  'author',
  'body',
  'dateCreated',
  'dateUpdated',
  'index',
  'lastUpdatedBy',
  'participantSid',
  'sid',
  'type',
] as const;
