import { CompanyContact } from '@inovirtue/company';
import { useCallback } from 'react';
import { updateInvitation } from '../api/companiesApi';

export const useInvitationEdit = () => {
  const submit = useCallback(async (companyId: string, contact: CompanyContact) => {
    await updateInvitation(companyId, contact);
  }, []);

  return {
    submit,
  };
};
