import { useCallback, useMemo, useState } from 'react';
import { FooterRef } from '../Popover.model';

export const useExternalFooter = () => {
  const [footerHeight, setFooterHeight] = useState<number | null>(null);

  const footerRef = useCallback<FooterRef>((node) => {
    setFooterHeight(node?.getBoundingClientRect().height ?? null);
  }, []);

  const isExternalFooter = useMemo(() => footerHeight !== null, [footerHeight]);

  return { footerRef, footerHeight: footerHeight ?? 0, isExternalFooter };
};
