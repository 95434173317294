import { generatePath, useHistory, useParams } from 'react-router';
import { useWorkshopDetails } from '../../../Modules/Workshops/hooks/useWorkshopDetails';
import WorkshopDetailsView from '../WorkshopDetailsView/WorkshopDetailsView';
import { WorkshopMembersList, IncompleteWorkshopBar } from '@inovirtue/admin';
import routes from '../../routes/constants/Routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useWorkshopMembers } from '../../../Modules/Workshops/hooks/useWorkshopMembers';
import { useCallback } from 'react';

const WorkshopDetailsMembersView = () => {
  const { id } = useParams<{ id: string }>();
  const { workshop, canEdit, hasMembers, hasScheduledSessions, refresh } = useWorkshopDetails(id);
  const { groupEditing } = useFlags();
  const { data: members } = useWorkshopMembers(id);
  const { push } = useHistory();
  const goToPathWithId = useCallback(
    (path: string) => {
      push(generatePath(path, { id }));
    },
    [push, id],
  );
  return (
    <div data-testid='group-details-members-view'>
      {
        <WorkshopDetailsView
          workshop={workshop}
          currentTab='members'
          refreshRequest={() => refresh()}
        >
          <>
            {groupEditing && canEdit && (
              <IncompleteWorkshopBar
                showAddMembers={!hasMembers}
                showScheduleSessions={!hasScheduledSessions}
                onScheduleSessionsClick={() => goToPathWithId(routes.WorkshopsBooking)}
                onAddMembersClick={() => goToPathWithId(routes.WorkshopAddMembers)}
              />
            )}
            {members &&
              (groupEditing ? (
                <WorkshopMembersList
                  data={members}
                  isEditable={canEdit}
                  onAddClick={() => push(generatePath(routes.WorkshopEditMembers, { id }))}
                />
              ) : (
                <WorkshopMembersList
                  data={members}
                  isEditable={false}
                  onAddClick={() => push(generatePath(routes.WorkshopEditMembers, { id }))}
                />
              ))}
          </>
        </WorkshopDetailsView>
      }
    </div>
  );
};

export default WorkshopDetailsMembersView;
