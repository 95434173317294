import { createQuery } from '@datorama/akita';
import { BehaviorSubject, combineLatest, map, of } from 'rxjs';
import { activeSinkId$, hasAudioInputDevices$, selectedAudioInputDeviceId$ } from './audio';
import { VideoCallState, videoCallStore } from './video-call.store';
import { hasVideoInputDevices$, selectedVideoInputDeviceId$ } from './video/video.query';
import Video from 'twilio-video';

export const videoCallQuery = createQuery<VideoCallState>(videoCallStore);

export const roomName$ = new BehaviorSubject(videoCallQuery.getValue().options?.roomName);
videoCallQuery.select((s) => s.options?.roomName).subscribe(roomName$);

export const isRoomValid$ = new BehaviorSubject(videoCallQuery.getValue().options?.isRoomValid);
videoCallQuery.select((s) => s.options?.isRoomValid).subscribe(isRoomValid$);

export const canRejoin$ = new BehaviorSubject(videoCallQuery.getValue().options?.canRejoin);
videoCallQuery.select((s) => s.options?.canRejoin).subscribe(canRejoin$);

export const step$ = new BehaviorSubject(videoCallQuery.getValue().step);
videoCallQuery.select((s) => s.step).subscribe(step$);

export const isAcquiringMediaError$ = new BehaviorSubject(
  videoCallQuery.getValue().isAcquiringMediaError,
);
videoCallQuery.select((s) => s.isAcquiringMediaError).subscribe(isAcquiringMediaError$);

export const isConnecting$ = new BehaviorSubject(videoCallQuery.getValue().isConnecting);
videoCallQuery.select((s) => s.isConnecting).subscribe(isConnecting$);

export const isInitialized$ = new BehaviorSubject(videoCallQuery.getValue().isInitialized);
videoCallQuery.select((s) => s.isInitialized).subscribe(isInitialized$);

export const canJoin$ = new BehaviorSubject(false);
combineLatest([
  isRoomValid$,
  isInitialized$,
  hasVideoInputDevices$,
  hasAudioInputDevices$,
  selectedVideoInputDeviceId$,
  selectedAudioInputDeviceId$,
  activeSinkId$,
  of(Video.isSupported),
  isAcquiringMediaError$.pipe(map((value) => !value)),
])
  .pipe(map((conditions) => !conditions.some((condition) => !condition)))
  .subscribe(canJoin$);

export const error$ = new BehaviorSubject(videoCallQuery.getValue().error);
videoCallQuery.select((s) => s.error).subscribe(error$);

export const isChat$ = new BehaviorSubject(videoCallQuery.getValue().options?.isChat);
videoCallQuery.select((s) => s.options?.isChat).subscribe(isChat$);
