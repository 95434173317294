import { createStore } from '@datorama/akita';
import { TwilioError } from 'twilio-video';
import { StepType, Step } from './models/Step.model';

const STORE_KEY = 'videoCall';

export interface VideoCallOptions {
  roomName: string;
  roomType: string;
  isRoomValid: boolean;
  canRejoin: boolean;
  isChat: boolean;
}

export interface VideoCallState {
  step: StepType;
  isInitialized: boolean;
  isAcquiringMediaError: boolean;
  isFetching: boolean;
  isConnecting: boolean;
  options: VideoCallOptions;
  error: TwilioError | Error | null;
}

const initialState: Partial<VideoCallState> = {
  error: null,
  step: Step.CallPrepStep,
  isInitialized: false,
  isAcquiringMediaError: false,
  isConnecting: false,
};

export const videoCallStore = createStore<VideoCallState>(initialState, {
  name: STORE_KEY,
});
