import './WorkshopAddMembersView.scss';
import { WorkshopEditMembers } from '@inovirtue/admin';
import { Button, Modal } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useWorkshopAddMembers } from '../../hooks/useWorkshopAddMembers';

export const WorkshopAddMembersView = () => {
  const { navigateBack, updateSelectedMembers, saveChanges, employees } = useWorkshopAddMembers();

  return (
    <Modal
      isFullScreen={true}
      visible={true}
      onClose={navigateBack}
      contentCls='group-add-members-view__modal-content'
    >
      <div className='group-add-members-view'>
        <h1>{t('group:add-group-members.title')}</h1>
        <WorkshopEditMembers
          initialMembers={[]}
          allEmployees={employees}
          onChanged={updateSelectedMembers}
        />
        <Button className='group-add-members-view__save' type='primary' onClick={saveChanges}>
          {t('group:edit-group-members.save-members')}
        </Button>
      </div>
    </Modal>
  );
};
