import { t } from '@inovirtue/localization';
import { Form, message } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath, useHistory } from 'react-router';
import usePartnerDetails from '../../../App/hooks/usePartnerDetails';
import routes from '../../../App/routes/constants/Routes';
import { addPartner, getAllTenants, updatePartner } from '../api/partnersApi';
import { PartnerForm } from '../models/PartnerForm';

const { useForm } = Form;

export const usePartnerForm = (id?: string) => {
  const queryClient = useQueryClient();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery(['get_tenants'], getAllTenants);
  const { partner } = usePartnerDetails(id);

  const [form] = useForm<PartnerForm>();

  useEffect(() => {
    if (partner) {
      form.setFieldsValue({
        ...partner,
        smallLogo: {
          url: partner.smallLogoUrl,
        },
        logo: {
          url: partner.logoUrl,
        },
        background: {
          url: partner.backgroundUrl,
        },
        numbersAndFacts: {
          url: partner.numbersAndFactsUrl,
        },
      });
    }
  }, [partner, form]);

  const onSubmit = async (formFields: PartnerForm) => {
    let redirectId = null;
    try {
      setIsLoading(true);
      if (id && partner) {
        redirectId = id;
        await updatePartner(partner.name, formFields);
      } else {
        redirectId = await addPartner(formFields);
      }
      await queryClient.invalidateQueries(['get_partner', id]);

      message.success(t('save-success'));
      history.push(generatePath(routes.PartnerDetails, { id: redirectId }));
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        const errors = axiosError.response.data.messages.map(
          (m: { message: string }) => m.message,
        ) as string[];

        const errorMessage = errors.join('\n');
        message.error(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return {
    allTenants: data || [],
    form,
    onSubmit,
    isLoading,
  };
};
