import React from 'react';
import { Select, SelectProps } from 'antd';
import './LanguageSelect.scss';
import { English, German } from '@inovirtue/assets';

const { Option } = Select;

const Languages = [
  {
    name: 'English',
    value: 'en',
    icon: <English className='language-select__icon' />,
  },
  {
    name: 'Deutsch',
    value: 'de',
    icon: <German className='language-select__icon' />,
  },
];

export interface LanguageSelectProps extends Omit<SelectProps, 'onChange' | 'value'> {
  selectedLang: string;
  onLangChange: (lang: string) => void;
}

export const LanguageSelect = ({
  selectedLang,
  onLangChange,
  ...props
}: LanguageSelectProps): JSX.Element => {
  return (
    <Select value={selectedLang} onChange={onLangChange} {...props} data-testid="language-select">
      {Languages.map((lang) => (
        <Option value={lang.value} key={lang.value}>
          <div className='language-select__option'>
            {lang.icon}
            <span>{lang.name}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};
