import React, { FC, MouseEventHandler } from 'react';
import { Upload } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ActionTile } from '../Tile/ActionTile/ActionTile.Component';
import { TilesCollection } from '../TilesCollection/TilesCollection.Component';
import { RcFile } from 'antd/lib/upload';
import './GalleryUpload.Component.scss';
import { ImageTile } from '../Tile/ContentTile/ImageTile.Component';
import { VideoTile } from '../Tile/ContentTile/VideoTile.Component';
import { Button } from '../Button/Button';
const { Dragger } = Upload;

export type ExistingFile = {
  fileName: string;
  fileUrl: string;
  fileType: string;
};

export type GalleryUploadProps = {
  maxFiles: number;
  newFiles: RcFile[];
  onNewFileAdded(file: RcFile): string;
  onNewFileDelete(file: RcFile): void;
  uploadTileContent: React.ReactNode;
  isUploading: boolean;
  existingFiles?: ExistingFile[];
  onExistingFileDelete?(file: ExistingFile): void;
};

export const GalleryUpload: FC<GalleryUploadProps> = ({
  maxFiles,
  newFiles,
  onNewFileAdded,
  onNewFileDelete,
  uploadTileContent,
  isUploading,
  existingFiles = [],
  onExistingFileDelete,
}) => {
  const uploadTile = (
    <ActionTile>
      <Dragger
        showUploadList={false}
        fileList={newFiles}
        action={onNewFileAdded}
        listType='picture-card'
        maxCount={maxFiles}
        className='uploadTile'
        multiple={true}
        accept='image/*,video/*'
      >
        {uploadTileContent}
      </Dragger>
    </ActionTile>
  );

  const deleteButton = (onButtonClick: MouseEventHandler<HTMLElement>) => (
    <Button
      className='deleteButton'
      icon={<CloseOutlined />}
      shape='circle'
      onClick={onButtonClick}
    />
  );

  const commonTileProps = (key: string, onDeleteButtonClick: MouseEventHandler<HTMLElement>) => ({
    key: key,
    isLoading: isUploading,
    actions: !isUploading ? deleteButton(onDeleteButtonClick) : undefined,
  });

  const existingImagesTiles = existingFiles.map((file: ExistingFile) =>
    file.fileType === 'Image' ? (
      <ImageTile
        backgroundUrl={file.fileUrl}
        {...commonTileProps(file.fileUrl, () => onExistingFileDelete?.(file))}
      />
    ) : (
      <VideoTile
        videoUrl={file.fileUrl}
        {...commonTileProps(file.fileUrl, () => onExistingFileDelete?.(file))}
      />
    ),
  );

  const newFiletiles = newFiles.map((file: RcFile) =>
    file.type.startsWith('image') ? (
      <ImageTile
        backgroundUrl={URL.createObjectURL(file)}
        {...commonTileProps(file.name, () => onNewFileDelete?.(file))}
      />
    ) : (
      <VideoTile
        videoUrl={URL.createObjectURL(file)}
        {...commonTileProps(file.name, () => onNewFileDelete?.(file))}
      />
    ),
  );

  const tiles = existingImagesTiles.concat(newFiletiles);

  const totalFileCount = newFiles.length + existingFiles.length;

  return (
    <div className='galleryUpload'>
      <TilesCollection
        tiles={totalFileCount < maxFiles && !isUploading ? [uploadTile, ...tiles] : tiles}
      />
    </div>
  );
};
