import './PartnerProvidersList.scss';
import { EditOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Avatar, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { MembersIcon } from '../../icons/MembersIcon';
import { Provider } from '../../model/Provider';

type PartnerProvidersListProps = {
  data: Array<Provider>;
  onEditClick?: () => void;
};

export const PartnerProvidersList = ({ data, onEditClick }: PartnerProvidersListProps) => {
  const columns: ColumnsType<Provider> = useMemo(
    () => [
      {
        title: `${t('partners:tabs.providers')} (${data.length})`,
        width: '100%',
        key: 'partner-provider',
        render: (p: Provider) => {
          return (
            <Space className='partners-list__table-display_name' key={`mm_${p.name}`}>
              <Avatar src={p.avatarUrl} />
              <span>{p.displayName}</span>
            </Space>
          );
        },
      },
    ],
    [data.length],
  );

  return (
    <div className='partners-list'>
      <div className='partners-list__header'>
        <div className='partners-list__header__main'>
          <MembersIcon />
          <div className='partners-list__header__main__title'>
            <span>{t('partners:tabs.providers')}</span>
          </div>
        </div>
        <div className='partners-list__header__add'>
          <Button onClick={onEditClick}>
            <EditOutlined /> {t('partners:actions.edit-providers')}
          </Button>
        </div>
      </div>
      <div>
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </div>
  );
};
