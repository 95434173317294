import { configureStore } from '@reduxjs/toolkit';
import { draftMessagesReducer } from './draftMessagesSlice';
import { commonReducer } from './commonSlice';
import { horizonReducer } from './horizonSlice';
import {
  assembleConversation,
  assembleSubscribedConversations,
  conversationsReducer,
  upsertConversation,
} from './conversationsSlice';
import { addMessage, fetchMessages, fetchPrevMessages, messagesReducer } from './messagesSlice';
import {
  addParticipant,
  fetchParticipants,
  participantsReducer,
  upsertParticipant,
} from './participantsSlice';

const rootReducer = {
  common: commonReducer,
  conversations: conversationsReducer,
  participants: participantsReducer,
  messages: messagesReducer,
  draftMessages: draftMessagesReducer,
  horizon: horizonReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          assembleSubscribedConversations.fulfilled.type,
          assembleConversation.fulfilled.type,
          upsertConversation.type,
          addParticipant.type,
          upsertParticipant.type,
          fetchParticipants.fulfilled.type,
          fetchMessages.fulfilled.type,
          fetchPrevMessages.fulfilled.type,
          addMessage.type,
        ],
      },
    }),
});

export type ConversationsDispatch = typeof store.dispatch;
export type ConversationsState = ReturnType<typeof store.getState>;
