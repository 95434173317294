import './PartnerDetailsTabs.scss';
import { t } from '@inovirtue/localization';
import { Tabs } from 'antd';
import { useMemo } from 'react';

const { TabPane } = Tabs;

export type PartnerTabKey = 'details' | 'providers';

type TabsEntry = {
  label: string;
  url: string;
};

type PartnerDetailsTabsProps = {
  id: string;
  currentTab: PartnerTabKey;
  onChange: (key: string) => void;
};

export const PartnerDetailsTabs = ({ id, currentTab, onChange }: PartnerDetailsTabsProps) => {
  const tabs = useMemo(() => {
    const baseUrl = `/partners/${id}`;
    const defaultKey = `${baseUrl}/${currentTab}`;
    const mapping: Record<PartnerTabKey, TabsEntry> = {
      details: {
        url: `${baseUrl}/details`,
        label: t('partners:tabs.details'),
      },
      providers: {
        url: `${baseUrl}/providers`,
        label: t('partners:tabs.providers'),
      },
    };
    return { mapping, defaultKey };
  }, [id, currentTab]);

  return (
    <Tabs className='partner-details-tabs' defaultActiveKey={tabs.defaultKey} onChange={onChange}>
      {Object.values(tabs.mapping).map(({ label, url }) => (
        <TabPane tab={label} key={url} tabKey={url} />
      ))}
    </Tabs>
  );
};
