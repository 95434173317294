import { useCallback, useEffect, useState } from 'react';
import Provider from '../../../Modules/Providers/models/Provider';
import { FilterParams } from '../models/filterParams';

const useFilterProviders = (providers: Provider[], interests?: string[]) => {
  const isLoading = providers?.length <= 0;
  const [filteredProviders, setFilteredProviders] = useState<Provider[]>([]);

  const onFilterProviders = useCallback(
    (filterParams: FilterParams) => {
      let items = providers;
      if (!items) return;

      const tags = (filterParams?.tags ?? []).concat(filterParams?.languages ?? []);
      if (tags?.length)
        items = items.filter((f) => tags?.every((t) => f.interestKeys?.includes(t)));
      if (interests)
        items = items.sort(
          (a, b) =>
            interests.filter((x) => b.interestKeys?.includes(x)).length -
            interests.filter((x) => a.interestKeys?.includes(x)).length,
        );

      setFilteredProviders(items);
    },
    [providers, interests],
  );

  useEffect(() => {
    if (!isLoading) onFilterProviders({});
  }, [isLoading, onFilterProviders]);

  return {
    isLoading,
    filteredProviders,
    onFilterProviders,
  };
};

export default useFilterProviders;
