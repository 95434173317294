import { useMsal } from '@azure/msal-react';
import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { checkIfAccountExists, createAccount, getAccountProfileNames } from '../api/accounts';
import { setCurrentProfileName, setRoles } from '../../../Modules/Profile/store';
import { getProfileRoles } from '../../../Modules/Profile/api/profiles';

export const useCreateAccount = () => {
  const history = useHistory();
  const { instance } = useMsal();
  const { chatFlag } = useFlags();

  useEffect(() => {
    const [account] = instance.getAllAccounts();
    if (!chatFlag || !account) {
      history.push('/');
      return;
    }

    const fetchProfileRoles = async (profileName: string) => {
      const profileRoles = await getProfileRoles(profileName);
      setRoles(profileRoles);
    };

    const fetchProfiles = async () => {
      const [profileName] = await getAccountProfileNames(account.localAccountId);
      if (profileName) {
        setCurrentProfileName(profileName);
        fetchProfileRoles(profileName);
      }
    };

    const createSnsAccount = () => {
      createAccount({
        accountId: account.localAccountId,
        name: account.name ?? '',
        displayName: account.name ?? '',
        email: account.username,
      })
        .then(() => {
          fetchProfiles();
        })
        .catch(() => {
          showToast(t('errors:unhandled-error'));
        });
    };

    checkIfAccountExists(account.localAccountId)
      .catch((e) => {
        if (e.response.status == 404) createSnsAccount();
        else showToast(t('errors:unhandled-error'));
      })
      .finally(() => {
        history.push('/');
      });
  }, [chatFlag, history, instance]);
};
