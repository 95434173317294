import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useMemo } from 'react';
import { getCompanyById } from '../api/companiesApi';
import { Company } from '../models/domain/Company';

export const useCompany = (companyId: string) => {
  const fetchCompanyByIdMemo = useMemo(() => () => getCompanyById(companyId), [companyId]);
  const { data, isProcessing } = usePromiseWithProgress<Company>(fetchCompanyByIdMemo);
  return {
    company: data,
    isProcessing,
  };
};
