import { showToast } from '@inovirtue/components';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { t } from '@inovirtue/localization';
import { useCallback, useState } from 'react';
import { createConversation as createConversationRequest } from '../../../Modules/Chat/api/chat';
import { CreateConversationProfile } from '../../Chat/models/createConversationProfile';
import { searchProfile } from '../api/profiles';
import { SearchProfileItem } from '../models/SearchProfileItem';
import { currentProfileDisplayName$, currentProfileName$ } from '../store';

export const useProfileSearch = () => {
  const [searchResult, setSearchResult] = useState<SearchProfileItem[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const currentProfileName = useBehaviorSubjectState(currentProfileName$);
  const currentProfileDisplayName = useBehaviorSubjectState(currentProfileDisplayName$);

  const handleSearch = useCallback(async (value: string) => {
    try {
      setIsSearching(true);
      const response = value ? await searchProfile(value) : [];
      setSearchResult(response);
    } catch {
      showToast(t('errors:unhandled-error'));
    } finally {
      setIsSearching(false);
    }
  }, []);

  const createConversation = useCallback(
    async (value: SearchProfileItem): Promise<string> => {
      try {
        setIsCreating(true);
        const currentUser = {
          profileName: currentProfileName,
          displayName: currentProfileDisplayName,
        } as CreateConversationProfile;

        const participant = {
          profileName: value.name,
          displayName: value.displayName,
          profileType: value.profileType,
          avatarUrl: value.avatarUrl,
        };

        const conversationSid = await createConversationRequest([currentUser, participant]);
        return conversationSid;
      } catch {
        showToast(t('errors:unhandled-error'));
        return '';
      } finally {
        setIsCreating(false);
      }
    },
    [currentProfileDisplayName, currentProfileName],
  );

  return {
    isSearching,
    isCreating,
    searchResult,
    handleSearch,
    createConversation,
  };
};
