import { Provider } from '@inovirtue/admin';
import { useCallback, useState } from 'react';
import { getTenantProviders } from '../../Providers/api/Providers.Api';

export const useSearchProvider = () => {
  const [providers, setProviders] = useState<Provider[]>([]);

  const searchProviders = useCallback(async (searchText: string, tenantName: string) => {
    setProviders([]);
    if (searchText.length < 3) {
      return;
    }
    const data = await getTenantProviders(0, searchText, tenantName);

    setProviders(data);
  }, []);

  return { searchProviders, providers };
};
