import './CompanyContactForm.scss';
import { CompanyContact } from '@inovirtue/company';
import { Button, Loader } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Col, Form, Input, Row, Select } from 'antd';
import { usePositions } from '../../../Positions/hooks/usePositions';
import AddPositionButton from '../../../Positions/components/AddPositionsButton/AddPositionButton';
const { Option } = Select;

type CompanyContactFormProps = {
  companyId: string;
  onSubmit?: (formData: CompanyContact) => void;
  formData?: Partial<CompanyContact>;
  saveButtonLabel?: string;
};

export const CompanyContactForm = ({
  companyId,
  formData,
  onSubmit,
  saveButtonLabel = t('common:save'),
}: CompanyContactFormProps) => {
  const { data: positions, isProcessing, process, submitPositions } = usePositions(companyId);

  return isProcessing ? (
    <Loader spaceSize={'middle'} spinSize={'default'} />
  ) : (
    <div data-testid='company-contact-form'>
      <Form
        initialValues={formData}
        layout={'vertical'}
        onFinish={async (values) => {
          await onSubmit?.(values as CompanyContact);
        }}
      >
        <Form.Item name='id' hidden>
          <Input type='hidden' />
        </Form.Item>
        <Row className='company-contact-form__full-name' gutter={16}>
          <Col span={12}>
            <Form.Item
              name={['firstName']}
              label={t('common:label-first-name')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('common:label-first-name'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['lastName']}
              label={t('common:label-last-name')}
              rules={[
                {
                  required: true,
                  message: t('validation-message.field-required', {
                    fieldName: t('common:label-last-name'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={['positionId']}
          label={t('company:contacts.position')}
          rules={[
            {
              required: true,
              message: t('validation-message.field-required', {
                fieldName: t('company:contacts.position'),
              }),
            },
          ]}
        >
          <Select
            showSearch
            data-testid='positionId'
            dropdownClassName='company-contact-form__position-select'
            dropdownRender={(menu) => (
              <>
                {menu}
                <AddPositionButton
                  block
                  submitPositions={submitPositions}
                  companyId={companyId as string}
                  fetchPositions={process}
                  positions={positions ? positions : []}
                />
              </>
            )}
          >
            {positions?.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['email']}
          label={t('company:contacts.email-address')}
          rules={[
            {
              required: true,
              message: t('validation-message.field-required', {
                fieldName: t('company:contacts.email-address'),
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['phoneNumber']}
          label={t('company:contacts.phone-number')}
          rules={[
            {
              required: true,
              message: t('validation-message.field-required', {
                fieldName: t('company:contacts.phone-number'),
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className='company-contact-form__submit'>
          <Button data-testid='submit' htmlType='submit' type='primary'>
            {saveButtonLabel}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
