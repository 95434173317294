export type ProfileCategoryTagType =
    | 'interests'
    | 'expertise'
    | 'additionalLanguages'
    | 'growth'
    | 'wellbeing'
    | 'genericTopic';

export const ProfileCategoryTagTypes: { [key in ProfileCategoryTagType]: ProfileCategoryTagType } =
    {
        interests: 'interests',
        expertise: 'expertise',
        additionalLanguages: 'additionalLanguages',
        growth: 'growth',
        wellbeing: 'wellbeing',
        genericTopic: 'genericTopic',
    } as const;
