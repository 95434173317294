import { Select, SelectProps } from 'antd';
import React from 'react';
import { useCompaniesList } from '../../hooks/useCompaniesList';

const { Option } = Select;

export type CompaniesSelectProps = Pick<
  SelectProps,
  'onChange' | 'value' | 'className' | 'disabled'
>;

const CompaniesSelect = ({
  onChange,
  value,
  className,
  disabled,
}: CompaniesSelectProps): JSX.Element => {
  const { companies } = useCompaniesList();
  return (
    <Select
      onChange={onChange}
      value={value}
      className={className}
      disabled={disabled}
      data-testid='companies-select'
    >
      {companies?.map((el) => (
        <Option value={el.companyId} key={el.companyId}>
          {el.name}
        </Option>
      ))}
    </Select>
  );
};

export default CompaniesSelect;
