import './CompanyContactItem.scss';
import { CompanyContact } from '../../models/CompanyContact';
import { Divider } from 'antd';
import { CompanyContactStatusTag } from '../CompanyContactStatusTag/CompanyContactStatusTag';
import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import {
  CompanyContactActionProps,
  CompanyContactsAccessProps,
} from '../CompanyContacts/CompanyContacts';

export type CompanyContactItemProps = {
  contact: CompanyContact;
  showIcons?: boolean;
} & CompanyContactsAccessProps &
  CompanyContactActionProps;

export const CompanyContactItem = ({
  showStatus,
  canEdit,
  canDelete,
  canResendInvite,
  contact,
  showIcons = false,
  onEditClick,
  onDeleteClick,
  onResendInviteClick,
}: CompanyContactItemProps): JSX.Element => {
  return (
    <div className='company-contact-item'>
      <div className='company-contact-item__header'>
        <div>
          <span className='company-contact-item__header__full-name'>
            {contact.firstName} {contact.lastName}
          </span>
          {showStatus && contact.status && (
            <div data-testid='status-tag' className='company-contact-item__header__status'>
              <CompanyContactStatusTag status={contact.status} />
            </div>
          )}
        </div>
        <div>
          {canEdit && (
            <Button
              data-testid='edit-button'
              icon={<EditOutlined />}
              type='text'
              size='small'
              onClick={() => onEditClick?.(contact)}
            />
          )}
          {canDelete && (
            <Button
              data-testid='delete-button'
              icon={<DeleteOutlined />}
              type='text'
              size='small'
              onClick={() => onDeleteClick?.(contact)}
            />
          )}
        </div>
      </div>

      <Divider />
      <div className='company-contact-item__description'>
        <label>{showIcons ? <ContactsOutlined /> : t('company:contacts.position')}</label>
        <span>{contact.position?.name}</span>
        <label>{showIcons ? <MailOutlined /> : t('company:contacts.email-address')}</label>
        <span>{contact.email}</span>
        <label>{showIcons ? <PhoneOutlined /> : t('company:contacts.phone-number')}</label>
        <span>{contact.phoneNumber}</span>
      </div>
      {canResendInvite && contact.status == 'Pending' && (
        <Button onClick={() => onResendInviteClick?.(contact)}>
          {t('company:contacts.resend-invite')}
        </Button>
      )}
    </div>
  );
};
