import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { ProfileTypes } from '@inovirtue/profile';
import { addMinutes, addSeconds, differenceInSeconds, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { distinctUntilChanged, filter } from 'rxjs';
import {
  EXPIRATION_ALERT_SECONDS,
  EXTENSION_MINUTES,
  MAX_NUMBER_OF_EXTENSIONS,
  REMINDER_MINUTES,
} from '../../constants/videoCallExpiration';
import { currentProfile$ } from '../../store/participant';
import {
  fetchServerDate,
  getSessionExtendedNotification,
  onSessionExtended,
} from '../../store/video';
import { endDate$, sessionId$ } from '../../store/video-call-session';
import { SessionExtendedNotification } from '../../store/video/models/sessionExtendedNotification.model';

export interface VideoCallExpirationType {
  numberOfExtensions: number;
  timeLeftSeconds: number;
  timeLeftMinutes: number;
  showLeftMinutes: boolean;
  showSessionOver: boolean;
  showSessionExtendedEndCall: boolean;
  showSessionExtendedNotification: boolean;
  canExtendSession: boolean;
  extendSession: () => void;
  closeTimeLeftNotification: () => void;
  closeSessionExtendedNotification: () => void;
  closeSessionExtendedEndCallNotification: () => void;
  closeSessionOverScheduleNotification: () => void;
  showIsOverScheduleNotification: boolean;
}

export const useVideoCallExpiration = (): VideoCallExpirationType => {
  const [isTimeLeftClosed, setIsTimeLeftClosed] = useState<boolean>(false);
  const [isOverScheduleClosed, setIsOverScheduleClosed] = useState<boolean>(false);
  const [isSessionExtendedNotificationClosed, setIsSessionExtendedNotificationClosed] =
    useState<boolean>(true);
  const [
    isSessionExtendedEndCallNotificationClosed,
    setIsSessionExtendedEndCallNotificationClosed,
  ] = useState<boolean>(true);
  const [numberOfExtensions, setNumberOfExtensions] = useState<number>(0);
  const [initialServerTime, setInitialServerTime] = useState<Date | undefined>();
  const [serverTime, setServerTime] = useState<string>('');
  const [endDate, setEndDate] = useState<string | null>(useBehaviorSubjectState(endDate$));
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [timeLeftSeconds, setTimeLeftSeconds] = useState<number>(0);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const timeLeftMinutes = Math.floor(timeLeftSeconds / 60) + 1;
  const sessionId = useBehaviorSubjectState(sessionId$);
  const currentProfile = useBehaviorSubjectState(currentProfile$);
  const isProvider = currentProfile.type == ProfileTypes.ProviderProfile;
  const canExtendSession = isProvider;
  const checkInterval = 1000;

  const showLeftMinutes =
    !isTimeLeftClosed &&
    isSessionExtendedNotificationClosed &&
    numberOfExtensions === 0 &&
    timeLeftSeconds >= EXPIRATION_ALERT_SECONDS &&
    timeLeftSeconds < EXPIRATION_ALERT_SECONDS * REMINDER_MINUTES;

  const showSessionOver =
    isSubscribed &&
    !!serverTime &&
    numberOfExtensions !== MAX_NUMBER_OF_EXTENSIONS &&
    timeLeftSeconds <= EXPIRATION_ALERT_SECONDS &&
    canExtendSession;

  const showSessionExtendedEndCall =
    isSubscribed &&
    isSessionExtendedEndCallNotificationClosed &&
    numberOfExtensions === MAX_NUMBER_OF_EXTENSIONS &&
    timeLeftSeconds <= EXPIRATION_ALERT_SECONDS;

  const showSessionExtendedNotification =
    isSubscribed &&
    !canExtendSession &&
    !isSessionExtendedNotificationClosed &&
    isTimeLeftClosed &&
    numberOfExtensions !== 0;

  const notificationHandler = useCallback((notification: SessionExtendedNotification) => {
    setNumberOfExtensions((x) => (x += 1));
    setEndDate(new Date(notification.endTime).toISOString());
    setIsTimeLeftClosed(true);
    setIsSessionExtendedNotificationClosed(false);
  }, []);

  useEffect(() => {
    const getServerTime = async () => setInitialServerTime(new Date(await fetchServerDate()));
    getServerTime();
  }, []);

  useEffect(() => {
    if (!initialServerTime || endDate === null) return;
    const timerStartDate = new Date();
    const refreshServerTimeCheck = async () => {
      setServerTime(
        addSeconds(
          initialServerTime,
          Math.round((new Date().getTime() - timerStartDate.getTime()) / 1000),
        ).toISOString(),
      );
    };

    const intervalTimer = setInterval(() => refreshServerTimeCheck(), checkInterval);
    refreshServerTimeCheck();
    return () => clearInterval(intervalTimer);
  }, [initialServerTime, checkInterval, endDate]);

  useEffect(() => {
    if (endDate === null) {
      setIsExpired(false);
      return;
    }

    if (!serverTime || !endDate) return;

    const parsedEndDate = parseISO(endDate ?? '');
    const parsedServerTime = parseISO(serverTime ?? '');
    setIsExpired(!!parsedEndDate && parsedEndDate < parsedServerTime);
    setTimeLeftSeconds(differenceInSeconds(parsedEndDate, parsedServerTime));
  }, [endDate, serverTime]);

  const extendSession = useCallback(
    () => onSessionExtended(sessionId, addMinutes(parseISO(endDate ?? ''), EXTENSION_MINUTES)),
    [endDate, sessionId],
  );

  const closeTimeLeftNotification = useCallback(() => {
    setIsTimeLeftClosed(true);
  }, []);

  const closeSessionExtendedNotification = useCallback(() => {
    setIsSessionExtendedNotificationClosed(true);
  }, []);

  const closeSessionExtendedEndCallNotification = useCallback(() => {
    setIsSessionExtendedEndCallNotificationClosed(true);
  }, []);

  const closeSessionOverScheduleNotification = useCallback(() => {
    setIsOverScheduleClosed(true);
  }, []);

  useEffect(() => {
    if (showLeftMinutes && timeLeftMinutes == REMINDER_MINUTES - 1) closeTimeLeftNotification();
  }, [closeTimeLeftNotification, showLeftMinutes, timeLeftMinutes]);

  useEffect(() => {
    if (!currentProfile?.name || !sessionId) return;

    const observable = getSessionExtendedNotification();
    setIsSubscribed(!!observable);

    const sub = observable
      ?.pipe(
        distinctUntilChanged((prev, curr) => prev.endTime === curr.endTime),
        filter(
          (f) =>
            !!sessionId &&
            f.sessionId === sessionId &&
            f.profileName?.toLowerCase() == currentProfile.name.toLowerCase(),
        ),
      )
      .subscribe(notificationHandler);

    return () => {
      setIsSubscribed(false);
      sub?.unsubscribe();
    };
  }, [currentProfile?.name, sessionId, notificationHandler]);

  const showIsOverScheduleNotification = useMemo(
    () => isExpired && !isOverScheduleClosed,
    [isExpired, isOverScheduleClosed],
  );

  return {
    numberOfExtensions,
    timeLeftSeconds,
    timeLeftMinutes,
    showLeftMinutes,
    showSessionOver,
    showSessionExtendedEndCall,
    showSessionExtendedNotification,
    canExtendSession,
    extendSession,
    closeTimeLeftNotification,
    closeSessionExtendedNotification,
    closeSessionExtendedEndCallNotification,
    showIsOverScheduleNotification,
    closeSessionOverScheduleNotification,
  };
};
