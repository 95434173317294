import { Participant } from 'twilio-video';
import { Typography, Tooltip } from 'antd';
import useIsRecording from '../../hooks/useIsRecording/useIsRecording';
import usePublications from '../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import './MainParticipantInfo.Component.scss';
import { t } from '@inovirtue/localization';
import useParticipantDetails from '../../hooks/useParticipantDetails/useParticipantDetails';
import classNames from 'classnames';
import { room$ } from '../../store/video';
import { UserOutlined } from '@ant-design/icons';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar.Component';
import { useMemo } from 'react';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import { hasCameraEnabled } from '../../store/participant';

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  className?: string;
}

const MainParticipantInfo = ({
  participant,
  children,
  className,
}: MainParticipantInfoProps): JSX.Element => {
  const room = useBehaviorSubjectState(room$);
  const participantsCount = room?.participants?.size ?? 0;
  const localParticipant = room?.localParticipant;

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const screenSharePublication = publications.find((p) => p.trackName.includes('screen'));
  const isVideoEnabled = hasCameraEnabled(participant.identity);

  const isRecording = useIsRecording();

  const participantDetails = useParticipantDetails(participant);

  const mainParticipantClass = classNames(
    'main-participant-info',
    {
      'main-participant-info--transparent': !isRemoteParticipantScreenSharing,
    },
    className,
  );

  const displayName = useMemo(() => {
    return participantDetails?.displayName ?? participant?.identity;
  }, [participant?.identity, participantDetails?.displayName]);

  return (
    <div className={mainParticipantClass}>
      <div>
        {participantsCount <= 1 && <div className='main-participant-info__name'>{displayName}</div>}
        {isRecording && (
          <Tooltip title={t('recording_info')} placement='top'>
            <Typography data-testid='recording-indicator'>{t('recording')}</Typography>
          </Tooltip>
        )}
      </div>
      {!isVideoEnabled && !screenSharePublication && (
        <div className='main-participant-info__avatar'>
          <ProfileAvatar
            src={participantDetails?.avatarUrl}
            size={{ xs: 130, sm: 130, md: 130, lg: 200, xl: 200, xxl: 200 }}
            icon={<UserOutlined />}
          />
          <div className='main-participant-info__avatar-name'>{displayName}</div>
          {isParticipantReconnecting && (
            <div className='main-participant-info__avatar-reconnecting'>
              {t('videoCall:reconnecting')}
            </div>
          )}
        </div>
      )}
      {!isParticipantReconnecting && children}
    </div>
  );
};

export default MainParticipantInfo;
