import { Consumer } from '@inovirtue/admin';
import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useCallback, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { useCompanyEmployees } from '../../Modules/Companies/hooks/useCompanyEmployees';
import { useWorkshopDetails } from '../../Modules/Workshops/hooks/useWorkshopDetails';
import { useWorkshopMembersAdd } from '../../Modules/Workshops/hooks/useWorkshopMemberAdd';
import routes from '../routes/constants/Routes';

export const useWorkshopAddMembers = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const [selectedMembers, setSelectedMembers] = useState<Consumer[]>([]);

  const { workshop } = useWorkshopDetails(id);

  const { data: employees } = useCompanyEmployees(workshop?.selectedCompanyId);

  const navigateBack = useCallback(
    () => push(generatePath(routes.WorkshopDetailsMembers, { id })),
    [id, push],
  );

  const updateSelectedMembers = useCallback(
    (newMembers) => setSelectedMembers([...newMembers]),
    [],
  );

  const { addMembers } = useWorkshopMembersAdd();

  const saveChanges = useCallback(async () => {
    try {
      await addMembers(
        id,
        selectedMembers.map((x) => x.name),
      );
      showToast(t('common:save-success'));
      navigateBack();
    } catch (error) {
      showToast(t('errors:unhandled-error'));
    }
  }, [id, selectedMembers, navigateBack, addMembers]);

  return { employees, selectedMembers, updateSelectedMembers, saveChanges, navigateBack };
};
