import { Media } from '@twilio/conversations';

export const getMediaTempUrl = async (media: Media) => {
  try {
    const url = await media.getCachedTemporaryUrl();
    return url;
  } catch {
    const url = await media.getContentTemporaryUrl();
    return url;
  }
};

export const getBlobFile = async (media: Media) => {
  const url = await getMediaTempUrl(media);
  if (!url) return Promise.reject('Cannot resolve url.');

  const response = await fetch(url);
  return response.blob();
};
