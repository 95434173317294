import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { useContext } from 'react';
import { PopoverContext } from '../PopoverContext';

export interface PopoverFooterProps {
  actions?: ReactNode[];
  footerRef?: (node: HTMLUListElement | null) => void;
}

export const PopoverFooter = ({ actions: actionsProps, footerRef }: PopoverFooterProps) => {
  const { actions: actionsCtx, isFooterDivider } = useContext(PopoverContext);

  const footerCls = classNames('popover__footer', {
    'popover__footer--divider': isFooterDivider,
    'popover__footer--relative': !!footerRef,
  });

  const actions = actionsProps || actionsCtx;

  return (
    <>
      {actions && actions.length ? (
        <ul className={footerCls} ref={footerRef}>
          {actions.map((action, i) => {
            const key = (action as ReactElement).key;

            return (
              <li className='popover__footer-item' key={`popover-action-${key ?? i}`}>
                {action}
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};
