import { Locale, parseISO, formatRelative, isThisYear, format } from 'date-fns';
import { enUS } from 'date-fns/esm/locale';
import { resolvedLanguage } from '@inovirtue/localization';

const formatRelativeLocale = {
  lastWeek: 'MMM dd',
  yesterday: resolvedLanguage === 'en' ? "'Yesterday'" : "'Gestern'",
  today: resolvedLanguage === 'en' ? "'Today'" : "'Heute'",
  other: 'MMM dd',
};

const locale: Locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export const formatRelativeDate = (dateISO: string) => {
  const parsedDate = parseISO(dateISO);

  if (isThisYear(parsedDate)) return formatRelative(parsedDate, new Date(), { locale });

  return format(parsedDate, 'MMM dd, yyyy');
};
