import { Form, Select } from 'antd';
import './FilterCoaches.scss';
import { t } from '@inovirtue/localization';
import useFilterProvidersForm from '../../hooks/useFilterProvidersForm';
import { Button, Language, mappedLanguageIcons } from '@inovirtue/components';
import { SearchOutlined } from '@ant-design/icons';
import { FilterParams } from '../../models/filterParams';
import { Controller } from 'react-hook-form';

const { Option, OptGroup } = Select;

const languageIcons = mappedLanguageIcons;

export interface FilterCoachesFormProps {
  onFilterChange: (params: FilterParams) => void;
}

const FilterCoachesForm = ({ onFilterChange }: FilterCoachesFormProps): JSX.Element => {
  const { control, clearResults, categories, categoriesIsLoading, submitForm } =
    useFilterProvidersForm({
      onFilterChange,
    });

  return (
    <Form
      data-testid='filter-coaches-form'
      className={'filter-coaches'}
      name='filterCoaches'
      layout='horizontal'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={submitForm}
      autoComplete='off'
    >
      <div className='filter-coaches-container'>
        <Form.Item noStyle>
          <Controller
            name='tags'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={'filter-coaches-container__tags'}
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                loading={categoriesIsLoading}
                placeholder={t('consumer:coach-filters:select-expertise-tags')}
              >
                {categories &&
                  Array.from(categories.entries())
                    .filter((f) => f[0] !== 'additionalLanguages')
                    .map(([k, v]) => (
                      <OptGroup label={t(`tags:categories.${k}`)} key={k}>
                        {v.map((tag) => (
                          <Option key={tag} value={tag}>
                            {t(`tags:tags.${tag}`)}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
              </Select>
            )}
          />
        </Form.Item>
        <Form.Item noStyle>
          <Controller
            name='languages'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={'filter-coaches-container__tags'}
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                loading={categoriesIsLoading}
                placeholder={t('consumer:coach-filters:select-languages')}
              >
                {categories &&
                  Array.from(categories.entries())
                    .filter((f) => f[0] === 'additionalLanguages')
                    .map(([k, v]) => (
                      <OptGroup label={t(`tags:categories.${k}`)} key={k}>
                        {v.map((tag) => (
                          <Option key={tag} value={tag}>
                            <div className='filter-coaches-container__tags--language'>
                              {languageIcons[tag as Language]}
                              {t(`tags:tags.${tag}`)}
                            </div>
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
              </Select>
            )}
          />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            htmlType='submit'
            className={'filter-coaches-container__filter-button'}
            icon={<SearchOutlined />}
          >
            {t('consumer:coach-filters:filter-coaches')}
          </Button>
          <Button
            type='link'
            className={'filter-coaches-container__clear-button'}
            onClick={() => clearResults()}
          >
            {t('consumer:coach-filters:filter-clear')}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default FilterCoachesForm;
