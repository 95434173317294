import { useCallback } from 'react';
import { updateWorkshopMembers } from '../api/workshopApi';

export const useWorkshopMemberEdit = () => {
  const updateMembers = useCallback(async (companyId: string, members: string[]) => {
    await updateWorkshopMembers(companyId, members);
  }, []);

  return {
    updateMembers,
  };
};
