export const getInitials = (value: string, numberOfInitals = 2) => {
  const stringArr = value.toUpperCase().split(' ');
  const initialsArr = [];

  for (let i = 0; i < Math.min(stringArr.length, numberOfInitals); i++) {
    initialsArr.push(stringArr[i].charAt(0));
  }

  return initialsArr.join('');
};
