import { t } from '@inovirtue/localization';
import { useMemo } from 'react';
import { SelectedConvoSid } from '../../models/conversation';
import { BackOfficeRoles, ProfileType } from '../../models/participants';
import { selectIdentity } from '../../store/commonSlice';
import { selectConversationBySid } from '../../store/conversationsSlice';
import { selectAllParticipants, selectParticipantsByConvoId } from '../../store/participantsSlice';
import { useConvoSelector } from '../storeHooks/storeHooks';
import { useConversationType } from '../useConversationType/useConversationType';

export const useConversationDetails = (convoSid: SelectedConvoSid) => {
  const identity = useConvoSelector(selectIdentity);
  const convo = useConvoSelector(selectConversationBySid(convoSid));
  const participantEntity = useConvoSelector(selectParticipantsByConvoId(convoSid));
  const participants = useMemo(
    () => selectAllParticipants(participantEntity?.participatns),
    [participantEntity],
  );
  const { isOneOnOne, isWorkshop, isSupport } = useConversationType(convoSid);

  const receiver = useMemo(() => {
    if (isOneOnOne) return participants.find((element) => element.identity !== identity) || null;

    return null;
  }, [participants, isOneOnOne, identity]);

  const title = useMemo(() => {
    if (isSupport) return t('chat:company-support', { companyName: 'NWOW' });

    if (isWorkshop) return convo?.friendlyName || null;

    if (!receiver) return null;
    return receiver.attributes.displayName;
  }, [isWorkshop, isSupport, convo, receiver]);

  const role = useMemo(() => {
    if (!isOneOnOne || !receiver) return undefined;

    const profileType = receiver.attributes.profileType;
    const roles = receiver.attributes.roles;

    if (profileType === ProfileType.Provider) return t('chat:coach');

    if (
      roles &&
      profileType === ProfileType.BackOffice &&
      roles.includes(BackOfficeRoles.Consultant)
    )
      return t('chat:company-consultant', { companyName: 'NWOW' });

    return undefined;
  }, [isOneOnOne, receiver]);

  const companyName = useMemo(() => {
    if (!receiver) return undefined;

    return receiver.attributes.companyName;
  }, [receiver]);

  const avatarUrl = useMemo(() => {
    if (!receiver) return undefined;

    return receiver.attributes.avatarUrl;
  }, [receiver]);

  const lastMessageDate = useMemo(() => convo?.lastMessage.dateCreated, [convo]);

  return { title, role, companyName, avatarUrl, lastMessageDate };
};
