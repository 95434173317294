import { LayoutHeader } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { useHistory } from 'react-router';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import ProvidersList from '../components/ProvidersList/Providers.List';

const ProvidersView = () => {
  const { push } = useHistory();
  return (
    <BackofficeLayout
      headerContent={<LayoutHeader title={t('common:providers')} onNavigate={push} />}
      showHeader
    >
      <ProvidersList />
    </BackofficeLayout>
  );
};

export default ProvidersView;
