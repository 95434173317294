import { ReactNode } from 'react';
import { LayoutProvider } from '../../contexts/LayoutContext';
import { OnConvoSelect, SelectedConvoSid } from '../../models/conversation';
import { ConversationsSidebar } from '../ConversationsSidebar/ConversationsSidebar';
import './Conversations.scss';

export interface ConversationsProps {
  children: ReactNode;
  isTabView?: boolean;
  onConversationSelect?: OnConvoSelect;
  activeConvoSid?: SelectedConvoSid;
}

export const Conversations = ({
  children,
  onConversationSelect,
  isTabView = false,
  activeConvoSid,
}: ConversationsProps): JSX.Element => {
  return (
    <LayoutProvider {...{ isTabView, onConversationSelect, activeConvoSid }}>
      <div className='conversations'>{children}</div>
    </LayoutProvider>
  );
};

Conversations.Sidebar = ConversationsSidebar;
