import { useCallback, useEffect, useState } from 'react';

export function usePromiseWithProgress<T>(requestToHandle: () => Promise<T>): {
  isProcessing: boolean;
  data?: T;
  error?: unknown;
  process: () => Promise<void>;
} {
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<unknown>();

  const process = useCallback(async () => {
    setIsProcessing(true);
    try {
      setData(await requestToHandle());
    } catch (err) {
      setError(err);
    } finally {
      setIsProcessing(false);
    }
  }, [requestToHandle]);

  useEffect(() => {
    process();
  }, [process]);

  return {
    isProcessing,
    data,
    error,
    process,
  };
}
