import { Button } from '@inovirtue/components';
import { useEllipsisButton } from '@inovirtue/hooks';
import { Typography } from 'antd';
import { ReactNode } from 'react';
import { t } from '@inovirtue/localization';
import './AboutMe.scss';

const { Title, Paragraph } = Typography;

export interface AboutMeProps {
  description: ReactNode;
  rows: number;
}

const AboutMe = ({ description, rows }: AboutMeProps): JSX.Element => {
  const { isEllipsis, isReadMore, onEllipsis, onReadMore } = useEllipsisButton();

  return (
    <div className='about-me'>
      <Title className='about-me__title' level={2}>
        {t('profile:preview.titles.about-me')}
      </Title>
      <Paragraph
        className='about-me__description'
        ellipsis={isEllipsis ? { rows, onEllipsis } : false}>
        {description}
      </Paragraph>
      {isReadMore && <Button onClick={onReadMore}>{t('read-more')}</Button>}
    </div>
  );
};

export default AboutMe;
