import { Participant } from 'twilio-video';
import { ProfileParticipant } from './models/profileParticipant.model';
import { participantQuery } from './participant.query';
import { participantStore } from './participant.store';

export const setSelectedParticipant = (selectedParticipant: Participant | null): void => {
  participantStore.update((oldState) => ({
    ...oldState,
    selectedParticipant: selectedParticipant,
  }));
};

export const setScreenShareParticipant = (screenShareParticipant: Participant | null): void => {
  participantStore.update((oldState) => ({
    ...oldState,
    screenShareParticipant: screenShareParticipant,
  }));
};

export const setParticipantDetails = (identity: string, details: ProfileParticipant): void => {
  const participants = participantQuery.getValue().profileParticipants;
  participants.set(identity, details);
};

export const getParticipantDetails = (identity: string): ProfileParticipant | undefined => {
  const participants = participantQuery.getValue().profileParticipants;
  return participants.get(identity);
};

export const setCurrentProfile = (currentProfile: ProfileParticipant): void => {
  participantStore.update({ currentProfile });
};

export const addReconnectingParticipant = (participantIdentity: string): void => {
  participantStore.update((oldState) => ({
    ...oldState,
    reconnectingParticipants: [...oldState.reconnectingParticipants, participantIdentity],
  }));
};

export const removeReconnectingParticipant = (participantIdentity: string): void => {
  participantStore.update((oldState) => ({
    ...oldState,
    reconnectingParticipants: [
      ...oldState.reconnectingParticipants.filter((f) => f !== participantIdentity),
    ],
  }));
};

export const hasCameraEnabled = (participantIdentity: string): boolean => {
  const videoEnabledParticipants = participantQuery.getValue().cameraEnabledParticipants;
  return videoEnabledParticipants.includes(participantIdentity);
};

export const addCameraEnabledParticipant = (participantIdentity: string): void => {
  participantStore.update((oldState) => ({
    ...oldState,
    cameraEnabledParticipants: [...oldState.cameraEnabledParticipants, participantIdentity],
  }));
};

export const removeCameraEnabledParticipant = (participantIdentity: string): void => {
  participantStore.update((oldState) => ({
    ...oldState,
    cameraEnabledParticipants: [
      ...oldState.cameraEnabledParticipants.filter((f) => f !== participantIdentity),
    ],
  }));
};
