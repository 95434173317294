import {
  CustomerServiceFilled,
  FileExcelFilled,
  FileFilled,
  FilePdfFilled,
  FilePptFilled,
  FileWordFilled,
  FileZipFilled,
} from '@ant-design/icons';
import { ReactNode } from 'react';
import { FileExtensionEnum } from '../constants/extensions';
import { FileExtensionType } from '../models/extension';

export const mappedFileIcon: Record<FileExtensionType, ReactNode> = {
  [FileExtensionEnum.Word]: <FileWordFilled />,
  [FileExtensionEnum.Excel]: <FileExcelFilled />,
  [FileExtensionEnum.PowerPoint]: <FilePptFilled />,
  [FileExtensionEnum.Pdf]: <FilePdfFilled />,
  [FileExtensionEnum.Txt]: <FileFilled />,
  [FileExtensionEnum.Zip]: <FileZipFilled />,
  [FileExtensionEnum.Mp3]: <CustomerServiceFilled />,
};

export const getFileIcon = (extension: string | null): ReactNode => {
  if (!extension) return <FileFilled />;

  return mappedFileIcon[extension] || <FileFilled />;
};
