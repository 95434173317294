import { useForm } from 'react-hook-form';
import { Invitation, Invitations } from '../../model/InvitationsModel';
import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';

const defaultValues = {
  employeeId: '',
  firstName: '',
  lastName: '',
  email: '',
  positionId: '',
  departmentId: '',
};

export interface UseAddInvitationsParams {
  addInvitation: (invitation: Invitation) => void;
  invitationsList: Invitations;
}
const useAddInvitations = ({ addInvitation, invitationsList }: UseAddInvitationsParams) => {
  const { control, handleSubmit, reset } = useForm<Invitation>({
    defaultValues: {
      ...defaultValues,
    },
  });
  const onSubmit = (data: Invitation) => {
    addInvitation(data);
    reset(defaultValues);
  };
  const checkEmailDuplication = (value: string): boolean => {
    const success = !invitationsList.find((el: Invitation) => el.email === value);
    if (!success) showToast(t('common:email-already-added'), { type: 'error' });
    return success;
  };

  const checkEmployeeIdDuplication = (value: string): boolean => {
    const success = !invitationsList.find(
      (el: Invitation) => el.employeeId.toLocaleLowerCase() === value?.toLocaleLowerCase(),
    );
    if (!success) showToast(t('common:employee-id-already-added'), { type: 'error' });
    return success;
  };

  return {
    control,
    checkEmailDuplication,
    checkEmployeeIdDuplication,
    submiAction: handleSubmit(onSubmit),
  };
};

export default useAddInvitations;
