import './FileMessage.scss';
import { t } from '@inovirtue/localization';
import { Message } from '../Message/Message';
import { MessageBaseProps } from '../../models/message';
import { useMemo } from 'react';
import { getFileIcon } from '../../mappers/mappedFileIcon';
import classNames from 'classnames';
import { useMedia } from '../../hooks/useMedia/useMedia';
import { Spin } from 'antd';
import { FileExtensionEnum } from '../../constants/extensions';

export interface FileMessageProps extends MessageBaseProps {
  fileName: string | null;
  fileSize: string;
  extension: string | null;
}

export const FileMessage = ({
  message,
  fileName,
  fileSize,
  extension,
  convoSid,
  isSameAsPrev,
}: FileMessageProps): JSX.Element => {
  const { downloadMedia, isDownloading } = useMedia(message.sid);
  const fileIcon = useMemo(() => getFileIcon(extension), [extension]);

  const fileMessageIconCls = classNames('conversation-file-message__icon', {
    'conversation-file-message__icon--word': extension === FileExtensionEnum.Word,
    'conversation-file-message__icon--excel': extension === FileExtensionEnum.Excel,
    'conversation-file-message__icon--power-point': extension === FileExtensionEnum.PowerPoint,
  });

  return (
    <Message
      isSameAsPrev={isSameAsPrev}
      convoSid={convoSid}
      author={message.author}
      dateCreated={message.dateCreated}
      onClick={downloadMedia}
      title={t('chat:download')}>
      <div className='conversation-file-message' data-testid='conversation-file-message'>
        <div className={fileMessageIconCls}>{<Spin spinning={isDownloading}>{fileIcon}</Spin>}</div>
        <div className='conversation-file-message__content'>
          <div className='conversation-file-message__title'>{fileName}</div>
          <div className='conversation-file-message__size'>
            {t('chat:download')} | {fileSize}
          </div>
        </div>
      </div>
    </Message>
  );
};
