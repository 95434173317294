import './CompanyContactList.scss';
import { CompanyContact } from '../../models/CompanyContact';
import { CompanyContactItem } from '../CompanyContactItem/CompanyContactItem';
import {
  CompanyContactActionProps,
  CompanyContactsAccessProps,
} from '../CompanyContacts/CompanyContacts';

export type CompanyContactListProps = {
  contacts?: CompanyContact[];
} & CompanyContactsAccessProps &
  CompanyContactActionProps;

export const CompanyContactList = (props: CompanyContactListProps): JSX.Element => {
  const { contacts } = props;
  return (
    <div className='company-contact-list'>
      {contacts?.map((contact) => (
        <CompanyContactItem {...props} key={contact.id} contact={contact} />
      ))}
    </div>
  );
};
