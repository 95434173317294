import { Participant as TwilioParticipant } from '@twilio/conversations';
import { Override } from './override';

export type Participants = Record<string, TwilioParticipant[]>;

export enum ProfileType {
  Consumer = 'ConsumerProfile',
  Provider = 'ProviderProfile',
  BackOffice = 'BackOfficeProfile',
}

export enum BackOfficeRoles {
  Support = 'NWOW.BackOffice.Support',
  Consultant = 'NWOW.BackOffice.Consultant',
  Admin = 'NWOW.BackOffice.Admin',
}

export type ParticipantAttributes = {
  profileType: ProfileType;
  avatarUrl: string;
  displayName: string;
  companyName: string;
  roles: BackOfficeRoles[];
};

export type ParticipantPicked = Pick<TwilioParticipant, 'attributes' | 'identity' | 'sid'>;

export type Participant = Override<ParticipantPicked, { attributes: ParticipantAttributes }>;

export type ParticipantProperties = keyof Participant;

export const participantProperties: ReadonlyArray<ParticipantProperties> = [
  'attributes',
  'identity',
  'sid',
] as const;
