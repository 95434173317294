import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@inovirtue/components';
import classNames from 'classnames';
import { useDevicesInit } from '../../../hooks/useDevicesInit/useDevicesInit';
import { useMediaTracksSwitchOff } from '../../../hooks/useMediaTracksSwitchOff/useMediaTracksSwitchOff';
import { onRedirect } from '../../../store/video';
import DeviceSelectionMobile from '../../DeviceSelection/DeviceSelectionMobile.Component';
import './CallPrepStep.Component.scss';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../styles/breakpoints/breakpoints';
import DeviceSelection from '../../DeviceSelection/DeviceSelection.Component';

const CallPrepStep = (): JSX.Element => {
  const { switchOffMediaTracks } = useMediaTracksSwitchOff();
  useDevicesInit();
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mdBreak });

  const onBackHandler = () => {
    switchOffMediaTracks();
    onRedirect();
  };

  const deviceSelectionCls = classNames('callprepstep__device-selection');

  return (
    <>
      <Button
        className='callprepstep__back-button'
        icon={<ArrowLeftOutlined />}
        shape='circle'
        onClick={onBackHandler}
        data-testid='deviceSelectionBackBtn'
      />
      <div className='callprepstep'>
        {!isMobile && (
          <DeviceSelection
            {...{ isMobile }}
            className={deviceSelectionCls}
            onCancel={onBackHandler}
          />
        )}
        {isMobile && <DeviceSelectionMobile className={deviceSelectionCls} />}
      </div>
    </>
  );
};

export default CallPrepStep;
