import './WorkshopMemberSelect.scss';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { Avatar, Form, Select, Space } from 'antd';
import { useCallback } from 'react';
import { Consumer } from '../../model/Consumer';

export type WorkshopMemberSelectProps = {
  employees: Consumer[];
  onSelect: (employee: Consumer) => void;
  canAdd: boolean;
};

type FormData = {
  selectedEmployee?: string;
};

export const WorkshopMemberSelect = ({
  employees,
  onSelect,
  canAdd,
}: WorkshopMemberSelectProps) => {
  const [form] = Form.useForm();

  const onFormSubmit = useCallback(
    (formData: FormData) => {
      if (formData.selectedEmployee) {
        const foundMember = employees.find((x) => x.name == formData.selectedEmployee);
        if (foundMember) {
          onSelect(foundMember);
        }
      }
      form.resetFields();
    },
    [form, employees, onSelect],
  );

  return (
    <div className='group-member-select'>
      <Form form={form} onFinish={onFormSubmit} layout='inline'>
        <Form.Item name={['selectedEmployee']}>
          <Select
            className='group-member-select__member-select'
            showSearch
            allowClear
            placeholder={t('group:edit-group-members.placeholder')}
            optionLabelProp='title'
            filterOption={(input, option) => {
              const itemText = option?.title as string;
              return itemText.toLowerCase().includes(input.toLowerCase());
            }}
          >
            {employees.map((e) => (
              <Select.Option key={`select_${e.name}`} value={e.name} title={e.displayName}>
                <Space>
                  <Avatar size='small' src={e.avatarUrl} />
                  {e.displayName}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button disabled={!canAdd} htmlType='submit'>
          {t('common:add')}
        </Button>
      </Form>
    </div>
  );
};
