import { getApiClient } from '../../Common/api/apiClient';
import config from '../../../config';

export const getToken = async (
  name: string,
  roomName: string,
  roomType: string,
): Promise<string> => {
  const apiClient = await getApiClient(`${config.twilioVideoConfig.twilioTokenServerUrl}`);
  const { data } = await apiClient.post<{ token: string }>('token', {
    displayName: name,
    roomName,
    roomType,
    create_conversation: config.twilioVideoConfig.twilioConversationServiceDisabled,
  });

  return data?.token;
};
