import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

export const SignOut = (): JSX.Element => {
  const { instance } = useMsal();
  useEffect(() => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }, [instance]);

  return <></>;
};
