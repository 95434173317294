import './SearchProfileInfo.scss';
import { SearchProfileItem } from '../../models/SearchProfileItem';
import { Avatar } from 'antd';
import { ProfileTypes } from '@inovirtue/profile';

export interface SearchProfileInfoProps {
  profileInfo: SearchProfileItem;
  onClick: () => void;
}

const SearchProfileInfo = ({ profileInfo, onClick }: SearchProfileInfoProps): JSX.Element => {
  return (
    <>
      <div
        className='profile-info'
        onClick={onClick}
        data-testid={`profileInfo.${profileInfo.name}`}>
        <div className='profile-info__avatar'>
          <Avatar src={profileInfo.avatarUrl} />
        </div>
        <div className='profile-info__details'>
          <div className='profile-info__name'>{profileInfo.displayName}</div>
          <div className='profile-info__role'>
            {profileInfo.profileType === ProfileTypes.ProviderProfile
              ? 'Coach'
              : profileInfo.companyName}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchProfileInfo;
