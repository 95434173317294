import useSelectedParticipant from '../../hooks/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import './ParticipantList.Component.scss';
import classNames from 'classnames';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo.Component';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';

export interface ParticipantListProps {
  localParticipant: LocalParticipant;
  participants: RemoteParticipant[];
  className?: string;
}

const ParticipantList = ({
  localParticipant,
  participants,
  className,
}: ParticipantListProps): JSX.Element | null => {
  const selectedParticipant = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const isOneOnOne = participants?.length <= 1;

  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;
  const participantsListClass = classNames(
    'participant-list',
    {
      'participant-list--transparent': isRemoteParticipantScreenSharing,
    },
    className,
  );

  return (
    <div className={participantsListClass}>
      <ParticipantInfo participant={localParticipant} isLocalParticipant={true} />
      {participants.map((participant) => {
        const isSelected = participant === selectedParticipant;
        return (
          <ParticipantInfo
            key={participant.sid}
            participant={participant}
            isSelected={isSelected}
            shouldHideParticipant={isOneOnOne}
          />
        );
      })}
    </div>
  );
};

export default ParticipantList;
