export type OnlineStatus = 'Available' | 'Busy' | 'NotAvailable';

export type ProfileType = 'ConsumerProfile' | 'ProviderProfile';

export const ProfileTypes: { [key in ProfileType]: ProfileType } = {
    ConsumerProfile: 'ConsumerProfile',
    ProviderProfile: 'ProviderProfile',
} as const;

export interface Icebreaker {
    descriptiveWords: string[];
    recentlyRead: string;
    accomplishments: string;
    greatfulFor: string;
}

export interface UserProfile {
    id?: string;
    name?: string;
    displayName?: string;
    description?: string;
    aboutMe?: string;
    shortBio?: string;
    philosophy?: string;
    icebreaker?: Icebreaker;
    avatarUrl?: string;
    email?: string;
    onlineStatus?: OnlineStatus;
    type?: ProfileType;
    onboardingStep?: number;
    onboardingComplete?: boolean;
    tags?: string[];
}
