import { Company } from "../models/domain/Company";
import { CompanyDto } from "../models/dto/CompanyDto";

export const mapCompanyToCompanyDto = (input: Company): CompanyDto => {
    const { licenseStartDate, licenseEndDate, ...rest } = input;

    return {
        licenseStartDate: licenseStartDate?.format(),
        licenseEndDate: licenseEndDate?.format(),
        ...rest
    };
}