import { LayoutHeader } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { useHistory, useParams } from 'react-router';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import AssignmentRoot from '../components/Root/ConsumerEditRoot';

const ConsumerEdit = () => {
  const { name } = useParams<{ name: string }>();
  const { push } = useHistory();
  return (
    <BackofficeLayout
      headerContent={<LayoutHeader title={t('consumer:assign-providers')} onNavigate={push} />}
      showHeader
    >
      <AssignmentRoot profileName={name} />
    </BackofficeLayout>
  );
};

export default ConsumerEdit;
