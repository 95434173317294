import './WarningBar.scss';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Alert } from 'antd';
import classNames from 'classnames';

export type WarningBarProps = {
  highlitedText?: string;
  text?: string;
  level?: WarningBarLevel;
};

export type WarningBarLevel = 'warning' | 'info';
export const WarningBar = ({ highlitedText, text, level = 'info' }: WarningBarProps) => {
  const warningBarCls = classNames('warning-bar', `warning-bar__${level}`);
  return (
    <Alert
      className={warningBarCls}
      message={
        <>
          <ExclamationCircleFilled className={`warning-bar__${level}__icon`} />
          <span className='warning-bar__highlighted-text'>{highlitedText} </span>
          {text}
        </>
      }
    />
  );
};
