import './ConversationsItem.scss';
import { Badge } from 'antd';
import { ConversationBadge } from '../ConversationBadge/ConversationBadge';
import classNames from 'classnames';
import { formatRelativeDate } from '../../utils/formatRelativeDate';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';
import { ConvoType } from '../../models/conversation';
import { ConversationAvatar } from '../ConversationAvatar/ConversationAvatar';

export interface ConversationsItemProps {
  title: string;
  type?: ConvoType;
  avatarUrl?: string;
  unreadMessages?: number;
  lastMessageDate?: string | null;
  role?: string;
  companyName?: string;
  isActive?: boolean;
  isSelected?: boolean;
  className?: string;
  onClick?: () => void;
}

export const ConversationsItem = ({
  title,
  type,
  avatarUrl,
  unreadMessages,
  lastMessageDate,
  role,
  companyName,
  isActive = true,
  isSelected,
  className,
  onClick,
}: ConversationsItemProps): JSX.Element => {
  const { isTabView } = useLayoutContext();

  const conversationsItemCls = classNames(
    'conversations-item',
    {
      'conversations-item--selected': isSelected && !isTabView,
      'conversations-item--border': isTabView,
    },
    className,
  );
  const contentCls = classNames('conversations-item__content', {
    'conversations-item__content--end': isActive && companyName,
  });
  const dateCls = classNames('conversations-item__date', {
    'conversations-item__date--bold': unreadMessages,
  });

  return (
    <article data-testid='conversations-item' className={conversationsItemCls} onClick={onClick}>
      <ConversationAvatar className='conversations-item__avatar' {...{ title, type, avatarUrl }} />
      <div className={contentCls}>
        <h1 className='conversations-item__title'>{title}</h1>
        <ConversationBadge
          className='conversations-item__convo-badge'
          {...{ companyName, role, isActive }}
        />
      </div>
      <div className='conversations-item__info'>
        {!!unreadMessages && (
          <Badge className='conversations-item__count-badge' count={unreadMessages} />
        )}
        {!!lastMessageDate && (
          <span className={dateCls}>{formatRelativeDate(lastMessageDate)}</span>
        )}
      </div>
    </article>
  );
};
