import { LayoutHeader } from '@inovirtue/admin';
import { t } from '@inovirtue/localization';
import { useHistory } from 'react-router';
import BackofficeLayout from '../../../Layout/BackofficeLayout';
import { CompaniesList } from '../../../Modules/Companies/components/CompaniesList/CompaniesList';

const CompaniesListView = () => {
  const { push } = useHistory();
  return (
    <BackofficeLayout
      data-testid='companies-list-view'
      headerContent={<LayoutHeader title={t('common:companies')} onNavigate={push} />}
      showHeader
    >
      <CompaniesList />
    </BackofficeLayout>
  );
};

export default CompaniesListView;
