import moment from 'moment';
import format from 'date-fns/format';

export const longDateOnlyFormat = (date: moment.Moment | null): string =>
  date ? date.clone().format('LL') : '';

export const localeStringOrDefault = (
  date?: Date | string | undefined | null,
  defaultValue = '-',
) => (date ? new Date(date).toLocaleDateString() : defaultValue);

export const formatOrDefault = (
  date: Date | string | undefined | null,
  dateFnsFormat: string,
  defaultValue = '-',
) => (date ? format(new Date(date), dateFnsFormat) : defaultValue);
