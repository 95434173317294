import ParticipantList from '../ParticipantList/ParticipantList.Component';
import MainParticipant from '../MainParticipant/MainParticipant.Component';
import './Room.Component.scss';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { room$ } from '../../store/video';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import classNames from 'classnames';

const Room = (): JSX.Element => {
  const room = useBehaviorSubjectState(room$);
  const localParticipant = room?.localParticipant;
  const participants = useParticipants();

  const roomCls = classNames('room', {
    'room--single': localParticipant && participants.length === 1,
    'room--workshop': localParticipant && participants.length >= 2,
  });

  const mainParticipantCls = classNames('room__main-participant', {
    'room__main-participant--shrink': localParticipant && participants.length >= 2,
  });

  return (
    <div className={roomCls}>
      <MainParticipant className={mainParticipantCls} />
      {localParticipant && !!participants.length && (
        <ParticipantList
          className='room__participant-list'
          localParticipant={localParticipant}
          participants={participants}
        />
      )}
    </div>
  );
};

export default Room;
