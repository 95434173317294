import './WorkshopDetailsTabs.scss';
import { t } from '@inovirtue/localization';
import { Tabs } from 'antd';
import { useMemo } from 'react';

const { TabPane } = Tabs;

export type TabKey = 'details' | 'members' | 'sessions';

type TabsEntry = {
  label: string;
  url: string;
};

type WorkshopDetailsTabsProps = {
  id: string;
  currentTab: TabKey;
  onChange: (key: string) => void;
};

export const WorkshopDetailsTabs = ({ id, currentTab, onChange }: WorkshopDetailsTabsProps) => {
  const tabs = useMemo(() => {
    const baseUrl = `/workshops/${id}`;
    const defaultKey = `${baseUrl}/${currentTab}`;
    const mapping: Record<TabKey, TabsEntry> = {
      details: {
        url: `${baseUrl}/details`,
        label: t('group:group-details'),
      },
      members: {
        url: `${baseUrl}/members`,
        label: t('group:members'),
      },
      sessions: {
        url: `${baseUrl}/sessions`,
        label: t('group:sessions'),
      },
    };
    return { mapping, defaultKey };
  }, [id, currentTab]);

  return (
    <Tabs className='group-details-tabs' defaultActiveKey={tabs.defaultKey} onChange={onChange}>
      {Object.values(tabs.mapping).map(({ label, url }) => (
        <TabPane tab={label} key={url} tabKey={url} />
      ))}
    </Tabs>
  );
};
