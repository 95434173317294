import React from 'react';
import { Layout, Menu } from 'antd';
import './AppLayout.scss';
import { LogoutOutlined } from '@ant-design/icons';
import { t } from '@inovirtue/localization';
import { CreateMenuItemAntd } from '../../model/AntdMenuItem';
import { Button } from '@inovirtue/components';

const { Header, Content, Sider } = Layout;

export interface AppLayoutProps {
  children: React.ReactElement;
  headerContent?: React.ReactNode;
  menuItems: CreateMenuItemAntd[];
  onLogout: () => void;
  onNavigate: (path: string) => void;
  showHeader?: boolean;
  logo?: React.ReactElement;
  user?: {
    //Existing type should be used here instead, we don't need additional type here. Should be refactored.
    email: string;
    displayName: string;
  };
}
export const AppLayout = ({
  children,
  headerContent,
  onLogout,
  onNavigate,
  showHeader = true,
  logo,
  menuItems,
  user,
}: AppLayoutProps): JSX.Element => {
  return (
    <Layout className='app-layout' data-testid='app-layout'>
      <Sider className='app-layout__sidebar'>
        <div className='app-layout__logo'>{logo}</div>
        <Menu mode='inline' className='app-layout__navigation'>
          {menuItems.map((el) => (
            <Menu.Item key={el.path} onClick={() => onNavigate(el.path)} icon={el.icon}>
              {el.label}
            </Menu.Item>
          ))}
        </Menu>
        <footer className='app-layout__sidebar__footer'>
          {user && (
            <section className='app-layout__sidebar__footer__info'>
              <p className='app-layout__sidebar__footer__info__element'>{user.displayName}</p>
              <p className='app-layout__sidebar__footer__info__element'>{user.email}</p>
            </section>
          )}
          <Button
            icon={<LogoutOutlined />}
            onClick={onLogout}
            className='app-layout__sidebar__footer__logout'
          >
            {t('common:sign-out')}
          </Button>
        </footer>
      </Sider>
      <Layout className='app-body__layout'>
        {showHeader ? <Header className='app-body__header'>{headerContent}</Header> : null}
        <Content className='app-body__content'>{children}</Content>
      </Layout>
    </Layout>
  );
};
