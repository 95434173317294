import { NavLink } from 'react-router-dom';
import { t } from '@inovirtue/localization';

import './ProfileNavBar.scss';

const ProfileNavBar = ({ profileName }: { profileName: string }): JSX.Element => {
  return (
    <nav className='profileNavBar'>
      <NavLink
        to={`/coach/${profileName}/calendar`}
        className='nav__link'
        activeClassName='nav__link--active'>
        <span className='nav__link-content'>{t('home-nav.calendar')}</span>
      </NavLink>
      <NavLink
        to={`/coaches/${profileName}`}
        className='nav__link'
        activeClassName='nav__link--active'>
        <span className='nav__link-content'>{t('home-nav.profile')}</span>
      </NavLink>
    </nav>
  );
};

export default ProfileNavBar;
