import './WorkshopFormHeader.scss';

type WorkshopFormHeaderProps = {
  headerText: string;
  subheaderText: string;
};
const WorkshopFormHeader = ({ headerText, subheaderText }: WorkshopFormHeaderProps) => (
  <div className='group-form-header'>
    <p className='group-form-header__main'>{headerText}</p>
    <p className='group-form-header__sub'>{subheaderText}</p>
  </div>
);

export default WorkshopFormHeader;
