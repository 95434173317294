import { useState } from 'react';
import { useEventListener } from '@inovirtue/hooks';

export default function useHeight() {
  const [height, setHeight] = useState(window.innerHeight * (window.visualViewport?.scale || 1));

  const onResize = () => {
    setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
  };

  useEventListener('resize', onResize);
  return height + 'px';
}
