import { CloseCircleFilled } from '@ant-design/icons';
import { Popconfirm, Select } from 'antd';
import useProviderSerchable from '../../hooks/useProviderSerchable';
import { t } from '@inovirtue/localization';
import './ProvidersSearchSelect.scss';

const { Option } = Select;

export interface ProviderSearchSelectProps {
  changeAction: (value: string) => void;
  onClear: () => void;
}

const ProvidersSeachSelect = ({
  changeAction,
  onClear,
}: ProviderSearchSelectProps): JSX.Element => {
  const {
    loadCoaches,
    onChange,
    onSearch,
    providers,
    selectValue,
    confirmRemoveCoach,
    showPopupConfirm,
    cancelRemoveCoach,
    setShowPopupConfirm,
  } = useProviderSerchable();

  return (
    <div className='providers-select-container'>
      <Select
        showSearch
        loading={loadCoaches}
        value={selectValue}
        placeholder='Select a person'
        className='providers-select-container__select'
        optionFilterProp='children'
        onChange={(value) => {
          onChange(value);
          changeAction(value);
        }}
        onSearch={onSearch}
      >
        {providers.map((provider) => {
          return (
            <Option key={provider.value} value={provider.value}>
              {provider.name}
            </Option>
          );
        })}
      </Select>
      {selectValue && (
        <Popconfirm
          className='providers-select-container__select__icon'
          overlayClassName='providers-select-container__clear-popup'
          title={t('scheduler:workshops:clear-coach-confirmation')}
          visible={showPopupConfirm}
          onConfirm={() => {
            confirmRemoveCoach();
            onClear();
          }}
          onCancel={cancelRemoveCoach}
          placement='topRight'
          okText={t('scheduler:workshops:confirm-clear-coach')}
          okButtonProps={{ danger: true }}
          cancelText={t('scheduler:workshops:cancel-clear-coach')}
        >
          <CloseCircleFilled onClick={() => setShowPopupConfirm(true)} />
        </Popconfirm>
      )}
    </div>
  );
};

export default ProvidersSeachSelect;
