import moment from 'moment';

export const dateFromString = (value: moment.MomentInput): moment.Moment | null => {
    const parsedDate = moment(value);
    if (parsedDate.isValid()) {
        return parsedDate;
    }
    return null;
};

export const dateWithoutTimezone = (value: moment.Moment): moment.Moment => {
    return moment()
        .date(value.date())
        .month(value.month())
        .year(value.year())
        .utcOffset(0)
        .startOf('day');
};
