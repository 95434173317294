import React from 'react';
import ReactDOM from 'react-dom';
import AppFactory from './App/factory/AppFactory';
import { worker } from './mocks/browser';
import config from './config';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from 'react-query';

if (config.apiMocks.enabled) {
  worker.start();
}

const msalInstance = new PublicClientApplication(config.msalConfig.msalAuthConfig);
const App = AppFactory();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
