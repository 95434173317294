import './Button.scss';
import React from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import classNames from 'classnames';

export interface ButtonProps extends Omit<AntButtonProps, 'shape'> {
  shape?: Exclude<AntButtonProps['shape'], 'round'> | 'square';
  clear?: boolean;
}

const mappedButtonShape = new Map<ButtonProps['shape'], AntButtonProps['shape']>()
  .set('default', 'round')
  .set('square', 'default')
  .set('circle', 'circle');

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ children, shape, className, clear, ...props }, ref) => {
    const buttonShape = mappedButtonShape.get(clear ? 'square' : shape) || 'round';

    const buttonCls = classNames({ 'button--clear': clear }, className);

    return (
      <AntButton className={buttonCls} shape={buttonShape} {...props} ref={ref}>
        {children}
      </AntButton>
    );
  },
);

Button.displayName = 'Button';
