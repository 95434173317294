import { usePromiseWithProgress } from '@inovirtue/hooks';
import { useCallback } from 'react';
import { getConsultants } from '../api/companiesApi';
import { Consumer } from '@inovirtue/admin';

export const useConsultantList = () => {
  const fetchContacts = useCallback(() => getConsultants(), []);
  const { data, isProcessing, process } = usePromiseWithProgress<Consumer[]>(fetchContacts);

  const refresh = useCallback(() => {
    process();
  }, [process]);

  return {
    consultants: data,
    isProcessing,
    refresh,
  };
};
