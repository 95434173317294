import { t } from '@inovirtue/localization';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { WorkshopSlot } from '../../model/SlotModel';
import './SessionTile.scss';

export interface SessionTileProps extends WorkshopSlot {
  onClickActions: (id: string) => void;
}

const SessionTile = ({
  number,
  appoitmentsDetails,
  active,
  id,
  disabled,
  error,
  onClickActions,
}: SessionTileProps): JSX.Element => {
  const classNmaes = classNames('session-tile ', {
    'session-tile--active': active,
    'session-tile--disabled': disabled,
    'session-tile--error': error,
  });

  return (
    <>
      <div
        className={classNmaes}
        onClick={() => {
          if (disabled) return;
          onClickActions(id);
        }}>
        <p className='session-tile__subtitle'>
          {t('common:session')} {number}
        </p>
        {appoitmentsDetails ? (
          <>
            <h3 className='session-tile__title'>
              {dayjs(appoitmentsDetails.startDateTime).format('dddd, DD MMMM YYYY')}
            </h3>
            <p className='session-tile__details'>
              {dayjs(appoitmentsDetails.startDateTime).format('HH:mm')} -
              {dayjs(appoitmentsDetails.endDateTime).format('HH:mm')}
            </p>
          </>
        ) : (
          <h3 className='session-tile__title'>{t('scheduler:workshops:not-scheduled-yet')}</h3>
        )}
      </div>
      {error && <p className='session-tile__error'>{t('scheduler:workshops:unavailable-slot')}</p>}
    </>
  );
};

export default SessionTile;
