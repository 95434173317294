import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { disableFullPageLoader, enableFullPageLoader } from '../../../App/state';
import { InitializationStatus } from '../../models/InitializationStatus';
import { getAccountProfileNames } from '../../../Modules/Account/api/accounts';
import {
  setCurrentProfileDisplayName,
  setCurrentProfileName,
} from '../../../Modules/Profile/store';

export const useAppEntry = () => {
  const { inProgress, instance } = useMsal();
  const [initStatus, setInitStatus] = useState<InitializationStatus>(InitializationStatus.pending);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      enableFullPageLoader();
      const [account] = instance.getAllAccounts();
      if (account) {
        const init = async () => {
          try {
            const fetchProfiles = async () => {
              const [profileName] = await getAccountProfileNames(account.localAccountId);
              if (profileName) {
                setCurrentProfileName(profileName);
                setCurrentProfileDisplayName(account?.name ?? '');
              }
            };

            await fetchProfiles();
          } finally {
            setInitStatus(InitializationStatus.completed);
            disableFullPageLoader();
          }
        };

        init();
      } else {
        setInitStatus(InitializationStatus.rejected);
        disableFullPageLoader();
      }
    }
  }, [instance, inProgress]);

  return { initStatus };
};
