import { useConversationsContext } from '@inovirtue/chat';
import { showToast } from '@inovirtue/components';
import { t } from '@inovirtue/localization';
import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';
import routes from '../../../../App/routes/constants/Routes';

export const useWorkshopChatButton = () => {
  const { push } = useHistory();
  const { client } = useConversationsContext();

  const onClick = useCallback(
    async (workshopId: string) => {
      try {
        const convo = await client?.getConversationByUniqueName(workshopId);
        push(generatePath(routes.Chat, { sid: convo?.sid }));
      } catch {
        showToast(t('errors:entity_not_found'));
      }
    },
    [push, client],
  );

  return onClick;
};
