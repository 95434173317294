import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { getPartnerById } from '../../Modules/Partners/api/partnersApi';

const usePartnerDetails = (id?: string) => {
  const getPartnerDetails = useCallback(
    () => (id ? getPartnerById(id) : Promise.resolve(undefined)),
    [id],
  );
  const { data, isLoading } = useQuery(['get_partner', id], getPartnerDetails);

  return {
    isLoading,
    partner: data,
  };
};

export default usePartnerDetails;
