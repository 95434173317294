import './ConversationsSidebar.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useLayoutContext } from '../../hooks/useLayoutContext/useLayoutContext';

export interface ConversationsSidebarProps {
  children: ReactNode;
}

export const ConversationsSidebar = ({ children }: ConversationsSidebarProps): JSX.Element => {
  const { isConversationsList, isTabView } = useLayoutContext();

  const conversationsSidebarCls = classNames('conversations-sidebar', {
    'conversations-sidebar--tabview': isTabView,
  });

  return (
    <>
      {isConversationsList && (
        <div className={conversationsSidebarCls} data-testid='conversations-sidebar'>
          {children}
        </div>
      )}
    </>
  );
};
