import { Input, Table as AntdTable } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from '@inovirtue/localization';
import { FilterValue } from 'antd/lib/table/interface';

import './Table.scss';

const { Search } = Input;

export type TableSearchProps = {
  searchInputPlaceholder?: string;
  onSearch(searchString: string): void | Promise<void>;
};

export type TablePaginationSettings = {
  pageIndex: number;
  setPageIndex(newIndex: number): void;
  pageSize: number;
  total: number;
};

export interface BaseTableProps<T> {
  onFilterChange?: (filters: Record<string, FilterValue | null>) => void;
  tableName: string;
  columns: ColumnsType<T>;
  rowKey: string;
  beforeTable?: JSX.Element;
}

export interface TableProps<T> extends BaseTableProps<T> {
  searchSettings?: TableSearchProps;
  pagination: TablePaginationSettings;
  isProcessing: boolean;
  entries: T[];
}

export const Table = <T extends object>({
  tableName,
  rowKey,
  columns,
  isProcessing,
  searchSettings,
  pagination,
  beforeTable,
  entries,
  onFilterChange,
}: TableProps<T>): JSX.Element => (
  <div className='inovirtue-table'>
    {beforeTable}
    <div className='inovirtue-table__header'>
      {tableName}
      {searchSettings && (
        <div className='inovirtue-table__search'>
          <Search
            data-testid='table-search-input'
            allowClear
            onSearch={searchSettings.onSearch}
            placeholder={searchSettings.searchInputPlaceholder}
          />
        </div>
      )}
    </div>
    <AntdTable
      loading={isProcessing}
      dataSource={entries}
      rowKey={rowKey}
      tableLayout='fixed'
      onChange={(_, filters) => onFilterChange?.(filters)}
      pagination={{
        onChange: (x: number) => {
          pagination.setPageIndex(x - 1);
        },
        pageSize: pagination.pageSize,
        showSizeChanger: false,
        total: pagination.total,
        showTotal: () => (
          <span>
            {t('table:pagination.total')}: {pagination.total}
          </span>
        ),
      }}
      columns={columns}
      scroll={{ y: 500 }}
    />
  </div>
);
