import { Redirect, Route, Switch } from 'react-router';
import { SignIn } from '../components/SignIn';

export const AnonymousRouting = (): JSX.Element => (
  <Switch>
    <Route exact path={'/sign-in'} component={SignIn} />
    <Route>
      <Redirect to='/sign-in' />
    </Route>
  </Switch>
);
