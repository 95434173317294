import { MessageFilled, MessageOutlined } from '@ant-design/icons';
import { useUnreadMessages } from '@inovirtue/chat';
import { useBehaviorSubjectState } from '@inovirtue/hooks';
import { Badge } from 'antd';
import { useCallback, useMemo } from 'react';
import { isChatOpen$, toggleIsChatOpen } from '../../store/video';

export const useChatButton = (convoSid: string) => {
  const isChatOpen = useBehaviorSubjectState(isChatOpen$);
  const unreadMessages = useUnreadMessages(convoSid);

  const icon = useMemo(
    () => (
      <>
        {isChatOpen && <MessageFilled />}
        {!isChatOpen && (
          <Badge count={unreadMessages} offset={[2, -2]} data-testid='unread-messages-badge'>
            <MessageOutlined />
          </Badge>
        )}
      </>
    ),
    [isChatOpen, unreadMessages],
  );

  const onClick = useCallback(() => toggleIsChatOpen(), []);

  return { icon, onClick };
};
