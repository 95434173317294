import React from 'react';
import classNames from 'classnames';
import { useContext } from 'react';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { PopoverProps } from '../Popover.model';
import { PopoverContext } from '../PopoverContext';
import { Button } from '../../Button/Button';

const renderButton = (
  isCloseIcon: PopoverProps['isCloseIcon'],
  onClose: PopoverProps['onClose'],
  onBack: PopoverProps['onBack'],
  isBack: PopoverProps['isBack'],
) => {
  if (!(isCloseIcon && onClose)) return null;

  if (onBack && isBack) {
    return (
      <Button
        icon={<ArrowLeftOutlined />}
        shape='circle'
        onClick={onBack}
        data-testid='popover-back-btn'
      />
    );
  } else {
    return (
      <Button
        icon={<CloseOutlined />}
        shape='circle'
        onClick={onClose}
        data-testid='popover-close-btn'
      />
    );
  }
};

const PopoverHeader = (): JSX.Element => {
  const { title, headerLeft, headerRight, isHeaderDivider, isCloseIcon, onClose, onBack, isBack } =
    useContext(PopoverContext);

  const headerCls = classNames('popover__header', {
    'popover__header--divider': isHeaderDivider,
  });

  return (
    <div className={headerCls}>
      <div className='popover__header-left'>
        {renderButton(isCloseIcon, onClose, onBack, isBack)}
        {headerLeft}
      </div>
      {title}
      <div className='popover__header-right'>{headerRight}</div>
    </div>
  );
};

export default PopoverHeader;
