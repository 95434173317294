export interface GenericLocaleDict<T> {
  en: T;
  [key: string]: T;
}
export const getBrowserLanguage = <T>(value: GenericLocaleDict<T>): T => {
  const browserLang = navigator.language.split('-')[0];
  return Object.keys(value).includes(browserLang)
    ? value[browserLang as keyof typeof value]
    : value.en;
};
