import { Card, Typography } from 'antd';
import { ReactNode } from 'react';
import './IcebreakerItem.scss';

const { Title, Paragraph } = Typography;

export interface IcebreakerItemProps {
  icon: ReactNode;
  title: string;
  description: ReactNode;
}

const IcebreakerItem = ({ icon, title, description }: IcebreakerItemProps): JSX.Element => {
  return (
    <Card className='icebreaker-item'>
      {icon}
      <Title className='icebreaker-item__title' level={3}>
        {title}
      </Title>
      <Paragraph className='icebreaker-item__description'>{description}</Paragraph>
    </Card>
  );
};

export default IcebreakerItem;
