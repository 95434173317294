import './CompanyContactDeleteForm.scss';
import { CompanyContactItem, CompanyContact } from '@inovirtue/company';
import { Button } from '@inovirtue/components';
import { t } from '@inovirtue/localization';

export type CompanyContactDeleteFormProps = {
  contact: CompanyContact;
  onDelete: (contact: CompanyContact) => void;
};

export const CompanyContactDeleteForm = ({ contact, onDelete }: CompanyContactDeleteFormProps) => {
  return (
    <div className='company-contact-delete-form'>
      <span className='company-contact-delete-form__question'>
        {t('company:contacts.delete-contact-question')}
      </span>
      <CompanyContactItem contact={contact} showIcons={true} />
      <Button
        className='company-contact-delete-form__remove-button'
        danger
        onClick={() => onDelete(contact)}
      >
        {t('common:remove')}
      </Button>
    </div>
  );
};
