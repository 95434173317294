import { t } from '@inovirtue/localization';
import { format, parseISO } from 'date-fns';
import { DataIcon } from '../../icons/DataIcon';
import { Workshop } from '../../model/Workshop';
import { CardField } from '../CardField/CardField';
import { DetailsCardBase } from '../DetailsCardBase/DetailsCardBase';

export type WorkshopDataProps = {
  workshop: Workshop;
  showCompany: boolean;
};

const groupDatesFormat = 'dd/MM/yyyy';

export const WorkshopData = ({ workshop, showCompany = false }: WorkshopDataProps) => {
  return (
    <DetailsCardBase header={t('group:group-data')} icon={<DataIcon />}>
      <>
        <CardField
          label={t('group:status.title')}
          value={t(`group:status.${workshop.status.toLowerCase()}`)}
        />
        <CardField
          label={t('group:group-goal')}
          value={`${workshop.sessionsInTotal.toString()} ${t('group:sessions').toLowerCase()}`}
        />
        {showCompany && (
          <CardField label={t('common:company')} value={workshop.selectedCompanyName || ''} />
        )}
        <CardField
          label={t('common:start-date')}
          value={workshop.startDate ? format(parseISO(workshop.startDate), groupDatesFormat) : ''}
        />
        <CardField
          label={t('common:end-date')}
          value={workshop.endDate ? format(parseISO(workshop.endDate), groupDatesFormat) : ''}
        />
      </>
    </DetailsCardBase>
  );
};
